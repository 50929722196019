import React from 'react';
import { Agency } from '../../app/api/mapper';
import BreadCrumb from './BreadCrumb';
import { provider, useInstances } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { AgencyModel } from '@assets/models/agencies/Agency.model';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { MandateStore } from '../../Stores/Mandate.store';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { HoverDropdown } from '../../ui/HoverDropdown';

type LocalStore = [AgenciesStore, MandateStore, AgencyInvoiceStore];

const LeftBlock = provider()(observer(() => {
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();

  const [
    agenciesStore,
    mandateStore,
    agencyInvoiceStore,
  ]: LocalStore = useInstances<LocalStore>(AgenciesStore, MandateStore, AgencyInvoiceStore);

  const handleSelectAgency = (objSelected: string) => {
    const selectedAgency: AgencyModel | undefined = agenciesStore.agenciesList.find((agency: Agency) => agency.agencyName === objSelected);

    if (selectedAgency) {
      agencyInvoiceStore.reset();
      mandateStore.reset();
      agenciesStore.setCurrentAgency(selectedAgency);
      navigate('/dashboard');
    }
    return;
  };
  return (
    <div className="relative flex items-center">
      <HoverDropdown className={'h-12'} variant={'green'} beforeArrow={agenciesStore.currentAgency?.agencyName || 'Sélectionnez une agence'}>
        {(agenciesStore.currentAgency && !!agenciesStore.agenciesList.length) &&
          (<ul className="bg-white shadow-2xl text-primary rounded-br10 px-0 m-0 mt-2 py-4 space-y-2">
            {agenciesStore.agenciesList.map((agency, index) => (
              <li
                key={index}
                onClick={() => handleSelectAgency(agency.agencyName)}
                className="px-4 h-10 text-sm flex items-center justify-start cursor-pointer hover:bg-green/50 transition-colors"
              >
                {agency.agencyName}
              </li>
            ))}
          </ul>)
        }
      </HoverDropdown>
      <BreadCrumb className="z-0 h-12" navigate={navigate} pathname={location.pathname}/>
    </div>
  );
}));

export default LeftBlock;

// Pour Mobile a mettre juste apres la premiere div
// <IconButton
//   color="inherit"
//   aria-label="open drawer"
//   edge="start"
//   onClick={props.setMobileOpen}
// >
//   <svg viewBox="0 0 100 80" width="40" height="40">
//     <rect width="100" height="20" fill={COLORS.primary}/>
//     <rect y="30" width="100" height="20" fill={COLORS.primary}/>
//     <rect y="60" width="100" height="20" fill={COLORS.primary}/>
//   </svg>
// </IconButton>
