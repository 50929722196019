import { makeAutoObservable, runInAction } from 'mobx';
import { CreateAgencyOrderRequest } from '../assets/requests/orders/CreateAgencyOrder.request';
import { OrderBankWireReference } from '../assets/models/orders/OrderBankWireReference.model';
import { ConfirmAgencyOrderResponse } from '../assets/responses/orders/ConfirmAgencyOrder.response';
import { onCall } from '../Services/firebase.service';
import { errorStore } from './Error.store';

export class PayingServiceStore {
  bankWireReference: OrderBankWireReference = null;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  reset() {
    this.bankWireReference = null;
  }

  async createOrder(orderToCreate: CreateAgencyOrderRequest): Promise<boolean> {
    try {
      const createOrderResponse: ConfirmAgencyOrderResponse = await onCall('ORDERS-createAgencyOrder_onCall', orderToCreate);
      runInAction(() => {
        this.bankWireReference = createOrderResponse.bankWireReference;
      });
      return true;
    } catch (error) {
      errorStore.catchError(error);
      return false;
    }
  }
}
