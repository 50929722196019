/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CompanyUser } from './CompanyUser';

export type CompanyUserRequestResult = {
    errorCode?: CompanyUserRequestResult.errorCode;
    errorMessage?: string | null;
    result?: CompanyUser;
}

export namespace CompanyUserRequestResult {

    export enum errorCode {
        NONE = 'None',
        USER_UNAUTHORIZED = 'UserUnauthorized',
        INVALID_TOKEN = 'InvalidToken',
        INVALID_TICKET = 'InvalidTicket',
        INVALID_ENTITY = 'InvalidEntity',
        INVALID_FILE = 'InvalidFile',
        INVALID_IBAN = 'InvalidIban',
        REGISTRATION_NUMBER = 'RegistrationNumber',
        BAD_REQUEST = 'BadRequest',
        CONFLICT = 'Conflict',
        INTERNAL_SERVER = 'InternalServer',
    }


}
