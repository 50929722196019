import clsx from 'clsx';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

export const RecapBlock = ({ children, className }: Props) => {
  return (
    <div className={clsx('border-l-0 border-t-0 border-b-0 border-r border-secondary/30 border-solid ' +
      'flex px-8 gap-10', className)}>
      {children}
    </div>
  );
};
