import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { provider, useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { AccountingViewStore } from '../Stores/viewStore/AccountingView.store';
import { AgenciesStore } from '../Stores/Agencies.store';
import { AgencyModel } from '@assets/models/agencies/Agency.model';
import InvoicesComponent from './tabs/Invoices/InvoicesComponent';
import MillesimeReceiptsComponent from './tabs/MillesimeReceipts/MillesimeReceiptsComponent';
import DeactivationsReportComponent from './tabs/Deactivations/DeactivationsReportComponent';
import { PageBlock } from 'ui/layout/PageBlock';
import { SectionWrapper } from '../ui/layout/SectionWrapper';
import { TabBar, TabDefinition } from '../ui/TabBar';

const AccountingPage: FunctionComponent = provider(AccountingViewStore)(observer<AgenciesStore>(() => {
  const agenciesStore: AgenciesStore = useInstance<AgenciesStore>(AgenciesStore);
  const [accountingViewStore, setAccountingViewStore] = useState<AccountingViewStore | null>(null);

  const { t } = useTranslation('accounting');
  const location = useLocation();

  const tabs: TabDefinition[] = [
    {
      label: t('pageSubtitle'),
      path: '/accounting/invoices',
      Component: Link,
      isVisible: () => true,
    },
    {
      label: t('tabMillesimeReceipt'),
      path: '/accounting/millesime-receipts',
      Component: Link,
      isVisible: () => true,
    },
    {
      label: t('tabDeactivations'),
      path: '/accounting/deactivations',
      Component: Link,
      isVisible: () => true,
    },
  ];

  useEffect(() => {
    const agency: AgencyModel | undefined = agenciesStore.currentAgency;
    if (agency) {
      setAccountingViewStore(new AccountingViewStore(agency));
    } else {
      setAccountingViewStore(null);
    }
  }, [agenciesStore.currentAgency]);

  return (
    <>
      {accountingViewStore &&
        <PageBlock>
          <SectionWrapper>
            <h1>{t('pageTitle')}</h1>
            <TabBar tabs={tabs} />

            {location.pathname === '/accounting/invoices'
              && <InvoicesComponent accountingStore={accountingViewStore}/>
            }

            {location.pathname === '/accounting/millesime-receipts'
              && <MillesimeReceiptsComponent accountingStore={accountingViewStore}/>
            }

            {location.pathname === '/accounting/deactivations'
              && <DeactivationsReportComponent accountingStore={accountingViewStore} agenciesStore={agenciesStore}/>
            }
          </SectionWrapper>
        </PageBlock>
      }
    </>
  );
}));

export default AccountingPage;