import React, { FC, ReactNode } from 'react';
import { AuthPanel } from './AuthPanel';

interface Props {
  children: ReactNode;
}

export const AuthLayout: FC<Props> = ({children}) => {
  return (
    <div className={'flex'}>
      <AuthPanel/>
      <div className={'flex items-center flex-col justify-center w-full md:w-1/2'}>
        <div className={'p-4 w-full min-h-96'}>
          {children}
        </div>
      </div>
    </div>
  )
}