import { callable } from './httpWrapper.service';
import { CreateAgencyOrderRequest } from '../assets/requests/orders/CreateAgencyOrder.request';
import { ConfirmAgencyOrderResponse } from '../assets/responses/orders/ConfirmAgencyOrder.response';
import { OptionalTimePeriodRequest } from '../assets/requests/TimePeriod.request';
import { PaginationResponse } from '@assets/responses/PagingCollection.response';
import { BeneficiaryCreditModel } from '@assets/models/orders/Order.model';
import { PaginationRequest } from '@assets/requests/PagingCollection.request';
import {
  GetAgencyOrderProvisionSummaryResponse
} from '@assets/responses/orders/GetAgencyOrderProvisionSummary.response';
import {
  convertBeneficiaryCreditFromCentsToEuros,
  convertConfirmAgencyOrderResponse,
  convertGetAgencyOrderProvisionSummaryResponseFromCentsToEuros,
} from '../assets/utils/orders/order.util';

export async function getAgencyOrderProvision(agencyId: string, orderId: string, request: PaginationRequest<BeneficiaryCreditModel>): Promise<PaginationResponse<BeneficiaryCreditModel>> {
  return callable(
    'getAgencyOrderProvision',
    { agencyId, orderId },
    request,
  ).then(response => {
    const beneficiariesCreditsInEuros: BeneficiaryCreditModel[] = response.items.map(convertBeneficiaryCreditFromCentsToEuros);
    return {
      ...response,
      items: beneficiariesCreditsInEuros,
    }
  });
}

export async function getAgencyOrderProvisionSummary(agencyId: string, orderId: string): Promise<GetAgencyOrderProvisionSummaryResponse> {
  return callable(
    'getAgencyOrderProvisionSummary',
    { agencyId, orderId }
  ).then(convertGetAgencyOrderProvisionSummaryResponseFromCentsToEuros);
}

export async function createAgencyOrder (requestBody: CreateAgencyOrderRequest): Promise<ConfirmAgencyOrderResponse> {
  return callable(
    'createAgencyOrder',
    requestBody,
  ).then(convertConfirmAgencyOrderResponse);
}

export async function getBeneficiaryProvisions(agencyId: string, beneficiaryId: string, requestBody: OptionalTimePeriodRequest): Promise<BeneficiaryCreditModel[]> {
  return callable(
    'getBeneficiaryProvisions',
    { agencyId, beneficiaryId },
    requestBody,
  ).then(beneficiaryCredits => beneficiaryCredits.map(convertBeneficiaryCreditFromCentsToEuros));
}
