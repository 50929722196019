import * as React from 'react';
import Sidebar from './Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import FooterComponent from '../Layout/FooterComponents/FooterComponent';
import { provider } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import CookiesComponent from '../Cookies/CookiesComponent';

export interface IConnectedComponentProps {
  children: any;
}

const Layout = provider(
)(observer((props: IConnectedComponentProps) => {
  const expirationDate: Date = new Date();
  expirationDate.setMonth(expirationDate.getMonth() + 6);

  return (
    <div className={'flex flex-col min-h-screen'}>
      <div className={'flex'}>
        <Sidebar aria-label="mailbox folders"/>
        <div className={'flex flex-col flex-1 min-h-full bg-background'}>
          <Header setMobileOpen={() => false}/>
          <main className={'bg-background p-10 min-h-screen'}>{props.children}</main>
        </div>
      </div>
      <FooterComponent/>
      <CookiesComponent/>
    </div>
  );
}));

export default Layout;

//@todo Gestion du responsive à voir
// import { ConnectedViewStore } from '../Stores/viewStore/ConnectedView.store';
// type LocalStores = [ConnectedViewStore];
//provider(ConnectedViewStore)
// const [connectedViewStore]: [ConnectedViewStore] = useInstances<LocalStores>(ConnectedViewStore);

// <a className={classes.skip} href="#main-content">Aller au contenu principal</a>
// <div className={'flex'} id={'page'}>
//   <div className={'w-36 shadow-sidebar'} aria-label="mailbox folders" id={'sidebar'}>
//     <Hidden smUp implementation="js">
//       <Drawer
//         variant="temporary"
//         open={connectedViewStore.mobileOpen}
//         onClose={() => connectedViewStore.setMobileOpen(false)}
//         ModalProps={{
//           keepMounted: true,
//         }}
//       >
//         <SidebarMenu/>
//       </Drawer>
//     </Hidden>
//     <Hidden xsDown implementation="js">
//       <Drawer anchor="left" PaperProps={{ style: { height: 'inherit', position: 'inherit' } }} variant="permanent"
//               open>
//         <SidebarMenu/>
//       </Drawer>
//     </Hidden>
//   </div>
//   <div className={classes.topMenu}>
//     <Header
//       setMobileOpen={() => connectedViewStore.setMobileOpen(!connectedViewStore.mobileOpen)} {...props}/>
//     <main id="main-content" className={'flex-1'}>
//       {props.children}
//     </main>
//   </div>
// </div>
// <FooterComponent/>
// <CookiesComponent></CookiesComponent>