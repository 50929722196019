const reloading = {
  title: 'Rechargements pour',
  cancel: 'Annuler',
  amount: 'Montant',
  activeBeneficiaries_one: '{{count}} collaborateur actif',
  activeBeneficiaries_other: '{{count}} collaborateurs actifs',
  dailyAmount: 'Montant alloué au repas par jour et par collaborateur',
  contribution: 'Correspond aux {{companyQuota}}% de la participation entreprise',
  addBeneficiary: 'Ajouter un collaborateur',
  nextStep: 'Étape suivante',
  provisionManaged: 'Informations sur la participation Employeur pour {{month}}',
  provisionNotManaged: 'Abondement de la participation employeur pour {{month}}',
  provision_subtitle: '{{totalNumberOfWorkingDays}} jours travaillés × part employeur de {{quota}}',
  provisionTitle_one: 'Part employeur pour {{count}} collaborateur pour {{month}}',
  provisionTitle_other: 'Part employeur pour {{count}} collaborateurs pour {{month}}',
  subscriptionTitle_one: 'Titres restaurant : Abonnement pour {{count}} collaborateur pour {{month}}',
  subscriptionTitle_other: 'Titres restaurant : Abonnement pour {{count}} collaborateurs pour {{month}}',
  subscription: 'Frais d’abonnement',
  excl: 'HT',
  incl: 'TTC',
  transaction_one: '{{count}} collaborateur × abonnement de',
  transaction_other: '{{count}} collaborateurs × abonnement de',
  totalExcl: 'Total HT',
  vat: 'TVA',
  totalIncl: 'Total abonnements',
  reload: 'Confirmer ma commande',
  provisionAmount: 'Net à payer part employeur',
  total: 'Net à payer : Total Abonnements TTC + Participation employeur',
  reloadingDate: 'Mois à recharger',
  close: 'Fermer',
  orderParams: 'Mes paramètres de commande',
  beneficiaries: 'Mes collaborateurs',
  validation: 'Validation',
  payment: 'Paiement',
  SELECT_METHOD_RECHARGING: 'Méthode de rechargement',
  PARAMETERS: 'Vos paramètres de commande',
  ADD_NEW_BENEFICIARIES_UPLOAD: 'Validation du fichier',
  LIST_BENEFICIARIES: 'Vos collaborateurs',
  LIST_BENEFICIARIES_FROM_CSV: 'Vos collaborateurs',
  CONFIRMATION: 'Validation',
  YOUR_INFORMATION: 'Vos informations',
  PAYMENT: 'Paiement',
  titleChoice: 'Recharger les droits de vos collaborateurs',
  subTitleChoice: 'Comment souhaitez-vous renseigner les rechargements à effectuer ?',
  titleUpload: 'Pouvez-vous nous envoyer votre fichier ?',
  subTitleUpload: 'Notre modèle de fichier est disponible ici',
  newReload: 'Nouveau Rechargement',
  recharginSuccessSubTitle: '😬 Parfait le fichier semble OK ! Voici les actions qui vont être réalisées.',
  recharginErrorSubTitle: '😬 Oups, il semble qu’il y ait un petit problème !',
  uploadRechargingSuccesMessage: '{{beneficiariesToRechargeCount}} collaborateurs auront leurs droits mis à jour pour un total de {{totalDays}} jours',
  cancelBtn: 'Annuler',
  proceedBtn: 'Étape suivante',
  validationOrder: {
    recap: 'Récapitulatif de commande',
    makeRefunds: 'Veillez à procéder aux remboursement dans les délais prévus',
    rechargingSuccess: '🎉 Félicitations, votre commande est confirmée. Vos collaborateurs vont être notifiés de leurs\n' +
      '                        nouveaux droits.',
    provisionManaged: 'Estimation basée sur le nombre de droits rechargés',
    provisionNotManaged: 'Provision basée sur le nombre de droits rechargés',
    provisioned: '100% seront provisionnés d’ici 1 à 2 jours.',
  },
  selectMethod: {
    uploadMethod: 'Charger un fichier',
    manualMethod: 'Renseigner manuellement',
  },
  reloadingInvoice: {
    reload: 'Rechargement pour',
    ref: 'Référence',
    numberWorker: 'Nombre de collaborateurs',
    reloadDetails: 'Voir le détail',
    invoiceDetails: 'Détail de la facture',
  },
  uploadErrors: {
    required: 'champ {{fieldName}} requis',
    invalidRegistrationNumber: 'numéro de matricule inconnu',
    invalidRange: 'la valeur du champ {{fieldName}} doit être comprise entre {{lowValue}} et {{highValue}}',
    invalidLength: 'la taille du champ Matricule ne doit pas excéder 35 caractères',
    inactiveBeneficiary: 'bénéficiaire inactif',
    NOT_A_STRING: 'le champ "{{field}}" ne doit pas contenir de chiffre.',
    NOT_A_NUMBER: 'le champ "{{field}}" doit être numérique.',
    IS_REQUIRED: 'le champ "{{field}}" est requis.',
    NOT_AN_ALPHANUMERIC: 'le champ "{{field}}" doit être alphanumérique.',
    NOT_A_DATE: 'le champ "{{field}}" doit respecter le format dd/mm/aaaa.',
    NOT_RESPECT_LENGTH: 'le champ "{{field}}" doit contenir entre {{minLength}} et {{maxLength}} caractères.',
    NOT_RESPECT_MAX_LENGTH: 'le champ "{{field}}" doit contenir un maximum de {{maxLength}} caractères.',
    NOT_VALUE_BETWEEN: 'le champ "{{field}}" doit être compris entre {{minValue}} et {{maxValue}}.',
    REGISTRATION_NUMBER_ALREADY_TAKEN: 'le champ "{{field}}", le matricule "{{expectedValue}}" est déjà attribué à un autre bénéficiaire..',
    USER_REF_NOT_FOUND: 'Le matricule "{{expectedValue}}" est inconnu',
    NOT_VALUE_GREATER: 'Zone "{{field}}" : La valeur doit être supérieure à {{minValue}}',
    NOT_ACTIVE_THIS_MONTH: 'Le matricule "{{expectedValue}}" est un bénéficiaire inactif.',
  },
  uploadFields: {
    titleField_one: '{{count}} erreur à la ligne {{lineNumber}}',
    titleField_other: '{{count}} erreurs à la ligne {{lineNumber}}',
    field: 'Champ',
    RegistrationNumber: 'matricule',
    registrationNumber: 'matricule',
    Year: 'année',
    Month: 'mois',
    NumberOfWorkingDays: 'Nombre de jours travaillés',
  },
  snackError: '😒 Désolé, le contrôle du fichier a remonté au moins une erreur, essayez d\'en sélectionner un nouveau.',
  errorNaming: '🧐 Veuillez vérifier l\'orthographe de la colonne {{undefinedKey}}',
  schemaError: {
    title: '🧐 Votre Fichier ne semble pas standard...',
    title2: 'Pouvez-vous nous aider à rapprocher les différentes colonnes ?',
  },
  since6months: 'Depuis 6 mois',
  since1year: 'Depuis 1 an',
  since2years: 'Depuis 2 ans',
  sinceBeginning: 'Depuis le début',

  yourInformation: {
    title: 'Veuillez renseigner vos informations',
    customerReferenceArea: 'Mes références à indiquer sur la facture',
    internalOrderNumberArea: 'Numéro de bon de commande client :',
    marketNumberArea: 'Numéro de marché :',
    commitmentNumberArea: 'Numéro d’engagement :',
    customerReferenceArea_DISSOCIATED_PAYMENT: 'Mes références à indiquer sur le relevé des provisions',
    feesCustomerReferenceArea: 'Mes références à indiquer sur la facture d’abonnement',
  },
  referenceTitle: 'Rappel de vos références à mentionner sur la facture',
  referenceTitle_DISSOCIATED_PAYMENT: 'Rappel de vos références à mentionner sur la facture d’abonnement et le relevé de provision',
};

export default reloading;
