import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { RechargingViewStore } from '../../../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../../../Stores/viewStore/StepperRecharging.store';
import { BeneficiariesStore } from '../../../Stores/Beneficiaries.store';
import { useInstances } from 'react-ioc';
import { toJS } from 'mobx';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import { workingDayNumberScope } from '../../../Utils/Tester/BeneficiaryTester.service';
import { Spinner } from '../../../ui/Spinner';
import { DataSpreadsheet } from '../../../ui/DataSpreadsheet';
import { Button } from '../../../ui/Buttons/Button';

type LocalStore = [RechargingViewStore, StepperRechargingStore, BeneficiariesStore];

const OrderListBeneficiariesFromCsv: FunctionComponent = observer(() => {
  const [
    rechargingViewStore,
    stepperRechargingStore,
    beneficiariesStore,
  ]: [
    RechargingViewStore,
    StepperRechargingStore,
    BeneficiariesStore
  ] = useInstances<LocalStore>(RechargingViewStore, StepperRechargingStore, BeneficiariesStore);

  const beneficiariesToRecharge: BeneficiaryModel[] = toJS(beneficiariesStore.listOfBeneficiariesToRecharge);
  const { t } = useTranslation('reloading');

  const listBeneficiariesGrid: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Collaborateur',
      flex: 2,
      renderCell: function birthdateCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>{`${params.row.firstName} ${params.row.lastName}`} </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Adresse Email',
      flex: 2,
    },
    {
      field: 'ibanLastNumbers',
      headerName: 'IBAN',
      flex: 1,
      renderCell: function birthdateCell(params: GridCellParams) {
        return (
          <div>
            {`***********${params.value}`}
          </div>
        );
      },
    },
    {
      field: 'numberOfWorkingDays',
      headerName: 'Jours travaillés',
      flex: 1,
      sortable: false,
      renderCell: function actionCell(params: GridCellParams) {
        return (
          <div className={'ml-5'}>
            <input
              type="number"
              className={'flex font-bold justify-center items-center p-4 rounded-full focus:outline-none focus:border-background focus:shadow-outline'}
              style={{
                border: 'none',
                outline: 'none',
                background: 'var(--color-background-light)',
                WebkitAppearance: 'none',
                appearance: 'textfield',
                MozAppearance: 'textfield',
              }}
              pattern="[0-9]{2}"
              value={params.value as number ?? 0}
              max={workingDayNumberScope.min}
              min={workingDayNumberScope.max}
              onChange={(e) => {
                const objIndex = beneficiariesToRecharge.findIndex(obj => obj.uid === params.row.id);
                beneficiariesToRecharge[objIndex].numberOfWorkingDays = Number.parseFloat(e.target.value);
                beneficiariesStore.setBeneficiariesToRecharge([...beneficiariesToRecharge]);
              }}
            />

          </div>
        );
      },
    },
    {
      field: 'isActive',
      headerName: 'Actif',
      flex: 1,
      renderCell: function isActiveCell() {
        return (
          <div className={'ml-4 h-full flex items-center'}>
            <div className={'w-2 h-2 rounded-full bg-status-success'}/>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {beneficiariesStore.isLoading
        ? <Spinner size={'lg'} />
        : <>
          <div className={'flex justify-between py-10'}>
            <p className={''}>Renseignez ici le nombre de jours travaillés pour chacun de vos collaborateurs.</p>
            <Button onClick={() => rechargingViewStore.setOpenBeneficiaryDetails(true)}>
              {t('addBeneficiary')}
            </Button>
          </div>
          <DataSpreadsheet
            rows={beneficiariesToRecharge}
            columns={listBeneficiariesGrid}
            disableRowSelectionOnClick
            showHeader
          />
          <div className={'flex justify-end gap-4'}>
            <Button variant={'outlined'} onClick={() => stepperRechargingStore.goToPreviousStep()}>

              {t('cancel')}
            </Button>
            <Button
              disabled={beneficiariesToRecharge.findIndex((d) => isNaN(d.numberOfWorkingDays)) !== -1}
              onClick={() => stepperRechargingStore.goToNextStep()}
            >
              {t('nextStep')}
            </Button>
          </div>
        </>
      }
    </>
  );
});

export default OrderListBeneficiariesFromCsv;
