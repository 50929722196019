import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../Style/MuiStyles/Button';
import { useDocumentDetailsStyles } from '../../DocumentDetailsStyles';
import { MillesimeReceiptModel } from '../../../../assets/models/millesime/MillesimeReceipt.model';

import arrow from '../../../../assets/svg/arrow.svg';
import close from '../../../../assets/svg/close.svg';

interface MillesimeReceiptDetailsComponentProps {
  receipt: MillesimeReceiptModel;
  isDownloadingReceipt?: boolean;
  onDownloadReceipt?: () => void;
  onClose: () => void;
}

const MillesimeReceiptDetailsComponent: FunctionComponent<MillesimeReceiptDetailsComponentProps> = ({ receipt, onClose, isDownloadingReceipt, onDownloadReceipt }: MillesimeReceiptDetailsComponentProps) => {
  const documentDetailsClasses = useDocumentDetailsStyles({ document: receipt });
  const { t } = useTranslation('accounting');
  const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

  return (
    <div className={documentDetailsClasses.block}>
      <div className={documentDetailsClasses.header}>
        <h1>{t('restitutionReceiptDetails.title', { reference: receipt.receiptReference })}</h1>
        <img className={documentDetailsClasses.imgClose} alt="close" src={close} onClick={onClose}/>
      </div>
      <p className={documentDetailsClasses.subtitle}>{t('restitutionReceiptDetails.subTitle')}</p>
      <div className={documentDetailsClasses.status}>
        <div className={documentDetailsClasses.statusBlock}>
          <div className={documentDetailsClasses.circle}/>
          <span>
            {t(`restitutionReceiptDetails.statusLabel_${receipt.status}`)}
        </span>
        </div>
        <div className={documentDetailsClasses.statusBlock}>
          <p>{t(`restitutionReceiptDetails.bankTransferDetail_${receipt.restitutionMethod}`, { IBAN: receipt.restitutionIBAN })}</p>
        </div>
      </div>

      <div className={documentDetailsClasses.title2}>
        {t('restitutionReceiptDetails.restitutionDescription')}
      </div>
      <div className={documentDetailsClasses.secondBlock}>
        <p
          className={documentDetailsClasses.subscription}>{t('restitutionReceiptDetails.restitutionDescription_MILLESIME', { year: receipt.year })}</p>
        <p className={documentDetailsClasses.amount}>
          {currencyFormat.format(receipt.restitutionAmount)}
        </p>
      </div>
      <p className={documentDetailsClasses.transaction}>
        {t('restitutionReceiptDetails.beneficiariesCount_one', { count: receipt.beneficiaryCount })}
      </p>
      <hr/>

      <div className={documentDetailsClasses.paymentBlock}>
        <div className={documentDetailsClasses.total}>
          <p>{t('restitutionReceiptDetails.totalLabel')}</p>
          <p className={documentDetailsClasses.amount}>
            {currencyFormat.format(receipt.restitutionAmount)}
          </p>
        </div>
      </div>

      <div className={documentDetailsClasses.buttonBlock}>
        <Button
          isFetching={isDownloadingReceipt}
          variant="contained"
          color="secondary"
          onClick={() => onDownloadReceipt?.()}>
          <>
                <span
                  className={documentDetailsClasses.download}>{t(`restitutionReceiptDetails.downloadButtonLabel`)}</span>
            <img width={12} src={arrow} alt="down-arrow"/>
          </>
        </Button>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary">
          {t('restitutionReceiptDetails.closeButtonLabel')}
        </Button>
      </div>
    </div>
  );
};

export default MillesimeReceiptDetailsComponent;
