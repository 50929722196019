import React, { ReactNode } from 'react';
import { Button } from '../../ui/Buttons/Button';

interface Props {
  onClick: () => void;
  buttonText: string;
  children: ReactNode;
}

const CardBase = (props: Props) => {
  const { children, buttonText, onClick } = props;

  return (
    <div
      className={'w-64 min-h-96 p-4 flex flex-col justify-between bg-background rounded-br10 hover:shadow-xl cursor-pointer transition-all duration-200 ease-in-out'}>
      <div className={'flex flex-1 flex-col space-y-4'}>
        {children}
      </div>
      <Button
        variant="outlined"
        size={'large'}
        fullWidth
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default CardBase;
