import React from 'react';
import { IMandateSignatureDialog } from './MandateSignatureDialog';
import { useInstances } from 'react-ioc';
import { MandateStore } from '../../../../Stores/Mandate.store';
import { AgenciesStore } from '../../../../Stores/Agencies.store';
import { Button } from '../../../../ui/Buttons/Button';
import { ReactComponent as MandateSvg } from '../../../../assets/svg/mandate/mandateSignedError.svg';

const MandateSignatureError = (props: IMandateSignatureDialog) => {
  const [mandateStore, agenciesStore] = useInstances(MandateStore, AgenciesStore);
  const addMandate = () => mandateStore.createMandate(agenciesStore.currentAgency?.uid);

  return (
    <>
      <div className={'pt-32 flex flex-col justify-center items-center space-y-16'}>
        <MandateSvg title={'mandate signature'} className={'w-32 h-32'} />
        <p className={'text-sm text-center'}>
          Nous sommes désolés, nous avons rencontré un problème technique,<br/>
          merci de relancer la procédure de signature.
        </p>
      </div>
      <div className={'flex justify-end gap-4 pt-10'}>
        <Button
          variant='outlined'
          onClick={props.onClose}
        >
          {'Annuler'}
        </Button>
        <Button onClick={addMandate}>
          {'Relancer la procédure de signature'}
        </Button>
      </div>
    </>
  );
};

export default MandateSignatureError;
