import { action, makeAutoObservable, runInAction } from 'mobx';
import { getAgencyPayinReports, getAgencyPayins } from '../Services/payinsAPI.service';
import { GetAgencyPayinResponse } from '../assets/responses/payins/GetAgencyPayin.response';
import { OptionalTimePeriodRequest } from '../assets/requests/TimePeriod.request';
import { PayinReportModel } from '../assets/models/payins/PayinReport.model';

export class PayinStore {
  isPayinLoading: boolean = false;
  payinError: string;

  agencyPayins: GetAgencyPayinResponse[] = null;
  agencyPayinReport: PayinReportModel[] = null;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action reset() {
    this.isPayinLoading = false;
    this.agencyPayins = null;
    this.payinError = null;
  }

  @action setPayinLoading (isLoading: boolean) {
    this.isPayinLoading = isLoading;
  }

  async fetchAgencyPayins(agencyId: string) {
    try {
      this.isPayinLoading = true;

      const agencyPayinsResponse: GetAgencyPayinResponse[]|null = await getAgencyPayins(agencyId);
      runInAction(() => {
        if (agencyPayinsResponse) {
          this.agencyPayins = agencyPayinsResponse;
        }

        this.isPayinLoading = false;
      });
    } catch (error) {
      this.payinError = 'error agencyPayins loaded';
      console.error('PayinStore: ', error);
    } finally {
      this.setPayinLoading(false);
    }
  }

  async fetchAgencyPayinReports(agencyId: string, fromDate?: number, toDate?: number) {
    const rangeTime: OptionalTimePeriodRequest = {};

    if (fromDate) {
      rangeTime.from = fromDate;
    }

    if (toDate) {
      rangeTime.to = toDate;
    }

    try {
      this.isPayinLoading = true;

      const agencyPayinReportResponse: PayinReportModel[] = await getAgencyPayinReports(agencyId, rangeTime);
      runInAction(() => {
        if (agencyPayinReportResponse) {
          this.agencyPayinReport = agencyPayinReportResponse;
        }

        this.isPayinLoading = false;
      });
    } catch (error) {
      this.payinError = 'error agencyPayins loaded';
      console.error('PayinStore: ', error);
    } finally {
      this.setPayinLoading(false);
    }
  }
}
