const beneficiary = {
  paybackSearchPlaceholder: '10/11/2021, 3,50€...',
  since3months: 'Depuis 3 mois',
  since6months: 'Depuis 6 mois',
  since1year: 'Depuis 1 an',
  since2years: 'Depuis 2 ans',
  sinceBeginning: 'Depuis le début',
  title: 'Collaborateurs',
  subtitlePartOne_one: '{{count}} collaborateur actuellement renseigné',
  subtitlePartOne_other: '{{count}} collaborateurs actuellement renseignés',
  subtitlePartTwo_one: '(dont {{count}} sortant, ',
  subtitlePartTwo_other: ' (dont {{count}} sortants, ',
  addBeneficiary: 'Ajouter un collaborateur',
  hidden: 'les masquer',
  display: 'les afficher',
  searchPlaceholder: 'Jean, dupond, 12345...',
  detailsTitle: 'Détail du compte de ',
  registrationNumber: 'Matricule du salarié dans l’entreprise',
  registrationNumberPlaceholder: '123456',
  email: 'Adresse email',
  emailPlaceholder: 'jean.dupond@mail.com',
  lastName: 'Nom',
  lastNamePlaceholder: 'Dupond',
  firstName: 'Prénom',
  firstNamePlaceholder: 'Jean',
  cancel: 'Annuler',
  confirmCancel: 'Non, annuler',
  confirmUpdate: 'Oui, je confirme',
  add: 'Ajouter',
  addAndClose:'Créer et fermer',
  addAndContinue:'Créer et ajouter un nouveau collaborateur',
  modify: 'Modifier',
  modifyWithIban:'Oui, je confirme',
  closeWithIban:'Non, annuler',
  close: 'Fermer',
  firstRightDate: 'Date de début des droits',
  mobile: 'Téléphone mobile',
  mobilePlaceholder: '06 55 43 12 11',
  numberOfWorkingDays: 'Nb jours par défaut',
  numberOfWorkingDaysPlaceholder: '20',
  dailyVoucherUsageLimit: 'Nb repas par jour',
  dailyVoucherUsageLimitPlaceholder: '1',
  weeklyVoucherUsageLimit: 'Nb repas par semaine',
  weeklyVoucherUsageLimitPlaceholder: '5',
  monthlyVoucherUsageLimit: 'Nb repas par mois',
  monthlyVoucherUsageLimitPlaceholder: '30',
  streetAddress: 'Adresse',
  streetAddressPlaceholder: '12 rue des mimosas',
  additionalAddress: 'Complément d\'adresse',
  additionalAddressPlaceholder: 'Bat. A',
  postalCode: 'Code postal',
  postalCodePlaceholder: '75001',
  city: 'Ville',
  cityPlaceholder: 'Paris',
  Mr: 'M.',
  Mrs: 'Mme',
  Unknow: '',
  iban: 'Iban',
  ibanPlaceholder: 'FR7630001007941234567890185',
  day_one: '{{count}} jour',
  day_other: '{{count}} jours',
  tooltipAddBeneficiaries: 'En cliquant ici, vous pourrez télécharger un fichier Excel que vous n\'aurez plus qu\'à renseigner et nous transmettre par mail',
  uploadBeneficiariesDrawer: {
    title: 'Ajouter ou mettre à jour des collaborateurs',
    subtitle: 'L’ajout ou la modification de plusieurs collaborateurs simultanément peut se faire par fichier',
    labelDownloadFile: 'Notre modèle de fichier est disponible ici',
    uploadFile: 'Lancer l\'import du fichier',
    hasError: 'Fermer',
  },
  uploadBeneficiariesResultDrawer: {
    title: 'Import de collaborateurs',
  },
  errorSnackBar: '😞 Désolé, ce fichier génère une erreur, essayez d\'en sélectionner un nouveau',
  errorNaming: '😞 Veuillez vérifier l\'orthographe de la colonne {{undefinedKey}}',
  qrCodeButtonLabel: 'Imprimer un QR Code',
  confirmationSnackBar: 'Les modifications ont bien été enregistrées',
  qrCodeRegenButton : 'Regénérer le QR code',
  confirmationSnackBarRegenQrCode: 'Le QR code du collaborateur a bien été renouvelé.',
};

export default beneficiary;
