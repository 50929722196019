export function getFrenchMoneyFormat(input: number, withCurrencySign: boolean = true): string {
  const fractionDigits = 2;
  const style = withCurrencySign ? 'currency' : 'decimal';

  return input.toLocaleString('fr-FR', {
    style,
    currency: 'EUR',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}
