export function sanitizeIBAN(input: unknown): string {
  if (typeof input !== 'string') {
    return '';
  }

  return input
    .trim()
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '')
    ;
}
