import React, { FC } from 'react';
import { ReactComponent as AddSvg } from './../../assets/svg/add.svg';
import { Button } from '../../ui/Buttons/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick?: () => void;
}

export const OrderCardEmpty: FC<Props> = ({onClick}) => {
  const {t} = useTranslation('reloading');
  return (
    <div
      onClick={onClick}
      className={'bg-white w-64 min-h-96 border border-dashed rounded-br10 p-4 flex flex-col justify-between hover:shadow-xl hover:border-primary/50 cursor-pointer transition-all duration-200 ease-in-out'}
    >
      <div className={'flex-1 flex justify-center items-center'}>
        <div className={'w-16 h-16 bg-background rounded-full flex justify-center items-center'}>
          <AddSvg className="w-4 h-4 text-primary" />
        </div>
      </div>
      <Button
        variant="contained"
        fullWidth
        size={'large'}
        className={'!px-0'}
        onClick={onClick}
      >
        {t('newReload')}
      </Button>
    </div>
  )
}