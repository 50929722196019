const mandate = {
  header: 'Signature de votre mandat de prélèvement',
  subHeader: 'Cette étape n\'est à faire qu\'une seule fois et autorisera le paiement de vos commandes par prélèvement.',
  cancelBtn: 'Annuler',
  submitBtn: 'Ok, C\'est parti !',
  description: 'Pour  sécuriser cette étape',
  description2: 'vous allez être redirigé vers notre partenaire de paiement.',
  success: {
    title: 'Parfait !',
    description: 'Félicitations, le paiement de vos commandes Open!Eat se fera maintenant par prélèvement.',
    backBtn: 'Revenir à l\'écran de règlement'
  },
  error: {
    title: 'Oops ! un petit problème est survenu !',
    description: 'Nous sommes désolé, nous avons rencontré un problème technique,',
    description2: 'merci de relancer la procédure.',
    cancelBtn: 'Annuler',
    submitBtn: 'Relancer la procédure',
  }
};

export default mandate;
