import { makeStyles } from '@mui/styles';
import React, { FunctionComponent } from 'react';
import FooterComponent from '../Layout/FooterComponents/FooterComponent';
import { COLORS, FONT } from '../Style/Style';
import mango from '../assets/mangopay-logo.svg';

const useDefaultStyles = makeStyles(() => ({
  container: {
    padding: '6rem 10rem',
    fontFamily: FONT.primary,
    color: COLORS.textPrimary,
  },
  titlePage: {
    borderWidth: 1,
    borderColor: COLORS.textPrimary,
    borderStyle: 'solid',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: FONT.secondary,
  },
  mangoPayLogo: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
  }

}));

const MangoPayRGPD: FunctionComponent = () => {
  const defaultStyles = useDefaultStyles();

  return (
    <>
      <div className={defaultStyles.container}>
        <div className={defaultStyles.mangoPayLogo}>
          <div>
            <img src={mango} alt={'logo mangoPay'} style={{width: '100%'}}/>
          </div>
        </div>
        <div className={defaultStyles.titlePage}>
          <h1 style={{fontSize: '2rem'}}>Politique de confidentialité</h1>
        </div>
        <div style={{paddingTop: '1rem', paddingBottom: '3rem'}}>
          <p style={{fontStyle: 'italic', float: 'right'}}>Date de dernière mise à jour : 16 septembre 2022</p>
        </div>
        <h2>1. Introduction</h2>
        <p>
          Cette politique de confidentialité (<strong>« Politique de confidentialité »</strong>) a pour objet  de Vous informer sur les activités de traitement effectuées sur vos Données personnelles
          par MANGOPAY, ainsi que sur vos droits en tant que personne concernée par la législation nationale applicable,
          notamment par le Règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection des données, dénommé « Règlement général sur la protection des données » ou « RGPD ».
        </p>
        <br/>
        <p>
          <strong>« Données personnelles »</strong> désigne toute information relative à une personne identifiée ou identifiable.
          Une   personne   physique   identifiée   ou   identifiable   est   une   personne   pouvant   être   identifiée,
          directement ou indirectement, notamment par référence à un identifiant (comme un nom, un numéro d'identification,  des  données  de  localisation,  etc.)
          ou  à  un  ou  plusieurs  éléments  propres  à  son  état physique, physiologique, mental, économique, culturel ou social.
        </p>
        <br/>
        <p>
          L'entité traitant vos données, agissant en qualité de responsable de traitement, est MANGOPAY S. A. (<strong>« MANGOPAY »</strong>), une société anonyme de droit luxembourgeois dont le siège social est situé 2 Avenue Amélie,
          L-1125 Luxembourg et enregistrée au Registre du Commerce et des Sociétés du Luxembourg sous le numéro B173459, autorisée à exercer des services de paiement et de monnaie électronique,
          en qualité  d'établissement  de  monnaie  électronique.  MANGOPAY  est  agréé  par  la  Commission  de Surveillance du Secteur Financier du Luxembourg, 283 route d'Arlon L-1150 Luxembourg,
          <a href={'https://www.cssf.lu/fr/'} style={{color: COLORS.primary}}>www.cssf.lu</a>.
        </p>
        <br/>
        <p>
          MANGOPAY a désigné un délégué à la protection des données, un « DPD ». Vous pouvez contacter ce dernier à l'adresse mail suivante : <a href={'mailto:dpo.mangopay@mangopay.com'}  style={{color: COLORS.primary}}>dpo.mangopay@mangopay.com</a>.
        </p>
        <h2>2. Cette politique de confidentialité vous affecte-t-elle ?</h2>
        <p>
          MANGOPAY fournit des services de paiement et de monnaie électronique par le biais de plateformes en ligne (sites Internet ou applications mobiles) exploitées par ses partenaires (<strong>« les Partenaires »</strong>).
          Ces Partenaires gèrent des plateformes par lesquelles ils exercent leurs activités, par exemple, de vente en ligne, de marketplace,
          d'intermédiation en financement collectif (don, financement participatif) et ont décidé d'intégrer la solution de paiement MANGOPAY pour traiter les paiements sur leurs plateformes.
          Lors  de  votre  inscription  auprès  de  ces  Partenaires,  il  peut  également  vous  être  demandé  de  vous inscrire aux services fournis par MANGOPAY,
          lesquels vous permettront de transférer des fonds ou de recevoir des paiements pour des transactions effectuées sur la plateforme du Partenaire. Pour fournir ces services de paiement,
          MANGOPAY est amené à traiter vos Données personnelles. Par <strong>« Vous »</strong>, nous entendons :
        </p>
        <br/>
        <ul>
          <li><p>Les personnes s'étant inscrites aux services de MANGOPAY en leur qualité de <strong>personne physique</strong>;</p></li>
          <li><p>Les personnes physiques liées à une personne morale s'étant inscrites aux services de MANGOPAY (ex : représentant légal, ayant droit économique, etc.).</p></li>
          <li><p>Les personnes physiques liées à un Partenaire ;</p></li>
          <li><p>Les personnes physiques ayant effectué un paiement sur la plateforme d'un Partenaire à l'aide d'une page de paiement MANGOPAY.</p></li>
          <li><p>Les utilisateurs du site Internet (<a href={'https://www.mangopay.com/fr/'} style={{color: COLORS.primary}}>https://www.mangopay.com/fr/u</a>) et du blog (<a href={'https://blog.mangopay.com/fr'} style={{color: COLORS.primary}}>https://blog.mangopay.com/fr</a>) de MANGOPAY.</p></li>
        </ul>
        <h2>3. Quelles Données personnelles sont traitées par MANGOPAY ? Comment sont-elles collectées ? </h2>
        <p>Les Données personnelles suivantes sont traitées par MANGOPAY :</p>
        <ul>
          <li><p>Votre IBAN</p></li>
          <li><p>Votre nom et prénom, si nécessaire.</p></li>
        </ul>
        <p>
          Parce que MANGOPAY offre une solution en marque blanche, vos Données à caractère personnel sont collectées directement par notre Partenaire et ensuite transférées à MANGOPAY pour
          l’exécution des ordres de paiement sortants pour lesquels vous êtes bénéficiaires.
        </p>
        <h2>4. Sur quelle base légale et dans quels buts vos Données personnelles sont-elles traitées ?</h2>
        <p>Vos Données personnelles sont traitées par MANGOPAY :</p>
        <ul>
          <li style={{paddingBottom: '1rem'}}>
            <p>
              <strong>Pour  remplir  les  obligations  contractuelles  :</strong> le  traitement  de  vos  Données  personnelles  est nécessaire  à  l'exécution  des  obligations  contractuelles  que  MANGOPAY
              a  envers  Vous,  ainsi que  pour  prendre  des  mesures,  à  votre  demande,  avant  de  conclure  un  contrat.  Cette  base juridique est utilisée pour certaines activités de traitement,
              notamment :
            </p>
            <br/>
            <ul>
              <li><p>L'inscription aux services (services de paiement ou de monnaie électronique)</p></li>
              <li><p>L’exécution d'opérations de paiement</p></li>
              <li><p>La gestion des relations avec les clients (comme la fourniture de relevés d'opération)</p></li>
              <li><p>Le traitement de vos questions ou de vos éventuelles réclamations</p></li>
              <li><p>Le traitement des paiements par carte (lorsque Vous effectuez un paiement par carte sur la plateforme d'un Partenaire)</p></li>
            </ul>
          </li>
          <li style={{paddingBottom: '1rem'}}>
            <p>
              <strong>Sur la base des intérêts légitimes de MANGOPAY :</strong> le traitement de vos Données personnelles peut  être  fondé  sur  les  intérêts  légitimes  de  MANGOPAY,  nécessaires
              à  ses  activités  de prestataire  de  services  de  paiement.  Cela  est  vrai  pour  certaines  activités  de  traitement, notamment :
            </p>
            <br/>
            <ul>
              <li><p>Lutter contre la fraude</p></li>
              <li><p>Améliorer la qualité des services de MANGOPAY</p></li>
              <li><p>Maintenir la sécurité de l'API de MANGOPAY et des services en général</p></li>
            </ul>
          </li>
          <li style={{paddingBottom: '1rem'}}>
            <p>
              <strong>Sur la base de ses obligations légales :</strong> en tant qu'entité régulée, MANGOPAY est soumis à des obligations  légales  strictes  qui  l'obligent  à  effectuer  des
              traitements  de  données  spécifiques pour les finalités suivantes :
            </p>
            <br/>
            <ul>
              <li><p>Respecter les obligations légales et réglementaires qui lui sont imposées en sa qualité de  prestataire  de  services  de  paiement,  et  notamment  pour  la  lutte
                contre  le blanchiment de capitaux et le financement du terrorisme</p></li>
              <li><p>Coopérer avec les autorités compétentes chargées  de l'application de la loi ou de la surveillance prudentielle, en cas de contrôle ou d'enquête</p></li>
            </ul>
          </li>
          <li style={{paddingBottom: '1rem'}}>
            <p>
              <strong>Sur  la  base  de  votre  consentement  :</strong>  le  traitement  de  vos  Données  personnelles  peut  être soumis  à  votre  consentement.
              Votre  consentement  est  requis  pour  certaines  activités  de traitement, notamment :
            </p>
            <br/>
            <ul>
              <li><p>L'utilisation de certains cookies</p></li>
              <li><p>L'abonnement à la newsletter de MANGOPAY</p></li>
            </ul>
          </li>
        </ul>
        <h2>5. Combien de temps vos Données personnelles sont-elles conservées ?</h2>
        <p>
          MANGOPAY conservera vos Données personnelles pour une durée n'excédant pas celle nécessaire à la réalisation de la finalité du traitement.
        </p>
        <p>
          Si Vous vous êtes inscrit aux services de MANGOPAY par l'intermédiaire d'un de ses Partenaires, vos Données personnelles seront conservées pendant la durée de la relation contractuelle.
          Lorsque Vous cesserez  d'utiliser  les  services,  toutes  vos  Données  personnelles  seront  définitivement  effacées,
          à l'exception  des  Données  personnelles  qui  doivent  être  conservées  par  MANGOPAY  pour  des  raisons légales. Voici les délais de conservation des données qui s'appliquent :
        </p>
        <ul>
          <li><p>
            Conformément   aux   obligations   légales   de   MANGOPAY   en   matière   de   lutte   contre   le blanchiment d'argent et le financement du terrorisme,
            toute information sur les transactions et toute documentation et information fournies dans le cadre de la procédure « Know-Your-Customer » (« KYC ») de MANGOPAY seront
            conservées jusqu'à dix (10) ans, à compter de la fin de la relation contractuelle.
          </p></li>
          <li><p>
            Conformément  aux  dispositions  légales  en  vigueur,  les  données  nécessaires  au  traitement d'éventuelles contestations ou litiges et communications avec l'équipe
            support de MANGOPAY seront conservées pendant cinq (5) ans. Les enregistrements audio à des fins de contrôle de qualité ne seront conservés que pendant une période de six (6) mois.
          </p></li>
        </ul>
        <p>
          Les Données personnelles générées par l'utilisation des cookies par MANGOPAY sont soumises à des durées de conservation spécifiques qui sont précisées <a href={'https://www.mangopay.com/fr/cookies/'} style={{color: COLORS.primary}}>ici</a>.
        </p>
        <h2>6. Où sont stockées les Données personnelles ?</h2>
        <p>
          Les   serveurs   utilisés   par   MANGOPAY   pour   stocker   vos   Données   personnelles   sont   situés   au Luxembourg.
        </p>
        <p>
          De plus, MANGOPAY peut transférer vos Données personnelles à ses sous-traitants dont les services sont nécessaires à l'exécution des services de MANGOPAY.
          Certains de ces sous-traitants traitent vos données  personnelles  en  dehors  du  territoire  de  l'Union  européenne  ou  de  l'Espace  économique européen.
          Dans  ce  cas,  MANGOPAY  garantit  que  le  pays  dans  lequel  vos  Données  personnelles  sont traitées   est   couvert   par   une   décision
          d'adéquation   de   la   Commission   européenne.   À   défaut, MANGOPAY s'assure de la mise en place de garanties appropriées telles que les Clauses Contractuelles Types (<strong>« CCT »</strong>)
          adoptées par la Commission Européenne et toute mesure supplémentaire adéquate, le cas échéant.
        </p>
        <h2>7. Qui  sont  les  destinataires  des  Données  Personnelles  ?  Sont-elles  transférées  à  des tiers ?</h2>
        <h3 style={{textDecoration: 'underline'}}>7.1 Personnel autorisé de MANGOPAY</h3>
        <p>
          Seuls  les  membres  du  personnel  de  MANGOPAY  spécifiquement  autorisés  et  ayant  besoin  d'en connaître     peuvent  accéder  à  vos  Données  personnelles,
          pour  les  besoins  de  l'accomplissement  de leurs tâches. Les collaborateurs de MANGOPAY ayant accès à vos Données personnelles sont soumis à de
          strictes  obligations  de  confidentialité,  ainsi  qu'au  secret  professionnel,  et  ils  ont  été  formés  à  la protection des données à caractère personnel.
        </p>
        <h3 style={{textDecoration: 'underline'}}>7.2 Autorités compétentes</h3>
        <p>
          MANGOPAY peut être amené à transférer des Données personnelles aux autorités compétentes, telles que  les  autorités  publiques,
          les  organismes  de  lutte  contre  le  blanchiment  et  le  financement  du terrorisme, ou les autorités de surveillance bancaire.
          Avant toute divulgation de Données personnelles, chaque  demande  d'une  autorité  impliquant  vos  Données  personnelles  sera  analysée
          par  le  service juridique de MANGOPAY afin d'évaluer si l'autorité est qualifiée de  destinataire autorisé.
        </p>
        <h3 style={{textDecoration: 'underline'}}>7.3 Les sous-traitants de MANGOPAY</h3>
        <p>
          MANGOPAY fait appel à certains prestataires pour exercer ses activités de prestataire de services de paiement  et
          pour  fournir  les  services  que  Vous  avez  demandés  (par  exemple,  hébergement  d'un système  informatique,
          établissements  de  crédit  pour  la  protection  des  fonds  ou  la  réalisation d'opérations de paiement, etc.).
          Pour mener à bien leurs prestations, certains prestataires sont amenés à  traiter  vos  Données  personnelles  pour  le  compte  de  MANGOPAY.
          Ces  sous-traitants  traitent  vos Données personnelles uniquement sur les instructions de MANGOPAY et exclusivement dans le but de fournir  leurs  services  à  MANGOPAY.
          Sauf  accord  exprès  de  votre  part,  les  sous-traitants  ne  sont  pas autorisés à utiliser vos Données personnelles pour leur propre compte.
        </p>
        <br/>
        <p>
          Les  prestataires  de  services  tiers  de  MANGOPAY  sont  sélectionnés  avec  soin  et  sont  soumis  à  des obligations  contractuelles  strictes,
          ce  qui  inclut  des  obligations  d'assurer  un  niveau  de  sécurité approprié,  des  obligations  de  confidentialité  et
          des  obligations  de  mettre  en  œuvre  des  mesures techniques   et   organisationnelles   appropriées,   etc.   En   adressant   une   demande   à   MANGOPAY (<a href={'mailto:dpo.mangopay@mangopay.com'}  style={{color: COLORS.primary}}>dpo.mangopay@mangopay.com</a>),
          vous pouvez obtenir une liste détaillée des sous-traitants impliqués dans le traitement de vos Données personnelles.
        </p>
        <h2>8. Quels sont vos droits concernant vos Données personnelles ?</h2>
        <h3 style={{textDecoration: 'underline'}}>8.1 Droit d'accès</h3>
        <p>
          Vous avez le droit d'accéder à vos Données personnelles.
          Si Vous exercez ce droit, MANGOPAY Vous adressera une copie des caractéristiques du traitement de vos Données personnelles (les finalités du traitement,
          les catégories de données concernées, etc.). Ces informations Vous seront fournies dans un format électronique couramment utilisé. Toutefois, Vous avez la possibilité de
          demander que ces informations   Vous   soient   fournies   dans   un   autre   format,   sous   réserve   que   MANGOPAY   soit techniquement en mesure de Vous fournir
          les informations dans le format demandé en fonction des moyens disponibles ou des particularités de la demande en question.
        </p>
        <br/>
        <p>
          Vous  êtes  informé  que  MANGOPAY  pourra  exiger  le  paiement  de  frais  en  fonction  des  coûts administratifs encourus, si Vous demandez des copies supplémentaires.
        </p>
        <h3 style={{textDecoration: 'underline'}}>8.2 Droit de rectification</h3>
        <p>
          Si vos Données personnelles sont inexactes ou incomplètes, Vous avez le droit de demander que ces Données personnelles soient rectifiées ou mises à jour.
        </p>
        <h3 style={{textDecoration: 'underline'}}>8.3 Droit à l'effacement (« Droit à l'oubli »)</h3>
        <p>
          Conformément à la réglementation applicable, Vous pouvez demander l'effacement de vos Données personnelles si l'une des conditions suivantes s'applique :
        </p>
        <ul>
          <li><p>
            Vos Données personnelles ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou traitées
          </p></li>
          <li><p>
            Vos Données personnelles ont fait l'objet d’un traitement illégal
          </p></li>
          <li><p>
            Vos Données personnelles ont été collectées uniquement sur la base de votre consentement et Vous souhaitez le retirer
          </p></li>
          <li><p>
            En  raison  de  votre  situation  particulière,  vous  vous  opposez  au  traitement  de  vos  Données personnelles fondé sur les intérêts légitimes de MANGOPAY,
            y compris le profilage, et il n'existe aucun motif légitime prépondérant pour le traitement
          </p></li>
          <li><p>
            Vous vous opposez au traitement de vos Données personnelles à des fins de marketing direct
          </p></li>
          <li><p>
            Vos Données personnelles doivent être effacées pour se conformer à une obligation légale en vertu de la loi applicable.
          </p></li>
        </ul>
        <p>
          Veuillez  noter  que  votre  demande  de  suppression  de  vos  Données  personnelles  sera  refusée  si  le traitement est nécessaire pour exercer le droit à la liberté d'expression et d'information,
          pour l'exercice ou  la  défense  de  revendications  légales,  ou  pour  se  conformer  à  une  obligation  légale  à  laquelle MANGOPAY est soumis.
        </p>
        <h3 style={{textDecoration: 'underline'}}>8.4 Droit de limiter le traitement des données</h3>
        <p>
          Vous avez le droit de demander la limitation du traitement de vos Données personnelles dans l'un des cas suivants :
        </p>
        <ul>
          <li><p>
            Si Vous contestez l'exactitude de vos Données personnelles. Si le traitement de vos Données personnelles   est   limité   en   vertu   de   ce   motif,   elles   ne   seront   traitées   qu'avec   votre consentement
          </p></li>
          <li><p>
            Si le traitement est illicite et que Vous souhaitez que le traitement de vos Données personnelles soit restreint à la place de l’effacement de vos Données personnelles
          </p></li>
          <li><p>
            Si  MANGOPAY  n'a  plus  besoin  de  vos  Données  personnelles  mais  qu'elles  sont  encore nécessaires à la constitution ou à l'exercice de la défense de revendications  légales
          </p></li>
          <li><p>
            Si  Vous  vous  êtes  opposé  au  traitement  de  vos  Données  personnelles  fondé  sur  les  intérêts légitimes  de  MANGOPAY  au  regard  de  votre  situation  particulière,
            le  traitement  de  vos Données personnelles sera restreint jusqu'à ce qu'il ait été établi que les motifs légitimes de MANGOPAY ont préséance, ou non, sur les vôtres.
          </p></li>
        </ul>
        <p>
          Si le traitement de vos Données personnelles a été restreint, MANGOPAY vous informera avant la levée de la restriction.
        </p>
        <h3 style={{textDecoration: 'underline'}}>8.5 Droit d'opposition au traitement des Données personnelles</h3>
        <p>
          Si vous bénéficiez d'une situation particulière, Vous pouvez vous opposer au traitement de vos Données personnelles fondé sur les intérêts légitimes de MANGOPAY.
          À moins que MANGOPAY démontre des motifs impérieux et légitimes justifiant le traitement qui prévalent sur vos droits, libertés et intérêts, ou encore si le traitement est nécessaire à la constatation,
          l'exercice ou la défense d'un droit en justice, vos Données personnelles ne seront plus traitées.
        </p>
        <br/>
        <p>
          Vous avez également le droit de Vous opposer au traitement de vos Données personnelles à des fins de marketing direct sans justifier de motifs d'opposition.
        </p>
        <br/>
        <p>
          Si vos Données personnelles sont traitées par MANGOPAY à des fins statistiques, Vous avez le droit de vous  opposer  au  traitement  pour  des  motifs  tenant  à  votre  situation  particulière.
          Si  Vous  vous  y opposez sur cette base, vos Données personnelles ne seront plus traitées à cette fin, à moins que le traitement soit nécessaire à l'exécution d'une tâche effectuée pour des raisons d'intérêt public.
        </p>
        <h3 style={{textDecoration: 'underline'}}>8.6 Droit à la portabilité des données</h3>
        <p>
          Vous avez le droit de recevoir les Données personnelles que vous avez transmises à MANGOPAY si le traitement de vos Données personnelles est basé sur votre consentement ou sur un contrat entre Vous et  MANGOPAY,
          et  qu'il  est  effectué  par  des  moyens  automatisés.  Vos  Données  personnelles  seront transférées  dans  un  format  structuré,  actuellement  utilisé  et  lisible  par  ordinateur.
          Vous  avez également  le  droit  de  demander  que  vos  Données  personnelles  soient  transmises  directement  à  un autre responsable du traitement, lorsque cela est techniquement possible.
        </p>
        <br/>
        <p>
          Dans  le  cas  où  le  traitement  de  vos  Données  personnelles  est  nécessaire  à  l'exécution  d'une  tâche effectuée dans l'intérêt public, votre demande sera refusée.
        </p>
        <h2>9. Comment exercer vos droits ?</h2>
        <p>
          Vous  pouvez  exercer  vos  droits  en  contactant  soit  le  Partenaire  de  MANGOPAY,  soit  MANGOPAY directement  à <a href={'mailto:dpo.mangopay@mangopay.com'}  style={{color: COLORS.primary}}>dpo.mangopay@mangopay.com</a>.
          Vous  pouvez  également  adresser  votre  requête  par voie postale à l'adresse suivante : MANGOPAY S. A., 2 Avenue Amélie, L-1125 Luxembourg.
        </p>
        <br/>
        <p>
          Pour  toute  demande  d'exercice  de  droits  faisant  apparaître  un  doute  raisonnable  sur  votre  identité, MANGOPAY pourra demander que votre identité soit vérifiée avant de traiter votre requête.
          Les réponses à vos demandes vous seront communiquées par voie électronique, sauf si Vous demandez qu'elles  soient  communiquées  autrement.  Dans  ce  cas,  vous  devez  spécifier  le  format  préféré  dans votre requête.
        </p>
        <br/>
        <p>
          MANGOPAY  s'engage  à  répondre  à  toute  requête  immédiatement.  Dans  tous  les  cas,  Vous  recevrez une réponse dans un délai maximum d'un mois (30 jours) à compter de la réception de votre requête. Toutefois,
          nous  Vous  informons  que  ce  délai  peut  être  porté  à  deux  (2)  mois  si  votre  requête  est particulièrement complexe, ou si le nombre de requêtes à traiter est particulièrement important.
          Dans ce cas, Vous serez informé d'une telle prolongation et de ses motifs dans un délai d'un mois (30 jours) à compter de la réception de votre requête.
        </p>
        <br/>
        <p>
          Si MANGOPAY n'est pas en mesure de satisfaire votre requête, Vous en serez informé au plus tard dans un  délai  d'un  mois  (30  jours)  à  compter  de  la  réception  de  votre  requête.  Les  raisons  du  refus  de MANGOPAY Vous seront communiquées.
        </p>
        <br/>
        <p>
          En  cas  de  requêtes  manifestement  infondées  ou  excessives,  notamment  en  raison  de  leur  caractère répétitif, MANGOPAY pourra refuser de répondre à vos requêtes ou exiger le paiement de frais tenant compte des coûts administratifs supportés pour répondre à vos demandes.
        </p>
        <h2>10. Comment vos Données personnelles sont-elles sécurisées ?</h2>
        <p>
          MANGOPAY met en œuvre des mesures techniques et organisationnelles appropriées afin d'assurer la confidentialité et l'intégrité de vos Données personnelles, et notamment, d'empêcher leur destruction, perte,
          altération,   divulgation   non   autorisée   ou   accès   non   autorisé.   Ces   mesures   de   sécurité comprennent le chiffrement, la pseudonymisation,
          ainsi que la mise en œuvre de mesures garantissant la disponibilité et la résilience constante de l'infrastructure informatique de MANGOPAY.
        </p>
        <br/>
        <p>
          Le cas échéant, toute faille de sécurité qualifiée de violation de Données personnelles comportant des risques élevés pour vos droits et libertés Vous sera notifiée dans les meilleurs délais
          par MANGOPAY ou par le Partenaire de MANGOPAY avec lequel Vous êtes en relation commerciale.
        </p>
        <h2>13. Modification de la Politique de confidentialité</h2>
        <p>
          Veuillez noter que cette Politique de confidentialité peut être mise à jour ou modifiée à tout moment. Vous trouverez une indication de la date de la dernière mise à jour sur la première page.
        </p>
      </div>
      <FooterComponent />
    </>
  )
}

export default MangoPayRGPD;