import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import openeat from '../assets/logo-text-new.svg';
import MailSvg from '../assets/mail';
import resetSuccess from '../assets/reset-pass-success.svg';
import { UserConnectStore } from '../Stores/UserConnect.store';
import { validationEmail } from '../Utils/Login.service';
import { AuthLayout } from './AuthLayout';
import { Button } from '../ui/Buttons/Button';
import { InputField } from '../ui/Input';

type LocalStore = [UserConnectStore];
type LocalFormValue = {
  email: string
};

const LoginResetPasswordPage = observer(() => {

  const [userConnectStore]: LocalStore = useInstances<LocalStore>(UserConnectStore);
  const { isNewUrlSent }: UserConnectStore = userConnectStore;

  const { t } = useTranslation('auth');
  const search: string = location.search;
  const params: URLSearchParams = new URLSearchParams(search);
  const email: string = params.get('email') ?? '';

  const sendEmailForResetPassword: ({ email }: LocalFormValue, setSubmitting: (isSubmitting: boolean) => void) => Promise<void> = useCallback(async ({ email }: LocalFormValue, setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    await userConnectStore.requestPasswordChange(email);
    setSubmitting(false);
  }, []);

  const formik = useFormik({
    initialValues: {
      email,
    },
    validationSchema: validationEmail,
    onSubmit: (
      values: LocalFormValue,
      { setSubmitting }: FormikHelpers<LocalFormValue>,
    ) => sendEmailForResetPassword(values, setSubmitting),
  });


  return (
    <AuthLayout>
      {isNewUrlSent
        ? (
          <div className={'flex flex-col items-center space-y-8'}>
            <img className="block md:hidden w-[200px]" alt="Openeat" src={openeat}/>
            <img className={'w-[125px] mb-4'} alt="Succès du reset de mot de passe" src={resetSuccess}/>
            <p>{t('subTitleResetPasswordSuccess')}</p>
          </div>
        )
        : (
          <div className={'flex flex-col items-center space-y-8'}>
          <img className="block md:hidden w-[200px]" alt="Openeat" src={openeat}/>
            <p>{t('subtitleResetPassword')}</p>
            <p>{t('subtitleResetPassword_two')}</p>
            <form className={'w-full lg:w-2/3 xl:w-1/2 space-y-4'} onSubmit={formik.handleSubmit} noValidate>
              <InputField
                id="email"
                name="email"
                label={t('email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.email) && formik.touched.email}
                endIcon={<MailSvg/>}
                fullWidth
                required
                placeholder={t('emailPlaceholder')}
                errorMessage={formik.errors.email}
                autoFocus
                autoComplete="new-password"
              />
              <Button
                variant="contained"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                className={'mx-auto'}
                type="submit"
              >
                {t('resetPassword')}
              </Button>
            </form>
          </div>
        )}
    </AuthLayout>
  );
});

export default LoginResetPasswordPage;