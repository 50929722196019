import { ManagementUnitModel } from '../../models/agencies/ManagementUnit.model';
import { AgencyAdminManagementUnitSettingModel } from '../../models/agencyAdmin/AgencyAdmin.model';

export const DEFAULT_MANAGEMENT_UNIT_CODE = 0;
export const DEFAULT_MANAGEMENT_UNIT_ID = '_DEFAULT_MANAGEMENT_UNIT_';
export const DEFAULT_MANAGEMENT_UNIT_NAME = 'unité par défaut';

export function getDefaultAgencyManagementUnit(allAgencyUnits: ManagementUnitModel[]): ManagementUnitModel | null {
  const defaultUnit: ManagementUnitModel | undefined = allAgencyUnits.find(isDefaultManagementUnit);

  return defaultUnit ?? null;
}

export function isDefaultManagementUnit(unit: Pick<ManagementUnitModel, 'uid'>): boolean {
  return unit.uid === DEFAULT_MANAGEMENT_UNIT_ID;
}

export function transformAgencyAdminManagementUnitSettingToManagementUnitModel(setting: AgencyAdminManagementUnitSettingModel, isActive: boolean = true): ManagementUnitModel {
  const { uid, creationDate, agencyId, managementUnitCode, managementUnitName, managementUnitShortName } = setting;

  const managementUnit: ManagementUnitModel = {
    uid,
    creationDate,
    agencyId,
    code: managementUnitCode,
    name: managementUnitName,
    active: isActive,
  };

  if (managementUnitShortName) {
    managementUnit.shortName = managementUnitShortName;
  }

  return managementUnit;
}
