import React from 'react';
import { observer } from 'mobx-react-lite';
import MandateSignaturePending from './MandateSignaturePending';
import MandateSignatureError from './MandateSignatureError';
import MandateSignatureSuccess from './MandateSignatureSuccess';
import { MandateStore } from '../../../../Stores/Mandate.store';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../../../ui/Dialog';
import { ReactComponent as CloseSvg } from '../../../../assets/svg/close.svg';

export interface IMandateSignatureDialog {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
}

const MandateSignatureDialog = observer((props: IMandateSignatureDialog) => {
  const { isSignaturePending, hasSignatureError, isMandateSigned }: MandateStore = useInstance(MandateStore);
  const { t } = useTranslation('mandate');

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
    >
      <div className={'h-screen p-10'}>
        <div className={'flex justify-between items-center pb-5'}>
          <h1>{t('header')}</h1>
          <CloseSvg
            onClick={props.onClose}
            className={'text-primary cursor-pointer hover:text-primary/75 transition-colors ease-in-out duration-300'}
          />
        </div>

        <div>
          <p>{t('subHeader')}</p>
          { isSignaturePending && <MandateSignaturePending {...props} /> }
          { hasSignatureError && <MandateSignatureError {...props} /> }
          { isMandateSigned && <MandateSignatureSuccess {...props} /> }
        </div>
      </div>

    </Dialog>

  );
});

export default MandateSignatureDialog;
