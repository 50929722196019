import React, { FunctionComponent, useEffect, useState } from 'react';
import AddBeneficiariesUploadSuccess from './AddBeneficiariesUploadSuccess';
import { useInstances } from 'react-ioc';
import { BeneficiariesUploadStore, LocalUploadRef } from '../../../Stores/BeneficiariesUpload.store';
import { BeneficiariesFileUploadModel } from '@assets/models/beneficiaries/BeneficiariesFileUpload.model';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'ui/Spinner';
import { ReactComponent as CloseSvg } from '../../../assets/svg/close.svg';

interface AddBeneficiariesUploadManagerProps {
  uploadRef: LocalUploadRef;
  onClose: () => void;
}

export const AddBeneficiariesUploadSuccessDrawer: FunctionComponent<AddBeneficiariesUploadManagerProps> = (props) => {
  const [beneficiariesUploadStore] = useInstances(BeneficiariesUploadStore);
  const { t } = useTranslation('beneficiaries');

  const [fileUploadDetails, setFileUploadDetails] = useState<BeneficiariesFileUploadModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasServerError, setHasServerError] = useState<boolean>(false);

  useEffect(() => {
    if (!props.uploadRef) {
      return;
    }

    setIsLoading(true);
    setHasServerError(false);

    beneficiariesUploadStore.getFileUploadDetails(props.uploadRef)
      .then((uploadDetails) => setFileUploadDetails(uploadDetails))
      .catch(() => setHasServerError(true))
      .finally(() => setIsLoading(false));
  }, [props.uploadRef]);

  const {
    beneficiariesCreated = [],
    beneficiariesUpdated = [],
    beneficiariesFailed = []
  } = fileUploadDetails ?? {};

  return (
    <div className={'min-h-screen text-primary p-10'}>
      <div className={'flex justify-between pb-10'}>
        <h1>{t('uploadBeneficiariesResultDrawer.title')}</h1>
        <CloseSvg className={'w-10 h-10 text-primary'}/>
      </div>

      <div className={'flex justify-center'}>

        {isLoading && (
          <Spinner size={'lg'}/>
        )}

        {!isLoading && hasServerError && (
          'Erreur'
        )}

        {!isLoading && !hasServerError && fileUploadDetails && (
          <AddBeneficiariesUploadSuccess
            beneficiariesCreated={beneficiariesCreated}
            beneficiariesUpdated={beneficiariesUpdated}
            beneficiariesFailed={beneficiariesFailed}
          />
        )}
      </div>
    </div>
  );
};