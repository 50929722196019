
/* eslint-disable max-len */
import React from 'react';
import { COLORS } from '../Style/Style';

interface SvgProps extends React.SVGProps<SVGSVGElement>
{
    color?: string;
}

const Mail = ({ color = COLORS.textPrimary, ...rest }: SvgProps) => (
  <svg width='20px' height='17px' viewBox='0 0 20 17' version='1.1' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>mail</title>
    <g id='POC-Desktop' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='04_00_Connexion' transform='translate(-1253.000000, -495.000000)' fill={color}>
        <g id='Composants-/-Mobile&amp;Web-/-Input-/-Vide' transform='translate(871.000000, 461.000000)'>
          <g id='Icones-/-Calendrier' transform='translate(382.000000, 34.000000)'>
            <path d='M18.6363636,0.5 L1.36363636,0.5 C0.610520796,0.5 0,1.11725448 0,1.87867647 L0,14.7463235 C0,15.5077455 0.610520796,16.125 1.36363636,16.125 L18.6363636,16.125 C19.3894792,16.125 20,15.5077455 20,14.7463235 L20,1.87867647 C20,1.11725448 19.3894792,0.5 18.6363636,0.5 Z M18.6363636,13.7720588 L13.7511535,8.60508637 L12.6430661,9.43773987 L17.6727273,14.7463235 L2.32727273,14.7463235 L6.9895618,9.21197659 L6.55433921,8.88136515 L5.97480563,8.43993509 L1.36363636,13.7720588 L1.36363636,4.92555147 L9.54545455,11.1617647 C9.78787879,11.3455882 10.1212121,11.3455882 10.3636364,11.1617647 L18.6272727,4.92095588 L18.6363636,13.7720588 Z M18.6363636,1.87867647 L18.6363636,3.20220588 L9.96818182,9.75091912 L1.36363636,3.20220588 L1.36363636,1.87867647 L18.6363636,1.87867647 Z' id='Shape'></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Mail;
