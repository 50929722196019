import { makeStyles } from '@mui/styles';
import { COLORS, FONT, FONT_WEIGHT } from '../../Style/Style';

export const useFooterComponentStyles = makeStyles(() => ({
  root: {
    flex: '0 0 auto',
    fontFamily: FONT.primary,
    display: 'flex',
    flexDirection: 'row',
    height: '210px',
    backgroundColor: COLORS.footerBlock,
    color: COLORS.textPrimary,
    fontSize: '14px',
    padding: '30px 65px',
  },
  leitmotif: {
    color: COLORS.black,
    fontWeight: FONT_WEIGHT.semiBold,
    marginTop: '9px',
    marginBottom: '64px',
  },
  address: {
    justifyContent: 'flex-end',
  },
  blockLarge: {
    flex: '0 0 40%',
    minWidth: '0px',
    padding: '0 2rem',
  },
  block: {
    flex: '0 0 20%',
    minWidth: '0px',
    padding: '0 2rem',
  },
  title: {
    color: COLORS.grey,
    fontSize: '16px',
    letterSpacing: '2px',
  },
  content: {
    marginTop: '16px',
    cursor: 'pointer',
    display: 'flex',
    color: COLORS.textPrimary,
    textDecoration: 'none',
    '& svg': {
      marginRight: '10px',
    },
    '& img': {
      marginRight: '10px',
    },
    '& a': {
      color: COLORS.textPrimary,
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  logos: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '140px',
    marginTop: '16px',
    color: COLORS.textPrimary,
  },
  backDrop: {
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
}));
