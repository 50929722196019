import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ProfilesList from '../Components/Lists/ProfilesList';
import { Button } from '../../ui/Buttons/Button';
import { PageBlock } from 'ui/layout/PageBlock';
import { SectionWrapper } from '../../ui/layout/SectionWrapper';
import { BackButton } from '../../ui/Buttons/BackButton';

const ProfilesPage = observer(() => {
  const { t } = useTranslation('settings');

  const handleCreateUser = () => {
    console.log('clicked');
  };

  return (
    <PageBlock>
      <SectionWrapper>
        <div className={'flex justify-between'}>
          <div className={'flex items-center gap-2'}>
            <BackButton to={'/settings'}/>
            <h1>{t('profiles')}</h1>
          </div>
          <Button onClick={handleCreateUser}>
            {t('addProfileLabel')}
          </Button>
        </div>
        <ProfilesList/>
      </SectionWrapper>
    </PageBlock>
  );
});

export default ProfilesPage;