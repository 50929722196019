import { action, makeAutoObservable } from 'mobx';

export class BeneficiariesViewStore {
  showInactiveBeneficiaries: boolean = false;
  openDialog: boolean = false;
  inputFilter: string = '';

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setShowInactiveBeneficiaries (isActive: boolean) {
    this.showInactiveBeneficiaries = isActive;
  };

  @action setOpenDialog (isOpen: boolean) {
    this.openDialog = isOpen;
  }

  @action setInputFilter (str: string) {
    this.inputFilter = str;
  }
};
