import React, { FunctionComponent } from 'react';
import { MonthYearInput } from './MonthYearInput';

export const EditableGridMonthYear: FunctionComponent<any> = (props) => {
  const { id, api, field, value: date = new Date() } = props;
  const handleOnChange = (monthIndex: number, fullYear: number) => {
    const newDate = new Date(fullYear, monthIndex, 1);
    api.setEditCellValue({ id, field, value: newDate });
    api.commitCellChange({ id, field });
  };

  return (
    <>
      <MonthYearInput monthIndex={date.getMonth()} fullYear={date.getFullYear()} onChange={handleOnChange}/>
    </>
  );
};
