import { DomainError } from './domainError.util';

export declare type FunctionsErrorCode =
  'ok'
  | 'cancelled'
  | 'unknown'
  | 'invalid-argument'
  | 'deadline-exceeded'
  | 'not-found'
  | 'already-exists'
  | 'permission-denied'
  | 'resource-exhausted'
  | 'failed-precondition'
  | 'aborted'
  | 'out-of-range'
  | 'unimplemented'
  | 'internal'
  | 'unavailable'
  | 'data-loss'
  | 'unauthenticated';

export declare type ErrorChannel =
  | 'STANDARD'
  | 'GENIUS'
  | 'CRITICAL'
  | 'INPUT'
  | 'SUPPORT'
  | 'SERVICES'
  | 'AUTH';

export type ManageErrorArgument = {
  code: FunctionsErrorCode,
  message: string,
  verbose?: boolean,
  statusCode?: number,
  channel?: ErrorChannel
};

export function manageError({ code, message, verbose = true, statusCode = 500, channel = 'STANDARD'}: ManageErrorArgument): never {
  if (verbose) {
    console.error(message);
  }
  throw new DomainError(message, code, statusCode, channel);
}
