/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateBeneficiaryAccountCommand = {
  agencyId: string;
  email: string;
  firstRightDate: string;
  activeSundaysAndHolidays?: boolean;
  title: CreateBeneficiaryAccountCommand.title;
  firstname: string;
  lastname: string;
  mobilephone?: string | null;
  registrationNumber: string;
  streetAddress: string;
  additionalAddress?: string | null;
  postalCode: string;
  city: string;
  countryCode: string;
  iban?: string | null;
  numberOfWorkingDays: number;
}

export namespace CreateBeneficiaryAccountCommand {

  export enum title {
    UNKNOW = 'Unknow',
    MR = 'Mr',
    MRS = 'Mrs',
  }

}
