import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, IconButton,
  Link,
  Paper, SvgIcon, Tooltip,
  Typography,
  Skeleton
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { ReactComponent as PaperClipSvg } from '../../../../assets/svg/paper-clip.svg';
import { RestitutionMethod, RightsReconductionType } from '../../../../assets/models/agencies/Agency.model';
import { formatCurrencyToEuro } from '../../../../Utils/format.utils';
import { AgencyMillesimeReport, BeneficiaryMillesimeReport } from '@assets/models/millesime/MillesimeReports.model';
import { observer } from 'mobx-react-lite';
import { ProductType } from '@assets/models/products/Products.model';
import {
  BeneficiariesReportsData,
  MillesimeReportsViewStore,
} from '../../../../Stores/viewStore/MillesimeReportsView.store';
import {
  AsyncPaginatedTable,
  AsyncPaginatedTableProps,
  TableColumn,
} from '../../../../Component/AsyncPaginatedTable/AsyncPaginatedTable';
import downloadIcon from '../../../../assets/download.svg';
import { Spinner } from 'ui/Spinner';

interface EndOfMillesimeReportPanelProps {
  agencyReport: AgencyMillesimeReport<any, 'DONE'>;
  beneficiariesReportsData?: BeneficiariesReportsData;
  onCollapsedStateChange?: (collapsed: boolean) => void;
  onBeneficiariesReportsPageChange?: (page: number) => void;
  onBeneficiariesReportsPageSizeChange?: (pageSize: number) => void;
  onDownloadReceipt?: () => void;
  isDownloadingReceipt?: boolean;
  onDownloadBeneficiariesReportsCsv?: () => void;
  isPreparingBeneficiariesReportsCsv?: boolean;
}

interface RestitutionDetailsTableProps extends Omit<AsyncPaginatedTableProps<BeneficiaryMillesimeReport<ProductType, 'DONE'>, 'uid'>, 'columns' | 'classes' | 'rowKey'> {

}

const EndOfMillesimeReports: FunctionComponent<any> = observer(({ reportsStore }: {reportsStore: MillesimeReportsViewStore<ProductType>}) => {

  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    reportsStore.fetchAgencyReports('DONE');
  }, []);

  const handleCollapsedStateChange = useCallback((report: AgencyMillesimeReport<ProductType, 'DONE'>, collapsed: boolean) => {
    if (collapsed) {
      reportsStore.clearBeneficiariesReports(report.uid);
    } else {
      reportsStore.fetchBeneficiariesReports(report.uid, { requiredPage: 0, pageSize: pageSize });
    }
  }, [pageSize]);

  const handlePageChange = useCallback((report: AgencyMillesimeReport<ProductType, 'DONE'>, page: number) => {
    reportsStore.fetchBeneficiariesReports(report.uid, { requiredPage: page, pageSize: pageSize });
  }, [pageSize]);

  const handlePageSizeChange = useCallback((report: AgencyMillesimeReport<ProductType, 'DONE'>, newPageSize: number) => {
    setPageSize(newPageSize);
    reportsStore.fetchBeneficiariesReports(report.uid, { requiredPage: 0, pageSize: newPageSize });
  }, []);

  const handleDownloadReceipt = useCallback((report: AgencyMillesimeReport<ProductType, 'DONE'>) => {
    reportsStore.downloadReceipt(report.uid);
  }, []);

  const handleDownloadCsv = useCallback((report: AgencyMillesimeReport<ProductType, 'DONE'>) => {
    reportsStore.downloadBeneficiariesReportsCsv(report.uid);
  }, []);

  return (
    <div className={'flex flex-col my-5 gap-4'}>
      {
        reportsStore.isLoadingAgencyReports
        ? (<div className={'w-full h-full flex items-center justify-center'}>
            <Spinner size={'lg'}/>
          </div>)
        : (
            reportsStore.agencyReports.map((report: AgencyMillesimeReport<ProductType, 'DONE'>) =>
              (<EndOfMillesimeReportPanel
                key={report.uid}
               agencyReport={report}
               beneficiariesReportsData={reportsStore.getBeneficiariesReportsData(report.uid)}
               onCollapsedStateChange={(collapsed: boolean) => handleCollapsedStateChange(report, collapsed)}
               onBeneficiariesReportsPageChange={(page: number) => handlePageChange(report, page)}
               onBeneficiariesReportsPageSizeChange={(pageSize: number) => handlePageSizeChange(report, pageSize)}
               onDownloadReceipt={() => handleDownloadReceipt(report)}
               isDownloadingReceipt={reportsStore.isDownloadingReceipt(report.uid)}
               onDownloadBeneficiariesReportsCsv={() => handleDownloadCsv(report)}
               isPreparingBeneficiariesReportsCsv={reportsStore.isPreparingBeneficiariesReportsCsv(report.uid)}
              />),
            )
          )
      }
    </div>
  );
});

const EndOfMillesimeReportPanel: FunctionComponent<EndOfMillesimeReportPanelProps> = ({
  agencyReport,
  beneficiariesReportsData,
  onCollapsedStateChange ,
  onBeneficiariesReportsPageChange,
  onBeneficiariesReportsPageSizeChange,
  onDownloadReceipt,
  isDownloadingReceipt,
  onDownloadBeneficiariesReportsCsv,
  isPreparingBeneficiariesReportsCsv,
}) => {

  const { t } = useTranslation('millesime');
  const {
    year, rightsReconductionType, restitutionMethod, restitutionIBAN, totalAmountOfUnusedCredits, receiptUrl
  } = agencyReport;

  const [reportsCount, setReportsCount] = useState<number | null>(null);

  useEffect(() => {
    if (!beneficiariesReportsData || beneficiariesReportsData?.count === -1) {
      setReportsCount(null);
    }
    if (beneficiariesReportsData?.count > -1) {
      setReportsCount(beneficiariesReportsData.count);
    }
  }, [beneficiariesReportsData?.count]);

  const shouldDisplayBeneficiariesReports = totalAmountOfUnusedCredits > 0;
  const shouldShowRestitutionIban = (rightsReconductionType === 'DROP' && restitutionMethod === 'RESTITUTION_IBAN_PAYOUT');
  const shouldShowRestitutionTotalAmount = typeof totalAmountOfUnusedCredits === 'number';
  const shouldShowInvoiceDownloadLink = typeof agencyReport.receiptId !== 'undefined';

  const getAccordionSummaryText = (selectedRightsReconductionType: RightsReconductionType, restitutionMethod: RestitutionMethod): string => {
    return selectedRightsReconductionType === 'KEEP'
      ? t('endOfMillesimeReportPanel.selectedTypeLabel.KEEP')
      : t(`endOfMillesimeReportPanel.selectedTypeLabel.DROP.${restitutionMethod}`);
  };

  const handleDownloadReceipt = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    if (isDownloadingReceipt) {
      return;
    }

    onDownloadReceipt?.();
  }, [isDownloadingReceipt]);

  return (
    <div className={'bg-background rounded-br10 p-4'}>
      <div className={'flex-flex-wrap gap-2 px-4 py-6'}>
        <Typography variant="inherit" component="div">
          {t('endOfMillesimeReportPanel.title', { year })}
        </Typography>
        {shouldShowInvoiceDownloadLink &&
            <div className={'flex'}>
              {
                isDownloadingReceipt
                  ? (<Spinner />)
                  : (<PaperClipSvg title="paper clip icon" className={'w-4 h-4 text-primary'}/>)
              }
              <Link href={'#'} underline="always" className={'text-sm text-primary ml-2'} onClick={handleDownloadReceipt}>
                {t('endOfMillesimeReportPanel.downloadReceiptLabel')}
              </Link>
            </div>
        }
      </div>

      <div className={'flex flex-col'}>
        <Accordion
          className={'bg-inherit border-none shadow-none before:hidden'}
          onChange={(event, expanded: boolean) => onCollapsedStateChange?.(!expanded)}
          disabled={!shouldDisplayBeneficiariesReports}
          sx={{
            '& .MuiPaper-root.MuiAccordion-root.Mui-disabled': {
              backgroundColor: 'inherit',
            },
            '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-disabled': {
              opacity: 1,
            },
          }}
        >
          <AccordionSummary
            expandIcon={shouldDisplayBeneficiariesReports && <ExpandMore/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={'grid gap-x-[100px] gap-y-[20px] grid-cols-[600px_240px_auto] items-end w-full text-primary text-sm md:grid-cols-1'}>
              <div>
                <Typography variant="inherit" component="div">
                  {getAccordionSummaryText(rightsReconductionType, restitutionMethod)}
                </Typography>
              </div>
              <div>
                {shouldShowRestitutionIban &&
                  <div className="restitutionIbanContainer">
                    <Typography variant="inherit" component="div" className={'text-muted text-sm mb-2'}>
                      {t('endOfMillesimeReportPanel.restitutionIbanLabel')}
                    </Typography>
                    <Typography variant="inherit" component="div">
                      {restitutionIBAN || '-'}
                    </Typography>
                  </div>
                }
              </div>
              <div>
                {shouldShowRestitutionTotalAmount &&
                  <div className="restitutionAmountContainer">
                    <Typography variant="inherit" component="div" className={'text-muted text-sm mb-2'}>
                      {t('endOfMillesimeReportPanel.restitutionTotalAmountLabel')}
                    </Typography>
                    <Typography variant="inherit" component="div">
                      {formatCurrencyToEuro(totalAmountOfUnusedCredits)}
                    </Typography>
                  </div>
                }
              </div>
            </div>

          </AccordionSummary>
          <AccordionDetails className={'flex items-center justify-end'}>
            <div className={'bg-white w-full rounded-br10'}>
              <Paper className={'p-4 border-none rounded-br10 shadow-none'}>
                <div className={'p-2 pt-0 mb-2 text-primary text-sm flex items-center'}>
                  <Typography variant="inherit" component="div" className={'grow-1'}>
                    {
                      reportsCount === null
                      ? (<Skeleton animation="wave" variant="text"/>)
                      : t('endOfMillesimeReportPanel.restitutionDetailsTable.beneficiaryCount', { count: reportsCount })
                    }
                  </Typography>
                  <Tooltip title="exporter au format CSV">
                    <div>
                      <IconButton
                        onClick={onDownloadBeneficiariesReportsCsv}
                        disabled={reportsCount === null || isPreparingBeneficiariesReportsCsv}
                      >
                        {
                          isPreparingBeneficiariesReportsCsv
                            ? (<Spinner />)
                            : (
                              <SvgIcon viewBox="0 0 18 18" fontSize="small" style={{color: 'black'}}>
                                <use href={`${downloadIcon}#download`}/>
                              </SvgIcon>
                            )
                        }
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
                <RestitutionDetailsTable paginatedData={beneficiariesReportsData}
                                         onPageChange={onBeneficiariesReportsPageChange}
                                         onPageSizeChange={onBeneficiariesReportsPageSizeChange}
                />
              </Paper>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

const RestitutionDetailsTable: FunctionComponent<RestitutionDetailsTableProps> = ({
  paginatedData,
  onPageChange,
  onPageSizeChange
}) => {

  const columns: TableColumn<BeneficiaryMillesimeReport<ProductType, 'DONE'>>[] = [
    {
      id: 'beneficiaryFullName',
      label: 'Collaborateur',
      align: 'left',
      minWidth: 170,
      render: (report: BeneficiaryMillesimeReport<ProductType, 'DONE'>) => `${report.firstName} ${report.lastName}`
    },
    {
      id: 'totalAmountOfUnusedCredits',
      label: 'Montant',
      align: 'left',
      minWidth: 170,

      valueKey: 'totalAmountOfUnusedCredits',
      format: (amount: number) => formatCurrencyToEuro(amount),
    },
  ];

  return <AsyncPaginatedTable
    rowKey={'uid'}
    columns={columns}
    paginatedData={paginatedData}
    onPageChange={onPageChange}
    onPageSizeChange={onPageSizeChange}
  />;
}

export default EndOfMillesimeReports;
