import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../../Stores/Agencies.store';
import { Spinner } from '../../../../ui/Spinner';

type LocalStore = [AgenciesStore];

const MethodDissociatedBankTransfer = observer(() => {
  const { t } = useTranslation('payment');

  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const {paymentBankAccountMain, paymentBankAccountFees, isBankAccountsLoading}: AgenciesStore = agenciesStore;

  return (
    <div className={'space-y-4'}>
      <div className={'bg-background rounded-br10 p-4 space-y-6'}>
        <h2 className={'text-base m-0'}>
          {t('dissociatedBankTransfer.mainTitle')}
        </h2>
        <div className={'inline-flex flex-col border-solid !border rounded-br10 border-primary/50 p-4 space-y-2'}>
          <p>{t('dissociatedBankTransfer.mainLabel')}</p>
          <p className={'font-bold'}>
            {
              isBankAccountsLoading
                ? <Spinner />
                : t('bankTransfer.iban', { iban: paymentBankAccountMain?.iban ?? '-' })
            }
          </p>
        </div>
      </div>
      <div className={'bg-background rounded-br10 p-4 space-y-6'}>
        <h2 className={'text-base m-0'}>
          {t('dissociatedBankTransfer.feesTitle')}
        </h2>
        <div className={'inline-flex flex-col border-solid !border rounded-br10 border-primary/50 p-4 space-y-2'}>
          <p>{t('dissociatedBankTransfer.feesLabel')}</p>
          <p className={'font-bold'}>
            {
              isBankAccountsLoading
                ? <Spinner />
                : t('bankTransfer.iban', { iban: paymentBankAccountFees?.iban ?? '-' })
            }
          </p>
        </div>
      </div>
    </div>
  )
})

export default MethodDissociatedBankTransfer;