import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import logoText from '../assets/logo-text-new.svg';
import { useMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { UserConnectStore } from '../Stores/UserConnect.store';
import { useInstance } from 'react-ioc';

export const AuthPanel: () => React.JSX.Element = observer(() => {
  const userConnectStore: UserConnectStore = useInstance<UserConnectStore>(UserConnectStore);
  const { isNewUser } = userConnectStore;

  const isLogin = useMatch('/login');
  const isPasswordLogin = useMatch('/login/password');
  const isReinitialisation = useMatch('/login/reinitialisation');
  const isResetPassword = useMatch('/reset-password');
  const isRegister = useMatch('/register-admin');

  const tName: string = useMemo(() => {
    if (isLogin || isPasswordLogin || isReinitialisation) {
      return 'auth';
    }
    if (isResetPassword || isRegister) {
      return 'resetpassword';
    }
    return 'auth';
  }, [isLogin, isPasswordLogin, isReinitialisation, isResetPassword, isRegister]);

  const { t } = useTranslation(tName);

  return (
    <div
      className="hidden sm:flex flex-col items-center flex-1 bg-no-repeat bg-background bg-left-bottom p-8 h-screen"
    >
      <img className="pt-5 w-[250px] self-start" alt="Openeat" src={logoText}/>

      {isLogin && (<>
        <p className={'font-bold text-5xl text-center pt-[200px]'}>
          {t('infoPanel.title').replace('restaurant ', 'restaurant\n')}
        </p>
        <p className={'pt-5 text-center text-2xl'}>
          {t('infoPanel.subtitle')
            .replace('vos ', 'vos\n')
            .replace('votre ', 'votre\n')}
        </p>
      </>)}

      {(isPasswordLogin || isReinitialisation) && (<>
        <p className={'font-bold text-5xl text-center pt-[200px]'}>
          {t('hello')}
        </p>
        <p className={'pt-5 text-center text-2xl'}>
          {t('hello2')}
        </p>
      </>)}

      {(isResetPassword || isRegister) &&
        <p className={'text-center text-2xl pt-[200px]'}>
          {isNewUser ? t('title').replace('!', '!\n') : t('title2')}
        </p>
      }

    </div>
  )
})