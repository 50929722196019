import { makeAutoObservable } from 'mobx';

export class RechargingViewStore {
  openOrderDetails = false;
  openInvoiceDetails = false;
  openBeneficiaryDetails = false;
  today: Date = new Date();
  currentMonth: Date = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
  reloadingDateKey: string = sessionStorage.getItem('reloadingDateKey')
    ||'unknown';
  reloadingDate: { unknown: null, current: Date; before: Date; after: Date } =
    {
      unknown: null,
      before: new Date(new Date(this.today.getFullYear(), this.today.getMonth(), 1).setMonth(this.currentMonth.getMonth() - 1)),
      current: this.currentMonth,
      after: new Date(new Date(this.today.getFullYear(), this.today.getMonth(), 1).setMonth(this.currentMonth.getMonth() + 1)),
    };

  get selectedReloadingDate(): Date {
    return this.reloadingDate[this.reloadingDateKey];
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setInitialData() {
    this.currentMonth = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
    this.setReloadingDateKey('unknown');
  }

  setOpenOrderDetails(value: boolean) {
    this.openOrderDetails = value;
  }

  setOpenInvoiceDetails(value: boolean) {
    this.openInvoiceDetails = value;
  }

  setOpenBeneficiaryDetails(value: boolean) {
    this.openBeneficiaryDetails = value;
  }

  setReloadingDateKey(value: string) {
    this.reloadingDateKey = value;
  }

  setRechargingMonthCsv(rechargingYear: number, rechargingMonth: number) {
    const rechargingYearTimeStamp: number = new Date().setFullYear(rechargingYear, rechargingMonth - 1, 1);
    this.currentMonth = new Date(rechargingYearTimeStamp);
    this.reloadingDate = { // we do not use before and after at this point + setInitialData when switching to manual recharging
      unknown: null,
      before: this.currentMonth,
      current: this.currentMonth,
      after: this.currentMonth,
    };
    this.setReloadingDateKey('current');
  }
}
