import { BeneficiaryModel } from '../assets/models/beneficiaries/Beneficiary.model';
import { BeneficiaryProvision } from '../assets/responses/orders/GetAgencyOrderProvision.response';

export function sortBeneficiaryList(beneficiariesList: BeneficiaryModel[]): BeneficiaryModel[] {
  return [...beneficiariesList].sort(compareBeneficiaries);
}

export function sortBeneficiaryProvisionList(beneficiariesList: BeneficiaryProvision[]): BeneficiaryProvision[] {
  return beneficiariesList.sort((
      { beneficiary: beneficiaryA }: BeneficiaryProvision,
      { beneficiary: beneficiaryB }: BeneficiaryProvision,
    ) => compareBeneficiaries(beneficiaryA, beneficiaryB));
}

export function compareBeneficiaries(beneficiaryA: BeneficiaryModel, beneficiaryB: BeneficiaryModel) {
  const fullNameA: string = beneficiaryA.lastName.toLowerCase() + beneficiaryA.firstName.toLowerCase();
  const fullNameB: string = beneficiaryB.lastName.toLowerCase() + beneficiaryB.firstName.toLowerCase();

  return fullNameA.localeCompare(fullNameB);
}