import React, { FunctionComponent, useEffect } from 'react';

const ChatBotComponent: FunctionComponent = (props) => {
  const chatUrl = 'https://www.watcheezy.net/deliver/targetfirst.js?wzkey=ff4ef34da7d3b9b6e4f2bfa178ae1a3c';
  const botIconBoxShadow = 'rgb(0 0 0 / 20%) 0px 3px 18px;';

  useEffect(() => {
    const existingScript: HTMLElement | null = document.getElementById('chatbot-script');
    if (!existingScript) {
      const node: HTMLScriptElement = document.createElement('script');
      node.id = 'chatbot-script';
      node.src = chatUrl;
      node.type = 'text/javascript';
      node.async = true;
      node.defer = true;
      document.getElementById('chat-container').appendChild(node);
    }
  }, []);

  useEffect(() => {
    const chatBotIcon: HTMLElement | null = document.getElementById('mydesk-outer');
    if (chatBotIcon) {
      chatBotIcon.style.display = 'block';
      chatBotIcon.parentElement.style.boxShadow = botIconBoxShadow;
    }
    
    return () => {
      const chatBotIcon: HTMLElement | null = document.getElementById('mydesk-outer');
      if (chatBotIcon) {
        chatBotIcon.style.display = 'none';
        chatBotIcon.parentElement.style.boxShadow = 'none';
      }
    };
  }, []);

  return (<div id="chat-container">
  </div>);
};

export default ChatBotComponent;