
export function formatCurrencyToEuro(value: number) {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency', currency: 'EUR',
  }).format(value);
}

export function formatDateToLocaleString(date: Date | string | number, locales: string = 'fr-FR') {
  return new Date(date).toLocaleDateString(locales);
}

export function sanitizeEmailFromSearchParams(email: string): string {
  // URLSearchParams replaces `+` characters by spaces when parsing.
  // When reading an email address, we should transform back to `+`.

  return sanitizeEmail(email)
    .replace(' ', '+');
}

export function sanitizeEmail(email: string): string {
  return email.trim().toLowerCase();
}