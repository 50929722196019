import { action, makeAutoObservable } from 'mobx';

export class AddNewBeneficiariesUploadViewStore {
  file: File = null;
  fileLoading: boolean = false;
  csvRowError: boolean = false;
  showErrorDialog: boolean = false;

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setFileLoading (value: boolean) {
    this.fileLoading = value;
  }

  @action setCsvRowError (value: boolean) {
    this.csvRowError = value;
  }

  @action setShowErrorDialog (value: boolean) {
    this.showErrorDialog = value;
  }

  @action setFile (value: File) {
    this.file = value;
  }
}
