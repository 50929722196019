import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethodType } from '../../assets/types/PaymentMethod.type';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { PayingServiceStore } from '../../Stores/PayingService.store';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { useInstances } from 'react-ioc';
import CopyButton from '../../ui/Buttons/CopyButton';
import { getFrenchMoneyFormat } from '../../assets/utils/money/currencyFormat.util';
import { Button } from '../../ui/Buttons/Button';
import {ReactComponent as CongratsSvg} from '../../assets/svg/congrats.svg';
import {ReactComponent as WarningSvg} from '../../assets/svg/warninPoint.svg';

type LocalStore = [PayingServiceStore, OrderPaymentViewStore];

const OrderSummary: FunctionComponent = () => {
  const [
    payingServiceStore,
    orderPaymentViewStore,
  ]: LocalStore = useInstances<LocalStore>(PayingServiceStore, OrderPaymentViewStore);

  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation('payment');

  const expectedBankWirePaymentDescriptor: string = payingServiceStore.bankWireReference?.expectedBankWirePaymentDescriptor;
  const paymentMethod: PaymentMethodType = orderPaymentViewStore.paymentMethod;
  const amount = getFrenchMoneyFormat(orderPaymentViewStore.orderDetails.netAmountPayable, false);

  useEffect(() => sessionStorage.removeItem('reloadingDateKey'), []);

  return (
    <>
      <div className={'flex flex-col items-center space-y-16 py-10'}>
        <p className={'font-bold text-xl'}>{t('success.felicitations')}</p>
        <CongratsSvg title={'congratulations logo'} className={''}/>
        <div>
          <p className={'font-bold text-xl'}>{t('success.paymentDescription', { total: amount })}</p>
          <p className={'font-bold text-xl'}>{t('success.paymentDescription2')}</p>
        </div>
        {paymentMethod === 'BANK_TRANSFER' &&
            <div className={'flex justify-between items-center w-full rounded-br10 bg-background p-6'}>
                <div className={'flex justify-between items-center gap-4'}>
                    <WarningSvg className={'w-8 h-8 text-status-info'} title={'exclamation logo'} />
                    <span>{t('success.bankTransferDescription')}</span>
                </div>
                <div className={'flex justify-between items-center'}>
                    <span className={'font-bold text-xl'}>{expectedBankWirePaymentDescriptor}</span>
                    <CopyButton value={expectedBankWirePaymentDescriptor}/>
                </div>
            </div>
        }
        {paymentMethod === 'DISSOCIATED_BANK_TRANSFER' &&
            <div className={'flex bg-background p-10 rounded-br10 gap-4'}>
                <WarningSvg className={'w-8 h-8 text-status-info'} title={'exclamation logo'} />
                <div className={'flex flex-col space-y-2'}>
                    <p>{t('success.dissociatedBankTransferDescription')}</p>
                    <p>
                      {t('success.dissociatedBankTransferDescriptionTransfer')} <span className={'underline'}>{t('success.dissociatedBankTransferDescriptionFees')}</span></p>
                    <p>{t('success.dissociatedBankTransferDescriptionAnd')}</p>
                    <p>
                      {t('success.dissociatedBankTransferDescriptionTransfer')} <span className={'underline'}>{t('success.dissociatedBankTransferDescriptionMain')}</span></p>
                    <div className={'flex items-center'}>
                        <span className={'font-bold text-xl'}>{expectedBankWirePaymentDescriptor}</span>
                        <CopyButton value={expectedBankWirePaymentDescriptor} />
                    </div>
                </div>
            </div>
        }
      </div>
      <div className={'flex justify-end pb-10'}>
        <Button onClick={() => navigate('/reloading')}>
          {t('success.btnSubmit')}
        </Button>
      </div>
    </>
  );
};

export default OrderSummary;
