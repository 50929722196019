import React, { FunctionComponent } from 'react';
import { Spinner } from 'ui/Spinner';

const PageLoader: FunctionComponent = () => {
  return (
    <div className={'h-screen w-screen flex items-center justify-center'}>
      <Spinner size={'lg'}/>
    </div>
  );
};

export default PageLoader;
