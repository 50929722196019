
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@mui/material';
import { COLORS } from '../Style/Style';

const DownArrow = ({ svgColor = COLORS.textPrimary, ...rest }: any) => {
  return (
    <SvgIcon width='15px' height='9px' viewBox='0 0 15 9' version='1.1' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <title>Open menu</title>
      <g id='POC-Desktop' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='01_00_Dashboard' transform='translate(-1337.000000, -56.000000)' fill={svgColor}>
          <g id='Composants-/-Web-/-topbarre' transform='translate(1032.000000, 36.000000)'>
            <g id='Avatar' transform='translate(98.000000, 0.000000)'>
              <g id='Icones-/-Fleche-bas' transform='translate(207.000000, 20.000000)'>
                <path d='M4.87886658,-2.83349209 L4.9259932,-2.78334657 L11.0401954,3.8682634 C11.2808668,4.15165184 11.3152484,4.85595468 11.0401954,5.10391956 C11.0207937,5.12141052 8.97269621,7.34813955 4.89590289,11.7841067 C4.63746832,12.0503731 4.2184631,12.0503731 3.96002853,11.7841067 C3.71774612,11.5344819 3.70260347,11.1394538 3.91460058,10.8716536 L3.96002853,10.8198707 L9.68645196,4.48937617 L3.92993822,-1.8853863 C3.68926686,-2.16877474 3.71713867,-2.59952171 3.99219166,-2.84748659 C4.23286302,-3.06445586 4.58303729,-3.06959098 4.82805138,-2.8776365 L4.87886658,-2.83349209 Z' id='Combined-Shape' transform='translate(7.500000, 4.483806) rotate(90.000000) translate(-7.500000, -4.483806) '></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default DownArrow;
