import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTestReport } from '../../../Utils/Tester/BaseTester.service';
import {
  BeneficiaryRowTestResult,
  CsvBeneficiary,
} from '../../../Utils/Tester/BeneficiaryTester.service';
import { ReactComponent as WarningSvg } from '../../../assets/svg/warning.svg';
import { ReactComponent as ArrowSvg } from '../../../assets/svg/arrow.svg';

interface IAddBeneficiariesFromCsvResult {
  fileTestResult: BeneficiaryRowTestResult[];
  hasErrorOfNbColumn: boolean;
}

const entityStartLine = 2;

const AddBeneficiariesFromCsvError: FunctionComponent<IAddBeneficiariesFromCsvResult> = (props: IAddBeneficiariesFromCsvResult) => {
  const { t } = useTranslation('beneficiariesfromcsv');

  const beneficiaryWithErrorList: BeneficiaryRowTestResult[] = useMemo(() => {
    return props.fileTestResult.filter(row => row.errors.length > 0);
  }, [props.fileTestResult]);

  const accordionBaseClasses = "bg-status-error-light rounded shadow-none mt-1.5 pb-5 before:h-0";
  const accordionDetailsClasses = "rounded-b-br10 px-4 py-1.5 border-none";

  return (
    <>
      {props.hasErrorOfNbColumn && (
        <Accordion className={accordionBaseClasses}>
          <AccordionSummary
            expandIcon={<ArrowSvg className="w-10 h-10" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={accordionDetailsClasses}
          >
            <Typography className="flex text-sm">
              <WarningSvg className="w-10 h-10" />
              <span className="pl-2.5 font-bold text-status-error">
                Erreur sur le nombre de colonne présentes. Il doit y avoir 15 colonnes.
              </span>
            </Typography>
          </AccordionSummary>
        </Accordion>
      )}

      {beneficiaryWithErrorList?.map((row: BeneficiaryRowTestResult, index: number) => (
        <Accordion key={index} className={accordionBaseClasses}>
          <AccordionSummary
            expandIcon={<ArrowSvg className="w-10 h-10" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={accordionDetailsClasses}
          >
            <Typography className="flex text-sm">
              <WarningSvg className="w-10 h-10" />
              <span className="pl-2.5 font-bold text-status-error">
                {t('titleLineError', { count: row.errors.length, lineNumber: row.rowIndex + entityStartLine })}
              </span>
            </Typography>
          </AccordionSummary>

          {row.errors.map((fieldError: FieldTestReport<CsvBeneficiary>, index: number) => (
            <AccordionDetails key={index} className={accordionDetailsClasses}>
              <Typography className="flex text-sm">
                <span className="pl-25 font-normal text-status-error">
                  {t(
                    fieldError.errorMessage,
                    {
                      field: fieldError.columnName,
                      minValue: fieldError.minValue,
                      maxValue: fieldError.maxValue,
                      minLength: fieldError.minLength,
                      maxLength: fieldError.maxLength,
                      lowerRange: fieldError.lowerRange,
                      upperRange: fieldError.upperRange,
                      expectedValue: fieldError.expectedValue,
                    },
                  )}
                </span>
              </Typography>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </>
  );
};

export default AddBeneficiariesFromCsvError;