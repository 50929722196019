/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FindMandateOutputDto = {
    providerId?: string | null;
    documentUrl?: string | null;
    approvalUrl?: string | null;
    status?: FindMandateOutputDto.status;
    statusDescription?: string | null;
    bankReference?: string | null;
}

export namespace FindMandateOutputDto {

    export enum status {
        CREATED = 'CREATED',
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
        EXPIRED = 'EXPIRED',
        UNKNOWN = 'UNKNOWN',
    }


}
