import React, { FC, useCallback } from 'react';
import { ClickAwayListener, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as CopySvg } from './../../assets/svg/file-copy.svg';

interface Props {
  value: string;
  className?: string;
}

const CLASSES = {
  button: 'group flex items-center bg-transparent border-none cursor-pointer',
  ico: 'w-8 h-8 text-primary hover:text-primary/75 hover:animate-bounce-once',
  animation: 'transition-all duration-100 ease-in-out',
}

const CopyButton: FC<Props> = (props) => {
  const {className, value} = props;
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  async function copyLabelToClipboard() {
    handleTooltipOpen();
    await navigator.clipboard.writeText(value);
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copié !"
          placement="top-end"
        >
          <button className={clsx(CLASSES.button, CLASSES.animation, className)} onClick={() => copyLabelToClipboard()}>
            <CopySvg className={clsx(CLASSES.ico, CLASSES.animation)} />
          </button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

export default CopyButton;