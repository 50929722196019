/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GuidArrayRequestResult = {
    errorCode?: GuidArrayRequestResult.errorCode;
    errorMessage?: string | null;
    result?: Array<string> | null;
}

export namespace GuidArrayRequestResult {

    export enum errorCode {
        NONE = 'None',
        USER_UNAUTHORIZED = 'UserUnauthorized',
        INVALID_TOKEN = 'InvalidToken',
        INVALID_TICKET = 'InvalidTicket',
        INVALID_ENTITY = 'InvalidEntity',
        INVALID_FILE = 'InvalidFile',
        INVALID_IBAN = 'InvalidIban',
        REGISTRATION_NUMBER = 'RegistrationNumber',
        BAD_REQUEST = 'BadRequest',
        CONFLICT = 'Conflict',
        INTERNAL_SERVER = 'InternalServer',
    }


}
