import Yup from '../i18n/validation';

export const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/).required()
});

export const validationEmail = Yup.object().shape({
  email: Yup.string().email().required('Veuillez renseigner votre adresse mail'),
})

export const validationPassword = Yup.object().shape({
  password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/).required('Le mot de passe ne respecte pas les conditions requise.'),
})