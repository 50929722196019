import React from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  condition: boolean;
  label: string;
  size?: 'sm' | 'base' | 'xl';
}

export const StatusLed = (props: Props) => {
  const { label, className, condition, size = 'base' } = props;
  const ledColor: string = condition ? 'bg-green-led' : 'bg-status-failed';

  return (
    <div className={clsx('flex space-x-2 item-center', className)}>
      <div className={`w-3.5 h-3.5 rounded-full ${ledColor}`}></div>
      <p className={`text-${size}`}>{label}</p>
    </div>
  );
};
