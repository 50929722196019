/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyUserRequestResult } from '../models/CompanyUserRequestResult';
import type { SendContactMessageCommand } from '../models/SendContactMessageCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CompanyUserService {

  /**
   * Send Contact Message
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static sendContactMessage(
    requestBody?: SendContactMessageCommand,
  ): CancelablePromise<void> {
    return __request({
      method: 'POST',
      path: `/api/companyuser/contact/sendmessage`,
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Get companyUser
   * @returns CompanyUserRequestResult Success
   * @throws ApiError
   */
  public static getCompanyUser(): Promise<CompanyUserRequestResult> {
    return Promise.resolve({
      'result': {
        id: '7f9f25c0-5d0f-489a-bf0f-80aa3a5e6922', 'companyId': 'dbc2c09d-a719-4ece-cd6d-08d99eadebd2',
        firstName: 'Alain', 'lastName': 'Térieur', 'email': 'alainterieur@yopmail.com',
        'createdOn': '2021-11-03T09:48:53.1605851', 'modifiedOn': '2021-11-03T09:48:53.1605129',
      }, 'errorCode': 'None',
    } as CompanyUserRequestResult);
  }

}
