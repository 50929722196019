import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Skeleton,
  ClassNameMap,
} from '@mui/material';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { IPaginatedData } from '../../Models/Interfaces/IPaginatedData.model';

type TableClassKey = 'tableCell' | 'tablePagination';

type TablePaginationActionsProps = {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  isLoading?: boolean;
};

export interface TableColumn<EntityType extends Record<string, any> = Record<string, any>, ValueKey extends keyof EntityType = keyof EntityType> {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right';

  valueKey?: ValueKey;
  format?: (cellValue: EntityType[ValueKey]) => string;
  render?: (rowValue: EntityType) => React.JSX.Element | string
}


export interface AsyncPaginatedTableProps<
  EntityType extends { [P in RowKey]: string },
  RowKey extends keyof EntityType,
> {
  columns: TableColumn<EntityType>[];
  rowKey: RowKey;
  paginatedData?: IPaginatedData<EntityType>;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (page: number) => void;

  /**
   * Override or extend the styles applied to the component.
   */
  classes?: Partial<ClassNameMap<TableClassKey>>;
}

export function AsyncPaginatedTable<
  EntityType extends { [P in RowKey]: string },
  RowKey extends keyof EntityType,
>({
    columns,
    rowKey,
    paginatedData,
    onPageChange,
    onPageSizeChange,

  }: AsyncPaginatedTableProps<EntityType, RowKey>) {
  const {
    count: totalCount = 0,
    items: items = [],
    page = 0,
    pageSize= 10,
    isLoading = false,
  } = paginatedData ?? {};

  const handlePageChange = (event: unknown, newPage: number) => {
    onPageChange?.(newPage);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize: number = parseInt(event.target.value, 10);
    onPageSizeChange?.(newPageSize);
  };

  function getCellContent(column: TableColumn<EntityType>, item: EntityType) {
    if (column.render) {
      return column.render(item);
    }

    if (column.valueKey && column.format) {
      return column.format(item[column.valueKey]);
    }

    if (column.valueKey) {
      return item[column.valueKey];
    }

    return '';
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={'text-primary !bg-white !border-none'}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { isLoading && (
              [...Array(pageSize)].map((_row, index) => (
                <TableRow key={index}>
                  {
                    columns.map((column) => (
                      <TableCell key={column.id} component="td" scope="row" className={'text-primary !bg-white !border-none'}>
                        <Skeleton animation="wave" variant="text" />
                      </TableCell>
                    ))
                  }
                </TableRow>
              ))
            )}
            { !isLoading && items.map((item) => {
              return (
                <TableRow tabIndex={-1} key={item[rowKey]}>
                  {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={'text-primary !bg-white !border-none'}
                      >
                        {
                          getCellContent(column, item)
                        }
                      </TableCell>
                    )
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        className={'text-primary'}
        count={totalCount}
        rowsPerPageOptions={[10, 25, 50, 100]}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
        page={page}
        onPageChange={handlePageChange}
        ActionsComponent={(props: TablePaginationActionsProps & {
          children?: React.ReactNode | undefined
        }) => <TablePaginationActions {...props} isLoading={isLoading}/>}
      />
    </>
  );
};

interface CustomTablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;

  isLoading: boolean;
}

function TablePaginationActions(props: CustomTablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange, isLoading } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: '32px' }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={isLoading || page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={isLoading || page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isLoading || page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={isLoading || page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </div>
  );
}