import { action, makeAutoObservable, runInAction } from 'mobx';
import { PaybackModel } from '../assets/models/paybacks/Payback.model';
import { GetBeneficiaryPaybacksRequest } from '../assets/requests/paybacks/GetBeneficiaryPaybacks.request';
import { getBeneficiaryPaybacks } from '../Services/paybackAPI.service';

export class BeneficiaryPaybackStore {
  error = null;
  isLoading = false;

  getBeneficiaryPaybacksStatus = 'PENDING';

  beneficiaryPaybacks: PaybackModel[] = [];

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setLoadingPaybacks (isLoading: boolean = true) {
    this.isLoading = isLoading;
  }

  @action reset() {
    this.isLoading = false;
    this.getBeneficiaryPaybacksStatus = 'PENDING';
    this.beneficiaryPaybacks = [];
  }

  async getBeneficiaryPaybacks(beneficiaryUid: string, request?: GetBeneficiaryPaybacksRequest) {
    this.setLoadingPaybacks();
    
    if (!request) {
      const currentDate: Date = new Date();
      request.from = currentDate.setMonth(currentDate.getMonth() - 3);
    }
    
    try {
      const getPaybacksResponse: PaybackModel[] = await getBeneficiaryPaybacks(beneficiaryUid, request);

      runInAction(() => {
        if (getPaybacksResponse) {
          this.beneficiaryPaybacks = getPaybacksResponse;
          this.getBeneficiaryPaybacksStatus = 'SUCCESS';
        }
      });
    } catch (error) {
      this.getBeneficiaryPaybacksStatus = 'ERROR';
    }

    this.setLoadingPaybacks(false);
  }
}
