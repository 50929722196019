import { PayinReportModel } from '../../models/payins/PayinReport.model';
import { GetAgencyPayinResponse } from '../../responses/payins/GetAgencyPayin.response';
import { convertCentToEuro } from '../functions/convertCentToEuro.util';

export function convertGetAgencyPayinResponseFromCentsToEuros(response: GetAgencyPayinResponse): GetAgencyPayinResponse {
  const { amount, includingFeesAmount } = response;

  return {
    ...response,
    amount: convertCentToEuro(amount),
    includingFeesAmount: convertCentToEuro(includingFeesAmount),
  };
}

export function convertPayinReportFromCentsToEuros(payinReport: PayinReportModel): PayinReportModel {
  const { totalAmountOfPayins, totalAmountOfCredits, totalAmountOfPaybacks } = payinReport;

  return {
    ...payinReport,
    totalAmountOfPayins: convertCentToEuro(totalAmountOfPayins),
    totalAmountOfCredits: convertCentToEuro(totalAmountOfCredits),
    totalAmountOfPaybacks: convertCentToEuro(totalAmountOfPaybacks),
  };
}
