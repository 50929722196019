import React from 'react';
import { COLORS } from '../Style/Style';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  width?: string | number;
  height?: string | number;
}

export const ArrowRightSvg = ({ color = COLORS.textPrimary, height = 13, width = 14 }: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 14 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.5H13M13 6.5L8 1.5M13 6.5L8 11.5"
        stroke={color}
        strokeWidth={"1.5"}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  )
}