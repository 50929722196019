import { DiscreteVoucherModel } from '../../models/discreteVouchers/DiscreteVoucher.model';

export const DEFAULT_VAT_RATE = 20;

export function getIncludingTaxesAmount(amountExcl: number, vatRate: number, fractionDigits: number = 0): number {
  return +(amountExcl + amountExcl * (vatRate / 100)).toFixed(fractionDigits);
}

export function getExcludingTaxesAmount(amountIncl: number, vatRate: number, fractionDigits: number = 0): number {
  return +(amountIncl / (1 + vatRate / 100)).toFixed(fractionDigits);
}

// maybe this should directly be in voucher's data ?
export function getSubAndPimRealAmounts(voucher: DiscreteVoucherModel) {
  let {
    agencyParticipationAmount, subVatRate = DEFAULT_VAT_RATE, pimAmount = 0, pimVatRate = DEFAULT_VAT_RATE,
  } = voucher;
  // calculate real pim and sub amounts. We consume pim first then sub
  agencyParticipationAmount = Math.round(agencyParticipationAmount); // normally already rounded but just in case
  const pimAmountTTC: number = Math.min(agencyParticipationAmount, getIncludingTaxesAmount(pimAmount, pimVatRate));
  const subAmountTTC: number = agencyParticipationAmount - pimAmountTTC;  // 0 if agencyParticipationAmount <= pimAmount
  const subAmountHT: number = getExcludingTaxesAmount(subAmountTTC, subVatRate);
  const pimAmountHT: number = getExcludingTaxesAmount(pimAmountTTC, pimVatRate);

  return {
    subAmountTTC,
    subAmountHT,
    pimAmountTTC,
    pimAmountHT,
  };
}
