import React from 'react';
import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material';
import { COLORS } from '../Style';
import clsx from 'clsx';

const SpinnerAdornment = ({ color }: { color: string }) => {
  return (
    <CircularProgress
      color='primary'
      style={{ marginLeft: '5px', color }}
      size={20}
    />
  );
};

  interface ButtonComponentProps extends ButtonProps {
    isFetching?: boolean;
    imgSrc?: string;
  }

const CLASSES = {
  base: 'font-primary rounded-br30 transition-colors duration-300 flex items-center justify-center cursor-pointer',
  variants: {
    contained: 'bg-primary text-white border border-gr hover:bg-opacity-90 hover:bg-primary',
    outlined: 'border border-gr text-primary bg-white hover:bg-gray-100',
    text: 'text-primary bg-transparent hover:bg-gray-50',
  },
  size: {
    small: 'px-4 py-2 text-sm',
    medium: 'px-6 py-3 text-base',
    large: 'px-8 py-4 text-lg',
  },
};

const Button = (props:ButtonComponentProps) => {
  const {
    children,
    isFetching,
    variant,
    imgSrc,
    ...rest
  } = props;

  const spinnerColor: string = variant === 'outlined' ? COLORS.textPrimary : COLORS.white;

  const classes = clsx(
    CLASSES.base,
    CLASSES.variants[variant],
  );

  return (
    <MuiButton {...rest} variant={variant} className={classes}>
      {imgSrc && <img src={imgSrc} alt="button icon" style={{ marginRight: '10px' }} />}
      {children}
      {isFetching && <SpinnerAdornment color={spinnerColor} />}
    </MuiButton>
  );
};

export default Button;
