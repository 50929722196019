import React, { useCallback, useEffect, useState } from 'react';
import { UseSortableData, useSortableData } from '../../../Utils/SortGenericList';
import { useInstances } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { AgencyAdminProfileDefinition } from '@assets/models/security/agencyAdmins.model';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { getListOfProfiles } from '../../../assets/utils/security/agencyAdmins.util';

import { ReactComponent as ArrowBracketSvg } from '../../../assets/svg/arrowBracket.svg';
import clsx from 'clsx';

type LocalStore = [AgenciesStore];

const ProfilesList = () => {
  const { t } = useTranslation('settings');

  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const { currentAgency } = agenciesStore;
  const [rawListOfProfiles, setRawListOfProfiles] = useState<AgencyAdminProfileDefinition[]>([]);

  const listOfProfiles: UseSortableData<AgencyAdminProfileDefinition> = useSortableData(rawListOfProfiles);

  const fetchProfiles: () => Promise<void> = useCallback(async () => {
    if (currentAgency?.uid) {
      const profiles = await getListOfProfiles(currentAgency.uid);
      setRawListOfProfiles(profiles);
    }
  }, [currentAgency?.uid]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const groupCapabilities: (capabilities: string[]) => Record<string, {
    read: boolean;
    write: boolean
  }> = (capabilities: string[]) => {
    return capabilities.reduce((acc, capability) => {
      const [category, action] = capability.split('.');
      if (!acc[category]) {
        acc[category] = { read: false, write: false };
      }
      acc[category][action] = true;
      return acc;
    }, {} as Record<string, { read: boolean; write: boolean }>);
  };

  const getArrowDirection = (key: string) => {
    if (!listOfProfiles) {
      return null;
    }
    return (
      listOfProfiles.sortConfig.key === key && listOfProfiles.sortConfig.direction === 'asc'
        ? '-rotate-90'
        : 'rotate-90'
    );
  };

  if (!listOfProfiles || !listOfProfiles.items) {
    return <div>{t('loading')}</div>; // State for loading or error handling
  }

  return (
    <table className={'w-full border-separate border-spacing-y-2.5'}>
      <thead>
      <tr>
        <th
          className={'text-left font-bold p-3 hover:cursor-pointer hover:opacity-90'}
          onClick={() => listOfProfiles.requestSort('uid')}>
          <div className={'flex gap-4'}>
            <p>{t('identifier')}</p>
            <ArrowBracketSvg width={10}
                             className={`${getArrowDirection('uid')} text-primary transition-all ease-in-out duration-500`}/>
          </div>
        </th>
        <th className={'text-left font-bold p-3 hover:cursor-pointer hover:opacity-90'} onClick={() => listOfProfiles.requestSort('name')}>
          <div className={'flex gap-4'}>
            <p>{t('name')}</p>
              <ArrowBracketSvg width={10}
                               className={`${getArrowDirection('name')} text-primary transition-all ease-in-out duration-500`}/>
          </div>
        </th>
        <th className={'text-left font-bold p-3 hover:cursor-pointer hover:opacity-90'}>{t('rights')}</th>
        <th className={'text-left font-bold p-3 hover:cursor-pointer hover:opacity-90'}></th>
      </tr>
      </thead>


      <tbody>
      {listOfProfiles.items.map((profileDefinition, index) => {
        const groupedCapabilities = groupCapabilities(profileDefinition.capabilities);

        return (
          <tr key={index} className={'h-12 hover:cursor-pointer hover:opacity-900 bg-background rounded-br20'}>
            <td className={'p-3'}>
              {profileDefinition.code.toString().padStart(3, '0')}
            </td>
            <td className={'p-3'}>{profileDefinition.name}</td>
            <td className={'p-3'}>
              {Object.keys(groupedCapabilities).map((category, idx) => {
                const { write } = groupedCapabilities[category];
                return (
                  <span
                    key={idx}
                    className={clsx('text-primary px-2 py-1 rounded-lg mr-2', write ? 'bg-green' : 'bg-green/50')}>
                    {t(category)}
                  </span>
                );
              })}
            </td>
            <td className={'p-3'}>
              <ArrowBracketSvg width={10} className={'flex self-center text-primary/70'}/>
            </td>
          </tr>
        );
      })}
      </tbody>
    </table>
  );
};

export default ProfilesList;
