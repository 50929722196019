/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetAgencyInvoiceOutputDto = {
    id?: string;
    fullReference?: string | null;
    subscriptionsBeneficiaryCount?: number;
    subscriptionUnitPrice?: number;
    subscriptionVatRate?: number;
    subscriptionVat?: number;
    subscriptionExcludingTaxes?: number;
    subscriptionIncludingTaxes?: number;
    provisionTotalAmount?: number;
    provisionDailyAmount?: number;
    provisionTotalDays?: number;
    provisionCompanyQuota?: number;
    provisionBeneficiaryCount?: number;
    currencyCode?: string | null;
    invoiceDate?: string;
    fileUrl?: string | null;
    fileName?: string | null;
    createdOn?: string;
    statusDate?: string | null;
    status?: GetAgencyInvoiceOutputDto.status;
}

export namespace GetAgencyInvoiceOutputDto {

    export enum status {
        CREATED = 'CREATED',
        PENDING = 'PENDING',
        SUCCEEDED = 'SUCCEEDED',
        FAILED = 'FAILED',
        REFUND_CREATED = 'REFUND_CREATED',
        REFUND_SUCCEEDED = 'REFUND_SUCCEEDED',
        REFUND_FAILED = 'REFUND_FAILED',
        REPUDIATION_CREATED = 'REPUDIATION_CREATED',
        REPUDIATION_SUCCEEDED = 'REPUDIATION_SUCCEEDED',
        REPUDIATION_FAILED = 'REPUDIATION_FAILED',
        UNKNOWN = 'UNKNOWN',
    }


}
