import { ErrorChannel, FunctionsErrorCode } from './manageError.util';

export class DomainError extends Error {
  readonly statusCode: number;
  readonly time: Date;
  readonly code: FunctionsErrorCode;
  readonly messageToDisplay: string;
  readonly channel: ErrorChannel;
  sourceIp?: string;
  userAgent?: string;
  origin?: string;

  constructor(message: string, code: FunctionsErrorCode, statusCode: number, channel: ErrorChannel = 'STANDARD') {
    super(message);
    this.messageToDisplay = message;
    this.code = code;
    this.statusCode = statusCode;
    this.time = new Date();
    this.channel = channel;
  }
}
