const accounting = {
  creationDateLabel_STANDARD: 'Date de la facture',
  creationDateLabel_DISSOCIATED_FEES: 'Date de la facture',
  creationDateLabel_DISSOCIATED_PROVISIONS: 'Date du relevé',
  invoiceTitle_STANDARD: 'Facture N°',
  invoiceTitle_DISSOCIATED_FEES: 'Facture N°',
  invoiceTitle_DISSOCIATED_PROVISIONS: 'Relevé N°',
  amount_STANDARD: 'Montant TTC',
  amount_DISSOCIATED_FEES: 'Montant TTC',
  amount_DISSOCIATED_PROVISIONS: 'Montant HT',
  seeDetails_STANDARD: 'Voir le détail',
  seeDetails_DISSOCIATED_FEES: 'Détail de la facture des abonnements',
  seeDetails_DISSOCIATED_PROVISIONS: 'Détail du relevé des provisions',
  toPay: 'En attente de {{mode}}',
  paidDirectDebit: 'Prélevé le',
  paidBankTransfer: 'Virement effectué le',
  pageTitle: 'Documents comptables',
  pageSubtitle: 'Liste des documents Open!Eat',
  tabAgencyInvoice: 'Rechargement',
  tabMillesimeReceipt: 'Fin de millésime',
  since6months: 'Depuis 6 mois',
  since1year: 'Depuis 1 an',
  since2years: 'Depuis 2 ans',
  sinceBeginning: 'Depuis le début',
  directDebit: 'Prélevé le',
  directDebitRef: 'Bordereau de versement n°',
  invoiceSearchPlaceholder: 'F123456, 10/11/2021,...',
  provisionSearchPlaceholder: 'P123456, 10/11/2021, 123,00 €...',
  invoiceDetails: {
    title_STANDARD: 'Détail de la facture n°{{reference}} du {{date}}',
    title_DISSOCIATED_FEES: 'Détail de la facture n°{{reference}} du {{date}}',
    title_DISSOCIATED_PROVISIONS: 'Détail du relevé de provision n°{{reference}} du {{date}}',
    subtitle: 'Retrouvez les informations concernant votre facture',
    statusPaidDirectDebit: 'Prélevé le ',
    statusPaidBankTransfer: 'Virement effectué le ',
    statusToPay_STANDARD: 'Facture en attente de paiement par {{mode}}',
    statusToPay_DISSOCIATED_FEES: 'Facture en attente de paiement par {{mode}}',
    statusToPay_DISSOCIATED_PROVISIONS: 'Relevé en attente de paiement par {{mode}}',
    provision: 'Montant de la participation employeur',
    subscriptionTitle_one: 'Titres restaurant : Abonnement pour {{count}} collaborateur pour {{month}}',
    subscriptionTitle_other: 'Titres restaurant : Abonnement pour {{count}} collaborateurs pour {{month}}',
    subscription: 'Frais d’abonnement',
    provisionTitle: 'Abondement de la participation employeur pour {{month}}',
    reloading: 'Basé sur le rechargement N° {{reference}} du {{date}}',
    excl: 'HT',
    incl: 'TTC',
    transaction_one: '{{count}} collaborateur',
    transaction_other: '{{count}} collaborateurs',
    totalExcl: 'Total HT',
    total: 'Net à payer : Total abonnements TTC + Part Employeur',
    vat: 'TVA',
    totalFees_STANDARD: 'Net à payer abonnement',
    totalFees_DISSOCIATED_FEES: 'Net à payer',
    totalProvisions: 'Net à payer part employeur',
    download_STANDARD: 'Télécharger la facture',
    download_DISSOCIATED_FEES: 'Télécharger la facture',
    download_DISSOCIATED_PROVISIONS: 'Télécharger le relevé',
    expectedDescriptorLabel: 'Référence à rappeler :',
    close: 'Fermer',
  },
  restitutionReceiptCard: {
    statusLabel_CREATED: 'Virement en cours',
    statusLabel_SUCCEEDED: 'Payée',
    statusLabel_FAILED: 'Echec du virement',
    restitutionDateLabel: 'Date de la relevé de restitution',
    receiptNumberLabel: 'Relevé n°',
    receiptDescription_MILLESIME: 'Millésime',
    receiptDescription_BENEFICIARY_DEACTIVATION: 'Sortie du dispositif',
    receiptDescription_CREDIT_REFUND: 'Retrait de droits',
    beneficiariesCount_one: '{{count}} collaborateur',
    beneficiariesCount_other: '{{count}} collaborateurs',
    restitutionAmountLabel: 'Montant HT',
    actionButtonLabel: 'Voir le détail',
  },
  restitutionReceiptDetails: {
    title: 'Détail du relevé n° {{reference}}',
    subTitle: 'Retrouvez les informations concernant votre relevé',
    statusLabel_CREATED: 'En cours de virement',
    statusLabel_SUCCEEDED: 'Payée',
    statusLabel_FAILED: 'Echec du virement',
    bankTransferDetail_DIRECT_DEBIT_IBAN_PAYOUT: 'Remboursement sur compte de prélèvement',
    bankTransferDetail_RESTITUTION_IBAN_PAYOUT: 'Compte {{IBAN}}',
    restitutionDescription: 'Restitution de solde non consommé',
    restitutionDescription_MILLESIME: 'Fin de millésime {{year}}',
    beneficiariesCount_one: '{{count}} collaborateur concerné',
    beneficiariesCount_other: '{{count}} collaborateurs concernés',
    totalLabel: 'Net à restituer',
    closeButtonLabel: 'Fermer',
    downloadButtonLabel: 'Télécharger le relevé',
  },
  tabDeactivations: 'Sortie du dispositif',
  deactivationReportDetails: {
    restitutionDate: 'Date de la restitution',
    releveNumber: 'Relevé n°',
    quiteDispositif: 'Sortie du dispositif',
    ACCEPTED_RESTITUTION_ON_WALLET: 'Payée',
    ACCEPTED_RESTITUTION_PAYOUT_CREATED: 'Virement en cours',
    ACCEPTED_RESTITUTION_PAYOUT_SUCCESS: 'Payée',
    ACCEPTED_RESTITUTION_PAYOUT_FAILED: 'Échec du virement',
    seeDetails: 'Voir le détail',
    amount: 'Montant HT',
    title: 'Détail du relevé n°',
    subtitle: 'Retrouvez les informations concernant votre relevé',
    download: 'Télécharger le relevé',
    noIban: 'IBAN non disponible pour le moment',
    restitution_DIRECT_DEBIT_IBAN_PAYOUT: 'Remboursement sur compte de prélèvement',
    restitutionNoConsum: 'Restitution de solde non consommé',
    deactivatedCollab: 'Désactivation  collaborateur',
    toPay: 'Net à payer',
  },

};

export default accounting;
