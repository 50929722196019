import React from 'react';
import { IMandateSignatureDialog } from './MandateSignatureDialog';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MandateSvg } from '../../../../assets/svg/mandate/mandateSignedSuccess.svg';
import { Button } from '../../../../ui/Buttons/Button';

const MandateSignatureSuccess = (props: IMandateSignatureDialog) => {
  const { t } = useTranslation('mandate');

  return (
    <>
      <p>{t('success.title')}</p>

      <div className={'pt-32 flex flex-col justify-center items-center space-y-16'}>
        <MandateSvg title={'mandate signature'} className={'w-32 h-32'}/>

        <p className={'text-sm text-center'}>
          {t('success.description')}
        </p>
      </div>

      <div className={'flex justify-end gap-4 pt-10'}>
        <Button onClick={props.onClose}>
          {t('success.backBtn')}
        </Button>
      </div>
    </>
  );
};

export default MandateSignatureSuccess;
