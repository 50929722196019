import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { useNavigate } from 'react-router';
import closedEye from '../assets/closed-eye.svg';
import eye from '../assets/eye.svg';
import openeat from '../assets/logo-text-new.svg';
import { LoginRequest } from '../assets/requests/auth/Login.request';
import { setUserEmail } from '../Services/httpWrapper.service';
import { UserConnectStore } from '../Stores/UserConnect.store';
import { LoginViewStore } from '../Stores/viewStore/LoginView.store';
import { regexPassword } from '../Utils/regexList';
import { FirebaseError } from '@firebase/util';
import { Button } from '../ui/Buttons/Button';
import { InputField } from '../ui/Input';
import { AuthLayout } from './AuthLayout';
import { Toaster } from '../ui/Toaster';

type LocalStore = [UserConnectStore, LoginViewStore];

interface LocalFormValue {
  password: string;
}

const LoginPasswordPage = observer(() => {

  const [userConnectStore, loginViewStore]: LocalStore = useInstances<LocalStore>(UserConnectStore, LoginViewStore);
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const isPasswordHidden: boolean = loginViewStore.isPasswordHidden;
  const [loginErrorMessage, setLoginErrorMessage] = useState(null);

  useEffect(() => {
    if (!loginViewStore.email) {
      navigate('/login');
    }
  }, [loginViewStore.email]);

  async function loginPassword({ password }: LocalFormValue, setSubmitting: (isSubmitting: boolean) => void) {
    setSubmitting(true);
    const request: LoginRequest = {
      userName: loginViewStore.email,
      password: password,
    };

    try {
      await userConnectStore.login(request);

      if (userConnectStore.loginStatus === 'SUCCESS') {
        setUserEmail(loginViewStore.email);
        navigate('/dashboard');
      }
    } catch (e: any) {
      let errorMessage: string = e.message ?? t('unknownLoginError');
      if (e instanceof FirebaseError) {
        // see list of firebaseError codes here https://firebase.google.com/docs/reference/js/auth#autherrorcodes
        switch (e.code) {
          case 'auth/wrong-password':
          case 'auth/user-not-found':
            errorMessage = t('credentialsError');
            break;
          default:
            break;
        }
      }
      setLoginErrorMessage(errorMessage);
      loginViewStore.setShowErrorSnackbar(true);
    }
    setSubmitting(false);
  }

  function activeSubmit(pass: string) {
    return regexPassword.test(pass);
  }

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: (
      values: LocalFormValue,
      { setSubmitting }: FormikHelpers<LocalFormValue>,
    ) => loginPassword(values, setSubmitting),
  });

  function resetPassword() {
    navigate('/login/reinitialisation');
  }

  return (
    <>
      <AuthLayout>
        <div className={'flex flex-col items-center space-y-8'}>
          <img className="block md:hidden w-[200px]" alt="Openeat" src={openeat}/>
          <p className={'font-bold md:text-3xl text-center'}>{t('subtitle')}</p>
          <form className={'w-full lg:w-1/2 space-y-4'} onSubmit={formik.handleSubmit} noValidate>
            <InputField
              id="password"
              name="password"
              label={t('password')}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.password) && formik.touched.password}
              type={isPasswordHidden ? 'password' : 'text'}
              endIcon={<img
                style={{ cursor: 'pointer' }}
                alt={'eye'}
                width="20"
                src={isPasswordHidden ? eye : closedEye}
                onClick={() => loginViewStore.setIsPasswordHidden(!isPasswordHidden)}
              />}
              fullWidth
              required
              placeholder={t('passwordPlaceholder')}
              errorMessage={formik.errors.password}
              autoComplete="current-password"
            />
            <div className={'text-sm cursor-pointer hover:underline'} onClick={() => resetPassword()}>
              {t('forgotPassword')}
            </div>
            <div className={'flex justify-around'}>
              <Button
                variant="contained"
                disabled={formik.isSubmitting}
                type="button"
                onClick={() => navigate('login')}
              >
                {t('cancel')}
              </Button>
              <Button
                variant="outlined"
                loading={formik.isSubmitting}
                disabled={!activeSubmit(formik.values.password)}
                type="submit"
              >
                {t('connection')}
              </Button>
            </div>
          </form>
        </div>
      </AuthLayout>
      <Toaster
        open={loginViewStore.showErrorSnackbar}
        onClose={() => loginViewStore.setShowErrorSnackbar(false)}
        variant={'error'}
      >
        {loginErrorMessage}
      </Toaster>
    </>
  );
});

export default LoginPasswordPage;