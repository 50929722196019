import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { apiMapping } from '../assets/api-mapping';
import {
  FunctionDescriptor,
  FunctionMethod,
  FunctionName,
  FunctionNameWithoutParamsOrRequest,
  FunctionNameWithParams,
  FunctionNameWithParamsAndRequest,
  FunctionNameWithRequest,
  FunctionPath,
  FunctionRequestType,
  FunctionResponseType,
  FunctionUrlParamsType,
} from '../assets/lambdas/Lambda.type';
import { getAccessToken } from './authAPI.service';

export function removeItems() {
  localStorage.removeItem('userEmail');
  localStorage.removeItem('currentAgency');
  localStorage.removeItem('openPendingOrderDetails');
}

export function setUserEmail(newEmail: string) {
  localStorage.setItem('userEmail', newEmail);
}

export async function callable<L extends FunctionNameWithoutParamsOrRequest>(
  functionName: L,
): Promise<FunctionResponseType<L>>;
export async function callable<L extends FunctionNameWithParams>(
  functionName: L,
  params: FunctionUrlParamsType<L>,
): Promise<FunctionResponseType<L>>;
export async function callable<L extends FunctionNameWithRequest>(
  functionName: L,
  request: FunctionRequestType<L>,
): Promise<FunctionResponseType<L>>;
export async function callable<L extends FunctionNameWithParamsAndRequest>(
  functionName: L,
  params: FunctionUrlParamsType<L>,
  request: FunctionRequestType<L>,
): Promise<FunctionResponseType<L>>;
export async function callable<L extends FunctionName>(
  functionName: L,
  params?: FunctionUrlParamsType<L> | FunctionRequestType<L>,
  request?: FunctionRequestType<L>,
): Promise<FunctionResponseType<L>> {
  const { path: functionPath, verb: method, authType }: FunctionDescriptor<L> = apiMapping[functionName];
  const {
    functionParams, functionRequest,
  } = normalizeFunctionParams<L>(functionPath, params as any, request);

  if (!!functionPath && method) {
    const url: string = generateUrl(functionPath, functionParams);
    const headers = await getHeaders(authType);
    return call<L>(headers, method, url, functionRequest);
  }
  return Promise.reject(new Error('Cannot find corresponding lambda path'));
}

function normalizeFunctionParams<L extends FunctionName>(
  lambdaPath: FunctionPath<L>,
  params?: FunctionUrlParamsType<L> | FunctionRequestType<L>,
  request?: FunctionRequestType<L>,
): ({ functionParams: FunctionUrlParamsType<L>, functionRequest: FunctionRequestType<L> }) {
  const hasParams: boolean = /\/:([^\/]+)/g.test(lambdaPath);

  if (!hasParams && !!params) {
    request = params as FunctionRequestType<L>;
    params = void 0;
  }

  return { functionParams: params as FunctionUrlParamsType<L>, functionRequest: request as FunctionRequestType<L> };
}

function generateUrl<L extends FunctionName>(path: FunctionPath<L>, params: FunctionUrlParamsType<L>): string {
  const urlParamPattern = /\/:([^\/]+)/g;

  return path.replace(urlParamPattern, (_: string, paramKey: string) => {

    if (hasKeys(params)) {
      const paramExists: boolean = paramKey in params;

      if (paramExists) {
        return `/${(params as any)[paramKey]}`;
      }
    }

    throw new Error(`api param '${paramKey}' is missing for '${path}'`);
  });
}

function hasKeys(params: any): params is { [key: string]: string } {
  return Object.keys(params || {}).length > 0;
}

async function call<L extends FunctionName>(
  headers: AxiosRequestHeaders,
  method: FunctionMethod<L>,
  url: string,
  request?: FunctionRequestType<L>,
): Promise<FunctionResponseType<L>> {
  let response: FunctionResponseType<L>;
  switch (method) {
    case 'GET':
      response = (await axios.get<FunctionResponseType<L>, AxiosResponse<FunctionResponseType<L>>>(
        url,
        { params: request, headers },
      )).data;
      break;

    case 'POST':
      response = (await axios.post<FunctionResponseType<L>, AxiosResponse<FunctionResponseType<L>>, FunctionRequestType<L>>(
        url,
        request,
        { headers },
      )).data;
      break;
    case 'PUT':
      response = (await axios.put<FunctionResponseType<L>, AxiosResponse<FunctionResponseType<L>>, FunctionRequestType<L>>(
        url,
        request,
        { headers },
      )).data;
      break;
    case 'DELETE':
      response = (await axios.delete<FunctionResponseType<L>, AxiosResponse<FunctionResponseType<L>>, FunctionRequestType<L>>(
        url,
        { headers },
      )).data;
      break;
    default:
      return Promise.reject(() => new Error('unhandled descriptor verb'));
  }

  return response;
}

async function getHeaders(authType: string): Promise<AxiosRequestHeaders> {
  const needsAuth = (authType !== 'NONE');

  if (!needsAuth) {
    return {};
  }

  const accessToken: string | undefined = await getAccessToken();
  return { sebauth: accessToken };
}
