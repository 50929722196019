const resetpassword = {
  title: '👋 Bienvenue ! Nous allons choisir ensemble votre mot de passe',
  title2: '🔑 Vous devez définir un nouveau mot de passe.',
  subtitle: 'Veuillez définir votre mot de passe.',
  subtitle_tow: 'Veuillez définir votre nouveau mot de passe.',
  inputPassword: 'Mot de passe',
  inputPasswordPlaceholder: 'Choisissez un mot de passe',
  inputConfirmPassword: 'Confirmation de votre mot de passe',
  inputConfirmPasswordPlaceholder: 'Veuillez confirmer votre mot de passe',
  passwordInformations: '💪 Votre mot de passe doit au moins contenir :\n',
  resetPassword: 'Réinitialiser mon mot de passe',
  newPassword: 'Choisir mon mot de passe',
  errorSnackBar: {
    resetPassword: '😕 Impossible de changer le mot de passe',
    requestPasswordChange: '😕 Votre adresse mail ne semble pas correcte',
  },
  expiredUrlButtonLabel: 'Demander un nouveau lien',
  expiredUrlSubtitle: '😕 Votre lien de réinitialisation de mot de passe a expiré.',
  linkExpired: '😬 Le lien a expiré.',
  newUrlSent: '👍 Vérifiez votre boite mail, nous venons de vous envoyer un nouveau lien.',
};

export default resetpassword;
