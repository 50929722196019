import { MenuItem } from '@mui/material';
import React from 'react';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import Yup from '../../../i18n/validation';
import { observer } from 'mobx-react-lite';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { ManagementUnitModel } from '../../../assets/models/agencies/ManagementUnit.model';
import { DEFAULT_MANAGEMENT_UNIT_ID } from '../../../assets/utils/agencies/managementUnit.util';
import { Select } from '../../../ui/Select';

export const AGENCY_MANAGEMENT_UNIT = 'agencyManagementUnitId';

const SectorizationFormComponent: FunctionFormComponent = observer(({
                                                                      formik, beneficiary, isEditMode = false,
                                                                      disableModification = false, showTitle = true,
                                                                    }) => {
  const [agenciesStore]: [AgenciesStore] = useInstances<[AgenciesStore]>(AgenciesStore);
  const agencyManagementUnits: ManagementUnitModel[] = agenciesStore.currentAgencyAdminManagementUnits;
  const listOfAgencyManagementUnits = agenciesStore.currentAgency.useAgencyManagementUnit ? agencyManagementUnits : [];

  return (
    <div className={'space-y-2'}>
      {showTitle &&
          <h3 className={'font-bold'}>
              Sectorisation
          </h3>}
      {agenciesStore.currentAgency?.useAgencyManagementUnit && (
        <Select
          value={formik.values[AGENCY_MANAGEMENT_UNIT]}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(AGENCY_MANAGEMENT_UNIT, evt.target.value)}
          id="managementUnit-select"
          disabled={disableModification}
          label="Unité de gestion"
          fullWidth
          required
        >
          {listOfAgencyManagementUnits?.map((unit) =>
            (<MenuItem key={unit.uid} value={unit.uid}>{unit.name}</MenuItem>)
          )}
        </Select>
      )}
    </div>
  );
}) as FunctionFormComponent;

SectorizationFormComponent.getValidationSchema = () => {
  return Yup.object().shape({});
};

SectorizationFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [AGENCY_MANAGEMENT_UNIT]: beneficiary?.agencyManagementUnitId ?? DEFAULT_MANAGEMENT_UNIT_ID,
  };
};

export default SectorizationFormComponent;