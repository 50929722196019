import { countryCodeAndRelatedIbanLength } from './countryCodeRelatedIbanMaxLength';

export function obfuscateIbanNumbersUtil(iban: string, nbCharsToKeep: number = 4): string {
  const splittedIban: string[] = iban.split('');
  const countryCode: string = `${splittedIban[0]}${splittedIban[1]}`.toUpperCase();
  const storageArray: string[] = splittedIban.reverse();
  const deleteCount: number = (countryCodeAndRelatedIbanLength[countryCode] as number || 27) - nbCharsToKeep;
  let hiddenSymbols = '';
  for (let i = 1; i <= deleteCount; i += 1) {
    hiddenSymbols += '*';
  }
  storageArray.splice(nbCharsToKeep, deleteCount, hiddenSymbols);
  return storageArray.reverse().join('');
}

