import PrintQRCodeButton from '../BeneficiaryAccountInformations/PrintQRCodeButton';
import { Skeleton } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import RegenQRCodeButton from '../BeneficiaryAccountInformations/RegenQRCodeButton';

export interface IQrCodeSectionProps {
  qrcodeString: string;
  isLoading: boolean;
  onRequestRegenQRCode: (confirm: boolean) => Promise<void>;
}

const QrCodeSectionComponent: FunctionComponent<IQrCodeSectionProps> = ({ qrcodeString, isLoading, onRequestRegenQRCode }) => {
  const { t } = useTranslation('beneficiaries');

  return <>
    <div className={'ml-1'}>
      <h3 className={'font-bold'}>
        Qr Code
      </h3>
      {isLoading
        ? (
          <Skeleton variant={'rectangular'} width={190} height={50} className={'rounded-br30'}/>
        )
        : (
          <div className={'flex justify-around w-2/3'}>
              <RegenQRCodeButton
                buttonLabel={t('qrCodeRegenButton')}
                onRequestRegenQRCode={onRequestRegenQRCode}
              />
            <PrintQRCodeButton
              title=""
              qrCodeString={qrcodeString}
              buttonLabel={t('qrCodeButtonLabel')}
            />
          </div>
        )
      }
    </div>
  </>;
};

export default QrCodeSectionComponent;