import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AccountingViewStore } from '../../../Stores/viewStore/AccountingView.store';
import ReceiptCard from '../../../Component/ReceiptCard/ReceiptCard';
import { useDocumentsTabStyles } from '../DocumentsTabStyles';
import MillesimeReceiptDetailsComponent from './MillesimeReceiptDetails/MillesimeReceiptDetailsComponent';
import { MillesimeReceiptModel } from '@assets/models/millesime/MillesimeReceipt.model';
import { Spinner } from '../../../ui/Spinner';
import { Dialog } from '../../../ui/Dialog';

const MillesimeReceiptsComponent: FunctionComponent<any> = observer(({ accountingStore }: { accountingStore: AccountingViewStore }) => {
  const documentsTabClasses = useDocumentsTabStyles();
  const [receipts, setReceipts] = useState<MillesimeReceiptModel[]>([]);
  const [selectedReceipt, setSelectedReceipt] = useState<MillesimeReceiptModel | undefined>(undefined);

  useEffect(() => {
    accountingStore.fetchMillesimeReceipts()
      .then(() => setReceipts(accountingStore.millesimeReceipts));
  }, []);

  const handleOpenButton = (selectedReceipt: MillesimeReceiptModel) => {
    setSelectedReceipt(selectedReceipt);
    accountingStore.setOpenDialog(true);
  };

  const handleCloseButton = () => {
    accountingStore.setOpenDialog(false);
  };

  const handleDownloadReceipt = () => {
    if (!selectedReceipt) {
      return;
    }
    accountingStore.downloadDocumentPdf(selectedReceipt);
  }

  return (
    <>
      {accountingStore.isLoadingMillesimeReceipts
        ? <Spinner size={'lg'}/>
        :
        <>
          <div className={documentsTabClasses.documentList}>
            {receipts.map(receipt =>
              <ReceiptCard key={receipt.uid}
                           receipt={receipt}
                           receiptType={'MILLESIME'}
                           openReceiptDetails={() => handleOpenButton(receipt)}
              />
            )}
          </div>

          <Dialog open={accountingStore.openDialog} onClose={handleCloseButton}>
            <MillesimeReceiptDetailsComponent
              receipt={selectedReceipt}
              onClose={handleCloseButton}
              onDownloadReceipt={handleDownloadReceipt}
              isDownloadingReceipt={accountingStore.isDownloadingDocumentPdf}
            />
          </Dialog>
        </>
      }
    </>
  );
});

export default MillesimeReceiptsComponent;
