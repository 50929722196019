import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import React, { useCallback, useEffect } from 'react';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import isValidIBANNumber from '../../../Function/IbanValidator';
import { useFormLock } from '../../../Hooks/useFormLock';
import { Button } from '../../../ui/Buttons/Button';
import { InputField } from '../../../ui/Input';
import { useTranslation } from 'react-i18next';

export const IBAN = 'iban';
export const IBAN_LOCK = 'isIbanLocked';

const BankInformationFormComponent: FunctionFormComponent = (props) => {
  const { t } = useTranslation('beneficiaries');
  const {
    formik, isEditMode, disableModification, setFieldEnabled, setFieldDisabled,
  } = props;

  const [isIbanLocked, setIsIbanLocked] = useFormLock(formik, IBAN_LOCK);

  const sanitizeIban: (iban: string) => string = (iban: string): string => iban.trim().replace(/\s+/g, '');

  useEffect(() => {
    if (isIbanLocked) {
      setFieldDisabled?.(IBAN);
    } else {
      setFieldEnabled?.(IBAN);
    }
  }, [isIbanLocked]);

  const getFlag: (iban: string) => string = useCallback((iban: string) => {
    const sanitizedIban: string = iban.trim().toUpperCase().replace(/[^A-Z0-9]/g, '');
    if (!sanitizedIban || sanitizedIban.length < 2) {
      return '';
    }
    const countryCode: string = iban.slice(0, 2);
    return countryCode.toLowerCase();
  }, []);

  return (
    <div className={'space-y-2'}>
      <h3 className={'font-bold'}>
        Référence bancaire
      </h3>
      <div className={'flex items-center gap-4'}>
        <div className={'w-1/2'}>
          <InputField
            id={IBAN}
            name={IBAN}
            label={t(IBAN)}
            value={formik.values[IBAN]}
            onChange={(e) => formik.setFieldValue(IBAN, sanitizeIban(e.target.value))}
            onBlur={formik.handleBlur}
            countryFlag={getFlag(formik.values[IBAN])}
            required
            fullWidth
            error={formik.touched[IBAN] && Boolean(formik.errors[IBAN])}  // ajout ici
            errorMessage={formik.touched[IBAN] && formik.errors[IBAN] as string}  // et ici
            disabled={isEditMode && isIbanLocked || disableModification}
          />
        </div>
        {isEditMode && isIbanLocked &&
            <Button className={'h-fit'} onClick={() => setIsIbanLocked(false)} disabled={disableModification}>
                Déverrouiller pour modifier
            </Button>
        }
      </div>
    </div>
  );
};

BankInformationFormComponent.getValidationSchema = (disabledFields?: string[]) => {
  Yup.addMethod(Yup.string, 'isValidIBANNumber', isValidIBANNumber);

  return Yup.object().shape({
    [IBAN]: disabledFields && disabledFields.includes(IBAN)
      ? Yup.string()
      : (Yup.string() as any).required().isValidIBANNumber(),
  });
};

BankInformationFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [IBAN]: beneficiary?.ibanLastNumbers ? `************${beneficiary?.ibanLastNumbers}` : '',
    [IBAN_LOCK]: true,
  };
};

export default BankInformationFormComponent;
