import clsx from 'clsx';
import React from 'react';

interface Props {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'white' | 'black' | 'default';
}

const CLASSES = {
  base: 'animate-spin flex items-center justify-center rounded-full border-solid',
  variant: {
    white: 'border-background-light border-l-grey',
    black: 'border-primary border-l-primary/50',
    default: 'border-background-block border-l-green',
  },
  size: {
    sm: 'h-6 w-6 border-[4px]',
    md: 'h-10 w-10 border-[6px]',
    lg: 'h-12 w-12 border-[6px]',
  }
}

export const Spinner: ({ size }: Props) => React.JSX.Element = ({size = 'md', variant = 'default'}: Props) => {
  return (<div className={clsx(CLASSES.base, CLASSES.variant[variant], CLASSES.size[size])}/>)
}