import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import downArrowIcon from '../../../assets/downArrow.svg';
import warningIcon from '../../../assets/warningGreen.svg';
import checked from './../../../assets/checked.svg';
import success from './../../../assets/mandateSignedSuccess.svg';
import warningFailed from './../../../assets/warning-failed.png';
import successWithFailed from './../../../assets/success-with-failed.png';
import {
  SerializedMinimalBeneficiaryUploadErrorModel,
  SerializedMinimalBeneficiaryUploadModel,
} from '@assets/models/beneficiaries/BeneficiariesFileUpload.model';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

interface AddBeneficiariesUploadSuccessProps {
  beneficiariesCreated: SerializedMinimalBeneficiaryUploadModel[];
  beneficiariesUpdated: SerializedMinimalBeneficiaryUploadModel[];
  beneficiariesFailed: SerializedMinimalBeneficiaryUploadErrorModel[];
}

const AddBeneficiariesUploadSuccess: FunctionComponent<AddBeneficiariesUploadSuccessProps> = observer((props) => {
  const {
    beneficiariesCreated = [],
    beneficiariesUpdated = [],
    beneficiariesFailed = []
  } = props;

  const { t } = useTranslation('beneficiariesfromcsv');

  const accordionBaseClasses = "rounded shadow-none mt-1.5 pb-5 before:h-0";
  const accordionDetailsClasses = "rounded-b-br10 px-4 py-1.5 border-none";
  const typographyClasses = "flex justify-center text-sm";
  const titleClasses = "pl-2.5 font-bold text-primary";
  const textClasses = "pl-25 font-normal text-primary";

  return (
    <div>
      <div className={'w-1/3'}>
        <h3>{t('subtitleResultSuccess')}</h3>
        {beneficiariesFailed.length === 0
          ? <>
            <div className={'p-8'}>
              <img src={success} alt={'success'} className={'w-24 object-contain'}/>
            </div>
            <p>{t('subtitleResultSuccessSecond')}</p>
          </>
          : <>
            <div className={'p-8'}>
              <img src={successWithFailed} alt={'success with failed'} className={'w-24 object-contain'}/>
            </div>
            <p>{t('subtitleResultSuccessWithFailed', { count: beneficiariesFailed.length })}</p>
          </>
        }

      </div>

      {beneficiariesFailed.length > 0 &&
          <Accordion className={clsx(accordionBaseClasses, "bg-status-error/25")}>
              <AccordionSummary
                  expandIcon={<img src={downArrowIcon} alt="downArrow"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={accordionDetailsClasses}
              >
                  <Typography className={typographyClasses}>
                      <img src={warningFailed} alt="failed" className={'w-5 object-contain'}/>
                      <span
                          className={titleClasses}>{t('titleFailed', { count: beneficiariesFailed.length })}</span>
                  </Typography>
              </AccordionSummary>
            {
              beneficiariesFailed?.map((beneficiaryUpload, index) => {
                return (
                  <AccordionDetails key={index} className={accordionDetailsClasses}>
                    <Typography className={typographyClasses}>
                      {beneficiaryUpload.codeError === 'already-exists'
                        ? <span className={textClasses}>
                            {beneficiaryUpload.firstName} {beneficiaryUpload.lastName} {beneficiaryUpload.email}, l'utilisateur existe déjà.
                          </span>
                        : <span className={textClasses}>
                            {beneficiaryUpload.firstName} {beneficiaryUpload.lastName} {beneficiaryUpload.email}, erreur interne.
                          </span>}
                    </Typography>
                  </AccordionDetails>
                );
              })
            }
          </Accordion>}

      {beneficiariesCreated.length === 0
        ? <div className={clsx(accordionBaseClasses, 'bg-status-success')}>
          <div className={'rounded-b-br10 p-4 pt-4 pl-4 border-none'}>
            <Typography className={'flex text-sm'}>
              <img src={warningIcon} alt="warning"/>
              <span
                className={titleClasses}>{t('titleAdded', { count: beneficiariesCreated.length })}</span>
            </Typography>
          </div>
        </div>
        : <Accordion className={clsx(accordionBaseClasses, 'bg-status-success')}>
          <AccordionSummary
            expandIcon={<img src={downArrowIcon} alt="downArrow"/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={accordionDetailsClasses}
          >
            <Typography className={typographyClasses}>
              <img src={checked} alt="checked"/>
              <span
                className={titleClasses}>{t('titleAdded', { count: beneficiariesCreated.length })}</span>
            </Typography>
          </AccordionSummary>
          {
            beneficiariesCreated?.map((row, index) => {
              return (
                <AccordionDetails key={index} className={accordionDetailsClasses}>
                  <Typography className={typographyClasses}>
                          <span className={textClasses}>
                            {row.firstName} {row.lastName}
                          </span>
                  </Typography>
                </AccordionDetails>
              );
            })
          }
        </Accordion>}
      {beneficiariesUpdated.length === 0
        ? <div className={clsx(accordionBaseClasses, 'bg-status-success')}>
          <div className={'rounded-b-br10 p-4 pt-4 pl-4 border-none'}>
            <Typography className={'flex text-sm'}>
              <img src={warningIcon} alt="warning"/>
              <span
                className={titleClasses}>{t('titleUpdate', { count: beneficiariesUpdated.length })}</span>
            </Typography>
          </div>
        </div>
        : <Accordion className={clsx(accordionBaseClasses, 'bg-status-success')}>
          <AccordionSummary
            expandIcon={<img src={downArrowIcon} alt="downArrow"/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={accordionDetailsClasses}
          >
            <Typography className={typographyClasses}>
              <img src={checked} alt="checked"/>
              <span
                className={titleClasses}>{t('titleUpdate', { count: beneficiariesUpdated.length })}</span>
            </Typography>
          </AccordionSummary>
          {
            beneficiariesUpdated?.map((row, index) => {
              return (
                <AccordionDetails key={index} className={accordionDetailsClasses}>
                  <Typography className={typographyClasses}>
                        <span className={textClasses}>
                          {row.firstName} {row.lastName}
                        </span>
                  </Typography>
                </AccordionDetails>
              );
            })
          }
        </Accordion>}
    </div>
  );
});

export default AddBeneficiariesUploadSuccess;