/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetAgencyContractOutputDto = {
    id?: string;
    status?: GetAgencyContractOutputDto.status;
    startDate?: string;
    endDate?: string | null;
    dailyAmount?: number;
    companyQuota?: number;
    currencyCode?: string | null;
    priceAmountPerBeneficiary?: number;
    isManagedPaymentMode?: boolean;
    signatoryEmail?: string | null;
    signatoryFirstName?: string | null;
    signatoryLastName?: string | null;
    createdOn?: string;
    modifiedOn?: string;
}

export namespace GetAgencyContractOutputDto {

    export enum status {
        CREATED = 'Created',
    }


}
