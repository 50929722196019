import React, { useState } from 'react';
import { Button } from '../../../ui/Buttons/Button';
import { ReactComponent as ReloadSvg } from 'assets/svg/reload.svg';
import { Dialog } from '../../../ui/Dialog';

const RegenQRCodeButton = ({ buttonLabel, onRequestRegenQRCode }) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
      setIsDialogOpen(true);
  };

  const handleDialogEntry = async (confirm: boolean) => {
    if (confirm) {
      onRequestRegenQRCode(true)
    }
      setIsDialogOpen(false);
  };

  return (
    <div>
      <Button onClick={handleOpenDialog}>
        <ReloadSvg className="w-10 h-10" />
        {buttonLabel}
      </Button>

      <Dialog open={isDialogOpen} onClose={() => handleDialogEntry(false)}>
        <p>Renouveler le QR code</p>
        <hr/>
        <div className={'py-10'}>
          Le renouvellement du QR code du collaborateur rendra
          l’ancien obsolète et inutilisable.<br/><br/>
          Vous confirmer le renouvellement ?
        </div>
        <div className={'flex justify-center gap-4'}>
          <Button onClick={() => handleDialogEntry(false)}>
            Non, annuler
          </Button>
          <Button onClick={() => handleDialogEntry(true)}>
            Oui, renouveler le QR code
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default RegenQRCodeButton;