import { makeAutoObservable, runInAction } from 'mobx';
import { getFlagValueFromConfig } from '../assets/utils/configuration/featureFlags';
import { getFeatureFlags } from '../Services/appConfig.service';
import { FeatureFlagsConfig, FeatureFlagsConfigValue } from '../assets/models/configuration/featureFlags.model';

export class AppConfigStore {
  private featureFlags: FeatureFlagsConfig = {};
  private lastUpdate: number = 0;
  private intervalId: number | null = null;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    void this.fetchLastConfig();
  }

  public getFlagValue<T extends FeatureFlagsConfigValue = FeatureFlagsConfigValue>(flagName: string, defaultValue: boolean = false): T {
    return getFlagValueFromConfig<T>(this.featureFlags, flagName, defaultValue);
  }

  public getLastUpdateTime(): number {
    return this.lastUpdate;
  }

  public isReady(): boolean {
    return this.lastUpdate > 0;
  }

  public startPolling(interval: number = 300000): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      void this.fetchLastConfig();
    }, interval) as unknown as number;
  }

  public stopPolling(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private async fetchLastConfig() {
    const requestTimestamp = Date.now();
    const featureFlags = await getFeatureFlags();
    runInAction(() => {
      this.featureFlags = featureFlags;
      this.lastUpdate = requestTimestamp;
    });
  }
}
