import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import downArrowIcon from '../../../assets/downArrow.svg';
import warningIcon from '../../../assets/warningGreen.svg';
import { BeneficiaryRowTestResult } from '../../../Utils/Tester/BeneficiaryTester.service';

interface IAddBeneficiariesFromCsvResult {
  fileTestResult: BeneficiaryRowTestResult[];
}

const AddBeneficiariesFromCsvSuccess: FunctionComponent<IAddBeneficiariesFromCsvResult> = (props: IAddBeneficiariesFromCsvResult) => {
  const { t } = useTranslation('beneficiariesfromcsv');

  const beneficiariesToCreate: BeneficiaryRowTestResult[] = useMemo(() => {
    return props.fileTestResult.filter(row => row.userValidity === 'USER_UNKNOWN');
  }, [props.fileTestResult]);

  const beneficiariesToUpdate: BeneficiaryRowTestResult[] = useMemo(() => {
    return props.fileTestResult.filter(row => row.userValidity === 'USER_EXISTS');
  }, [props.fileTestResult]);

  const beneficiariesToCreateCount: number = beneficiariesToCreate?.length;
  const beneficiariesToUpdateCount: number = beneficiariesToUpdate?.length;

  return (
    <>
      {beneficiariesToCreateCount === 0 ? (
        <div className="w-1/3">
          <div className="p-4 pt-4 pl-4 border-none">
            <Typography className="flex text-sm">
              <img src={warningIcon} alt='warning'/>
              <span className="pl-2.5 font-bold text-primary">
                {t('titleAddedAnalyse', { count: beneficiariesToCreateCount })}
              </span>
            </Typography>
          </div>
        </div>
      ) : (
        <Accordion className="bg-status-success rounded shadow-none mt-1.5 pb-5 before:h-0">
          <AccordionSummary
            expandIcon={<img src={downArrowIcon} alt='downArrow'/>}
            aria-controls='panel1a-content'
            id='panel1a-header'
            className="rounded-br10 rounded-bl-br10 px-4 py-1.5 border-none"
          >
            <Typography className="flex justify-center text-sm">
              <img src={warningIcon} alt='warning'/>
              <span className="pl-2.5 font-bold text-primary">
                {t('titleAddedAnalyse', { count: beneficiariesToCreateCount })}
              </span>
            </Typography>
          </AccordionSummary>
          {beneficiariesToCreate?.map((row, index) => (
            (row.firstName && row.lastName) && (
              <AccordionDetails
                key={index}
                className="rounded-br10 rounded-bl-br10 px-4 py-1.5 border-none"
              >
                <Typography className="flex justify-center text-sm">
                  <span className="pl-25 font-normal text-primary">
                    {row.firstName} {row.lastName}
                  </span>
                </Typography>
              </AccordionDetails>
            )
          ))}
        </Accordion>
      )}

      {beneficiariesToUpdateCount === 0 ? (
        <div className="bg-status-success rounded shadow-none mt-1.5 pb-5 before:h-0">
          <div className="rounded-br10 rounded-bl-br10 p-4 pt-4 pl-4 border-none">
            <Typography className="flex text-sm">
              <img src={warningIcon} alt='warning'/>
              <span className="pl-2.5 font-bold text-primary">
                {t('titleUpdateAnalyse', { count: beneficiariesToUpdateCount })}
              </span>
            </Typography>
          </div>
        </div>
      ) : (
        <Accordion className="bg-status-success rounded shadow-none mt-1.5 pb-5 before:h-0">
          <AccordionSummary
            expandIcon={<img src={downArrowIcon} alt='downArrow'/>}
            aria-controls='panel1a-content'
            id='panel1a-header'
            className="rounded-br10 rounded-bl-br10 px-4 py-1.5 border-none"
          >
            <Typography className="flex justify-center text-sm">
              <img src={warningIcon} alt='warning'/>
              <span className="pl-2.5 font-bold text-primary">
                {t('titleUpdateAnalyse', { count: beneficiariesToUpdateCount })}
              </span>
            </Typography>
          </AccordionSummary>
          {beneficiariesToUpdate?.map((row, index) => (
            <AccordionDetails
              key={index}
              className="rounded-br10 rounded-bl-br10 px-4 py-1.5 border-none"
            >
              <Typography className="flex justify-center text-sm">
                <span className="pl-25 font-normal text-primary">
                  {row.firstName} {row.lastName}
                </span>
              </Typography>
            </AccordionDetails>
          ))}
        </Accordion>
      )}
    </>
  );
};

export default AddBeneficiariesFromCsvSuccess;