import { MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { Button } from '../../ui/Buttons/Button';
import { Select } from '../../ui/Select';
import RechargingOrderExistDialog from '../../Component/Dialog/RechargingOrderExistDialog/RechargingOrderExistDialog';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import { getParticipationPercent } from '../../Utils/AgencyContractValues.service';
import { ReactComponent as ForkSpoonSvg } from '../../assets/svg/fork-spoon.svg';
import { ReactComponent as CheeseSvg } from '../../assets/svg/cheese.svg';
import { ReactComponent as CalendarSvg } from '../../assets/svg/calendar.svg';

type LocalStore = [AgenciesStore, RechargingViewStore, StepperRechargingStore, AgencyInvoiceStore, OrderPaymentViewStore];

/**
 * Tab: Rechargement des droits Manuel
 * Step: Mes paramètres de commande (PARAMETERS)
 */
const OrderParameters: FunctionComponent = observer(() => {
  const [
    agenciesStore,
    rechargingViewStore,
    stepperRechargingStore,
    agencyInvoiceStore,
    orderPaymentViewStore,
  ]: LocalStore = useInstances<LocalStore>(AgenciesStore, RechargingViewStore, StepperRechargingStore, AgencyInvoiceStore, OrderPaymentViewStore);
  const { t } = useTranslation('reloading');
  const isMultiClass: boolean = agenciesStore.currentAgency?.useBeneficiaryClassification;
  const [hasError, setHasError] = useState(false);
  const [alreadyHasOrderForSelectedMonth, setAlreadyHasOrderForSelectedMonth] = useState(false);

  const nextStep: () => void = useCallback(() => {
    if (rechargingViewStore.reloadingDateKey === 'unknown') {
      setHasError(true);
    } else {
      testOrderMonth();
    }
  }, [rechargingViewStore.reloadingDateKey]);

  const selectMonth: (evt: React.ChangeEvent<HTMLInputElement>) => void = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    rechargingViewStore.setReloadingDateKey(evt.target.value);
    sessionStorage.setItem('reloadingDateKey', evt.target.value);
    orderPaymentViewStore.setOrderDetails({
      ...orderPaymentViewStore.orderDetails,
      year: rechargingViewStore.selectedReloadingDate.getFullYear(),
      month: rechargingViewStore.selectedReloadingDate.getMonth() + 1,
    });
    setHasError(false);
  }, []);

  const testOrderMonth: () => void = useCallback(() => {
    const test: boolean = agencyInvoiceStore.invoices.some((invoice) => {
      const date: Date = new Date(invoice.invoiceDate);
      return date.getMonth() === rechargingViewStore.selectedReloadingDate.getMonth()
        && date.getFullYear() === rechargingViewStore.selectedReloadingDate.getFullYear();
    });
    if (!test) {
      return stepperRechargingStore.goToNextStep();
    }
    setAlreadyHasOrderForSelectedMonth(test);
  }, [rechargingViewStore.selectedReloadingDate, agencyInvoiceStore.invoices]);

  useEffect(() => {
    rechargingViewStore.setInitialData();
  }, []);

  useEffect(() => {
    const registeredReloadingDateKey: string | null = sessionStorage.getItem('reloadingDateKey');

    if (registeredReloadingDateKey) {
      rechargingViewStore.setReloadingDateKey(registeredReloadingDateKey);
    }
  }, []);

  return (
    <>
      <div className={'flex flex-col justify-center items-center py-20 space-y-10'}>
        {
          agenciesStore.currentAgencyBeneficiaryClassification?.map((classification, index) => (
            ((!isMultiClass || classification.beneficiaryCount > 0) && classification.creditRedemptionMethod === 'LINEAR') && (
              <div key={index} className={'w-full md:w-1/2 bg-background mx-auto p-10 rounded-br10'}>
                {isMultiClass && (<p className={'font-semibold'}>{classification.name}</p>)}
                <div className={'flex justify-between'}>

                  <div className={'flex flex-col justify-center items-center space-y-4'}>
                    <ForkSpoonSvg className={'w-12 h-12'} title={'Chargement de fichier'}/>
                    <span className={'text-xl font-bold'}>
                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' })
                          .format(classification.contractFacialValue)}
                      </span>
                    <p className={'text-center text-sm'}>{t('dailyAmount').replace('au', 'au\n')}</p>
                  </div>

                  <div className={'flex flex-col justify-center items-center space-y-4'}>
                    <CheeseSvg className={'w-12 h-12'} title={'Chargement de fichier'}/>
                    <span className={'text-xl font-bold'}>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency', currency: 'EUR',
                        }).format(Number(classification.contractAgencyPart))}
                      </span>
                    <p className={'text-center text-sm'}>
                      {t('contribution', {
                        companyQuota: getParticipationPercent(classification.contractAgencyPart, classification.contractFacialValue)
                          .toFixed(2),
                      }).replace('la', 'la\n')}
                    </p>
                  </div>
                </div>
              </div>
            )
          ))
        }

        <div className={'w-full md:w-1/2 rounded-br10 shadow-card p-10 flex justify-between items-center'}>
          <CalendarSvg className={'w-16 h-16 mr-10'} title={'Chargement de fichier'}/>
          <div style={{ flex: 1, position: 'relative' }}>
            <Select
              value={rechargingViewStore.reloadingDateKey}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => selectMonth(evt)}
              labelId="label"
              id="select"
              label={'Quel mois souhaitez-vous recharger ?'}
              fullWidth
              required
            >
              {Object.entries(rechargingViewStore.reloadingDate).map(([dateKey, date], index) => {
                return (<MenuItem value={dateKey} key={index}>
                  {dateKey === 'unknown'
                    ? 'Selectionnez un mois'
                    : moment(date).format('MMMM YYYY')
                  }
                </MenuItem>);
              })}
            </Select>
            {hasError && <span className={'text-status-error text-sm'}>La saisie d’un mois est nécessaire</span>}
          </div>

        </div>

        <div className={'w-full md:w-1/2 flex justify-center gap-4'}>
          <Button className={'px-20'} variant="outlined" onClick={() => stepperRechargingStore.goToPreviousStep()}>
            {t('cancel')}
          </Button>
          <Button onClick={nextStep} className={'px-20'}>
            {t('nextStep')}
          </Button>
        </div>
      </div>

      <RechargingOrderExistDialog
        isActive={alreadyHasOrderForSelectedMonth}
        onClose={() => setAlreadyHasOrderForSelectedMonth(false)}
        takeNewOrder={() => stepperRechargingStore.goToNextStep()}
        orderMonth={rechargingViewStore.selectedReloadingDate}
      />
    </>
  );
});

export default OrderParameters;
