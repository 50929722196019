import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import AgencyAdminsList from '../Components/Lists/AgencyAdminsList';
import { useNavigate } from 'react-router';
import { PageBlock } from '../../ui/layout/PageBlock';
import { SectionWrapper } from '../../ui/layout/SectionWrapper';
import { Button } from '../../ui/Buttons/Button';
import { BackButton } from '../../ui/Buttons/BackButton';

const AgencyAdminsPage = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation('settings');
  const handleCreateEmpUser = () => {
    navigate('/settings/add-agencyadmin');
  };

  return (
    <PageBlock>
      <SectionWrapper>
        <div className={'flex justify-between items-center'}>
          <div className={'flex items-center gap-2'}>
            <BackButton to={'/settings'}/>
            <h1>{t('agencyAdmins')}</h1>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateEmpUser}
            type="button">
            {t('addAgencyAdminLabel')}
          </Button>
        </div>
        <AgencyAdminsList/>
      </SectionWrapper>
    </PageBlock>
  );
});

export default AgencyAdminsPage;
