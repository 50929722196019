import { action, makeAutoObservable } from 'mobx';

export class OrderValidationViewStore {
  newActiveBeneficiaryCount: number = 0;

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setNewActiveBeneficiaryCount (value: number) {
    this.newActiveBeneficiaryCount = value;
  }
}
