/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BeneficiaryOutputDto = {
    id?: string;
    email?: string | null;
    firstname?: string | null;
    lastname?: string | null;
    mobilephone?: string | null;
    ibanLastNumbers?: string | null;
    registrationNumber?: string | null;
    title?: BeneficiaryOutputDto.title;
    isActive?: boolean;
    numberOfWorkingDays?: number;
}

export namespace BeneficiaryOutputDto {

    export enum title {
        UNKNOW = 'Unknow',
        MR = 'Mr',
        MRS = 'Mrs',
    }
}
