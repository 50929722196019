import { FirebaseApp, initializeApp } from 'firebase/app';
import { FirebaseStorage, StorageReference, getStorage as getFirebaseStorage, ref, getDownloadURL, getBlob } from 'firebase/storage';
import { CURRENT_ENV } from '../assets/api-mapping';
import { firebaseConfig } from '../assets/firebase.config';

let firebaseApp: FirebaseApp | undefined;

function getStorage(): FirebaseStorage {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig[CURRENT_ENV] ?? firebaseConfig['DEV']);
  }
  return getFirebaseStorage(firebaseApp);
}

export async function getDownloadUrl(path: string): Promise<string> {
  const objectRef = getFileRef(path);
  const blob = await getBlob(objectRef);

  return window.URL.createObjectURL(blob);
}

export async function getFileUrl(path: string): Promise<string> {
  const objectRef = getFileRef(path);

  return getDownloadURL(objectRef);
}

function getFileRef(path: string): StorageReference {
  const storage = getStorage();

  return ref(storage, path);
}