import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import { CreateBeneficiaryRequest } from '../../../assets/requests/beneficiaries/CreateBeneficiary.request';
import { UpdateBeneficiaryRequest } from '../../../assets/requests/beneficiaries/UpdateBeneficiary.request';
import capitalizeFirstLetter from '../../../Function/CapitalizeFirstLetter';
import Yup from '../../../i18n/validation';
import { BeneficiariesStore, UpdateBeneficiaryFullRequest } from '../../../Stores/Beneficiaries.store';
import { BeneficiariesDialogViewStore } from '../../../Stores/viewStore/BeneficiariesDialogView.store';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import {
  allowToModifyBeneficiaryDeactivationConfig,
} from '../../../assets/utils/beneficiaries/beneficiaryDeactivation.util';
import { AgencyModel } from '@assets/models/agencies/Agency.model';
import { IBeneficiaryDetailsProps } from 'Models/Interfaces/IBeneficiaryDetailsProps.model';
import AccountInformationFormComponent, { EMAIL_LOCK } from '../FormParts/AccountInformationFormComponent';
import IdentityFormComponent from '../FormParts/IdentityFormComponent';
import BankInformationFormComponent, { IBAN_LOCK } from '../FormParts/BankInformationFormComponent';
import AddressFormComponent from '../FormParts/AddressFormComponent';
import QrCodeSectionComponent from '../FormParts/QrCodeSectionComponent';
import { Button } from '../../../ui/Buttons/Button';
import { Toaster } from 'ui/Toaster';
import { ReactComponent as WarningSvg } from '../../../assets/svg/warning.svg';

export interface BeneficiaryFormValues extends Omit<Partial<CreateBeneficiaryRequest> & UpdateBeneficiaryRequest, 'firstRightDate'> {
  firstRightDate: string;
  iban?: string;
}

type LocalStore = [BeneficiariesStore, BeneficiariesDialogViewStore];

function getValidationSchema(agency: AgencyModel, beneficiary?: BeneficiaryModel, disabledFields?: string[]) {
  let shape: any = {
    ...AccountInformationFormComponent.getValidationSchema(disabledFields).fields,
    ...IdentityFormComponent.getValidationSchema().fields,
  };

  if (agency.isManagedPaymentMode) {
    shape = {
      ...shape,
      ...BankInformationFormComponent.getValidationSchema(disabledFields).fields,
    };
  }

  if (['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(agency.contractType)) {
    shape = {
      ...shape,
      ...AddressFormComponent.getValidationSchema().fields,
    };
  }

  return Yup.object().shape(shape);
}

function getFormInitialValues(agency: AgencyModel, beneficiary?: BeneficiaryModel) {
  let initialValues: any = {
    ...AccountInformationFormComponent.getInitialValues(beneficiary),
    ...IdentityFormComponent.getInitialValues(beneficiary),

    agencyId: agency.uid,
  };

  if (agency.isManagedPaymentMode) {
    initialValues = {
      ...initialValues,
      ...BankInformationFormComponent.getInitialValues(beneficiary),
    };
  }

  if (['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(agency.contractType)) {
    initialValues = {
      ...initialValues,
      ...AddressFormComponent.getInitialValues(beneficiary),
    };
  }

  return initialValues;
}

const BeneficiaryAccountInformations: FunctionComponent<IBeneficiaryDetailsProps> =
  observer((props: IBeneficiaryDetailsProps) => {
    const [
      beneficiariesStore,
      beneficiariesDialogViewStore,
    ]: LocalStore = useInstances<LocalStore>(BeneficiariesStore, BeneficiariesDialogViewStore);
    const { beneficiary, agency, onClose }: IBeneficiaryDetailsProps = props;

    const disableModification: boolean = useMemo(() => {
      if (!beneficiary) {
        return false;
      }
      return !allowToModifyBeneficiaryDeactivationConfig(beneficiary);
    }, [beneficiary]);

    const { t } = useTranslation('beneficiaries');

    const [hasSecureDataToUpdate, setHasSecureDataToUpdate] = useState(false);
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const disabledFieldsRef = useRef<string[]>([]);
    const disabledFields = disabledFieldsRef.current;

    const shouldShowQrCodeSection = agency.contractType === 'PUBLIC_MEAL_SUBSIDY';
    const isServerError = beneficiariesDialogViewStore.isServerError;

    const validationSchema = getValidationSchema(agency, beneficiary, disabledFields);
    const initialValues: any = getFormInitialValues(agency, beneficiary);

    const setFieldDisabled = useCallback((fieldName: string) => {
      if (!disabledFieldsRef.current.includes(fieldName)) {
        disabledFieldsRef.current.push(fieldName);
      }
    }, []);

    const setFieldEnabled = useCallback((fieldName: string) => {
      const index = disabledFieldsRef.current.indexOf(fieldName);
      if (index === -1) {
        return;
      }

      disabledFieldsRef.current.splice(index, 1);
    }, []);

    const updateBeneficiary = async (
      beneficiaryToUpdate: BeneficiaryFormValues,
      setFieldError: (field: string, errorMsg: string) => void,
      setSubmitting: (isSubmitting: boolean) => void,
    ) => {

      const { firstName, lastName, registrationNumber, email, iban, ...beneficiaryMin } = beneficiaryToUpdate;
      const updateBeneficiaryRequest: UpdateBeneficiaryFullRequest = {
        agencyId: agency.uid,
        beneficiaryId: beneficiary.uid,
        beneficiaryMin: {
          ...beneficiaryMin,
          ...(firstName ? { firstName: capitalizeFirstLetter(firstName.toLowerCase()) } : {}),
          ...(lastName ? { lastName: lastName.toUpperCase() } : {}),
        },
      };

      if (initialValues.registrationNumber !== registrationNumber) {
        updateBeneficiaryRequest.registrationNumber = { registrationNumber };
      }

      if ('email' in beneficiaryToUpdate && typeof email === 'string' && initialValues.email !== email) {
        updateBeneficiaryRequest.beneficiaryEmail = { email };
      }

      if ('iban' in beneficiaryToUpdate && typeof iban === 'string' && initialValues.iban !== iban) {
        updateBeneficiaryRequest.beneficiaryIban = { iban };
      }

      try {
        await beneficiariesStore.updateBeneficiaryAll(updateBeneficiaryRequest);
        formik.resetForm();
        handleShowMessageSnackBar(t('confirmationSnackBar'));

      } catch (error) {
        beneficiariesDialogViewStore.setIsServerError(true);
        formik.resetForm();
      }
    };

    const handleShowMessageSnackBar = (message) => {
      setSnackbarMessage(message);
      setShowConfirmationMessage(true);
    };

    const handleRequestRegenQRCode = async (confirm: boolean) => {
      if (confirm) {
        try {
          await beneficiariesStore.regenQrCodeBeneficiary(beneficiariesStore.currentBeneficiary.uid, true);
          handleShowMessageSnackBar(t('confirmationSnackBarRegenQrCode'));
        } catch (error) {
          handleShowMessageSnackBar('Failed to regenerate QR code');
        }
      }
    };

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      validateOnBlur: true,
      enableReinitialize: true,
      onSubmit: async (beneficiaryFields: BeneficiaryFormValues, {
        setSubmitting,
        setFieldError,
      }: FormikHelpers<BeneficiaryFormValues>) => {
        setSubmitting(true);
        beneficiariesDialogViewStore.setIsServerError(false);

        const values = filterDisabledFields(beneficiaryFields, disabledFields);
        setHasSecureDataToUpdate(false);
        await updateBeneficiary(values, setFieldError, setSubmitting);
        setSubmitting(false);
      },
    });

    useEffect(() => {
      if (!beneficiary || !shouldShowQrCodeSection) {
        return;
      }
      beneficiariesStore.getBeneficiaryQrCodeString(beneficiary.uid);
    }, [beneficiary, shouldShowQrCodeSection]);

    useEffect(() => {
      if (!formik.values[EMAIL_LOCK] || (agency.isManagedPaymentMode && !formik.values[IBAN_LOCK])) {
        setHasSecureDataToUpdate(true);
      } else {
        setHasSecureDataToUpdate(false);
      }
    }, [agency, formik.values]);

    const handleOnSubmitButtonClicked: () => void = useCallback(() => {
      formik.handleSubmit();
    }, []);

    const handleOnCancelButtonClicked: () => void = useCallback(() => {
      setHasSecureDataToUpdate(false);
      formik.resetForm();

      if (!beneficiary?.isActive) {
        onClose();
      }
    }, []);

    return (
      <>
        <form onSubmit={formik.handleSubmit} noValidate className={'space-y-2 pb-32'}>
          <fieldset disabled={beneficiary?.isActive === false}>

            {/* --- INFORMATIONS SUR LE COMPTE --- */}
            <AccountInformationFormComponent isEditMode={true} disableModification={disableModification}
                                             beneficiary={beneficiary} formik={formik}
                                             setFieldEnabled={setFieldEnabled} setFieldDisabled={setFieldDisabled}/>
            {/* ---------------------------------- */}

            {/* ------------ IDENTITÉ ------------ */}
            <IdentityFormComponent isEditMode={true} disableModification={disableModification} beneficiary={beneficiary}
                                   formik={formik}/>
            {/* ---------------------------------- */}

            {/* ------------- ADRESSE ------------- */}
            {['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(agency.contractType) && (
              <AddressFormComponent isEditMode={true} disableModification={disableModification}
                                    beneficiary={beneficiary}
                                    formik={formik}/>
            )}
            {/* ---------------------------------- */}

            {/* -------- RÉFÉRENCE BANCAIRE ------- */}
            {agency.isManagedPaymentMode && (
              <BankInformationFormComponent isEditMode={true} disableModification={disableModification}
                                            beneficiary={beneficiary} formik={formik}
                                            setFieldEnabled={setFieldEnabled} setFieldDisabled={setFieldDisabled}/>
            )}
            {/* ---------------------------------- */}

            {isServerError && (
              <span className={'text-status-error'}>Erreur serveur veuillez réessayer plus tard !</span>
            )}
          </fieldset>

          {shouldShowQrCodeSection &&
              <QrCodeSectionComponent isLoading={beneficiariesStore.isLoadingQrCode}
                                      qrcodeString={beneficiariesStore.currentBeneficiaryQrCodeString}
                                      onRequestRegenQRCode={handleRequestRegenQRCode}
              />
          }

          <div className={'flex flex-col gap-4'}>

            {hasSecureDataToUpdate && (
              <div className={'flex gap-2 bg-status-info px-4 py-6 rounded-br10'}>
                <WarningSvg className={'text-primary'}/>
                <span>
                  Vous avez modifié des données sensibles, confirmez-vous la modifications de ces informations ?
                </span>
              </div>
            )}

            <div className={'flex justify-end gap-4'}>
              <Button
                onClick={handleOnCancelButtonClicked}
                disabled={!formik.dirty || formik.isSubmitting || disableModification}
              >
                {beneficiary?.isActive !== false ? (hasSecureDataToUpdate ? t('confirmCancel') : t('cancel')) : t('close')}
              </Button>
              {beneficiary?.isActive !== false &&
                  <Button
                      onClick={handleOnSubmitButtonClicked}
                      loading={formik.isSubmitting}
                      disabled={!formik.dirty || formik.isSubmitting || disableModification}
                  >
                    {hasSecureDataToUpdate ? t('confirmUpdate') : t('modify')}
                  </Button>
              }
            </div>
          </div>
        </form>
        <Toaster open={showConfirmationMessage} onClose={() => setShowConfirmationMessage(false)}>
          <>{snackbarMessage}</>
        </Toaster>
      </>);
  });

export default BeneficiaryAccountInformations;

function filterDisabledFields<T extends Record<string, any> = Record<string, any>>(values: T, disabledFields: string[]): T {
  const filteredValues = {};

  Object.keys(values).forEach(key => {
    if (!disabledFields.includes(key)) {
      filteredValues[key] = values[key];
    }
  });

  return filteredValues as T;
}