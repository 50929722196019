import { VoucherRestrictionModel } from '../assets/models/discreteVouchers/VoucherRestriction.model';
import { addMonths, differenceInCalendarMonths } from 'date-fns';

const millisecondsPerWeek = (7 * 24 * 60 * 60 * 1000);

export function getNbOfWeeksFormVoucher(voucher: VoucherRestrictionModel): number {
  const start = new Date(voucher.startDate);
  const end = new Date(voucher.endDate);
  return Math.round((end.getTime() - start.getTime()) / millisecondsPerWeek);
}

export function getEndDateFromNbOfWeeks(startDate: number, nbOfWeeks: number): number {
  return startDate + nbOfWeeks * millisecondsPerWeek;
}

export function getNbOfMonthsFormRestrictionPeriod(startDateMillis: number, endDateMillis: number): number {
  const startDate = new Date(startDateMillis);
  const endDate = new Date(endDateMillis);

  return differenceInCalendarMonths(endDate, startDate) + 1;
}

export function getEndDateFromNbOfMonths(startDateMillis: number, nbOfMonths: number): Date {
  const startDate: Date = new Date(startDateMillis);

  return new Date(addMonths(startDate, nbOfMonths).getTime() - 1);
}
