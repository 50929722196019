/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Agency } from './Agency';

export type Beneficiary = {
  id?: string;
  agencyId?: string;
  email?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  gcuVersion?: string | null;
  gcuDate?: string | null;
  mobilephone?: string | null;
  registrationNumber?: string | null;
  streetAddress?: string | null;
  additionalAddress?: string | null;
  postalCode?: string | null;
  city?: string | null;
  countryCode?: string | null;
  title?: Beneficiary.title;
  isAccountValidated?: boolean;
  isActive?: boolean;
  activeSundaysAndHolidays?: boolean;
  userPath?: Beneficiary.userPath;
  createdOn?: string;
  modifiedOn?: string;
  ibanLastNumbers?: string | null;
  numberOfWorkingDays?: number;
  firstRightDate?: string;
  agency?: Agency;
}

export namespace Beneficiary {

  export enum title {
    UNKNOW = 'Unknow',
    MR = 'Mr',
    MRS = 'Mrs',
  }

  export enum userPath {
    UNKNOW = 'Unknow',
    OPEN_BANKING = 'OpenBanking',
    BILL_PICTURE = 'BillPicture',
  }

}
