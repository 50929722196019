import { observer } from 'mobx-react-lite';
import React from 'react';
import { useInstances } from 'react-ioc';
import { OrderPaymentViewStore } from '../../../../Stores/viewStore/OrderPaymentView.store';
import MethodBankTransfer from './MethodBankTransfer';
import MethodDirectDebit from './MethodDirectDebit';
import MethodDissociatedBankTransfer from './MethodDissociatedBankTransfer';
import { PaymentMethodType } from '@assets/types/PaymentMethod.type';

type LocalStore = [OrderPaymentViewStore];

const MethodPaymentManager = observer(() => {
  const [orderPaymentViewStore]: LocalStore = useInstances<LocalStore>(OrderPaymentViewStore);
  const methodPayment: PaymentMethodType = orderPaymentViewStore.paymentMethod;
  return (
    <div className={'flex-1'}>
      {methodPayment === 'BANK_TRANSFER' && <MethodBankTransfer/>}
      {methodPayment === 'DIRECT_DEBIT' && <MethodDirectDebit/>}
      {methodPayment === 'DISSOCIATED_BANK_TRANSFER' && <MethodDissociatedBankTransfer/>}
    </div>
  );
});

export default MethodPaymentManager;
