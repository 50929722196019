import { makeAutoObservable, runInAction } from 'mobx';
import { CsvReloadingModel, CsvReloadingModelIEnumerableRequestResult, CsvService } from '../app/api/mapper';

export class CsvManagerStore {
  importResult: CsvReloadingModel[] = null;
  importStatus: string = 'pending';

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  * controlReloadingImport (agencyId: string, blob: Blob) {
    this.importStatus = 'pending';
    try {
      const formData = {
        AgencyId: agencyId,
        File: blob
      };
      const importRes: CsvReloadingModelIEnumerableRequestResult = yield CsvService.controlReloadingImport(formData);
      runInAction(() => {
        this.importResult = importRes.result;
        this.importStatus = 'success';
      });
    } catch (e) {
      this.importStatus = 'error';
    }
  }
}
