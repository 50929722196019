import React, { FC, ReactNode } from 'react';
import { ReactComponent as ArrowRight } from './../assets/svg/arrows/arrowRight.svg';
import { CommonProps } from '@mui/material/OverridableComponent';
import clsx from 'clsx';

interface Props {
  beforeArrow?: string;
  variant?: 'green' | 'default';
  childrenPosition?: 'left' | 'right';
  children?: ReactNode;
}

const CLASSES = {
  root: 'relative group z-10 h-full flex items-center',
  trigger: 'transition-all duration-300 ease-in-out cursor-pointer px-4 py-3 rounded-br30 flex justify-between gap-2 items-center text-sm font-medium h-12',
  variants: {
    green: 'bg-green group-hover:bg-green/50',
    default: 'group-hover:bg-background/50'
  },
  arrow: 'transform transition-transform rotate-0 group-hover:rotate-90',
  dropdown: 'absolute top-full hidden group-hover:block w-52',
  childrenPosition: {
    left: 'left-0',
    right: 'right-0',
  }
}

export const HoverDropdown: FC<Props & CommonProps> = ({beforeArrow, children, variant = 'default', childrenPosition = 'left', className}: Props & CommonProps) => {
  return (
    <div className={clsx(CLASSES.root, className)}>
      <div
        className={clsx(CLASSES.trigger, CLASSES.variants[variant])}>
        { beforeArrow ?? ''}
        <ArrowRight
          height={12}
          width={12}
          className={CLASSES.arrow}
        />
      </div>

      <div className={clsx(CLASSES.dropdown, CLASSES.childrenPosition[childrenPosition])}>
        {children}
      </div>
    </div>
  )
}