import { LiteralModel } from '../../models/specials/Literal.model';
import { sanitizeIBAN } from '../functions/sanitizeIBAN.util';

// This validator will be used both on back and front ends, we do not use a throw pattern but return booleans

export function isIBAN(IBAN: unknown): boolean {
    if (typeof IBAN !== "string") {
        return false;
    }


    return isValidIBANNumber(IBAN);
}

function isValidIBANNumber(input: string): boolean {
    const CODE_LENGTHS: LiteralModel = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
    };
    const iban = sanitizeIBAN(input);
    const code: RegExpMatchArray | null = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    if (!code) {
        return false;
    }
    let [, part1, part2, part3]: RegExpMatchArray = code;

    if (!part1 || !part2 || !part3) {
        return false;
    }

    if (iban.length !== CODE_LENGTHS[part1]) {
        return false;
    }

    const digits: string = (part3 + part1 + part2).replace(/[A-Z]/g, distance);

    return mod97(digits) === 1;
}

function distance (letter: string): string {
    return String(letter.charCodeAt(0) - 55);
}

function mod97(stringToEvaluate: string): string | number {
    let checksum: string | number = stringToEvaluate.slice(0, 2);
    let fragment: string;
    for (let offset = 2; offset < stringToEvaluate.length; offset += 7) {
        fragment = String(checksum) + stringToEvaluate.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}
