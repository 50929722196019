import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { OrderPaymentViewStore } from '../../../Stores/viewStore/OrderPaymentView.store';

type LocalStore = [OrderPaymentViewStore, AgenciesStore];

const OrderDetails: FunctionComponent = observer(() => {
  const [orderPaymentViewStore, agenciesStore]: LocalStore = useInstances<LocalStore>(OrderPaymentViewStore, AgenciesStore);
  const isMultiClass: boolean = agenciesStore.currentAgency?.useBeneficiaryClassification;
  const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

  const { contractAgencyPart, contractSubscriptionFee } = agenciesStore.currentAgencyDefaultContractPart;

  const {
    dateString,
    tva,
    totalHT,
    totalTTC,
    beneficiaryToRechargeCount,
    provisionTotalDays,
    provisionAmount,
    netAmountPayable,
  } = orderPaymentViewStore;

  const { t } = useTranslation('payment');

  return (
    <div className={'flex-1 rounded-br10 bg-background p-4 space-y-4 text-sm'}>

      <div className={'border-grey border-solid border-b border-x-0 border-t-0 pb-4'}>
        <h3 className={'text-sm m-0'}>
          {t('orderDetails.title', { count: beneficiaryToRechargeCount, month: dateString })}
        </h3>
        <p>{t('orderDetails.subscriptionTitle')}</p>
        { !isMultiClass && (<p>{t('orderDetails.subscription', { count: beneficiaryToRechargeCount, subComp: currencyFormat.format(contractSubscriptionFee) })}</p>) }
        { isMultiClass && (<p>{t('orderDetails.subscriptionMultiClass', { count: beneficiaryToRechargeCount })}</p>) }
        <p className={'text-right'}>
          {totalHT}
        </p>
      </div>

      <div className={'border-grey border-solid border-b border-x-0 border-t-0 pb-4'}>
        <div className={'flex justify-between'}>
          <p>{t('orderDetails.ht')}</p>
          <p>{totalHT}</p>
        </div>
        <div className={'flex justify-between'}>
          <p>{agenciesStore.currentAgency?.vat.label}</p>
          <p>{tva}</p>
        </div>
        <div className={'flex justify-between'}>
          <p>{t('orderDetails.ttc')}</p>
          <p>{totalTTC}</p>
        </div>
      </div>

      <div className={'border-grey border-solid border-b border-x-0 border-t-0 pb-4'}>
        <h3 className={'text-sm'}>
          {t('orderDetails.subtitle', { month: dateString })}
        </h3>
        <p>{t('orderDetails.provisionTitle')}</p>
        { !isMultiClass && (<p>{t('orderDetails.provision', { count: provisionTotalDays, subComp: currencyFormat.format(contractAgencyPart) })}</p>) }
        <p className={'text-right'}>
          {provisionAmount}
        </p>
      </div>

      <div>
        <h3 className={'text-sm'}>
          {t('orderDetails.totalTitle')}
        </h3>
        <div className={'flex justify-between'}>
          <p className={'text-lg font-bold'}>
            {t('orderDetails.total')}
          </p>
          <p className={'text-lg font-bold'}>
            {netAmountPayable}
          </p>
        </div>
      </div>
    </div>
  );
});

export default OrderDetails;
