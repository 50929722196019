import { action, makeAutoObservable, runInAction } from 'mobx';
import { LoginRequest } from '../assets/requests/auth/Login.request';
import { ConfirmationResponse } from '../assets/responses/Confirmation.response';
import {
  activateAgencyAdmin,
  authenticate,
  confirmPasswordChangeForAgencyAdmin,
  requestPasswordChangeForAgencyAdmin,
  signOutFromFirebaseAuth,
  validateHashForAgencyAdmin,
} from '../Services/authAPI.service';
import { removeItems, setUserEmail } from '../Services/httpWrapper.service';
import { errorStore } from './Error.store';
import { sanitizeEmail } from '../assets/utils/functions/sanitizeEmail.util';
import { ChangePasswordForAgencyAdminRequest } from '../assets/requests/auth/ChangePasswordForAgencyAdmin.request';
import { ValidateHashForAgencyAdminRequest } from '../assets/requests/auth//ValidateHashForAgencyAdmin.request';
import { ValidateHashForAgencyAdminResponse } from '../assets/responses/auth/ValidateHashForAgencyAdmin.response';
import { UserCredential } from 'firebase/auth';

export class UserConnectStore {
  isReady = false;
  loginStatus = 'PENDING';
  isConnected = false;

  resetPasswordStatus = 'PENDING';
  requestPasswordChangeStatus = 'PENDING';
  isFetching = false;

  locationPathName?: string;

  isHashValidating = true;
  isHashExpired = false;
  isNewUrlSent = false;

  loginError: any = null;

  get isNewUser(): boolean {
    return this.locationPathName === '/register-admin';
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setLocationPathName(value: string) {
    this.locationPathName = value;
  }

  @action userLogin() {
    this.isConnected = true;
    this.isReady = true;
  }

  @action
  async userLogout() {
    await signOutFromFirebaseAuth();
    removeItems();
    this.isConnected = false;
    this.isReady = true;
  }

  @action setIsFetching(value: boolean) {
    this.isFetching = value;
  }

  @action setHashValidating(value: boolean) {
    this.isHashValidating = value;
  }

  @action setIsHashExpired(value: boolean) {
    this.isHashExpired = value;
  }

  async login(request: LoginRequest) {
    try {
      const { user }: UserCredential = await authenticate(request);

      const { claims } = await user.getIdTokenResult();
      const { roles = [] } = claims as any;
      if (!roles.includes('AGENCY_ADMIN')) {
        runInAction(() => {
          this.userLogout();
        });
        throw new Error(`😬 Vous n'avez pas accès à ce service`);
      }

      runInAction(() => {
        setUserEmail(sanitizeEmail(request.userName));
        this.userLogin();
        this.loginStatus = 'SUCCESS';
        errorStore.dismiss(this.loginError);
      });
    } catch (e) {
      this.loginError = e;
      this.loginStatus = 'ERROR';
      throw e;
    } finally {
      this.isReady = true;
    }
  }

  async manageResetPassword(hashedPassword: string, email: string, newPassword: string) {
    const request: ChangePasswordForAgencyAdminRequest = {
      hash: hashedPassword,
      email: email,
      password: newPassword,
    };

    this.isNewUser
      ? await this.resetPassword(request, activateAgencyAdmin)
      : await this.resetPassword(request, confirmPasswordChangeForAgencyAdmin);
  }

  async requestPasswordChange(email: string) {
    try {
      this.requestPasswordChangeStatus = 'PENDING';
      this.setIsFetching(true);

      await requestPasswordChangeForAgencyAdmin({ email });
      runInAction(() => {
        this.requestPasswordChangeStatus = 'SUCCESS';
        this.isNewUrlSent = true;
      });
    } catch (error) {
      this.requestPasswordChangeStatus = 'ERROR';
    } finally {
      this.setIsFetching(false);
    }
  }

  async testUrlExpire(email: string, hash: string): Promise<void> {
    const request: ValidateHashForAgencyAdminRequest = {
      email, hash,
    };
    try {
      this.setHashValidating(true);
      const { valid }: ValidateHashForAgencyAdminResponse = await validateHashForAgencyAdmin(request);
      if (!valid) {
        this.setIsHashExpired(true);
      }
    } catch (e) {
      this.setIsHashExpired(true);
    } finally {
      this.setHashValidating(false);
    }
  }

  private async resetPassword(request: ChangePasswordForAgencyAdminRequest, callback: (request: ChangePasswordForAgencyAdminRequest) => Promise<ConfirmationResponse>) {
    try {
      this.resetPasswordStatus = 'PENDING';
      this.setIsFetching(true);

      const response: ConfirmationResponse = await callback(request);

      runInAction(() => {
        if (response.info === 'ok') {
          this.resetPasswordStatus = 'SUCCESS';
        }
      });
    } catch (error) {
      if (error.code === 'cancelled' || error.code === 'permission-denied') {
        this.setIsHashExpired(true);
      }
      this.resetPasswordStatus = 'ERROR';
    } finally {
      this.setIsFetching(false);
    }
  }
}
