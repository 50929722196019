import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { DeleteVoucherRestrictionRequest } from '@assets/requests/discreteVouchers/DeleteVoucherRestrictionRequest';
import { Dialog } from '../../../ui/Dialog';
import { Button } from '../../../ui/Buttons/Button';

interface PopupProps {
  open: boolean;
  close: () => void;
  remove: (request) => void;
  request: DeleteVoucherRestrictionRequest;
  loading: boolean;
}

export const DeleteVoucherConfirmationDialog = ({open, close, remove, request, loading}: PopupProps) => {

  return (

    <Dialog
      open={open}
      onClose={close}
      position={'center'}
    >
      <DialogTitle>Confirmer la suppression</DialogTitle>
      <DialogContent><p>Supprimer cette période</p></DialogContent>
      <DialogActions>
        <div className={'flex justify-end gap-4 items-center'}>
          <Button
            onClick={close}
            disabled={loading}
          >
            Non, annuler
          </Button>
          <Button
            onClick={() => remove(request)}
            loading={loading}
            disabled={loading}
          >
            oui, supprimer
          </Button>
        </div>
      </DialogActions>
    </Dialog>)
}
