import { LiteralModel } from '../../models/specials/Literal.model';

export const countryCodeAndRelatedIbanLength: LiteralModel = {
  DE: 22, AD: 24, AT: 20, BE: 16, BA: 20, BG: 22,
  CY: 28, HR: 21, DK: 18, ES: 24, EE: 20, FI: 18,
  FR: 27, GB: 22, GR: 27, HU: 28, IE: 22, IS: 26, IT: 27,
  LV: 21, LI: 21, LT: 20, LU: 20, MK: 19, MT: 31, ME: 22,
  NO: 15, NL: 18, PL: 28, PT: 25, CZ: 24, RO: 24, SM: 27,
  CS: 22, SK: 24, SI: 19, SE: 24, CH: 21,
};
