import React, { FunctionComponent, useEffect } from 'react';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { COLORS, FONT } from '../../../../Style/Style';
import { observer } from 'mobx-react-lite';
import { useAllocatedRightChartStyles } from './AllocatedRightChartStyles';
import { PayinReportModel } from '../../../../assets/models/payins/PayinReport.model';

export interface IAllocatedRightChartComponentProps {
  from: number;
  payinReport: PayinReportModel[];
}

const AllocatedRightChartComponent: FunctionComponent<IAllocatedRightChartComponentProps> = observer((props: IAllocatedRightChartComponentProps) => {
  const allocatedRightChartClasses = useAllocatedRightChartStyles();

  const convertedUnityReport: PayinReportModel[] = props.payinReport.map(monthlyReport => {
    return {
      ...monthlyReport,
      totalAmountOfPayins: monthlyReport.totalAmountOfPayins,
      totalAmountOfPaybacks: monthlyReport.totalAmountOfPaybacks,
      totalAmountOfCredits: monthlyReport.totalAmountOfCredits,
    };
  });

  const totalPayin = convertedUnityReport.reduce((total, next) => total + next.totalAmountOfPayins, 0);
  const totalPayback = convertedUnityReport.reduce((total, next) => total + next.totalAmountOfPaybacks, 0);
  const consumptionPercent = isNaN(totalPayback / totalPayin) ? 0 : totalPayback / totalPayin;
  const percent = Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 });
  const currency = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

  useEffect(() => {
    const canvas = document.getElementById('AllocatedRightChartComponent') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    Chart.register(BarController, CategoryScale, LinearScale, BarElement, LineController, LineElement, PointElement, LinearScale, Legend, Tooltip);

    const chart = new Chart(ctx, {
      type: 'bar',
      options: {
        scales: {
          x: {
            stacked: true,
            grid: {
              drawBorder: false,
              display: false,
            },
            offset: true,
            ticks: {
              color: COLORS.grey,
              font: {
                size: 14,
              },
              padding: 8,
            },
          },
          y: {
            grid: {
              drawBorder: false,
              borderDash: [8, 4],
            },
            beginAtZero: true,
            min: 0,
            ticks: {
              color: COLORS.grey,
              font: {
                size: 14,
              },
              padding: 20,
              callback: function (value: number) {
                return currency.format(value);
              },
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: function (title) {
                return title[0].label.replace(',', ' ');
              },
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += currency.format(context.parsed.y);
                }
                return label;
              },
            },
          },
          legend: {
            reverse: true,
            align: 'start',
            labels: {
              boxWidth: 8,
              color: COLORS.textPrimary,
              usePointStyle: true,
              padding: 80,
            },
          },

          title: {
            display: true,
            text: ['Chart.js Bar Chart', '2'],
          },
        },
      },
      data: {
        labels: convertedUnityReport.map((pro) => {
          const provisionDate = new Date(pro.year, pro.month - 1);
          const month = provisionDate.toLocaleString(undefined, { month: 'long' });
          return [month.charAt(0).toUpperCase() + month.slice(1), provisionDate.toLocaleString(undefined, { year: 'numeric' })];
        }),
        datasets: [{
          type: 'line',
          label: 'Crédits consommés',
          backgroundColor: COLORS.textPrimary,
          data: convertedUnityReport.map((pro) => pro.totalAmountOfPaybacks),
          borderDash: [10, 5],
          borderColor: COLORS.textPrimary,
          pointRadius: 5,
          pointHoverRadius: 7,
        },
        {
          borderSkipped: false,
          borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomLeft: 5,
            bottomRight: 5,
          },
          label: 'Participation employeur',
          backgroundColor: COLORS.primary,
          maxBarThickness: 44,
          data: convertedUnityReport.map((pro) => pro.totalAmountOfPayins),
        },
        {
          borderSkipped: false,
          borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomLeft: 5,
            bottomRight: 5,
          },
          label: 'Droits alloués',
          backgroundColor: COLORS.borderBlock,
          maxBarThickness: 44,
          data: convertedUnityReport.map((pro) => pro.totalAmountOfCredits),
        }],
      },
    });
    chart.update();
    return () => {
      chart.destroy();
    };
  }, [props.payinReport]);

  return (
    <>
      <div style={{
        fontSize: '40px',
        color: COLORS.textPrimary,
        fontFamily: FONT.secondary,
        marginTop: '70px',
        marginBottom: '60px',
      }}>
        <span>À ce jour, {percent.format(consumptionPercent)} ont été consommés soit potentiellement&nbsp;</span>
        <span style={{ color: COLORS.active }}>{currency.format(totalPayin - totalPayback)}</span>
        <span>&nbsp;d’économie.</span>
      </div>
      <div className={allocatedRightChartClasses.root}>
        <div className={allocatedRightChartClasses.chartBlock}>
          <div className={allocatedRightChartClasses.titleBlock}>
            <p className={allocatedRightChartClasses.title}>DROITS ET CONSOMMATIONS MENSUELS</p>
          </div>
          <canvas id='AllocatedRightChartComponent'></canvas>
        </div>
        <div className={allocatedRightChartClasses.rightBlock}>
          <span className={allocatedRightChartClasses.rightBlockTitle}>
            {currency.format(totalPayin)}
          </span>
          <span className={allocatedRightChartClasses.rightBlockSubtitle}>Cumul de la participation employeur pour cette période</span>
          <span className={allocatedRightChartClasses.rightBlockTitle}>
            {currency.format(totalPayin - totalPayback)} soit {percent.format(1 - consumptionPercent)}
          </span>
          <span className={allocatedRightChartClasses.rightBlockSubtitle}>Droits non consommés sur cette période</span>
        </div>
      </div>
    </>
  );
});

export default AllocatedRightChartComponent;
