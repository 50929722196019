import { callable } from './httpWrapper.service';
import { LoginRequest } from '../assets/requests/auth/Login.request';
import { ConfirmationResponse } from '../assets/responses/Confirmation.response';
import { ResetPasswordRequest } from '../assets/requests/auth/ResetPassword.request';
import { ChangePasswordForAgencyAdminRequest } from '../assets/requests/auth/ChangePasswordForAgencyAdmin.request';
import { ValidateHashForAgencyAdminRequest } from '../assets/requests/auth//ValidateHashForAgencyAdmin.request';
import { ValidateHashForAgencyAdminResponse } from '../assets/responses/auth/ValidateHashForAgencyAdmin.response';
import {
  getAuth as getAuthClient,
  Auth,
  signInWithEmailAndPassword as signInWithEmailAndPasswordClient,
  connectAuthEmulator,
  User,
  UserCredential,
} from 'firebase/auth';
import { CURRENT_ENV } from '../assets/api-mapping';
import { initializeApp, onCall } from './firebase.service';
import { CheckIsActivatedUserOnCallRequest } from '@assets/requests/auth/CheckIsActivatedUserOnCall.request';
import { CheckIsActivatedUserOnCallResponse } from '@assets/responses/auth/CheckIsActivatedUserOnCall.response';

let isConnectedToAuthEmulator: boolean = false;
let auth: Auth | undefined;

export function getAuth(): Auth {
  auth = auth ?? getAuthClient(initializeApp());
  if (CURRENT_ENV === 'LOCAL' && !isConnectedToAuthEmulator) {
    isConnectedToAuthEmulator = true;
    connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  }
  return auth;
}

export async function getAccessToken(): Promise<string | undefined> {
  const auth: Auth = getAuth();
  await auth.authStateReady();
  const currentUser: User | null = auth.currentUser;
  if (!!currentUser) {
    return await currentUser.getIdToken();
  }
}

export async function signOutFromFirebaseAuth(): Promise<void> {
  try {
    await getAuth().signOut();
  } catch (e) {
    return;
  }
}

export async function authenticate(request: LoginRequest): Promise<UserCredential> {
  const { userName: email, password }: LoginRequest = request;
  return await signInWithEmailAndPasswordClient(getAuth(), email, password);
}

export async function activateAgencyAdmin(request: ChangePasswordForAgencyAdminRequest): Promise<ConfirmationResponse> {
  return callable(
    'changePasswordForAgencyAdmin',
    request,
  );
}

export async function requestPasswordChangeForAgencyAdmin(request: ResetPasswordRequest): Promise<ConfirmationResponse> {
  return callable(
    'requestPasswordChangeForAgencyAdmin',
    request,
  );
}

export async function confirmPasswordChangeForAgencyAdmin(request: ChangePasswordForAgencyAdminRequest): Promise<ConfirmationResponse> {
  return callable(
    'changePasswordForAgencyAdmin',
    request,
  );
}

export async function checkIsActivatedUser(request: CheckIsActivatedUserOnCallRequest): Promise<CheckIsActivatedUserOnCallResponse> {
  return onCall(
    'AUTH-checkIsActivatedUser_onCall',
    request,
  );
}

export async function validateHashForAgencyAdmin(request: ValidateHashForAgencyAdminRequest): Promise<ValidateHashForAgencyAdminResponse> {
  return callable(
    ('validateHashForAgencyAdmin'),
    request,
  );
}
