const millesime = {
  rightsReconductionTypesExplorerBanner: {
    title: 'Attention ! Fin de millésime pour les droits {{endingMillesimeYear}} de vos collaborateurs, que souhaitez-vous faire ?',
    optionCardTitle: {
      DROP: 'Récupérer le solde non consommé',
      KEEP: 'Reporter le solde non consommé',
    },
    optionCardContent: {
      DROP: 'Les soldes non consommés vous seront restitués',
      KEEP: 'Les droits non consommés de vos collaborateurs seront reportés sur le millésime {{nextMillesimeYear}}',
    },
    linkDescription: 'Retrouvez votre choix dans l’onglet',
    linkLabel: 'Fin de millésime',
  },
  definedRightsReconductionTypeBanner: {
    title: 'Fin de millésime pour les droits {{endingMillesimeYear}} de vos collaborateurs',
    description: {
      DROP: 'Vous avez sélectionné la restitution des soldes non consommés de vos collaborateurs sur le millésime {{endingMillesimeYear}}',
      KEEP: 'Vous avez sélectionné le report des droits restants de vos collaborateurs sur le millésime {{nextMillesimeYear}}',
    },
    linkLabel: 'Modifier',
  },
  selectedTypeConfirmationModal: {
    title: 'Fin de millésime {{endingMillesimeYear}}',
    description: {
      DROP: 'Vous confirmez vouloir récupérer le solde non consommé de vos collaborateurs ?',
      KEEP: 'Vous confirmez reporter le solde non consommé sur le nouveau millésime ?',
    },
    subDescription: {
      DROP: 'Les soldes non consommés vous seront restitués',
      KEEP: 'Vos collaborateurs verront leur solde non consommé être reporté sur le nouveau millésime.',
    },
    cancelButtonLabel: 'Annuler',
    submitButtonLabel: 'Oui, je confirme',
  },
  rightsReconductionTypesExplorerPanel: {
    title: 'MILLÉSIME {{endingMillesimeYear}}',
    formControlLabel: {
      DROP: 'Récupérer le solde non consommé',
      KEEP: 'Reporter le solde non consommé',
    },
    helperText: {
      DROP: {
        'DIRECT_DEBIT_IBAN_PAYOUT': 'Les soldes non consommés vous seront restitués sur le compte de prélèvement.',
        'RESTITUTION_IBAN_PAYOUT': 'Les soldes non consommés vous seront restitués sur le compte {{restitutionIban}}.',
        'PROVISIONS_WALLET_DEPOSIT': 'Les soldes non consommés seront conservés dans votre portefeuille Openeat.',
      },
      KEEP: 'Les droits non consommés de vos collaborateurs seront reportés sur le millésime {{nextMillesimeYear}}',
    },
    defaultReconductionTypeText: 'Les droits non consommés de vos collaborateurs seront reportés par défaut.',
    subHelperText: {
      DROP: 'Vous pouvez toujours modifier votre méthode de restitution dans l’onglet',
    },
    helperLinkLabel: {
      DROP: 'Paiement et restitution',
    },
    submitButtonLabel: 'Valider',
  },
  definedRightsReconductionTypePanel: {
    title: 'MILLÉSIME {{endingMillesimeYear}}',
    definedTypeDescription: {
      DROP: {
        DIRECT_DEBIT_IBAN_PAYOUT: 'Vous avez choisi de récupérer le solde non consommé sur le compte de prélèvement.',
        RESTITUTION_IBAN_PAYOUT: 'Vous avez choisi de récupérer le solde non consommé en virement bancaire',
        PROVISIONS_WALLET_DEPOSIT: 'Vous avez choisi de conserver le solde non consommé dans votre portefeuille Openeat.',
      },
      KEEP: 'Les soldes non consommés seront reportés sur le nouveau millésime',
    },
    restitutionIbanLabel: 'Compte bancaire',
    infoMessage: 'Votre choix est modifiable jusqu’au {{ deadlineDate }}',
    submitButtonLabel: 'Modifier',
  },
  endOfMillesimeReportPanel: {
    title: 'MILLÉSIME {{year}}',
    downloadReceiptLabel: 'Télécharger le relevé de restitution',
    selectedTypeLabel: {
      DROP: {
        DIRECT_DEBIT_IBAN_PAYOUT: 'Vous avez choisi de récupérer les soldes non consommés sur votre compte de prélèvement',
        RESTITUTION_IBAN_PAYOUT: 'Vous avez choisi de récupérer les soldes non consommés par virement bancaire',
        PROVISIONS_WALLET_DEPOSIT: 'Vous avez choisi de laisser les soldes non consommés sur votre portefeuille Openeat',
      },
      KEEP: 'Vous avez choisi de reporter les soldes non consommés de vos collaborateurs',
    },
    restitutionIbanLabel: 'Compte bancaire',
    restitutionTotalAmountLabel: 'Montant total',
    restitutionDetailsTable: {
      beneficiaryCount_one: '{{count}} personne concernée',
      beneficiaryCount_other: '{{count}} personnes concernées',
      beneficiaryNameColumnName: 'Collaborateurs',
      restitutionAmountColumnLabel: 'Montant',
    }
  },
};

export default millesime;
