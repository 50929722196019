import { observer } from 'mobx-react-lite';
import React from 'react';
import { provider } from 'react-ioc';
import LeftBlock from './LeftBlock';
import RightBlock from './RightBlock';

export interface ITopMenuComponentProps {
  setMobileOpen: () => void;
}

const Header = provider()(observer((props: ITopMenuComponentProps) => {
  return (
    <header className="flex justify-between items-center bg-background px-10 h-24">
      <LeftBlock/>
      <RightBlock/>
    </header>
  );
}));

export default Header;
