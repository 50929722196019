import { MenuItem, Select } from '@mui/material';
import { InputSelect } from '../Style/MuiStyles/Select/Select';
import Downarrow from '../assets/down-arrow';
import { COLORS } from '../Style/Style';
import React from 'react';
import { SelectProps } from '@mui/material';

export type SelectElementProps<ListElement extends { uid: string, name?: string, [key: string]: any }> = {
  value: string,
  onChange: SelectProps['onChange'],
  id: string,
  title: string,
  disabled: boolean,
  list: ListElement[],
  isRequired?: boolean
  [key: string]: any
}

export const SelectElement = <ListElement extends {
  uid: string,
  name: string
}>(props: SelectElementProps<ListElement>) => {
  const {
    value, onChange, id, title, disabled, list, isRequired = true,
  }: SelectElementProps<ListElement> = props;

  return <Select
    value={value}
    onChange={onChange}
    id={id}
    disabled={disabled}
    input={<InputSelect title={title} required={isRequired}/>}
    IconComponent={(props) => <div
      style={{ marginTop: '0px' }}>{Downarrow({ svgColor: COLORS.textPrimary, ...props })}
    </div>}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      disablePortal: true,
    }}
  >
    {
      list.map((element: ListElement) =>
        <MenuItem key={element.uid} value={element.uid}>{element.name}</MenuItem>,
      )
    }
  </Select>;
};
