import React, {useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useCSVReader } from 'react-papaparse';
import { FileTestSet } from '../../../Utils/Tester/BaseTester.service';
import { ReactComponent as UploadSvg } from './../../../assets/svg/tabs/file-arrow.svg';
import { Button } from '../../../ui/Buttons/Button';
import clsx from 'clsx';

interface IFileUploadedManager<T> {
  file: any;
  setFile: (value: File) => void;
  testSet: FileTestSet<T>;
  analyseUploadingFile: (csvData: string[][], testSet: FileTestSet<T>) => void;
  fileTypeAccepted?: string;
  isLoading?: boolean;
  setIsLoading?: (value: boolean) => void;
  showNewTestResult?: boolean;
  setShowNewTestResult?: (value: boolean) => void;
  className?: string;
}

export default function FileUploadManager <T, >(props: IFileUploadedManager<T>) {
  const { t } = useTranslation('common');
  const [zoneHover, setZoneHover] = useState<boolean>(false);
  const { CSVReader } = useCSVReader();
  const {
    setFile,
    setShowNewTestResult,
    setIsLoading,
    analyseUploadingFile,
    isLoading,
    file,
    testSet,
    showNewTestResult,
  }: IFileUploadedManager<T> = props;

  const launchFileAnalyse = useCallback((e) => {
    e.stopPropagation();
    setIsLoading(true);
    analyseUploadingFile(file, testSet);
    setShowNewTestResult(false);
  }, [setIsLoading, analyseUploadingFile, setShowNewTestResult]);

  const onUploadAccepted = useCallback((results: any) => {
    setFile(results.data);
    setZoneHover(false);
    setShowNewTestResult(true);
  },[]);

  const onDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    setZoneHover(true);
  },[]);

  const onDragLeave = useCallback((event: DragEvent) => {
    event.preventDefault();
    setZoneHover(false);
  },[]);

  return (
    <CSVReader
      onUploadAccepted={onUploadAccepted}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      {({getRootProps, acceptedFile}: any) => (<>
        <div
          {...getRootProps()}
          className={clsx('p-14 w-1/2 mx-auto border border-dashed rounded-br10 transition-all duration-300 ease-in-out', zoneHover ? 'hover:border-status-success' : '')}
        >
          <div className={'flex flex-col justify-evenly h-full items-center space-y-4'}>
            <UploadSvg className="w-16 h-16 text-primary" title={'icône de téléchargement d\'un fichier'}/>
            {acceptedFile
              ? (<>
                  <p className={'font-bold'}>
                    {acceptedFile.name}
                  </p>
                  <span>
                    <a className={'cursor-pointer'}>{t('dropzone.link.search')}</a>
                    {t('dropzone.link.anotherFile')}
                  </span>
                  {showNewTestResult &&
                    <Button
                        variant='contained'
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={launchFileAnalyse}
                    >
                      {t('dropzone.readFile')}
                    </Button>}
                </>)
              : (<>
                  <p className={'font-bold'}>
                    {t('dropzone.titleEmpty')}
                  </p>
                  <span>
                    {t('dropzone.link.where')}
                    <a className={'cursor-pointer'}>{t('dropzone.link.search')}</a>
                    {t('dropzone.link.yourFile')}
                  </span>
                </>)
            }
          </div>
        </div>
      </>)}
    </CSVReader>
  );
};
