import { action, makeAutoObservable, runInAction } from 'mobx';
import { ProductType } from '../../assets/models/products/Products.model';
import {
  AgencyMillesimeReport, AgencyMillesimeReportState,
  BeneficiaryMillesimeReport,
} from '../../assets/models/millesime/MillesimeReports.model';
import { getAgencyBeneficiariesMillesimeReports, getAgencyMillesimeReports } from '../../Services/millesimeAPI.service';
import { PagingCollectionRequest } from '../../assets/requests/PagingCollection.request';
import { PaginationResponse } from '../../assets/responses/PagingCollection.response';
import { IPaginatedData } from 'Models/Interfaces/IPaginatedData.model';
import { ExportingBeneficiaryMillesimeReportModel } from '../../assets/models/millesime/ExportingMillesimeReports.model';
import { formatBeneficiaryMillesimeReportForExport } from '../../assets/utils/millesime/millesime.util';
import { downloadAsCSV } from '../../Utils/CsvDownload.utils';
import { downloadLink } from '../../Utils/Download.utils';
import { getDownloadUrl } from '../../Services/storage.service';
import { errorStore } from '../Error.store';

export interface BeneficiariesReportsData extends IPaginatedData<BeneficiaryMillesimeReport<ProductType, 'DONE'>> { }

export class MillesimeReportsViewStore<Product extends ProductType> {
  productType: Product;
  agencyId: string;

  agencyReports: AgencyMillesimeReport<Product>[] = [];

  isLoadingAgencyReports: boolean = false;

  private isDownloadingReceiptMap: Map<string, boolean> = new Map<string, boolean>();
  private isPreparingBeneficiariesReportsCsvMap: Map<string, boolean> = new Map<string, boolean>();

  private beneficiariesReportsDataMap: Map<string, BeneficiariesReportsData> = new Map<string, BeneficiariesReportsData>();

  constructor(productType: Product, agencyId: string) {
    this.productType = productType;
    this.agencyId = agencyId;

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action async fetchAgencyReports<State extends AgencyMillesimeReportState = AgencyMillesimeReportState>(state?: State): Promise<void> {
    this.isLoadingAgencyReports = true;

    try {
      const reports: AgencyMillesimeReport<Product, State>[] = await getAgencyMillesimeReports<Product, State>(this.agencyId, this.productType, state);

      runInAction(() => {
        this.agencyReports = reports.sort((a, b) => b.year - a.year);
      })
    } catch (e) {
      errorStore.catchError(new Error('Erreur lors de la récupération des informations de l\'agence'));
    }

    this.isLoadingAgencyReports = false;
  }

  @action async fetchBeneficiariesReports(agencyReportId: string, paginationRequest: PagingCollectionRequest): Promise<void> {
    const agencyReport: AgencyMillesimeReport<Product> | undefined = this.agencyReports.find(report => report.uid === agencyReportId);

    if (!agencyReport) {
      return;
    }

    let currentReportData: BeneficiariesReportsData = this.beneficiariesReportsDataMap.get(agencyReportId);

    if (currentReportData?.isLoading) {
      return;
    }

    let reportData = {
      page: paginationRequest.requiredPage,
      pageSize: paginationRequest.pageSize,
      items: [],
      count: -1,
      pageCount: 0,
      isLoading: true,
    };

    if (currentReportData) {
      reportData = {
        ...reportData,
        count: currentReportData.count,
        pageCount: currentReportData.pageCount,
      }
    }

    this.setBeneficiariesReportData(agencyReportId, reportData);

    try {
      const reportsResponse: PaginationResponse<BeneficiaryMillesimeReport<Product>> = await getAgencyBeneficiariesMillesimeReports<Product>(this.agencyId, this.productType, agencyReport.year, paginationRequest);

      reportData = {
        ...reportData,
        items: reportsResponse.items,
        count: reportsResponse.count,
        pageCount: reportsResponse.pageCount,
      }
      this.setBeneficiariesReportData(agencyReportId, reportData);
    } catch (e) {
      errorStore.catchError(new Error('Erreur lors de la récupération des informations des bénéficiaires'));
    }

    reportData = {
      ...reportData,
      isLoading: false,
    }
    this.setBeneficiariesReportData(agencyReportId, reportData);
  }

  @action async downloadReceipt(agencyReportId: string) {
    const agencyReport: AgencyMillesimeReport<Product> | undefined = this.agencyReports.find(report => report.uid === agencyReportId);

    if (!agencyReport || agencyReport.state != 'DONE') {
      return;
    }

    if (this.isDownloadingReceipt(agencyReportId)) {
      return;
    }

    this.setIsDownloadingReceipt(agencyReportId, true);

    try {
      const { receiptId } = agencyReport as AgencyMillesimeReport<Product, 'DONE'>;
      const url: string = await getDownloadUrl(`accounting_documents/${this.agencyId}/${receiptId}.pdf`);

      downloadLink(url, `receipt_millesime_${this.productType.toLowerCase()}_${agencyReport.year}_${this.agencyId}.pdf`);
    } catch(e) {
      errorStore.catchError(new Error('Erreur lors de la récupération du relevé'));
    }

    this.setIsDownloadingReceipt(agencyReportId, false);
  }

  @action async downloadBeneficiariesReportsCsv(agencyReportId: string) {
    const agencyReport: AgencyMillesimeReport<Product> | undefined = this.agencyReports.find(report => report.uid === agencyReportId);

    if (!agencyReport) {
      return;
    }

    if (this.isPreparingBeneficiariesReportsCsv(agencyReportId)) {
      return;
    }

    const reportData: BeneficiariesReportsData | undefined = this.getBeneficiariesReportsData(agencyReportId);

    if (!reportData || reportData.isLoading) {
      return;
    }

    this.setIsPreparingBeneficiariesReportsCsv(agencyReportId, true);

    try {
      const reportsResponse: PaginationResponse<BeneficiaryMillesimeReport<Product>> = await getAgencyBeneficiariesMillesimeReports<Product>(this.agencyId, this.productType, agencyReport.year, {
        requiredPage: 0,
        pageSize: reportData.count,
      });

      const formattedReports: ExportingBeneficiaryMillesimeReportModel[] = reportsResponse.items.map(formatBeneficiaryMillesimeReportForExport);

      downloadAsCSV<ExportingBeneficiaryMillesimeReportModel>(formattedReports, `export_millesime_${this.productType.toLowerCase()}_${agencyReport.year}_${this.agencyId}`);
    } catch(e) {
      errorStore.catchError(new Error('Erreur lors de la récupération du fichier csv'));
    }

    this.setIsPreparingBeneficiariesReportsCsv(agencyReportId, false);
  }

  @action private setBeneficiariesReportData(agencyReportId: string, reportData: BeneficiariesReportsData) {
    this.beneficiariesReportsDataMap.set(agencyReportId, reportData);
  }

  @action private setIsDownloadingReceipt(agencyReportId: string, value: boolean) {
    this.isDownloadingReceiptMap.set(agencyReportId, value);
  }

  @action private setIsPreparingBeneficiariesReportsCsv(agencyReportId: string, value: boolean) {
    this.isPreparingBeneficiariesReportsCsvMap.set(agencyReportId, value);
  }

  @action clearBeneficiariesReports(agencyReportId: string): void {
    this.beneficiariesReportsDataMap.delete(agencyReportId);
    this.isPreparingBeneficiariesReportsCsvMap.delete(agencyReportId);
  }

  getBeneficiariesReportsData(agencyReportId: string): BeneficiariesReportsData | undefined {
    return this.beneficiariesReportsDataMap.get(agencyReportId);
  }

  isDownloadingReceipt(agencyReportId: string): boolean {
    return this.isDownloadingReceiptMap.get(agencyReportId) ?? false;
  }

  isPreparingBeneficiariesReportsCsv(agencyReportId: string): boolean {
    return this.isPreparingBeneficiariesReportsCsvMap.get(agencyReportId) ?? false;
  }
}