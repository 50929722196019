import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import FooterComponent from '../Layout/FooterComponents/FooterComponent';
import logo from '../assets/logo-text-new.svg';
import { COLORS, FONT } from '../Style/Style';
import circleBackground from '../assets/circle-background.svg';
import { useNavigate } from 'react-router-dom';

const useCookiesStyles = makeStyles(() => ({
  root: {
    padding: '60px 130px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 210px)',
    background: `url(${circleBackground})  no-repeat`
  },
  title: {
    fontFamily: FONT.secondary,
    fontSize: '30px',
    color: COLORS.textPrimary,
    margin: '60px 0px'
  },
  subtitle: {
    fontFamily: FONT.secondary,
    fontSize: '20px',
    color: COLORS.textPrimary,
    margin: '60px 0px'
  },
  content: {
    fontSize: '14px',
    textAlign: 'justify',
    color: COLORS.textPrimary
  },
  logo: {
    cursor: 'pointer'
  }
}));

const LegalNoticeComponent:FunctionComponent = () => {
  const cookiesClasses = useCookiesStyles();
  const navigate = useNavigate();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={cookiesClasses.root}>
        <img className={cookiesClasses.logo} width={198} alt='logo' src={logo} onClick={() => navigate('/dashboard')} />
        <p className={cookiesClasses.title}>Mentions légales</p>
        <p className={cookiesClasses.content}>
          En vigueur au 20/07/2024
        </p>
        <p className={cookiesClasses.content}>
          Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la
          Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des Utilisateurs du site
          {window.location.hostname} les présentes mentions légales. La connexion et la navigation sur le site
          (Employeur) par l’Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.
          Ces dernières sont accessibles sur le site à la rubrique « Mentions légales ».
        </p>
        <p className={cookiesClasses.subtitle}>ARTICLE 1 : L’éditeur</p>
        <p className={cookiesClasses.content}>
          L’édition du site {window.location.hostname} est assurée par la Société SAS OPEN! au capital de 100000
          euros, immatriculée au RCS de Paris sous le numéro 891446668 dont le siège social est situé au 13 RUE
          DESCOMBES 75017 PARIS 17, adresse e-mail : hello@stayopen.io N° de TVA intracommunautaire :
          FR61891446668. Le Directeur de la publication est Jacques-Yves HARSCOUET.
        </p>
        <p className={cookiesClasses.subtitle}>ARTICLE 2 : L’hébergeur</p>
        <p className={cookiesClasses.content}>
          Hébergeur : le site est hébergé sur l’offre Google Cloud, géographiquement localisé à travers l'Europe.
        </p>
        <p className={cookiesClasses.subtitle}>ARTICLE 3 : Accès au site</p>
        <p className={cookiesClasses.content}>
          Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou
          non et pouvant découler d’une nécessité de maintenance. En cas de modification, interruption ou suspension
          des services, le site {window.location.hostname} ne saurait être tenu responsable.
        </p>
        <p className={cookiesClasses.subtitle}>ARTICLE 4 : Collecte des données</p>
        <p className={cookiesClasses.content}>
          Nous vous invitons à consulter notre politique de protection des données personnelles qui détaille les
          traitements de données personnelles mis en œuvre sur le site {window.location.hostname}. L’utilisateur exerce
          ce droit : par mail à l’adresse contact-pro@stayopen.io
        </p>
        <p className={cookiesClasses.subtitle}>ARTICLE 5 : Propriété intellectuelle</p>
        <p className={cookiesClasses.content}>
          Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du site web-
          {window.location.hostname}, sans autorisation de l’Editeur est prohibée et pourra entraîner des actions et
          poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.
        </p>
      </div>
      <FooterComponent />
    </div>
  );
};

export default LegalNoticeComponent;
