import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { useLocation, useNavigate } from 'react-router-dom';
import closedEye from './../assets/closed-eye.svg';
import eye from './../assets/eye.svg';
import openeat from './../assets/logo-text-new.svg';
import mailSent from './../assets/mailSent.svg';
import { UserConnectStore } from '../Stores/UserConnect.store';
import { LoginViewStore } from '../Stores/viewStore/LoginView.store';
import { regexPassword } from './../Utils/regexList';
import PageLoader from '../Component/PageLoader/PageLoader';
import { Button } from '../ui/Buttons/Button';
import { InputField } from '../ui/Input';
import { AuthLayout } from './AuthLayout';
import { Toaster } from 'ui/Toaster';

type LocalStore = [UserConnectStore, LoginViewStore];

interface LocalFormValue {
  password: string;
  confirmPassword: string;
}

interface PasswordComplexityReport {
  hasTwelveChar: boolean;
  hasALowerCase: boolean;
  hasAUpperCase: boolean;
  hasADigit: boolean;
  hasASpecial: boolean;
}

function testPassword(password: string): PasswordComplexityReport {
  const specialChars = /[^\da-zA-Z]/;
  const checkDigit = /\d/;

  return {
    hasTwelveChar: password.length > 11,
    hasALowerCase: password.toUpperCase() !== password,
    hasAUpperCase: password.toLowerCase() !== password,
    hasADigit: checkDigit.test(password),
    hasASpecial: specialChars.test(password),
  };
}

const AdminRegisterPage: FunctionComponent = observer(() => {
  const [userConnectStore, loginViewStore]: LocalStore = useInstances<LocalStore>(UserConnectStore, LoginViewStore);
  const { isNewUser, isNewUrlSent, isHashExpired, isFetching, isHashValidating }: UserConnectStore = userConnectStore;
  const { isNewPasswordHidden, isNewConfirmPasswordHidden }: LoginViewStore = loginViewStore;

  const [passwordComplexityReport, setPasswordComplexityReport] = useState<PasswordComplexityReport>(testPassword(''));
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  const hashedPassword = params.get('data');
  const email = params.get('email');

  const { t } = useTranslation('resetpassword');

  async function getNewUrl() {
    await userConnectStore.requestPasswordChange(email);
  }

  const resetPassword: ({ password }: {
    password: any
  }, setSubmitting) => Promise<void> = useCallback(async ({ password }, setSubmitting) => {
    setSubmitting(true);
    await userConnectStore.manageResetPassword(hashedPassword, email, password);
    if (userConnectStore.resetPasswordStatus === 'SUCCESS') {
      navigate(`/login?email=${encodeURI(email)}`);
    } else {
      setShowErrorSnackbar(true);
    }
    setSubmitting(false);
  }, [hashedPassword, email]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: (
      values: LocalFormValue,
      { setSubmitting }: FormikHelpers<LocalFormValue>,
    ) => resetPassword(values, setSubmitting),
  });

  const activeSubmit: (pass: string, confirmPass: string) => boolean = useCallback((pass: string, confirmPass: string) => {
    const testPass = regexPassword.test(pass);
    return testPass && pass === confirmPass;
  }, []);

  useEffect(() => {
    userConnectStore.setLocationPathName(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    userConnectStore.userLogout();
    void userConnectStore.testUrlExpire(email, hashedPassword);
  }, []);

  useEffect(() => {
    setPasswordComplexityReport(testPassword(formik.values.password));
  }, [formik.values.password]);

  if (isHashValidating) {
    return <PageLoader/>;
  }

  return (
    <>
      <AuthLayout>
        <div className={'flex flex-col justify-center items-center space-y-8'}>
          <img className="block md:hidden w-[200px] pb-10" alt="Openeat" src={openeat}/>
          {!isHashExpired
            ? (<>
              <div className={'text-sm flex flex-col items-center'}>
                {isNewUrlSent
                  ? (<>
                    <img alt="logo" width="125px" src={mailSent}/>
                    <p>{t('newUrlSent')}</p>
                  </>)
                  : (<p className={'align-center'}>{t('linkExpired')}</p>)
                }
              </div>
              {!isNewUrlSent &&
                  <Button
                      className={'mx-auto'}
                      variant="contained"
                      onClick={getNewUrl}
                      disabled={isFetching}
                      loading={isFetching}
                  >
                    {t('expiredUrlButtonLabel')}
                  </Button>
              }
            </>)
            : <>
              <p className={'text-sm'}>
                {isNewUser ? t('subtitle') : t('subtitle_tow')}
              </p>
              <form className={'w-full lg:w-2/3 xl:w-1/2 space-y-4'} onSubmit={formik.handleSubmit} noValidate>
                <InputField
                  id="password"
                  name="password"
                  label={t('inputPassword')}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.password) && formik.touched.password}
                  type={!isNewPasswordHidden ? 'password' : 'text'}
                  endIcon={<img
                    className={'cursor-pointer'}
                    alt={'eye'}
                    width="20"
                    src={isNewPasswordHidden ? eye : closedEye}
                    onClick={() => loginViewStore.setIsNewPasswordHidden(!isNewPasswordHidden)}
                  />}
                  fullWidth
                  required
                  placeholder={t('inputPasswordPlaceholder')}
                  errorMessage={formik.errors.password}
                />
                <InputField
                  id="confirmPassword"
                  name="confirmPassword"
                  label={t('inputConfirmPassword')}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.confirmPassword) && formik.touched.confirmPassword}
                  type={!isNewConfirmPasswordHidden ? 'password' : 'text'}
                  endIcon={<img
                    className={'cursor-pointer'}
                    alt={'eye'}
                    width="20"
                    src={isNewConfirmPasswordHidden ? eye : closedEye}
                    onClick={() => loginViewStore.setIsNewConfirmPasswordHidden(!isNewConfirmPasswordHidden)}
                  />}
                  fullWidth
                  required
                  placeholder={t('inputConfirmPasswordPlaceholder')}
                  errorMessage={formik.errors.confirmPassword}
                />
                <div className={'rounded-br30 bg-background p-4 text-sm space-y-2'}>
                  <p>{t('passwordInformations')}</p>
                  <p className={passwordComplexityReport.hasTwelveChar ? 'text-status-success' : ''}>- 12 caractères</p>
                  <p className={passwordComplexityReport.hasAUpperCase ? 'text-status-success' : ''}>- 1 majuscule</p>
                  <p className={passwordComplexityReport.hasALowerCase ? 'text-status-success' : ''}>- 1 minuscule</p>
                  <p className={passwordComplexityReport.hasADigit ? 'text-status-success' : ''}>- 1 chiffre</p>
                  <p className={passwordComplexityReport.hasASpecial ? 'text-status-success' : ''}>- 1 caractère spécial ex :
                    #§(@/$*%...
                  </p>
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  loading={formik.isSubmitting}
                  disabled={!activeSubmit(formik.values.password, formik.values.confirmPassword)}
                  type="submit"
                >
                  {isNewUser ? t('newPassword') : t('resetPassword')}
                </Button>
              </form>
            </>
          }
        </div>
      </AuthLayout>
      <Toaster
        open={showErrorSnackbar}
        onClose={() => setShowErrorSnackbar(false)}
        variant={'error'}
      >
        {t('errorSnackBar.resetPassword')}
      </Toaster>
    </>
  );
});

export default AdminRegisterPage;
