import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AccountingViewStore } from '../../../Stores/viewStore/AccountingView.store';
import { StyledSelect } from '../../../Style/MuiStyles/Select/Select';
import { useTranslation } from 'react-i18next';
import { AgencyRestitutionReceiptModel } from '@assets/models/accountingDocuments/AgencyAccountingDocument.model';
import DeactivationReportCard from '../../../Component/DeactivationReportCard/DeactivationReportCard';
import DeactivationDetailsComponent from './DeactivationsDetails/DeactivationDetailsComponent';

import { ReactComponent as GlassesSvg } from '../../../assets/svg/glasses.svg';

import { Dialog } from '../../../ui/Dialog';
import { Spinner } from '../../../ui/Spinner';
import { Select } from '../../../ui/Select';
import { MenuItem } from '@mui/material';
import { InputField } from '../../../ui/Input';

const DeactivationsReportComponent: FunctionComponent<any> = observer(({ accountingStore }: {
  accountingStore: AccountingViewStore,
}) => {
  const { t } = useTranslation('accounting');

  const [currentDeactivationReportKey, setCurrentDeactivationReportKey] = useState<number | undefined>(undefined);
  const [receipts, setReceipts] = useState<AgencyRestitutionReceiptModel[] | void>([]);
  const [sinceDate, setSinceDate] = useState<Date | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleClickOpen = (key: number) => {
    setCurrentDeactivationReportKey(key);
    accountingStore.setOpenDialog(true);
  };

  const handleClose = () => {
    accountingStore.setOpenDialog(false);
  };

  const handleInputChangeSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredReceipts = useMemo(() => {
    if (!receipts || !searchTerm) {
      return receipts;
    }

    return receipts.filter(item =>
      item.invoiceReference.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.beneficiaryLastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.beneficiaryFirstName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [receipts, searchTerm]);

  useEffect(() => {
    const fetchReceipts = async () => {
      try {
        const receiptsResponse = await accountingStore.getRestitutionReceipts(sinceDate);
        if (receiptsResponse) {
          setReceipts(receiptsResponse.filter((item) => item.documentType === 'RestitutionReceipt:FOOD_VOUCHER'));
        }
      } catch (error) {
        console.error('Failed to fetch receipts', error);
      }
    };
    fetchReceipts();
  }, [accountingStore.agency.uid, sinceDate]);

  const handleDateChange = (event) => {
    let from = new Date();
    switch (event.target.value) {
      case '0':
        from.setMonth(from.getMonth() - 6);
        break;
      case '1':
        from.setFullYear(from.getFullYear() - 1);
        break;
      case '2':
        from.setFullYear(from.getFullYear() - 2);
        break;
      case '3':
        from = null;
        break;
      default:
        from = null;
    }
    setSinceDate(from);
  };

  const transformDateToValue = (date: Date | null): string => {
    if (!date) {
      return '3';
    }

    const now = new Date();
    if (date.getFullYear() === now.getFullYear() - 2) {
      return '2';
    }
    if (date.getFullYear() === now.getFullYear() - 1) {
      return '1';
    }
    if (date.getMonth() === now.getMonth() - 6 && date.getFullYear() === now.getFullYear()) {
      return '0';
    }
    return '3';
  };

  //@todo changer les styles, il faut une entreprise avec des deactivations
  return (
    <>
      {accountingStore.isLoadingRestitutionReceipts ? (
        <Spinner size={'lg'}/>
      ) : (
        <div className={'space-y-4 py-6'}>
          <div className={'flex justify-between'}>
            <Select
              value={transformDateToValue(sinceDate)}
              onChange={handleDateChange}
              labelId="label"
              id="select"
              input={<StyledSelect/>}
            >
              <MenuItem value="0">{t('since6months')}</MenuItem>
              <MenuItem value="1">{t('since1year')}</MenuItem>
              <MenuItem value="2">{t('since2years')}</MenuItem>
              <MenuItem value="3">{t('sinceBeginning')}</MenuItem>
            </Select>
            <InputField
              placeholder={t('invoiceSearchPlaceholder')}
              onChange={handleInputChangeSearch}
              value={searchTerm}
              startIcon={<GlassesSvg/>}
            />
          </div>
          {filteredReceipts &&
            <>
              <div className={'flex gap-5 mt-12'}>
                {filteredReceipts?.map((receipt, i) => (
                  <DeactivationReportCard
                    key={i}
                    deactivationReportKey={i}
                    deactivationReport={receipt}
                    openDeactivationReport={handleClickOpen}
                  />
                ))}
              </div>
              <Dialog position={'center'}
                      open={accountingStore.openDialog}
                      onClose={handleClose}>
                <DeactivationDetailsComponent
                  receipt={filteredReceipts[currentDeactivationReportKey]}
                  onClose={handleClose}
                  accountingStore={accountingStore}
                />
              </Dialog>
            </>
          }

        </div>
      )}
    </>
  );
});

export default DeactivationsReportComponent;
