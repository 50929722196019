import { downloadLink } from './Download.utils';

export function downloadAsCSV<T extends Record<string, any>>(dataToExport: T[], filename: string = "open_export"): void {
  const csv: string[] = prepareCsv(dataToExport);
  downloadCsvBuffer(csv, filename);
}

export function downloadEmptyCsv(columnNames: string[], filename: string = "open_export"): void {
  const csv: string[] = prepareEmptyCsv(columnNames);
  downloadCsvBuffer(csv, filename);
}

function downloadCsvBuffer(csvBuffer: string[], filename: string): void {
  const blob: Blob = new Blob(csvBuffer, { type: 'text/csv' });
  const url: string = window.URL.createObjectURL(blob);

  downloadLink(url, filename);
}

function prepareCsv<T extends Record<string, any>>(dataToExport: T[]): string[] {
  const csv: string[] = [];
  const csvRow: string[] = [];
  csvRow.push(Object.keys(dataToExport[0]).join(';'));

  dataToExport.forEach((item: T) => {
    const values: string = Object.values(item).join(';');
    csvRow.push(values);
  });

  csv.push(csvRow.join('\n'));

  return csv;
}

function prepareEmptyCsv(columnNames: string[]): string[] {
  const csv: string[] = [];
  const csvRow: string[] = [];

  csvRow.push(columnNames.join(';'));
  csvRow.push(columnNames.map(name => '').join(';'))

  csv.push(csvRow.join('\n'));

  return csv;
}