import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { provider, useInstances } from 'react-ioc';
import capitalizeFirstLetter from '../../../Function/CapitalizeFirstLetter';
import { BeneficiariesDialogViewStore } from '../../../Stores/viewStore/BeneficiariesDialogView.store';
import BeneficiaryAccountInformations
  from '../BeneficiaryAccountInformations/BeneficiaryAccountInformations';
import BeneficiaryPaybackManagedComponent from '../BeneficiaryPaybackManaged/BeneficiaryPaybackManagedComponent';
import BeneficiaryProvisionsComponent from '../BeneficiaryProvisions/BeneficiaryProvisionsComponent';
import BeneficiaryTakeOutComponent from '../BeneficiaryTakeOut/BeneficiaryTakeOut';
import { AgencyModel } from '../../../assets/models/agencies/Agency.model';
import { IBeneficiaryDetailsProps } from '../../../Models/Interfaces/IBeneficiaryDetailsProps.model';
import BeneficiaryActivityComponent from '../BeneficiaryActivity/BeneficiaryActivityComponent';
import BeneficiaryOrganizationComponent from '../BeneficiaryOrganization/BeneficiaryOrganizationComponent';
import BeneficiaryVouchersComponent from '../BeneficiaryVouchers/BeneficiaryVouchersComponent';
import { TabBar } from 'ui/TabBar';
import { ReactComponent as CloseSvg } from './../../../assets/svg/close.svg';

type LocalStore = [BeneficiariesDialogViewStore];

interface TabDefinition {
  label: string;
  Component: FunctionComponent<IBeneficiaryDetailsProps>;
  isVisible: (agency?: AgencyModel) => boolean;
}

const tabs: TabDefinition[] = [
  {
    label: 'Compte',
    Component: BeneficiaryAccountInformations,
    isVisible: (agency?: AgencyModel) => true,
  },
  {
    label: 'Organisation',
    Component: BeneficiaryOrganizationComponent,
    isVisible: (agency?: AgencyModel) => ['PUBLIC_MEAL_SUBSIDY'].includes(agency?.contractType),
  },
  {
    label: 'Activité',
    Component: BeneficiaryActivityComponent,
    isVisible: (agency?: AgencyModel) => true,
  },
  {
    label: 'Droits attribués',
    Component: BeneficiaryProvisionsComponent,
    isVisible: (agency?: AgencyModel) => ['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(agency?.contractType),
  },
  {
    label: 'Dépenses validées',
    Component: BeneficiaryPaybackManagedComponent,
    isVisible: (agency?: AgencyModel) => ['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(agency?.contractType),
  },
  {
    label: 'Dépenses validées',
    Component: BeneficiaryVouchersComponent,
    isVisible: (agency?: AgencyModel) => ['PUBLIC_MEAL_SUBSIDY'].includes(agency?.contractType),
  },
  {
    label: 'Sortie dispositif',
    Component: BeneficiaryTakeOutComponent,
    isVisible: (agency?: AgencyModel) => true,
  },
];

const BeneficiaryDetails: FunctionComponent<IBeneficiaryDetailsProps> = provider(BeneficiariesDialogViewStore)(observer((props: IBeneficiaryDetailsProps) => {
  const [beneficiariesDialogViewStore]: [BeneficiariesDialogViewStore] = useInstances<LocalStore>(BeneficiariesDialogViewStore);
  const tabNumber = props.beneficiary === null ? 0 : beneficiariesDialogViewStore.tabNumber;
  const { t } = useTranslation('beneficiaries');

  const visibleTabs: TabDefinition[] = tabs.map(tab => ({
    ...tab,
    props: {
      beneficiary: props.beneficiary,
      agency: props.agency,
      onClose: props.onClose,
    },
  })).filter(tab => tab.isVisible(props.agency));

  if (!props.beneficiary) {
    return <></>;
  }

  return (
    <div className={'p-10 min-h-screen'}>
      <div className={'flex justify-between items-center'}>
        <h3>{`${t('detailsTitle')} ${capitalizeFirstLetter(props.beneficiary?.firstName)} ${props.beneficiary?.lastName.toUpperCase()}`}</h3>
        <CloseSvg
          onClick={props.onClose}
          title={t('close')}
          className={'text-primary cursor-pointer hover:text-primary/75 transition-colors ease-in-out duration-300'}
        />
      </div>
      <span>Vous pouvez consulter ici les données du collaborateur</span>
      <TabBar
        tabs={visibleTabs}
        value={tabNumber}
        onChange={(event, tabNumber) => beneficiariesDialogViewStore.setTabNumber(tabNumber)}
        aria-label="détail bénéficiaire"
      />
    </div>
  );
}));

export default BeneficiaryDetails;
