const footer = {
  title: 'Envoyez-nous un message',
  subtitle: 'Nous vous répondrons dans les plus brefs délais',
  emailLabel: 'Votre adresse email',
  emailPlaceholder: 'jean.dupond@mail.com',
  subjectLabel: 'Sujet',
  subjectPlaceholder: 'Ma demande',
  contentLabel: 'Détails de votre demande',
  contentPlaceholder: 'Bonjour, ...',
  leitmotif: 'L\'application qui rembourse le déjeuner de vos salariés',
  address: 'Open! 13, rue Descombes, 75017 Paris',
  assistance: 'ASSISTANCE',
  company: 'ENTREPRISE',
  website: 'Notre site web',
  confidentiality: 'CONFIDENTIALITÉ',
  cookies: 'Déclaration relative aux cookies',
  personalData: 'Données personnelles',
  cgu: 'Conditions générales de vente',
  ml: 'Mentions légales',
  about: 'À Propos de nous'
};

export default footer;
