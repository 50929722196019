import {manageError} from '../errors';

export function isValidUrl(urlToTest: unknown): boolean {
    if (typeof urlToTest !== 'string') {
        manageError({ code: 'invalid-argument', channel: 'INPUT', message: 'url must be a proper string' });
    }
    if (isDataURL(urlToTest as string)) {
        return false;
    }
    try {
        new URL(urlToTest as string);
    } catch (_) {
        return false;
    }
    return true;
}

export function isDataURL(s: string): boolean {
    const regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
    return !!s.match(regex);
}
