import {
  AgencyAdminCapability,
  AgencyAdminProfileAssociation,
  AgencyAdminProfileDefinition,
} from '../../models/security/agencyAdmins.model';
import { manageError } from '../errors';

type ADMIN_PROFILES =
  | 'ADMIN'
  | 'SUPER_ADMIN'
;

const PROFILES_MAP: { [key in ADMIN_PROFILES]: AgencyAdminProfileDefinition } = {
  SUPER_ADMIN: {
    uid: 'SUPER_ADMIN',
    code: 1,
    name: 'Super Administrateur',
    capabilities: [
      "identity.read",
      "identity.write",
      "beneficiary.read",
      "beneficiary.write",
      "accounting.read",
      "accounting.write",
      "settings.read",
      "settings.write",
    ]
  },
  ADMIN: {
    uid: 'ADMIN',
    code: 2,
    name: 'Administrateur',
    capabilities: [
      "identity.read",
      "identity.write",
      "beneficiary.read",
      "beneficiary.write",
      "accounting.read",
      "accounting.write",
    ]
  }
}

export async function getListOfProfiles(_agencyId: string): Promise<AgencyAdminProfileDefinition[]> {
  return Object.values(PROFILES_MAP);
}

export async function getAgencyAdminProfileDefinition(profileId: string): Promise<AgencyAdminProfileDefinition> {
  const id = mapAgencyAdminProfileId(profileId);

  return PROFILES_MAP[id];
}

export async function getAgencyAdminCapabilities(agencyAdminAssociation: AgencyAdminProfileAssociation): Promise<AgencyAdminCapability[]> {
  return getProfileCapabilities(agencyAdminAssociation.profileId);
}

export async function hasAgencyAdminCapability(agencyAdminAssociation: AgencyAdminProfileAssociation, capability: AgencyAdminCapability): Promise<boolean> {
  const capabilities = await getAgencyAdminCapabilities(agencyAdminAssociation);

  return capabilities.includes(capability);
}

export async function hasAllAgencyAdminCapabilities(agencyAdminAssociation: AgencyAdminProfileAssociation, capabilities: AgencyAdminCapability[]): Promise<boolean> {
  const existingCapabilities = await getAgencyAdminCapabilities(agencyAdminAssociation);

  return capabilities.every(capability => existingCapabilities.includes(capability));
}

function getProfileCapabilities(profileId: string): AgencyAdminCapability[] {
  const id = mapAgencyAdminProfileId(profileId);

  return id in PROFILES_MAP ? PROFILES_MAP[id].capabilities : [];
}

function mapAgencyAdminProfileId(profileId: string): ADMIN_PROFILES {
  switch (profileId) {
    case 'ADMIN':
      return 'ADMIN';
    case 'SUPER_ADMIN':
      return 'SUPER_ADMIN';
    default:
      manageError({
        code: 'invalid-argument', channel: 'INPUT', message: `Unknown agency admin profile id ${profileId}`,
      });
  }
}
