import { callable } from '../Services/httpWrapper.service';
import { ProductType } from '@assets/models/products/Products.model';
import { RightsReconductionType } from '../assets/models/agencies/Agency.model';
import {
  AgencyMillesimeReport,
  AgencyMillesimeReportState,
  BeneficiaryMillesimeReport,
} from '../assets/models/millesime/MillesimeReports.model';
import { GetAgencyMillesimeReportsRequest } from '../assets/requests/millesime/GetAgencyMillesimeReports.request';
import { PagingCollectionRequest } from '../assets/requests/PagingCollection.request';
import { PaginationResponse } from '../assets/responses/PagingCollection.response';
import { ConfirmationResponse } from '../assets/responses/Confirmation.response';
import { AgencyCurrentMillesimeStatusModel } from '../assets/models/millesime/AgencyMillesimeStatus.model';
import {
  convertAgencyMillesimeReportFromCentsToEuros,
  convertBeneficiaryMillesimeReportFromCentsToEuros,
} from '../assets/utils/millesime/millesime.util';

export async function getAgencyCurrentMillesimeStatus(agencyId: string): Promise<AgencyCurrentMillesimeStatusModel> {
  return callable(
    'getAgencyCurrentMillesimeStatus',
    { agencyId },
  );
}

export async function setSelectedRightsReconductionType(productType: ProductType, year: number, agencyId: string, rightsReconductionType: RightsReconductionType): Promise<ConfirmationResponse> {
  return callable(
    'setAgencyRightsReconductionType',
    { agencyId },
    { productType, year, rightsReconductionType },
  );
}

export async function resetSelectedRightsReconductionType(productType: ProductType, year: number, agencyId: string): Promise<ConfirmationResponse> {
  return callable(
    'resetAgencyRightsReconductionType',
    { agencyId },
    { productType, year },
  );
}

export async function getAgencyMillesimeReports<Product extends ProductType = ProductType, State extends AgencyMillesimeReportState = AgencyMillesimeReportState>(agencyId: string, productType: Product, state?: State): Promise<AgencyMillesimeReport<Product, State>[]> {
  const request: GetAgencyMillesimeReportsRequest = { productType };
  if (state) {
    request.state = state;
  }

  return callable(
    'getAgencyMillesimeReports',
    { agencyId },
    request,
  ).then(agencyReports => agencyReports
    .map(convertAgencyMillesimeReportFromCentsToEuros),
  ) as Promise<AgencyMillesimeReport<Product, State>[]>;
}

export async function getAgencyBeneficiariesMillesimeReports<Product extends ProductType = ProductType>(agencyId: string, productType: Product, year: number, paginationRequest: PagingCollectionRequest): Promise<PaginationResponse<BeneficiaryMillesimeReport<Product>>> {
  const { requiredPage = 0, pageSize = 10 } = paginationRequest;

  return callable(
    'getAgencyBeneficiariesMillesimeReports',
    { agencyId },
    { productType, year, requiredPage, pageSize },
  ).then(response => {
    const beneficiaryReportsInEuros = response.items.map(convertBeneficiaryMillesimeReportFromCentsToEuros);
    return {
      ...response,
      items: beneficiaryReportsInEuros,
    };
  }) as Promise<PaginationResponse<BeneficiaryMillesimeReport<Product>>>;
}
