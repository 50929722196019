import { action, makeAutoObservable } from 'mobx';

export class BeneficiariesDialogViewStore {
  tabNumber: number = 0;
  isServerError: boolean = false;

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setTabNumber (tab: number) {
    this.tabNumber = tab;
  };

  @action setIsServerError (isError: boolean) {
    this.isServerError = isError;
  };
};
