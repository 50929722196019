const nationality =
{
  AD: 'Andorrane',
  AE: 'Emirienne',
  AF: 'Afghane',
  AG: 'Antiguaise et barbudienne',
  AL: 'Albanaise',
  AM: 'Armenienne',
  AO: 'Angolaise',
  AR: 'Argentine',
  AT: 'Autrichienne',
  AU: 'Australienne',
  AZ: 'Azerbaïdjanaise',
  BA: 'Bosnienne',
  BB: 'Barbadienne',
  BD: 'Bangladaise',
  BE: 'Belge',
  BF: 'Burkinabe',
  BG: 'Bulgare',
  BH: 'Bahreinienne',
  BI: 'Burundaise',
  BJ: 'Beninoise',
  BN: 'Bruneienne',
  BO: 'Bolivienne',
  BR: 'Bresilienne',
  BS: 'Bahamienne',
  BT: 'Bhoutanaise',
  BW: 'Botswanaise',
  BY: 'Bielorusse',
  BZ: 'Belizienne',
  CA: 'Canadienne',
  CF: 'Centrafricaine',
  CG: 'Congolaise',
  CH: 'Suisse',
  CI: 'Ivoirienne',
  CL: 'Chilienne',
  CM: 'Camerounaise',
  CN: 'Chinoise',
  CO: 'Colombienne',
  CR: 'Costaricaine',
  CU: 'Cubaine',
  CV: 'Cap-verdienne',
  CY: 'Chypriote',
  CZ: 'Tcheque',
  DE: 'Allemande',
  DJ: 'Djiboutienne',
  DK: 'Danoise',
  DM: 'Dominiquaise',
  DO: 'Dominicaine',
  DZ: 'Algerienne',
  EC: 'Equatorienne',
  EE: 'Estonienne',
  EG: 'Egyptienne',
  ER: 'Erythreenne',
  ES: 'Espagnole',
  ET: 'Ethiopienne',
  FI: 'Finlandaise',
  FJ: 'Fidjienne',
  FM: 'Micronesienne',
  FR: 'Française',
  GA: 'Gabonaise',
  GD: 'Grenadienne',
  GE: 'Georgienne',
  GH: 'Ghaneenne',
  GM: 'Gambienne',
  GN: 'Guineenne',
  GQ: 'Equato-guineenne',
  GR: 'Hellenique',
  GT: 'Guatemalteque',
  GW: 'Bissau-Guinéenne',
  GY: 'Guyanienne',
  HN: 'Hondurienne',
  HR: 'Croate',
  HT: 'Haïtienne',
  HU: 'Hongroise',
  ID: 'Indonesienne',
  IE: 'Irlandaise',
  IL: 'Israélienne',
  IN: 'Indienne',
  IQ: 'Irakienne',
  IS: 'Islandaise',
  IT: 'Italienne',
  JM: 'Jamaïcaine',
  JO: 'Jordanienne',
  JP: 'Japonaise',
  KE: 'Kenyane',
  KG: 'Kirghize',
  KH: 'Cambodgienne',
  KI: 'Kiribatienne',
  KM: 'Comorienne',
  KN: 'Kittitienne-et-nevicienne',
  KP: 'Nord-coréenne',
  KR: 'Sud-coréenne',
  KW: 'Koweitienne',
  KZ: 'Kazakhstanaise',
  LA: 'Laotienne',
  LB: 'Libanaise',
  LC: 'Saint-Lucienne',
  LI: 'Liechtensteinoise',
  LK: 'Sri-lankaise',
  LR: 'Liberienne',
  LS: 'Lesothane',
  LT: 'Lituanienne',
  LU: 'Luxembourgeoise',
  LV: 'Lettone',
  LY: 'Libyenne',
  MA: 'Marocaine',
  MC: 'Monegasque',
  MD: 'Moldave',
  ME: 'Montenegrine',
  MG: 'Malgache',
  MH: 'Marshallaise',
  MK: 'Macedonienne',
  ML: 'Malienne',
  MM: 'Birmane',
  MN: 'Mongole',
  MR: 'Mauritanienne',
  MT: 'Maltaise',
  MU: 'Mauricienne',
  MV: 'Maldivienne',
  MW: 'Malawienne',
  MX: 'Mexicaine',
  MY: 'Malaisienne',
  MZ: 'Mozambicaine',
  NA: 'Namibienne',
  NE: 'Nigerienne',
  NG: 'Nigeriane',
  NI: 'Nicaraguayenne',
  NL: 'Neerlandaise',
  NO: 'Norvegienne',
  NP: 'Nepalaise',
  NR: 'Nauruane',
  NZ: 'Neo-zelandaise',
  OM: 'Omanaise',
  PA: 'Panameenne',
  PE: 'Peruvienne',
  PG: 'Papouane-neoguineenne',
  PH: 'Philippine',
  PK: 'Pakistanaise',
  PL: 'Polonaise',
  PR: 'Portoricaine',
  PS: 'Palestinienne',
  PT: 'Portugaise',
  PW: 'Palau',
  PY: 'Paraguayenne',
  QA: 'Qatarienne',
  RO: 'Roumaine',
  RS: 'Serbe',
  RU: 'Russe',
  RW: 'Rwandaise',
  SA: 'Saoudienne',
  SB: 'Salomonaise',
  SC: 'Seychelloise',
  SD: 'Soudanaise',
  SE: 'Suedoise',
  SG: 'Singapourienne',
  SI: 'Slovene',
  SK: 'Slovaque',
  SL: 'Sierra-leonaise',
  SM: 'Saint-Marinaise',
  SN: 'Senegalaise',
  SO: 'Somalienne',
  SR: 'Surinamaise',
  ST: 'Santomeenne',
  SV: 'Salvadorienne',
  SY: 'Syrienne',
  SZ: 'Swazie',
  TD: 'Tchadienne',
  TG: 'Togolaise',
  TH: 'Thaïlandaise',
  TJ: 'Tadjike',
  TL: 'Est-timoraise',
  TM: 'Turkmene',
  TN: 'Tunisienne',
  TO: 'Tonguienne',
  TR: 'Turque',
  TT: 'Trinidadienne',
  TV: 'Tuvaluane',
  TW: 'Taiwanaise',
  TZ: 'Tanzanienne',
  UA: 'Ukrainienne',
  UG: 'Ougandaise',
  US: 'Americaine',
  UY: 'Uruguayenne',
  UZ: 'Ouzbeke',
  VA: 'Vaticane',
  VC: 'Saint-Vincentaise-et-Grenadine',
  VE: 'Venezuelienne',
  VN: 'Vietnamienne',
  VU: 'Vanuatuane',
  WS: 'Samoane',
  YE: 'Yemenite',
  ZA: 'Sud-africaine',
  ZM: 'Zambienne',
  ZW: 'Zimbabweenne'
};

export default nationality;
