import React, { FunctionComponent, ReactNode } from 'react';
import { Typography } from '@mui/material';
import warningIcon from '../../assets/warning.svg';
import { fileUploadAnalysisErrorsStyles } from './FileUploadAnalysisErrorsStyles';
import helpUtf8 from '../../assets/helpUtf8.png';
import circleCross from '../../assets/circleCros.png';
import { useTranslation } from 'react-i18next';
import { FileTestResult } from '@utils/Tester/BaseTester.service';

interface ErrorBlockProps {
  children: ReactNode;
}

interface ErrorLineProps {
  key?: string | number;
  children: ReactNode;
}

const ErrorBlock: FunctionComponent<ErrorBlockProps> = ({ children }) => {
  const styles = fileUploadAnalysisErrorsStyles();

  return (
    <div className={styles.errorContainer}>
      <div className={styles.accordionBlockError}>
        <div className={styles.registrationNumberError}>
          {children}
        </div>
      </div>
    </div>
  )
}

const ErrorLine: FunctionComponent<ErrorLineProps> = ({ key, children }) => {
  const styles = fileUploadAnalysisErrorsStyles();

  return (
    <Typography key={key} className={styles.typographyError}>
      <img src={warningIcon} alt="warning"/>
      <span className={styles.titleTextError}>
          {children}
        </span>
    </Typography>
  )
};

const FileUploadAnalysisErrors = ({ fileTestResult }: { fileTestResult: FileTestResult<any> | null }) => {
  const { t } = useTranslation('beneficiariesfromcsv');
  const styles = fileUploadAnalysisErrorsStyles();

  return (
    <>
      { fileTestResult && fileTestResult.errors?.map(fileError => {
        if (fileError.errorMessage === 'EMPTY_COLUMN_NAME') {
          return (
            <ErrorBlock>
              {fileError.errorDetails.indices.map((emptyColumnIndex: number) => (
                <ErrorLine key={emptyColumnIndex}>
                  {t('EMPTY_COLUMN_NAME', { index: emptyColumnIndex })}
                </ErrorLine>
              ))}
            </ErrorBlock>
          )
        }

        if (fileError.errorMessage === 'UNKNOWN_COLUMN') {
          return (
            <ErrorBlock>
              {fileError.errorDetails.unknownColumns.map((unknownColumnName: string) => (
                <ErrorLine key={unknownColumnName}>
                  {t('UNKNOWN_COLUMN', { name: unknownColumnName })}
                </ErrorLine>
              ))}
            </ErrorBlock>
          )
        }

        if (fileError.errorMessage === 'MISSING_TESTSET_KEYS') {
          return (
            <ErrorBlock>
              {fileError.errorDetails.missing.map((missingColumn: { fieldName: string, columnName: string }) => (
                <ErrorLine key={missingColumn.fieldName}>
                  {t('MISSING_TESTSET_KEYS', { name: missingColumn.columnName })}
                </ErrorLine>
              ))}
            </ErrorBlock>
          )
        }

        if (fileError.errorMessage === 'FILE_EMPTY') {
          return (
            <ErrorBlock>
              <ErrorLine>
                {t('FILE_EMPTY')}
              </ErrorLine>
            </ErrorBlock>
          )
        }

        if (fileError.errorMessage === 'DUPLICATE_VALUES' && fileError.errorDetails.fieldName === 'registrationNumber') {
          return (
            <ErrorBlock>
              {fileError.errorDetails.duplicates.map((duplicate: string) => (
                <ErrorLine key={duplicate}>
                  {t('DUPLICATE_REGISTRATION_NUMBER', { duplicate })}
                </ErrorLine>
              ))}
            </ErrorBlock>
          )
        }

        if (fileError.errorMessage === 'DUPLICATE_VALUES' && fileError.errorDetails.fieldName === 'email') {
          return (
            <ErrorBlock>
              {fileError.errorDetails.duplicates.map((duplicate: string) => (
                <ErrorLine key={duplicate}>
                  {t('DUPLICATE_EMAIL', { duplicate })}
                </ErrorLine>
              ))}
            </ErrorBlock>
          )
        }

        if (fileError.errorMessage === 'NOT_UTF8') {
          return (
            <ErrorBlock>
              <Typography className={styles.typographyErrorUtf8}>
                <img src={warningIcon} alt="warning"/>
                <span className={styles.titleTextError}>
                            Une erreur est survenue
                          </span>
                <p className={styles.textErrorUtf8}>
                  Votre fichier ne semble pas être encodé en format « UTF-8 ». Cela pose notamment
                  des problèmes pour l’interprétation des caractères accentués.
                </p>
                <p className={styles.textErrorUtf8}>
                  Pour y remédier, sous Excel lors de l’enregistrement de votre fichier, vérifiez
                  l’option d’encodage pour sélectionner « CSV UTF-8 » :
                </p>
                <div className={styles.helpUtf8BoxImg}>
                  <img src={helpUtf8} alt={'help-error-utf8-file'}
                       className={styles.helpUtf8Img}/>
                </div>
                <div className={styles.textHelpUtf8}>
                  <div className={styles.warningUtf8}>
                    <img src={circleCross} alt={'warning-utf8'}
                         className={styles.helpUtf8Img}/>
                  </div>
                  <p>N'hésitez pas à contacter le support si vous souhaitez être accompagné.</p>
                </div>
              </Typography>
            </ErrorBlock>
          )
        }
      })}
    </>
  )
}

export default FileUploadAnalysisErrors;