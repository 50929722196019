import React, { FunctionComponent, useState } from 'react';
import { AutonomousService, PaymentRequest, PaymentRequestRequestResult } from '../../app/api/mapper';
import { PageSubtitle } from '../../Style/Style';
import StyledTextField from '../../Style/MuiStyles/TextField';
import Button from '../../Style/MuiStyles/Button';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { AgenciesStore } from '../../Stores/Agencies.store';

export interface INewPaymentRequestComponentProps {
  downloadCsvRequestPayment: (paymentRequest: PaymentRequest) => void;
  onClose: () => void;
  lastDate: string;
}

const NewPaymentRequestComponent: FunctionComponent<INewPaymentRequestComponentProps> = observer((props: INewPaymentRequestComponentProps) => {
  const agenciesStore: AgenciesStore = useInstance(AgenciesStore);
  const [isFetchingGetPaymentRequest, setIsFetchingGetPaymentRequest] = useState(false);
  const [to, setTo] = useState(new Date().toISOString().split('T')[0]);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>(null);

  const getRequestPayment = () => {
    if (!agenciesStore.currentAgency) return;
    setIsFetchingGetPaymentRequest(true);
    AutonomousService.getRequestPayment(agenciesStore.currentAgency.uid, to)
      .then((GetAgenciesListBeneficiaryPaybackOutputDto: PaymentRequestRequestResult) => {
        setPaymentRequest(GetAgenciesListBeneficiaryPaybackOutputDto?.result);
        if (!GetAgenciesListBeneficiaryPaybackOutputDto?.result) return;
        props.downloadCsvRequestPayment(GetAgenciesListBeneficiaryPaybackOutputDto?.result);
      })
      .catch(e => console.error(e))
      .finally(() => setIsFetchingGetPaymentRequest(false));
  };
  const Close =
    <div style={{ width: '100%', justifyContent: 'end', display: 'flex' }}>
      <Button
        onClick={props.onClose}
        variant='contained'
        color='primary'>
        Fermer
      </Button>
    </div>;

  return (
    <div style={{ height: '100vh', padding: '40px' }}>
      <h1>Générer un nouveau remboursement</h1>
      <PageSubtitle>
        Calculer la somme à rembourser sur une période.
      </PageSubtitle>
      {paymentRequest === null &&
          <>
            <h1 style={{ marginTop: '106px' }}>Jusqu’à quelle date souhaitez-vous calculer les remboursements ?</h1>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
              <span>Du {moment(props.lastDate).format('DD/MM/YYYY')}</span>
              <div style={{ width: '418px', marginLeft: '2rem' }}>
                <StyledTextField
                  id='to'
                  name='to'
                  label={'Jusqu\'au'}
                  value={to}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setTo(evt.target.value)}
                  required
                  fullWidth
                  type='date'
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
              <Button
                type='button'
                isFetching={isFetchingGetPaymentRequest}
                disabled={isFetchingGetPaymentRequest}
                onClick={() => getRequestPayment()}
                variant='contained'
                color='primary'>
                      Génrérer le remboursement
              </Button>
            </div>
          </>
      }
      {paymentRequest === undefined &&
          <>
            <h1 style={{ marginTop: '106px' }}>Aucun nouveau remboursement à effectuer !</h1>
            {Close}
          </>
      }
      {paymentRequest &&
          <>
            <h1 style={{ marginTop: '106px' }}>👍 Félicitations, votre nouveau remboursement a été généré.</h1>
            {Close}
          </>
      }
    </div>
  );
});

export default NewPaymentRequestComponent;
