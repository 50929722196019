import { action, makeAutoObservable, runInAction } from 'mobx';
import { AgencyModel } from '@assets/models/agencies/Agency.model';
import { AgencyAdminModel } from '@assets/models/agencyAdmin/AgencyAdmin.model';
import {
  BeneficiariesFileUploadModel,
  BeneficiariesFileUploadStatus,
} from '@assets/models/beneficiaries/BeneficiariesFileUpload.model';
import { CURRENT_ENV } from '../assets/api-mapping';
import { getDoc, subscribeToDocument } from '../Services/firebase.service';
import { Unsubscribe } from 'firebase/firestore';

const STORAGE_TOKEN = `openeat-${CURRENT_ENV.toLowerCase()}:beneficiaries-upload`;

export interface LocalUploadRef {
  uid: string;
  agencyId: string;
  agencyAdminId: string;
  beneficiaryCount: number;
  status: BeneficiariesFileUploadStatus;
  hidden: boolean;
  errorCount?: number;
}

interface LocalUploadRefList {
  [refId: string]: LocalUploadRef;
}

export class BeneficiariesUploadStore {
  activeRefs: LocalUploadRef[] = [];

  beneficiariesListsDirty: boolean = false;

  private refSubscriptionMap: Map<string, Unsubscribe> = new Map<string, Unsubscribe>();
  private currentAgencyAdmin: AgencyAdminModel | null = null;
  private currentAgency: AgencyModel | null = null;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setAgencyAdmin(agencyAdmin: AgencyAdminModel | null) {
    this.currentAgencyAdmin = agencyAdmin;
    this.refreshActiveRefs();
  }

  @action setAgency(agency: AgencyModel | null) {
    this.currentAgency = agency;
    this.refreshActiveRefs();
  }

  @action setContext(agencyAdmin: AgencyAdminModel | null, agency: AgencyModel | null) {
    this.currentAgencyAdmin = agencyAdmin;
    this.currentAgency = agency;
    this.refreshActiveRefs();
  }

  @action closeRef(ref: LocalUploadRef) {
    if (this.uploadIsOver(ref)) {
      this.stopRefTracking(ref);
    } else {
      this.hideRef(ref);
    }
  }

  @action refreshActiveRefs() {
    this.activeRefs = this.getCurrentRefs();

    this.refSubscriptionMap.forEach(unsubscribe => unsubscribe());
    this.refSubscriptionMap.clear();
    this.activeRefs.forEach(ref => this.subscribeToRefUpdates(ref));
  }

  @action saveUploadRef(uploadRef: BeneficiariesFileUploadModel) {
    const ref: LocalUploadRef = {
      uid: uploadRef.uid,
      agencyId: uploadRef.agencyId,
      agencyAdminId: uploadRef.uploadedByUserId,
      status: uploadRef.status,
      beneficiaryCount: uploadRef.uploadContent.length ?? 0,
      hidden: false,
    }

    this.saveRef(ref);
    this.refreshActiveRefs();
  }

  @action resetBeneficiariesListDirty() {
    this.beneficiariesListsDirty = false;
  }

  getFileUploadDetails(ref: LocalUploadRef): Promise<BeneficiariesFileUploadModel> {
    const path: string = this.getRefDocumentPath(ref);

    return getDoc<BeneficiariesFileUploadModel>(path);
  }

  @action private hideRef(ref: LocalUploadRef) {
    ref.hidden = true;
    this.saveRef(ref);
  }

  @action private stopRefTracking(ref: LocalUploadRef) {
    this.unsubscribeRefUpdates(ref);
    this.removeRef(ref);
    this.refreshActiveRefs();
  }

  private subscribeToRefUpdates(ref: LocalUploadRef) {
    if (this.uploadIsOver(ref)) {
      // No changes expected, avoid subscription
      return;
    }

    const path: string = this.getRefDocumentPath(ref);

    const unsubscribe = subscribeToDocument<BeneficiariesFileUploadModel>(path, (uploadRef: BeneficiariesFileUploadModel) => {
      runInAction(() => {
        ref.status = uploadRef.status;

        if (this.uploadIsOver(ref)) {
          ref.hidden = false;
          this.unsubscribeRefUpdates(ref);
        }

        if (uploadRef.beneficiariesFailed && uploadRef.beneficiariesFailed.length > 0) {
          ref.errorCount = uploadRef.beneficiariesFailed.length;
        }

        if (ref.status === 'DONE') {
          this.beneficiariesListsDirty = true;
        }

        this.saveRef(ref);
      })
    });

    this.refSubscriptionMap.set(ref.uid, unsubscribe);
  }

  private unsubscribeRefUpdates(ref: LocalUploadRef) {
    const unsubscribe: Unsubscribe | undefined = this.refSubscriptionMap.get(ref.uid);

    if (unsubscribe) {
      unsubscribe();
      this.refSubscriptionMap.delete(ref.uid);
    }
  }

  private getRefDocumentPath(ref: LocalUploadRef): string {
    return `AGENCIES/${ref.agencyId}/BENEFICIARIES_UPLOADS/${ref.uid}`;
  }

  private uploadIsOver(ref: LocalUploadRef) {
    return ref.status === 'DONE' || ref.status === 'FAILED';
  }

  private getCurrentRefs(): LocalUploadRef[] {
    if (!this.currentAgency || !this.currentAgencyAdmin) {
      return [];
    }

    const refList: LocalUploadRefList = this.getRefList();

    return Object.values(refList)
      .filter(ref => ref.agencyId === this.currentAgency.uid && ref.agencyAdminId === this.currentAgencyAdmin.uid)
  }

  private saveRef(ref: LocalUploadRef) {
    const allRefs: LocalUploadRefList = this.getRefList();
    allRefs[ref.uid] = ref;

    this.saveRefList(allRefs);
  }

  private removeRef(ref: LocalUploadRef) {
    const allRefs: LocalUploadRefList = this.getRefList();

    delete allRefs[ref.uid];

    this.saveRefList(allRefs);
  }

  private getRefList(): LocalUploadRefList {
    const refsJson = localStorage.getItem(STORAGE_TOKEN);

    if (!refsJson) {
      return {};
    }

    try {
      return JSON.parse(refsJson);
    } catch (e) {
      // on parsing error, clear all
      localStorage.removeItem(STORAGE_TOKEN);
      return {};
    }
  }

  private saveRefList(refList: LocalUploadRefList) {
    localStorage.setItem(STORAGE_TOKEN, JSON.stringify(refList));
  }
}