import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { RestitutionMethod, RightsReconductionType } from '../../../../assets/models/agencies/Agency.model';
import { useNavigate } from 'react-router';
import { Button } from '../../../../ui/Buttons/Button';

interface RightsReconductionTypesExplorerPanelProps {
  endingMillesimeYear: number;
  nextMillesimeYear: number;
  restitutionMethod: RestitutionMethod;
  restitutionIban?: string;
  allowToUpdate: boolean;
  isUpdating: boolean;
  onSubmitButtonClicked: (type: RightsReconductionType) => void;
}

const RightsReconductionTypesExplorerPanel: FunctionComponent<RightsReconductionTypesExplorerPanelProps> = (props) => {
  const {
    endingMillesimeYear,
    nextMillesimeYear,
    restitutionMethod,
    restitutionIban,
    allowToUpdate,
    isUpdating,
    onSubmitButtonClicked,
  } = props;

  const { t } = useTranslation('millesime');
  const navigate = useNavigate();

  const [selectedRightsReconductionType, setSelectedRightsReconductionType] = useState<RightsReconductionType>('DROP');

  const handleOnSelectedOptionChange = (event) => {
    setSelectedRightsReconductionType(event.target.value);
  };

  const handleOnSubmitRightsReconductionType = () => {
    onSubmitButtonClicked(selectedRightsReconductionType);
  };

  const navigateToPaymentParameters = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate('/agency/payment');
  }, []);

  return (
    <div className={'p-5 rounded-br10 bg-background'}>
      <div className={'lex flex-wrap justify-center gap-2 px-4 py-6'}>
        <Typography>
          {t('rightsReconductionTypesExplorerPanel.title', { endingMillesimeYear })}
        </Typography>
      </div>
      {allowToUpdate
        ? <>
          <FormControl>
            <RadioGroup
              aria-label="rightsReconductionType"
              name="rights-reconduction-type"
              value={selectedRightsReconductionType}
              onChange={handleOnSelectedOptionChange}
              className={'space-y-4'}
            >
              <div className={'pl-4'}>
                <FormControlLabel
                  value="DROP"
                  control={<Radio className={'text-primary'}/>}
                  label={t('rightsReconductionTypesExplorerPanel.formControlLabel.DROP')}
                />
                <FormHelperText className={'pl-5 -mt-2'}>
                  {t(`rightsReconductionTypesExplorerPanel.helperText.DROP.${restitutionMethod}`, { restitutionIban })}
                </FormHelperText>
                <FormHelperText className={'pl-5 -mt-1'}>
                  {t('rightsReconductionTypesExplorerPanel.subHelperText.DROP')}
                  {' '}
                  <Link href="/agency/payment" underline="always" className={'text-primary'}
                        onClick={navigateToPaymentParameters}>
                    {t('rightsReconductionTypesExplorerPanel.helperLinkLabel.DROP')}
                  </Link>
                </FormHelperText>
              </div>

              <div className={'pl-4'}>
                <FormControlLabel
                  value="KEEP"
                  control={<Radio className={'text-primary'}/>}
                  label={t('rightsReconductionTypesExplorerPanel.formControlLabel.KEEP')}
                />
                <FormHelperText className={'pl-5 -mt-2'}>
                  {t('rightsReconductionTypesExplorerPanel.helperText.KEEP', { nextMillesimeYear })}
                </FormHelperText>
              </div>
            </RadioGroup>
          </FormControl>
          <div className={'flex justify-end'}>
            <Button
              onClick={handleOnSubmitRightsReconductionType}
              disabled={isUpdating}
              loading={isUpdating}
            >
              {t('rightsReconductionTypesExplorerPanel.submitButtonLabel')}
            </Button>
          </div>
        </>
        : (<p className={'text-muted text-sm pl-4'}>
          {t('rightsReconductionTypesExplorerPanel.defaultReconductionTypeText')}
        </p>)
      }
    </div>
  );
};

export default RightsReconductionTypesExplorerPanel;
