import clsx from 'clsx';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

export const SectionWrapper = ({ children, className }: Props) => {
  return (
    <section className={clsx('bg-white px-5 py-10 rounded-br10', className)}>
      {children}
    </section>
  );
};
