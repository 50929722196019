import { ListItem } from '@mui/material';
import React, { FC } from 'react';
import clsx from 'clsx';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as HouseSvg } from '../../assets/svg/tabs/house-line.svg';
import { ReactComponent as BuildingsSvg } from '../../assets/svg/tabs/buildings.svg';
import { ReactComponent as UserSvg } from '../../assets/svg/tabs/user.svg';
import { ReactComponent as FileSvg } from '../../assets/svg/tabs/file-arrow.svg';
import { ReactComponent as ReloadSvg } from '../../assets/svg/tabs/reload.svg';
import { ReactComponent as GearSvg } from '../../assets/svg/tabs/gear.svg';
import { ReactComponent as EuroSvg } from '../../assets/svg/tabs/euro.svg';

interface sidebarMenuItemProps {
  title: string;
  linkTo: string;
  iconName: 'house_line' | 'buildings' | 'user' | 'file_arrow' | 'reload' | 'gear' | 'euro';
}

const CLASSES = {
  base: 'flex flex-col items-center justify-center gap-1 max-w-36 h-24 py-3 cursor-pointer text-center break-words text-sm hover:bg-green/50 transition-all ease-in-out duration-300',
  state: {
    active: 'bg-green border-r-7 border-solid border-r-black border-t-0 border-b-0 border-l-0',
    default: 'bg-white',
  },
};

const svgComponents = {
  house_line: HouseSvg,
  buildings: BuildingsSvg,
  user: UserSvg,
  file_arrow: FileSvg,
  reload: ReloadSvg,
  gear: GearSvg,
  euro: EuroSvg,//@todo réparer le svg il n'est pas présent dans symbol sur le figma
};

const MenuItem: FC<sidebarMenuItemProps> = (props) => {
  const { title, linkTo, iconName } = props;
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();

  const [, firstSegment] = location.pathname.split('/');
  const isCurrentPage: boolean = `/${firstSegment}` === linkTo;

  const classes: string = clsx(
    CLASSES.base,
    CLASSES.state[isCurrentPage ? 'active' : 'default'],
  );

  const SelectedSvg = svgComponents[iconName];

  return (
    <ListItem
      onClick={() => navigate(linkTo)}
      className={classes}
    >
      <SelectedSvg height={24} width={24}/>
      <div className="px-2">{title}</div>
    </ListItem>
  );
};
export default MenuItem;
