import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { MandateStatus } from '@assets/models/mandates/mandate.model';
import { PaymentMethodType } from '@assets/types/PaymentMethod.type';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { MandateStore } from '../../../Stores/Mandate.store';
import { PayingServiceStore } from '../../../Stores/PayingService.store';
import { OrderPaymentViewStore } from '../../../Stores/viewStore/OrderPaymentView.store';
import { StepperRechargingStore } from '../../../Stores/viewStore/StepperRecharging.store';
import MandateSignatureDialog from './MandateSignatureDialog/MandateSignatureDialog';
import MethodPaymentManager from './MethodPaymentManager/MethodPaymentManager';
import OrderDetails from './OrderDetails';
import { AgencyModel } from '@assets/models/agencies/Agency.model';
import { Button } from '../../../ui/Buttons/Button';

type LocalStore = [OrderPaymentViewStore, MandateStore, AgenciesStore, PayingServiceStore, StepperRechargingStore];

/**
 * Tab: Rechargement des droits
 * Step: Paiement (PAYMENT)
 */
const OrderPayment: FunctionComponent = observer(() => {
  const [
    orderPaymentViewStore,
    mandateStore,
    agenciesStore,
    payingServiceStore,
    stepperRechargingStore,
  ]: LocalStore = useInstances<LocalStore>(OrderPaymentViewStore, MandateStore, AgenciesStore, PayingServiceStore, StepperRechargingStore);
  const { t } = useTranslation('payment');
  // TODO : autonomyMode.
  // const autonomyMode: boolean = agenciesStore.currentAgency?.isManagedPaymentMode;
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpenMandateDialog }: OrderPaymentViewStore = orderPaymentViewStore;
  const { mandateStatus }: { mandateStatus: MandateStatus } = mandateStore;
  const { currentAgency }: { currentAgency: AgencyModel } = agenciesStore;
  const agencyPaymentMethod: PaymentMethodType = agenciesStore.currentAgency?.paymentMethod;

  useEffect(() => {
    if (!currentAgency) {
      return;
    }

    orderPaymentViewStore.setPaymentMethod(agencyPaymentMethod);
    switch (agencyPaymentMethod) {
      case 'DIRECT_DEBIT':
        mandateStore.reset();
        void mandateStore.findMandateByAgencyId(currentAgency.uid);
        break;
      case 'BANK_TRANSFER':
      case 'DISSOCIATED_BANK_TRANSFER':
        void agenciesStore.getAgencyBankAccountAlias();
        break;
    }
  }, [currentAgency, agencyPaymentMethod]);

  useEffect(() => {
    if (agencyPaymentMethod === 'DIRECT_DEBIT') {
      setIsConfirmButtonDisabled(mandateStatus !== 'ACTIVE');
    }
  }, [agencyPaymentMethod, mandateStatus]);

  const createOrder: () => Promise<void> = useCallback(async () => {
    const agencyId: string = currentAgency?.uid;
    const { agencyOrderCreationRequest }: OrderPaymentViewStore = orderPaymentViewStore;

    try {
      setIsLoading(true);
      await payingServiceStore.createOrder({ ...agencyOrderCreationRequest, agencyId });
      stepperRechargingStore.goToNextStep();
      setIsLoading(false);

    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [currentAgency]);

  return (
    <>
      <div className={'flex gap-4'}>
        <MethodPaymentManager/>
        <div className={'w-1/3 space-y-4'}>

          {orderPaymentViewStore.orderDetails && <OrderDetails/>}

          <Button
            disabled={isConfirmButtonDisabled || isLoading}
            loading={isLoading}
            onClick={createOrder}
          >
            {agencyPaymentMethod === 'DIRECT_DEBIT'
              ? t('directDebit.btnSubmit')
              : t('bankTransfer.btnSubmit')
            }
          </Button>
        </div>
      </div>

      <MandateSignatureDialog
        open={isOpenMandateDialog}
        isLoading={mandateStore.isFindingMandate}
        onClose={() => orderPaymentViewStore.setOpenMandateDialog(false)}
      />
    </>
  );
});

export default OrderPayment;
