import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { InvoiceModel } from '@assets/models/invoices/invoice.model';
import { useTranslation } from 'react-i18next';

export interface IInvoiceFeesSummaryProps {
  invoice: InvoiceModel;
  currencyFormat: Intl.NumberFormat;
  dateOptions: Intl.DateTimeFormatOptions;
}

const InvoiceFeesSummary: FunctionComponent<IInvoiceFeesSummaryProps> = observer((props: IInvoiceFeesSummaryProps) => {
  const { invoice, currencyFormat, dateOptions } = props;
  const { t } = useTranslation('accounting');

  const excludingTaxesAmount: number = invoice.feesAmountHT;
  const vatAmount: number = invoice.feesTotalVat;
  const includingTaxesAmount: number = invoice.feesAmountTTC;
  const invoiceDate: Date = new Date(props.invoice.invoiceDate);

  return (
    <div className={'px-8'}>
      <div className={'py-2 space-y-2'}>
        <div className={'font-semibold text-base'}>
          {t('invoiceDetails.subscriptionTitle',
            {
              count: props.invoice.subscriptionsBeneficiaryCount,
              month: invoiceDate.toLocaleDateString('fr-FR', dateOptions),
            })}
        </div>

        <div className={'flex justify-between'}>
          <p className={'text-sm'}>{t('invoiceDetails.subscription')}</p>
          <p className={'font-bold'}>{currencyFormat.format(excludingTaxesAmount)} HT</p>
        </div>

        <p className={'text-sm text-black/50'}>
          {t('invoiceDetails.transaction', { count: props.invoice.subscriptionsBeneficiaryCount })}
        </p>
      </div>

      <hr/>
      <div className={'flex flex-col items-end space-y-2'}>
        <div className={'w-80 flex justify-between'}>
          <p className={'text-sm'}>{t('invoiceDetails.totalExcl')}</p>
          <p className={'font-bold'}>
            {currencyFormat.format(excludingTaxesAmount)} HT
          </p>
        </div>

        <div className={'w-80 flex justify-between'}>
          <p className={'text-sm'}>
            {t('invoiceDetails.vat')} {props.invoice.subscriptionVatRate.toFixed(2)}%
          </p>
          <p className={'font-bold'}>
            {currencyFormat.format(vatAmount)} HT
          </p>
        </div>

        <div className={'w-80 flex justify-between '}>
          <p className={'text-sm'}>{t(`invoiceDetails.totalFees_${invoice.invoiceType}`)}</p>
          <p className={'font-bold'}>
            {currencyFormat.format(includingTaxesAmount)} TTC
          </p>
        </div>
      </div>
      <hr/>
    </div>
  );
});

export default InvoiceFeesSummary;