import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { RechargingViewStore } from '../../../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../../../Stores/viewStore/StepperRecharging.store';
import { BeneficiariesStore } from '../../../Stores/Beneficiaries.store';
import { useInstances } from 'react-ioc';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { Spinner } from '../../../ui/Spinner';
import { Button } from '../../../ui/Buttons/Button';
import { DataSpreadsheet } from '../../../ui/DataSpreadsheet';
import clsx from 'clsx';

type LocalStore = [RechargingViewStore, StepperRechargingStore, BeneficiariesStore];

/**
 * Tab: Rechargement des droits Manuel
 * Step: Mes collaborateurs (LIST_BENEFICIARIES)
 */
const OrderListBeneficiariesComponent: FunctionComponent = observer(() => {
  const [
    rechargingViewStore,
    stepperRechargingStore,
    beneficiariesStore,
  ]: [
    RechargingViewStore,
    StepperRechargingStore,
    BeneficiariesStore,
  ] = useInstances<LocalStore>(RechargingViewStore, StepperRechargingStore, BeneficiariesStore);

  const { selectedReloadingDate } = rechargingViewStore;
  const activeBeneficiariesCouldBeRecharged: BeneficiaryModel[] = beneficiariesStore.getActiveBeneficiariesAtDate(selectedReloadingDate);
  const { t } = useTranslation('reloading');

  useEffect(() => {
    beneficiariesStore.setBeneficiariesToRecharge(activeBeneficiariesCouldBeRecharged);
  }, [activeBeneficiariesCouldBeRecharged]);

  const listBeneficiariesGrid: GridColDef[] = [
    {
      field: 'firstname',
      headerName: 'Collaborateur',
      flex: 2,
      renderCell: function birthdateCell(params: GridRenderCellParams) {
        return (
          <p>{`${params.row.lastName} ${params.row.firstName}`} </p>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Adresse Email',
      flex: 2,
    },
    {
      field: 'ibanLastNumbers',
      headerName: 'IBAN',
      flex: 1,
      renderCell: function birthdateCell(params: GridRenderCellParams) {
        return (
          <p>{`***********${params.value}`}</p>
        );
      },
    },
    {
      field: 'numberOfWorkingDays',
      headerName: 'Jours travaillés',
      flex: 1,
      sortable: false,
      renderCell: function actionCell(params: GridRenderCellParams) {
        return (
          <div className={'ml-5'}>
            <input
              type="number"
              className={'flex font-bold justify-center items-center p-4 rounded-full focus:outline-none focus:border-background focus:shadow-outline'}
              style={{
                border: 'none',
                outline: 'none',
                background: 'var(--color-background-light)',
                WebkitAppearance: 'none',
                appearance: 'textfield',
                MozAppearance: 'textfield',
              }}
              pattern="[0-9]{2}"
              value={params.value as number ?? 0}
              max={31}
              min={0}
              onChange={(e) => {
                const objIndex = activeBeneficiariesCouldBeRecharged.findIndex(obj => obj.uid === params.row.uid);
                activeBeneficiariesCouldBeRecharged[objIndex].numberOfWorkingDays = Number.parseFloat(e.target.value);
                beneficiariesStore.setBeneficiariesList([...activeBeneficiariesCouldBeRecharged]);
              }}
            />

          </div>
        );
      },
    },
    {
      field: 'isActive',
      headerName: 'Actif',
      flex: 1,
      renderCell: function isActiveCell(params: GridRenderCellParams) {
        return (
          <div className={'ml-4 h-full flex items-center'}>
            <div className={clsx('w-2 h-2 rounded-full', params.value ? 'bg-status-success' : 'bg-status-failed')}/>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {beneficiariesStore.isLoading
        ? <Spinner size={'lg'} />
        : <>
            <div className={'flex justify-between py-10'}>
              <p className={''}>Renseignez ici le nombre de jours travaillés pour chacun de vos collaborateurs.</p>
              <Button onClick={() => rechargingViewStore.setOpenBeneficiaryDetails(true)}>
                {t('addBeneficiary')}
              </Button>
            </div>
            <DataSpreadsheet
              rows={activeBeneficiariesCouldBeRecharged}
              columns={listBeneficiariesGrid}
              disableRowSelectionOnClick
              showHeader
            />
            <div className={'flex justify-end gap-4'}>
              <Button variant={'outlined'} onClick={() => stepperRechargingStore.goToPreviousStep()}>
                {t('cancel')}
              </Button>
              <Button
                disabled={activeBeneficiariesCouldBeRecharged.findIndex((d) => isNaN(d.numberOfWorkingDays)) !== -1}
                onClick={() => stepperRechargingStore.goToNextStep()}
              >
                {t('nextStep')}
              </Button>
            </div>
          </>
      }
    </>
  );
});

export default OrderListBeneficiariesComponent;
