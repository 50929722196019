import { action, makeAutoObservable, runInAction } from 'mobx';
import { AgencyModel } from '../../assets/models/agencies/Agency.model';
import { InvoiceModel } from '../../assets/models/invoices/invoice.model';
import { MillesimeReceiptModel } from '../../assets/models/millesime/MillesimeReceipt.model';
import { getAgencyInvoices } from '../../Services/invoicesAPI.service';
import { getAgencyMillesimeReceipts, getRestitutionReceipts } from '../../Services/accountingDocumentsAPI.service';
import { getDownloadUrl, getFileUrl } from '../../Services/storage.service';
import { downloadLink } from '../../Utils/Download.utils';
import { errorStore } from '../Error.store';
import { AgencyRestitutionReceiptModel } from '@assets/models/accountingDocuments/AgencyAccountingDocument.model';

export class AccountingViewStore {
  agency: AgencyModel;

  agencyInvoices: InvoiceModel[] = []
  isLoadingAgencyInvoices: boolean = false;

  millesimeReceipts: MillesimeReceiptModel[] = [];
  isLoadingMillesimeReceipts: boolean = false;

  isLoadingRestitutionReceipts : boolean = false;

  isDownloadingDocumentPdf: boolean = false;

  search: string = '';
  openDialog: boolean = false;
  sinceDate: string = '1';

  constructor (agency: AgencyModel) {
    this.agency = agency;

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action async fetchAgencyInvoices(fromDate?: Date, toDate?: Date): Promise<void> {
    this.setLoadingAgencyInvoices(true);

    try {
      const invoices: InvoiceModel[] = await getAgencyInvoices(this.agency.uid, {});
      runInAction(()=> {
        this.agencyInvoices = this.sortDocumentsByCreationDate<InvoiceModel>(invoices);
      })
    } catch (e) {
      errorStore.catchError(new Error('Erreur lors de la récupération des factures'));
    }
    this.setLoadingAgencyInvoices(false);
  }

  @action async fetchMillesimeReceipts(): Promise<void> {
    this.setLoadingMillesimeReceipts(true);

    try {
      const receipts: MillesimeReceiptModel[] = await getAgencyMillesimeReceipts(this.agency.uid, 'FOOD_VOUCHER');
      runInAction(()=> {
        this.millesimeReceipts = this.sortDocumentsByCreationDate<MillesimeReceiptModel>(receipts);
      })
    } catch (e) {
      errorStore.catchError(new Error('Erreur lors de la récupération des relevés.'));
    }
    this.setLoadingMillesimeReceipts(false);
  }

  @action async downloadDocumentPdf(receipt: MillesimeReceiptModel): Promise<void> {
    if (this.isDownloadingDocumentPdf) {
      return;
    }
    this.setDownloadingDocumentPdf(true);

    try {
      const { uid, agencyId, productType, year  } = receipt;
      const url: string = await getDownloadUrl(`accounting_documents/${agencyId}/${uid}.pdf`);

      downloadLink(url, `receipt_millesime_${productType.toLowerCase()}_${year}_${agencyId}.pdf`);
    } catch(e) {
      errorStore.catchError(new Error('Erreur lors de la récupération du relevé'));
    }

    this.setDownloadingDocumentPdf(false);
  }

  @action setLoadingAgencyInvoices (isLoading: boolean = true) {
    this.isLoadingAgencyInvoices = isLoading;
  }

  @action setLoadingMillesimeReceipts (isLoading: boolean = true) {
    this.isLoadingMillesimeReceipts = isLoading;
  }

  @action setDownloadingDocumentPdf (isLoading: boolean = true) {
    this.isDownloadingDocumentPdf = isLoading;
  }

  @action setSearch (str: string) {
    this.search = str;
  }

  @action setOpenDialog (isOpen: boolean) {
    this.openDialog = isOpen;
  }

  @action setSinceDate (newSinceDate: string) {
    this.sinceDate = newSinceDate;
  }

  sortDocumentsByCreationDate<T extends InvoiceModel | MillesimeReceiptModel>(documents: T[], sortOrder: 'asc' | 'desc' = 'desc'): T[]  {
    if (sortOrder === 'asc') {
      return documents.sort(({ creationDate: a }, { creationDate: b }) => a - b);
    } else if (sortOrder === 'desc') {
      return documents.sort(({ creationDate: a }, { creationDate: b }) => b - a);
    } else {
      throw new Error('Invalid sort order specified');
    }
  }

  async getRestitutionReceipts(sinceDate : Date | null): Promise<AgencyRestitutionReceiptModel[] | void> {
    this.isLoadingRestitutionReceipts = true;
    const { uid: agencyId } = this.agency;
    try {
      const agencyRestitutionReceipts: AgencyRestitutionReceiptModel[] = await getRestitutionReceipts(agencyId, sinceDate);
      runInAction(() => {
        this.isLoadingRestitutionReceipts = false;
      });
      return agencyRestitutionReceipts;
    } catch (error) {
        console.log("error fetch receipts");
    } finally {
      runInAction(() => {
        this.isLoadingRestitutionReceipts = false;
      });
    }
  }

  @action async downloadDocumentPdfAgencyRestitutionReceipt(receipt: AgencyRestitutionReceiptModel): Promise<void> {
    if (this.isDownloadingDocumentPdf) {
      return;
    }

    this.setDownloadingDocumentPdf(true);

    try {
      const { uid, productType, agencyUid } = receipt;
      const url: string = await getDownloadUrl(`restitution_receipts/${agencyUid}/${uid}.pdf`);

      downloadLink(url, `receipt_restitution_${productType.toLowerCase()}.pdf`);
    } catch(e) {
      errorStore.catchError(new Error('Erreur lors de la récupération du relevé'));
    }

    this.setDownloadingDocumentPdf(false);
  }


}
