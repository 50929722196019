import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { AgencyDetailsStore } from '../../Stores/Agencies.store';
import { InputField } from '../../ui/Input';

const AgencyInformation: FunctionComponent = observer(() => {
  const { t } = useTranslation(['agency', 'country']);
  const agencyDetailsStore: AgencyDetailsStore = useInstance<AgencyDetailsStore>(AgencyDetailsStore);
  const agencyDetails = agencyDetailsStore.agencyDetails;
  return (
    <>
      <h3 className={'font-bold text-base pt-5 pl-5'}>{t('administrativeDataTitle')}</h3>
      <section className={'flex gap-4'}>
        <div className={'flex-1 space-y-2'}>
          <InputField
            label={t('ref')}
            value={agencyDetails.ref}
            fullWidth
            disabled
          />
          <InputField
            label={t('address')}
            value={agencyDetails?.streetAddress}
            fullWidth
            disabled
          />
          <InputField
            label={t('zipcode')}
            value={agencyDetails?.postalCode}
            fullWidth
            disabled
          />
          <InputField
            label={t('registrationNumber')}
            value={agencyDetails.registrationNumber}
            fullWidth
            disabled
          />
        </div>
        <div className={'flex-1 space-y-2'}>
          <InputField
            label={t('agencyName')}
            value={agencyDetails.agencyName}
            fullWidth
            disabled
          />
          <InputField
            label={t('additionalAddress1')}
            value={agencyDetails?.additionalAddress || t('notSpecified')}
            fullWidth
            disabled
          />
          <InputField
            label={t('city')}
            value={agencyDetails?.city}
            fullWidth
            disabled
          />
        </div>
      </section>

      <h3 className={'font-bold text-base pt-5 pl-5'}>{t('billingContact')}</h3>
      <section className={'flex gap-4'}>
        <div className={'flex-1 space-y-2'}>
          <InputField
            label={t('lastName')}
            value={agencyDetails?.billingContactLastName.toLocaleUpperCase()}
            fullWidth
            disabled
          />
          <InputField
            label={t('email')}
            value={agencyDetails?.billingContactEmail}
            fullWidth
            disabled
          />
        </div>
        <div className={'flex-1 space-y-2'}>
          <InputField
            label={t('firstName')}
            value={agencyDetails?.billingContactFirstName}
            fullWidth
            disabled
          />
          <InputField
            label={t('mobilephone')}
            value={agencyDetails?.billingContactMobilePhone}
            fullWidth
            disabled
          />
        </div>
      </section>

      <h3 className={'font-bold text-base pt-5 pl-5'}>{t('billingAddress')}</h3>
      <section className={'flex gap-4'}>
        <div className={'flex-1 space-y-2'}>
          <InputField
            label={t('address')}
            value={agencyDetails?.billingStreetAddress}
            fullWidth
            disabled
          />
          <InputField
            label={t('zipcode')}
            value={agencyDetails?.billingZipCode}
            fullWidth
            disabled
          />
        </div>
        <div className={'flex-1 space-y-2'}>
          <InputField
            label={t('additionalAddress1')}
            value={agencyDetails?.billingAdditionalAddress || t('notSpecified')}
            fullWidth
            disabled
          />
          <InputField
            label={t('city')}
            value={agencyDetails?.billingCity}
            fullWidth
            disabled
          />
        </div>
      </section>
    </>
  );
});

export default AgencyInformation;
