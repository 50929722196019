import { CountryCodeType } from './CountryCode.model';

export type SelectCountryCodeModel = { label: string; value: CountryCodeType };

export const ListOfCountryCode: SelectCountryCodeModel[] = [
  { label: 'Andorre', value: 'AD' },
  { label: 'Émirats arabes unis', value: 'AE' },
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Antigua-et-Barbuda', value: 'AG' },
  { label: 'Albanie', value: 'AL' },
  { label: 'Arménie', value: 'AM' },
  { label: 'Angola', value: 'AO' },
  { label: 'Argentine', value: 'AR' },
  { label: 'Autriche', value: 'AT' },
  { label: 'Australie', value: 'AU' },
  { label: 'Azerbaïdjan', value: 'AZ' },
  { label: 'Bosnie-Herzégovine', value: 'BA' },
  { label: 'Barbade', value: 'BB' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Belgique', value: 'BE' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Bulgarie', value: 'BG' },
  { label: 'Bahreïn', value: 'BH' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Bénin', value: 'BJ' },
  { label: 'Brunéi Darussalam', value: 'BN' },
  { label: 'Bolivie', value: 'BO' },
  { label: 'Brésil', value: 'BR' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bhoutan', value: 'BT' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bélarus', value: 'BY' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Canada', value: 'CA' },
  { label: 'République centrafricaine', value: 'CF' },
  { label: 'Congo-Brazzaville', value: 'CG' },
  { label: 'Suisse', value: 'CH' },
  { label: 'Côte d’Ivoire', value: 'CI' },
  { label: 'Chili', value: 'CL' },
  { label: 'Cameroun', value: 'CM' },
  { label: 'Chine', value: 'CN' },
  { label: 'Colombie', value: 'CO' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cap-Vert', value: 'CV' },
  { label: 'Chypre', value: 'CY' },
  { label: 'République tchèque', value: 'CZ' },
  { label: 'Allemagne', value: 'DE' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Danemark', value: 'DK' },
  { label: 'Dominique', value: 'DM' },
  { label: 'République dominicaine', value: 'DO' },
  { label: 'Algérie', value: 'DZ' },
  { label: 'Équateur', value: 'EC' },
  { label: 'Estonie', value: 'EE' },
  { label: 'Égypte', value: 'EG' },
  { label: 'Érythrée', value: 'ER' },
  { label: 'Espagne', value: 'ES' },
  { label: 'Éthiopie', value: 'ET' },
  { label: 'Finlande', value: 'FI' },
  { label: 'Fidji', value: 'FJ' },
  { label: 'États fédérés de Micronésie', value: 'FM' },
  { label: 'France', value: 'FR' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Grenade', value: 'GD' },
  { label: 'Géorgie', value: 'GE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gambie', value: 'GM' },
  { label: 'Guinée', value: 'GN' },
  { label: 'Guinée équatoriale', value: 'GQ' },
  { label: 'Grèce', value: 'GR' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guinée-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Croatie', value: 'HR' },
  { label: 'Haïti', value: 'HT' },
  { label: 'Hongrie', value: 'HU' },
  { label: 'Indonésie', value: 'ID' },
  { label: 'Irlande', value: 'IE' },
  { label: 'Israël', value: 'IL' },
  { label: 'Inde', value: 'IN' },
  { label: 'Irak', value: 'IQ' },
  { label: 'Islande', value: 'IS' },
  { label: 'Italie', value: 'IT' },
  { label: 'Jamaïque', value: 'JM' },
  { label: 'Jordanie', value: 'JO' },
  { label: 'Japon', value: 'JP' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kirghizistan', value: 'KG' },
  { label: 'Cambodge', value: 'KH' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Comores', value: 'KM' },
  { label: 'Saint-Kitts-et-Nevis', value: 'KN' },
  { label: 'Corée du Nord', value: 'KP' },
  { label: 'Corée du Sud', value: 'KR' },
  { label: 'Koweït', value: 'KW' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Laos', value: 'LA' },
  { label: 'Liban', value: 'LB' },
  { label: 'Sainte-Lucie', value: 'LC' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Libéria', value: 'LR' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Lituanie', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Lettonie', value: 'LV' },
  { label: 'Libye', value: 'LY' },
  { label: 'Maroc', value: 'MA' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Moldavie', value: 'MD' },
  { label: 'Monténégro', value: 'ME' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Îles Marshall', value: 'MH' },
  { label: 'Macédoine', value: 'MK' },
  { label: 'Mali', value: 'ML' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Mongolie', value: 'MN' },
  { label: 'Mauritanie', value: 'MR' },
  { label: 'Malte', value: 'MT' },
  { label: 'Maurice', value: 'MU' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Mexique', value: 'MX' },
  { label: 'Malaisie', value: 'MY' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Namibie', value: 'NA' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigéria', value: 'NG' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Pays-Bas', value: 'NL' },
  { label: 'Norvège', value: 'NO' },
  { label: 'Népal', value: 'NP' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nouvelle-Zélande', value: 'NZ' },
  { label: 'Oman', value: 'OM' },
  { label: 'Panama', value: 'PA' },
  { label: 'Pérou', value: 'PE' },
  { label: 'Papouasie-Nouvelle-Guinée', value: 'PG' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Pologne', value: 'PL' },
  { label: 'Porto Rico', value: 'PR' },
  { label: 'Territoire palestinien', value: 'PS' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Palaos', value: 'PW' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Roumanie', value: 'RO' },
  { label: 'Serbie', value: 'RS' },
  { label: 'Russie', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Arabie saoudite', value: 'SA' },
  { label: 'Îles Salomon', value: 'SB' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Soudan', value: 'SD' },
  { label: 'Suède', value: 'SE' },
  { label: 'Singapour', value: 'SG' },
  { label: 'Slovénie', value: 'SI' },
  { label: 'Slovaquie', value: 'SK' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Saint-Marin', value: 'SM' },
  { label: 'Sénégal', value: 'SN' },
  { label: 'Somalie', value: 'SO' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Sao Tomé-et-Principe', value: 'ST' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Syrie', value: 'SY' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Tchad', value: 'TD' },
  { label: 'Togo', value: 'TG' },
  { label: 'Thaïlande', value: 'TH' },
  { label: 'Tadjikistan', value: 'TJ' },
  { label: 'Timor oriental', value: 'TL' },
  { label: 'Turkménistan', value: 'TM' },
  { label: 'Tunisie', value: 'TN' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Turquie', value: 'TR' },
  { label: 'Trinité-et-Tobago', value: 'TT' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Taïwan', value: 'TW' },
  { label: 'Tanzanie', value: 'TZ' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'Ouganda', value: 'UG' },
  { label: 'États-Unis', value: 'US' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Ouzbékistan', value: 'UZ' },
  { label: 'État de la Cité du Vatican', value: 'VA' },
  { label: 'Saint-Vincent-et-les Grenadines', value: 'VC' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Viêt Nam', value: 'VN' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Samoa', value: 'WS' },
  { label: 'Yémen', value: 'YE' },
  { label: 'Afrique du Sud', value: 'ZA' },
  { label: 'Zambie', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];
