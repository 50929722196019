import { useCallback, useEffect, useState } from 'react';
import { debounce } from '@mui/material';

export function useDebounce(value: any, wait: number = 500) {
  const [debouncedValue, setDebouncedValue] = useState<any>(value);

  const debouncedCallback = useCallback(debounce((value) => {
    setDebouncedValue(value);
  }, wait), []);

  useEffect(() => {
    debouncedCallback(value);
    return debouncedCallback.clear;
  }, [value, debouncedCallback]);

  return [debouncedValue];
}