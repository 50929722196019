import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface GlobalDeleteConfirmationDialogProps {
  isActive: boolean;
  onClose: (value: boolean) => void;
  onConfirm: () => void;
  loading?: boolean;
  confirmText?: string;
  cancelText?: string;
  deleteMessage?: string;
}

const useStyles = makeStyles(() => ({
  dialogActions: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  loader: {
    marginRight: '10px',
  },
}));

export const GlobalDeleteConfirmationDialog: React.FC<GlobalDeleteConfirmationDialogProps> = ({
                                                                                                isActive, onClose,
                                                                                                onConfirm,
                                                                                                loading = false,
                                                                                                confirmText = 'Supprimer',
                                                                                                cancelText = 'Annuler',
                                                                                                deleteMessage = 'Êtes-vous certain de vouloir supprimer cet élément ?',
                                                                                              }) => {
  const classes = useStyles();

  return (
    <Dialog open={isActive} onClose={() => onClose(false)}>
      <DialogTitle>Confirmer la suppression</DialogTitle>
      <DialogContent>
        <p>{deleteMessage}</p>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} className={classes.loader}/>}
        >
          {confirmText}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onClose(false)}
          disabled={loading}
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GlobalDeleteConfirmationDialog;