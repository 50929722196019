import { Environment } from './types/Environment';

type FiresbaseConfig = {
  apiKey: string,
  authDomain: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
  measurementId: string
}
export const firebaseConfig: Record<Environment, FiresbaseConfig> = {
  LOCAL: {
    apiKey: 'AIzaSyDtppBSqxsKnFEMF4-vcRihE764L6sIeCA',
    authDomain: 'dev-open-eat.firebaseapp.com',
    projectId: 'dev-open-eat',
    storageBucket: 'dev-open-eat.appspot.com',
    messagingSenderId: '992794764960',
    appId: '1:992794764960:web:fcaae571edb2cb136d0bc5',
    measurementId: 'G-R2DBMNJFT1',
  },
  DEV: {
    apiKey: 'AIzaSyDtppBSqxsKnFEMF4-vcRihE764L6sIeCA',
    authDomain: 'dev-open-eat.firebaseapp.com',
    projectId: 'dev-open-eat',
    storageBucket: 'dev-open-eat.appspot.com',
    messagingSenderId: '992794764960',
    appId: '1:992794764960:web:fcaae571edb2cb136d0bc5',
    measurementId: 'G-R2DBMNJFT1',
  },
  UAT: {
    apiKey: 'AIzaSyAh9g_hXXD262EegmECsChdUVu7h92iBPg',
    authDomain: 'uat-open-eat.firebaseapp.com',
    projectId: 'uat-open-eat',
    storageBucket: 'uat-open-eat.appspot.com',
    messagingSenderId: '622693648553',
    appId: '1:622693648553:web:5f251e595ff08922bb81b8',
    measurementId: 'G-B5Y3DB1RZ1',
  },
  DEMO: {
    apiKey: 'AIzaSyAA5qlR8LI3R5gAw8zJm9SEsO_z1vm24C8',
    authDomain: 'demo2-open-eat.firebaseapp.com',
    projectId: 'demo2-open-eat',
    storageBucket: 'demo2-open-eat.appspot.com',
    messagingSenderId: '886580125240',
    appId: '1:886580125240:web:4adaecf97b29a7206610cd',
    measurementId: 'G-E14R471ENZ',
  },
  PROD: {
    apiKey: 'AIzaSyCwC_5JsGVCHC2wGDjPlaz3F0hbkZgEMIs',
    authDomain: 'prod-open-eat.firebaseapp.com',
    projectId: 'prod-open-eat',
    storageBucket: 'prod-open-eat.appspot.com',
    messagingSenderId: '406259893004',
    appId: '1:406259893004:web:1690769f556d8498ce7f29',
    measurementId: 'G-XDDTD9KFT4',
  },
};