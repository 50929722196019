import React, { FC } from 'react';
import { ReactComponent as Logo } from '../../assets/svg/logo/logo-full.svg';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { List } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { AgencyAdminStore } from 'Stores/AgencyAdmin.store';
import { useInstances } from 'react-ioc';
import MenuItem from './MenuItem';
import { CommonProps } from '@mui/material/OverridableComponent';

type LocalStore = [AgenciesStore, AgencyAdminStore];

const Sidebar: FC<CommonProps> = observer(() => {
  const [agenciesStore, agencyAdminStore] = useInstances<LocalStore>(AgenciesStore, AgencyAdminStore);
  const navigate: NavigateFunction = useNavigate();

  const hasSettingsAccess: boolean = agencyAdminStore.hasSettingsAccess;

  return (
    <div className="flex flex-col items-center w-36 pt-0 pb-12 z-50 shadow-sidebar">
      <Logo aria-label={'logo Openeat'} className={'w-28 h-24 cursor-pointer'} onClick={() => navigate('/dashboard')}/>
      <nav>
        <List className="pt-0" id={'sidebar-menu'}>
          <MenuItem title="Accueil" linkTo="/dashboard" iconName={'house_line'}/>

          {agenciesStore.currentAgency && agenciesStore.currentAgency.contractType !== 'PUBLIC_MEAL_SUBSIDY' && (
            <MenuItem title="Rechargement des droits" linkTo="/reloading" iconName={'reload'}/>
          )}

          <MenuItem title="Entreprise" linkTo="/agency" iconName={'buildings'}/>

          <MenuItem title="Collaborateurs" linkTo="/beneficiaries" iconName={'user'}/>

          {hasSettingsAccess && (
            <MenuItem title="Paramètres" linkTo="/settings" iconName={'gear'}/>
          )}

          {agenciesStore.currentAgency && agenciesStore.currentAgency?.contractType === 'PUBLIC_MEAL_SUBSIDY' && (
            <MenuItem title="Factures conventions" linkTo="/vouchers-invoices" iconName={'file_arrow'}/>
          )}

          {agenciesStore.currentAgency && agenciesStore.currentAgency?.contractType !== 'PUBLIC_MEAL_SUBSIDY' && (
            <MenuItem title="Factures" linkTo="/accounting" iconName={'file_arrow'}/>
          )}

          {agenciesStore.currentAgency &&
            agenciesStore.currentAgency?.contractType !== 'PUBLIC_MEAL_SUBSIDY' &&
            agenciesStore.currentAgency.isManagedPaymentMode === false && (
              <MenuItem title="Remboursements" linkTo="/paymentrequest" iconName={'euro'}/>
            )}

          {agenciesStore.currentAgency && agenciesStore.currentAgency?.contractType === 'PUBLIC_MEAL_SUBSIDY' && (
            <MenuItem title="Transactions" linkTo="/vouchers-transactions" iconName={'file_arrow'}/>
          )}

        </List>
      </nav>
    </div>
  );
});

export default Sidebar;
