import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { CreateBeneficiaryRequest } from '@assets/requests/beneficiaries/CreateBeneficiary.request';
import { UpdateBeneficiaryRequest } from '@assets/requests/beneficiaries/UpdateBeneficiary.request';
import { UpdateBeneficiaryActivityRequest } from '@assets/requests/beneficiaries/UpdateBeneficiaryActivityRequest';
import { UpdateBeneficiaryRefRequest } from '@assets/requests/beneficiaries/UpdateBeneficiaryRef.request';
import { BeneficiariesCountResponse } from '@assets/responses/beneficiaries/BeneficiariesCount.response';
import { ConfirmationResponse } from '@assets/responses/Confirmation.response';
import {
  SendMailsToUnboardedBeneficiariesResponse,
} from '@assets/responses/emails/SendMailsToUnboardedBeneficiaries.response';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { UploadBeneficiaryRequest } from '../assets/requests/beneficiaries/UploadBeneficiary.request';
import {
  SendMailsToUnboardedBeneficiariesRequest,
} from '../assets/requests/emails/SendMailsToUnboardedBeneficiaries.request';
import { isBeneficiaryActive, isBeneficiaryActiveToday } from '../assets/utils/beneficiaries/isBeneficiaryActive.util';
import {
  createOneBeneficiary,
  getAllBeneficiariesByAgencyId,
  getBeneficiariesCountByAgencyId,
  getBeneficiaryById,
  getBeneficiaryQrCodeString,
  manageBeneficiaryDeactivationSchedule,
  sendMailsToUnOnboardedBeneficiaries,
  updateBeneficiaryActivities,
  updateBeneficiaryEmailForAgency,
  updateBeneficiaryIbanForAgency,
  updateBeneficiaryRegistrationNumber,
  updateOneBeneficiary,
  uploadBeneficiaries,
} from '../Services/beneficiariesAPI.service';
import { sortBeneficiaryList } from '../Utils/sortBeneficiaryList';
import { UpdateBeneficiaryIBANRequest } from '@assets/requests/billings/UpdateBeneficiaryIBAN.request';
import { UpdateBeneficiaryEmailRequest } from '@assets/requests/beneficiaries/UpdateBeneficiaryEmail.request';
import { sanitizeEmail } from '../assets/utils/functions/sanitizeEmail.util';
import {
  ManageBeneficiaryDeactivationScheduleRequest,
} from '@assets/requests/beneficiaries/ManageBeneficiaryDeactivationSchedule.request';
import {
  BeneficiariesFileUploadModel
} from '@assets/models/beneficiaries/BeneficiariesFileUpload.model';

export interface UpdateBeneficiaryFullRequest {
  agencyId: string;
  beneficiaryId: string;
  beneficiaryMin?: UpdateBeneficiaryRequest;
  beneficiaryActivity?: UpdateBeneficiaryActivityRequest;
  registrationNumber?: UpdateBeneficiaryRefRequest;
  beneficiaryEmail?: UpdateBeneficiaryEmailRequest;
  beneficiaryIban?: UpdateBeneficiaryIBANRequest;
}

export class BeneficiariesStore {
  error = null;
  isLoading = false;

  createBeneficiaryStatus = 'PENDING';
  updateBeneficiaryStatus = 'PENDING';
  updateBeneficiaryActivityStatus = 'PENDING';
  updateBeneficiaryRefStatus = 'PENDING';
  updateBeneficiaryDeactivationStatus = 'PENDING';
  uploadBeneficiariesStatus = 'PENDING';
  sendMailsToUnOnboardedBeneficiariesStatus = 'PENDING';

  beneficiariesList: BeneficiaryModel[] = [];
  beneficiariesFilteredList: BeneficiaryModel[] = [];
  currentBeneficiary: BeneficiaryModel = null;
  currentBeneficiaryQrCodeString: string = null;
  isLoadingQrCode: boolean = false;

  private beneficiariesToRecharge: BeneficiaryModel[] = [];

  counters: BeneficiariesCountResponse = null;
  totalDaysToRecharge = 0;

  get totalBeneficiariesCount() {
    return this.counters?.totalBeneficiariesCount || 0;
  }

  get activeBeneficiariesCount() {
    return this.counters?.activeBeneficiariesCount || 0;
  }

  get inactiveBeneficiariesCount() {
    return this.totalBeneficiariesCount - this.activeBeneficiariesCount;
  }

  get activeBeneficiaryList(): BeneficiaryModel[] {
    return this.beneficiariesList.filter((beneficiary: BeneficiaryModel) => isBeneficiaryActiveToday(beneficiary));
  }

  get inactiveBeneficiaryList(): BeneficiaryModel[] {
    return this.beneficiariesList.filter((beneficiary: BeneficiaryModel) => !isBeneficiaryActiveToday(beneficiary));
  }

  get sortedBeneficiaryList(): BeneficiaryModel[] {
    return sortBeneficiaryList(this.beneficiariesList);
  }

  get listOfBeneficiariesToRecharge() {
    return this.beneficiariesToRecharge;
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setBeneficiariesToRecharge(value: BeneficiaryModel[]) {
    this.beneficiariesToRecharge = value;
  }

  @action setBeneficiariesList(value: BeneficiaryModel[]) {
    this.beneficiariesList = value;
  }

  @action setTotalDaysToRecharge(value: number) {
    this.totalDaysToRecharge = value;
  }

  @action setCurrentBeneficiary(value: BeneficiaryModel | null) {
    this.currentBeneficiary = value;
  }

  @action async refreshCurrentBeneficiary(): Promise<void> {
    const { uid } = this.currentBeneficiary;
    await this.fetchBeneficiaryById(uid);
  }

  filterBeneficiariesByString(showInactiveBeneficiaries: boolean, inputValueSearch: string) {
    this.beneficiariesFilteredList = this.sortedBeneficiaryList
      .filter((beneficiary: BeneficiaryModel) => {
        return showInactiveBeneficiaries || (!showInactiveBeneficiaries && isBeneficiaryActiveToday(beneficiary));
      })
      .filter(({ lastName, firstName, email, registrationNumber }: BeneficiaryModel) => {
        return [lastName, firstName, email, registrationNumber].some((value: string) => {
          return value?.toLocaleLowerCase?.().includes(inputValueSearch);
        });
      });
  }

  @action setBeneficiariesLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action setBeneficiaryQrCodeLoading(isLoading: boolean) {
    this.isLoadingQrCode = isLoading;
  }

  setBeneficiariesCount(data: BeneficiariesCountResponse) {
    this.counters = data;
  }

  async fetchAllBeneficiariesByAgencyId(agencyId: string): Promise<BeneficiaryModel[]> {
    this.setBeneficiariesLoading(true);

    try {
      const beneficiariesResponse: BeneficiaryModel[] = await getAllBeneficiariesByAgencyId(agencyId);
      runInAction(() => {
        if (beneficiariesResponse) {
          this.beneficiariesList = beneficiariesResponse;
        }
      });
      return beneficiariesResponse;
    } catch (e) {
      this.error = 'error on load beneficiaries';
      throw e;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async fetchBeneficiaryById(beneficiaryId: string) {
    this.setBeneficiariesLoading(true);

    try {
      const beneficiaryResponse: BeneficiaryModel = await getBeneficiaryById(beneficiaryId);

      runInAction(() => {
        if (beneficiaryResponse) {
          this.currentBeneficiary = beneficiaryResponse;
        }
      });
    } catch (e) {
      this.error = 'error on load beneficiary';
      throw e;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async fetchBeneficiariesCountByAgencyId(agencyId: string): Promise<BeneficiariesCountResponse> {
    this.setBeneficiariesLoading(true);

    try {
      const beneficiariesCountResponse: BeneficiariesCountResponse = await getBeneficiariesCountByAgencyId(agencyId);
      this.counters = beneficiariesCountResponse;
      return beneficiariesCountResponse;
    } catch (e) {
      this.error = 'error on load beneficiaries count';
      throw e;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async createBeneficiary(agencyId: string, beneficiaryToCreate: CreateBeneficiaryRequest): Promise<void> {
    this.setBeneficiariesLoading(true);

    try {
      const createBeneficiaryResponse: BeneficiaryModel = await createOneBeneficiary(agencyId, beneficiaryToCreate);

      runInAction(() => {
        if (createBeneficiaryResponse) {
          this.currentBeneficiary = createBeneficiaryResponse;
          this.createBeneficiaryStatus = 'SUCCESS';
        }
      });

    } catch (createBeneficiaryError) {
      this.error = createBeneficiaryError.response?.data
        ? createBeneficiaryError.response?.data.messageToDisplay
        : 'error on create beneficiary';

      this.createBeneficiaryStatus = 'ERROR';
      throw createBeneficiaryError;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async updateBeneficiary(agencyId: string, beneficiaryId: string, beneficiaryToUpdate: UpdateBeneficiaryRequest): Promise<void> {
    this.setBeneficiariesLoading(true);
    try {
      const confirmationResponse: ConfirmationResponse = await updateOneBeneficiary(agencyId, beneficiaryId, beneficiaryToUpdate);

      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.updateBeneficiaryStatus = 'SUCCESS';
        }
      });
      await this.refreshCurrentBeneficiary();

    } catch (updateBeneficiaryError) {
      this.error = updateBeneficiaryError.response?.data
        ? updateBeneficiaryError.response?.data.messageToDisplay
        : 'error on update beneficiary';

      this.updateBeneficiaryStatus = 'ERROR';
      throw updateBeneficiaryError;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async updateBeneficiaryActivity(beneficiaryId: string, request: UpdateBeneficiaryActivityRequest): Promise<void> {
    this.setBeneficiariesLoading(true);

    try {
      const confirmationResponse: ConfirmationResponse = await updateBeneficiaryActivities(beneficiaryId, request);

      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.updateBeneficiaryActivityStatus = 'SUCCESS';
        }
      });
      await this.refreshCurrentBeneficiary();

    } catch (updateBeneficiaryActivityError) {
      this.error = updateBeneficiaryActivityError.response?.data
        ? updateBeneficiaryActivityError.response?.data.messageToDisplay
        : 'error on update beneficiary activity';

      this.updateBeneficiaryActivityStatus = 'ERROR';
      throw updateBeneficiaryActivityError;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async updateBeneficiaryRef(agencyId: string, beneficiaryId: string, request: UpdateBeneficiaryRefRequest): Promise<void> {
    this.setBeneficiariesLoading(true);

    const standardUpdateRequest: UpdateBeneficiaryRefRequest = {
      registrationNumber: request.registrationNumber,
    };

    try {
      const confirmationResponse: ConfirmationResponse = await updateBeneficiaryRegistrationNumber(agencyId, beneficiaryId, standardUpdateRequest);

      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.updateBeneficiaryRefStatus = 'SUCCESS';
        }
      });
      await this.refreshCurrentBeneficiary();

    } catch (updateBeneficiaryRefError) {
      this.error = updateBeneficiaryRefError.response?.data
        ? updateBeneficiaryRefError.response?.data.messageToDisplay
        : 'error on update beneficiary ref';

      this.updateBeneficiaryRefStatus = 'ERROR';
      throw updateBeneficiaryRefError;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async uploadBeneficiaries(agencyId: string, beneficiariesToUpload: UploadBeneficiaryRequest[]): Promise<BeneficiariesFileUploadModel> {
    this.setBeneficiariesLoading(true);
    this.uploadBeneficiariesStatus = 'PENDING';
    try {
      const uploadBeneficiariesResponse: BeneficiariesFileUploadModel = await uploadBeneficiaries(agencyId, beneficiariesToUpload);

      this.uploadBeneficiariesStatus = 'SUCCESS';
      return uploadBeneficiariesResponse;
    } catch (e) {
      this.error = 'error on upload beneficiaries';
      this.uploadBeneficiariesStatus = 'ERROR';
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async updateBeneficiaryEmailForAgency(agencyId: string, beneficiaryId: string, request: UpdateBeneficiaryEmailRequest): Promise<void> {
    this.setBeneficiariesLoading(true);
    const updateEmailRequest: UpdateBeneficiaryEmailRequest = {
      email: sanitizeEmail(request.email),
    };
    try {
      await updateBeneficiaryEmailForAgency(agencyId, beneficiaryId, updateEmailRequest);
      await this.refreshCurrentBeneficiary();
    } catch (e) {
      this.error = e.response?.data
        ? e.response?.data.messageToDisplay
        : 'error on update beneficiary email';
      throw e;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async updateBeneficiaryIbanForAgency(agencyId: string, beneficiaryId: string, request: UpdateBeneficiaryIBANRequest) {
    this.setBeneficiariesLoading(true);
    try {
      await updateBeneficiaryIbanForAgency(agencyId, beneficiaryId, request);
    } catch (e) {
      this.error = e.response?.data
        ? e.response?.data.messageToDisplay
        : 'error on update beneficiary iban';
      throw e;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async sendBatchedMailsToUnOnboardedBeneficiaries(request: SendMailsToUnboardedBeneficiariesRequest): Promise<SendMailsToUnboardedBeneficiariesResponse> {
    this.setBeneficiariesLoading(true);

    try {
      const { mailsFailed, mailsSent } = await sendMailsToUnOnboardedBeneficiaries(request);

      runInAction(() => {
        if (!mailsFailed.length) {
          this.sendMailsToUnOnboardedBeneficiariesStatus = 'SUCCESS';
        } else {
          this.sendMailsToUnOnboardedBeneficiariesStatus = 'ERROR';
        }
      });

      return { mailsFailed, mailsSent };
    } catch (sendMailsToUnOnboardedBeneficiariesError) {
      this.error = sendMailsToUnOnboardedBeneficiariesError.response?.data
        ? sendMailsToUnOnboardedBeneficiariesError.response?.data.messageToDisplay
        : 'error on mails send';

      this.sendMailsToUnOnboardedBeneficiariesStatus = 'ERROR';
    }

    this.setBeneficiariesLoading(false);
  }

  async updateBeneficiaryAll(updateBeneficiaryAllRequest: UpdateBeneficiaryFullRequest,): Promise<ConfirmationResponse> {
    const {
      agencyId,
      beneficiaryId,
      beneficiaryMin,
      beneficiaryActivity,
      registrationNumber,
      beneficiaryEmail,
      beneficiaryIban,
    }: UpdateBeneficiaryFullRequest = updateBeneficiaryAllRequest;
    const updateBeneficiaryPromises: Promise<any>[] = [];

    if (beneficiaryMin) {
      const standardUpdateRequest: UpdateBeneficiaryRequest = {
        title: beneficiaryMin.title,
        firstName: beneficiaryMin.firstName,
        lastName: beneficiaryMin.lastName,
        streetAddress: beneficiaryMin.streetAddress,
        additionalAddress: beneficiaryMin.additionalAddress,
        postalCode: beneficiaryMin.postalCode,
        city: beneficiaryMin.city,
        countryCode: beneficiaryMin.countryCode,
        beneficiaryClassificationUid: beneficiaryMin.beneficiaryClassificationUid,
      };

      updateBeneficiaryPromises.push(updateOneBeneficiary(
        agencyId, beneficiaryId, standardUpdateRequest,
      ));
    }
    if (beneficiaryActivity) {
      updateBeneficiaryPromises.push(updateBeneficiaryActivities(
        beneficiaryId, beneficiaryActivity,
      ));
    }
    if (registrationNumber) {
      updateBeneficiaryPromises.push(updateBeneficiaryRegistrationNumber(
        agencyId, beneficiaryId, registrationNumber,
      ));
    }
    if (beneficiaryEmail) {
      updateBeneficiaryPromises.push(updateBeneficiaryEmailForAgency(
        agencyId, beneficiaryId, beneficiaryEmail,
      ));
    }

    if (beneficiaryIban) {
      updateBeneficiaryPromises.push(updateBeneficiaryIbanForAgency(
        agencyId, beneficiaryId, beneficiaryIban,
      ));
    }

    try {
      await Promise.all(updateBeneficiaryPromises);
      await this.refreshCurrentBeneficiary();
      return {
        info: 'ok',
      };
    } catch (e) {
      await this.refreshCurrentBeneficiary();
      throw e;
    }
  }

  getActiveBeneficiariesAtDate(date: Date): BeneficiaryModel[] {
    const month: number = date.getMonth() + 1;
    const year: number = date.getFullYear();

    return this.sortedBeneficiaryList
      .filter((beneficiary: BeneficiaryModel) => isBeneficiaryActive(beneficiary, year, month));
  }

  async manageBeneficiaryDeactivationSchedule(beneficiaryId: string, request: ManageBeneficiaryDeactivationScheduleRequest): Promise<void> {
    this.setBeneficiariesLoading(true);

    try {
      const confirmationResponse: ConfirmationResponse = await manageBeneficiaryDeactivationSchedule(beneficiaryId, request);

      if (confirmationResponse.info === 'ok') {
        runInAction(() => {
          this.updateBeneficiaryDeactivationStatus = 'SUCCESS';
        });
        await this.refreshCurrentBeneficiary();
      }
    } catch (updateBeneficiaryActivityError) {
      this.error = updateBeneficiaryActivityError.response?.data
        ? updateBeneficiaryActivityError.response?.data.messageToDisplay
        : 'Error while updating beneficiary deactivation';

      this.updateBeneficiaryDeactivationStatus = 'ERROR';
    }
    this.setBeneficiariesLoading(false);
  }

  async getBeneficiaryQrCodeString(beneficiaryId: string): Promise<void> {
    this.setBeneficiaryQrCodeLoading(true);

    try {
      const qrCodeString = await getBeneficiaryQrCodeString(beneficiaryId);
      runInAction(() => {
        this.currentBeneficiaryQrCodeString = qrCodeString;
      });
    } catch (e) {
      this.error = 'Error while fetching beneficiary QR code string';
    } finally {
      this.setBeneficiaryQrCodeLoading(false);
    }
  }

  async regenQrCodeBeneficiary(beneficiaryId: string, regen : boolean = true): Promise<void> {
    this.setBeneficiaryQrCodeLoading(true);
    try {
    const newQrCodeString =  await getBeneficiaryQrCodeString(beneficiaryId, regen);
      runInAction(() => {
        this.currentBeneficiaryQrCodeString = newQrCodeString;
      });
    } catch (e) {
      this.error = 'Error while regenerating beneficiary QR code';
    } finally {
      this.setBeneficiaryQrCodeLoading(false);
    }
  }
}
