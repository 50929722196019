import { callable } from './httpWrapper.service';
import { GetAgencyPayinResponse } from '../assets/responses/payins/GetAgencyPayin.response';
import { OptionalTimePeriodRequest } from '../assets/requests/TimePeriod.request';
import { PayinReportModel } from '../assets/models/payins/PayinReport.model';
import {
  convertGetAgencyPayinResponseFromCentsToEuros,
  convertPayinReportFromCentsToEuros,
} from '../assets/utils/payins/payin.util';

export async function getAgencyPayins(agencyId: string): Promise<GetAgencyPayinResponse[]> {
  return callable(
    'getAgencyPayins',
    { agencyId },
  ).then(response => response.map(convertGetAgencyPayinResponseFromCentsToEuros));
}

export async function getAgencyPayinReports(agencyId: string, requestBody: OptionalTimePeriodRequest): Promise<PayinReportModel[]> {
  return callable(
    'getAgencyPayinReports',
    { agencyId },
    requestBody,
  ).then(reports => reports.map(convertPayinReportFromCentsToEuros));
}
