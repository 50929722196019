import {
  FormControl,
  FormControlLabel, FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { AgencyModel, RestitutionMethod } from '../../assets/models/agencies/Agency.model';
import { AgenciesStore, AgencyDetailsStore } from '../../Stores/Agencies.store';
import { MandateStore } from '../../Stores/Mandate.store';
import { UpdateAgencyRestitutionRequest } from '@assets/requests/agencies/UpdateAgencyRestitution.request';
import { FormikHelpers, useFormik } from 'formik';
import Yup from '../../i18n/validation';
import isValidIBANNumber from '../../Function/IbanValidator';
import { obfuscateIbanNumbersUtil } from '../../assets/utils/databank/obfuscateIbanNumbers.util';
import SnackErrorComponent from 'Component/SnackbarError/SnackErrorComponent';
import { ReactComponent as MangoSvg } from '../../assets/svg/mangopay.svg';
import { InputField } from '../../ui/Input';
import { Button } from '../../ui/Buttons/Button';
import { Toaster } from 'ui/Toaster';

type LocalStore = [MandateStore, AgencyDetailsStore, AgenciesStore];

interface RestitutionFormFields extends Omit<UpdateAgencyRestitutionRequest, 'iban'> {
  restitutionIban: string;
}

const PaymentInformationComponent: FunctionComponent = observer(() => {
  const [mandateStore, agencyDetailsStore, agenciesStore]: [MandateStore, AgencyDetailsStore, AgenciesStore] = useInstances<LocalStore>(MandateStore, AgencyDetailsStore, AgenciesStore);
  const { restitutionMethod, restitutionIban } = agenciesStore;
  const [radioValue, setRadioValue] = useState<RestitutionMethod>(restitutionMethod || 'PROVISIONS_WALLET_DEPOSIT');
  const [isButtonContainerVisible, setIsButtonContainerVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const agencyDetails: AgencyModel = agencyDetailsStore.agencyDetails;
  const { t } = useTranslation(['agency', 'country']);

  const obfuscateIbanNumbers = (iban: string | null | undefined) => iban ? obfuscateIbanNumbersUtil(iban) : '';

  useEffect(() => {
    if (agencyDetails.paymentMethod !== 'DIRECT_DEBIT') {
      void agenciesStore.getAgencyBankAccountAlias();
    }
  }, [agencyDetails]);

  Yup.addMethod(Yup.string, 'isValidIBANNumber', isValidIBANNumber);

  const validationSchema = Yup.object().shape({
    restitutionMethod: Yup
      .string()
      .required(),
    restitutionIban: radioValue === 'RESTITUTION_IBAN_PAYOUT'
      ? Yup
        .string()
        .required()['isValidIBANNumber']()
      : Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      restitutionMethod,
      restitutionIban: obfuscateIbanNumbers(restitutionIban),
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: updateRestitution,
  });

  useEffect(() => {
    formik.setFieldValue('restitutionMethod', restitutionMethod);
    formik.setFieldValue('restitutionIban', obfuscateIbanNumbers(restitutionIban || ''));
  }, [restitutionMethod, restitutionIban]);

  const handleRadioChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    e.preventDefault();
    e.stopPropagation();

    const restitutionMethodValue: RestitutionMethod = e.target.value as RestitutionMethod;
    formik.setFieldValue('restitutionMethod', restitutionMethodValue);
    setRadioValue(restitutionMethodValue);
  };

  const handleIbanChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newIbanValue: string = event.target.value.trim().replace(/\s+/g, '');
    formik.setFieldValue(event.target.name, newIbanValue);
  };

  useEffect(() => {
    const ibanValue: string = formik.values.restitutionIban;
    const restitutionMethodValue: string = formik.values.restitutionMethod;
    const valuesHaveChanged: boolean = restitutionMethodValue !== restitutionMethod
      || (
        restitutionMethodValue === 'RESTITUTION_IBAN_PAYOUT'
        && ibanValue !== restitutionIban
        && ibanValue !== obfuscateIbanNumbers(restitutionIban)
      );

    setIsButtonContainerVisible(valuesHaveChanged);
  }, [restitutionIban, restitutionMethod, formik.values.restitutionIban, formik.values.restitutionMethod]);

  useEffect(() => {
  }, [restitutionMethod]);

  const cancelRestitutionUpdate = () => {
    formik.resetForm();
    setRadioValue(restitutionMethod);
    setIsButtonContainerVisible(false);
    setErrorMessage('');
  };

  async function updateRestitution(restitutionFields: RestitutionFormFields, {
    setSubmitting,
  }: FormikHelpers<UpdateAgencyRestitutionRequest>): Promise<void> {
    try {
      setErrorMessage('');
      setSubmitting(true);
      const request: UpdateAgencyRestitutionRequest = { restitutionMethod: restitutionFields.restitutionMethod };
      if (restitutionFields.restitutionMethod === 'RESTITUTION_IBAN_PAYOUT') {
        request.iban = restitutionFields.restitutionIban;
      }

      await agenciesStore.updateRestitutionMethod(request);
      if (agenciesStore.updateRestitutionStatus === 'SUCCESS') {
        formik.resetForm({
          values: {
            restitutionMethod: request.restitutionMethod,
            restitutionIban: obfuscateIbanNumbers(request.iban),
          },
        });
        setRadioValue(request.restitutionMethod);
      } else if (agenciesStore.updateRestitutionStatus === 'ERROR_MANGOPAY') {
        setErrorMessage('Impossible de créer un bankaccount chez notre fournisseur');
        formik.resetForm();
        setRadioValue(restitutionMethod);
      }
    } catch (e) {
      formik.resetForm();
      setRadioValue(restitutionMethod);
    } finally {
      setSubmitting(false);
    }
  }

  const onSubmitForm = useCallback(() => {
    formik.handleSubmit();
  }, []);

  const getFlag: (iban: string) => string = useCallback((iban: string) => {
    const sanitizedIban: string = iban.trim().toUpperCase().replace(/[^A-Z0-9]/g, '');
    if (!sanitizedIban || sanitizedIban.length < 2) {
      return '';
    }
    const countryCode: string = iban.slice(0, 2);
    return countryCode.toLowerCase();
  }, []);

  const cguMangoPay = 'https://mangopay.com/terms-and-conditions/payment-services';
  const evolutionMangoPay = 'https://docs.mangopay.com/support/update-terms/end-users';
  const rgpdMangoPay = 'https://mangopay.com/privacy-statement';

  return (
    <>
      <h3 className={'font-bold text-base pt-5 pl-5'}>{t('paymentTitle')}</h3>

      {mandateStore.mandate && (
        <a
          href={mandateStore.mandate.documentUrl}
          target="_blank" rel="noreferrer" className={'no-underline'}>
          <Button color="primary">
            {t('seeMandate')}
          </Button>
          <br/>
        </a>
      )}

      <section className={'flex flex-col md:flex-row gap-4 pb-2'}>
        {agencyDetails.paymentMethod === 'DISSOCIATED_BANK_TRANSFER' && (
          <>
            <InputField
              label={t('ibanSubscriptionDissociatedMain')}
              loading={agenciesStore.isBankAccountsLoading}
              fullWidth
              value={!agenciesStore.isBankAccountsLoading
                ? agenciesStore.paymentBankAccountMain?.iban || t('notSpecified')
                : ''
              }
              disabled
            />
            <InputField
              label={t('ibanSubscriptionDissociatedFees')}
              loading={agenciesStore.isBankAccountsLoading}
              fullWidth
              value={!agenciesStore.isBankAccountsLoading
                ? agenciesStore.paymentBankAccountFees?.iban || t('notSpecified')
                : ''
              }
              disabled
            />
          </>

        )}
      </section>
      <section className={'flex flex-col md:flex-row gap-4 pb-2'}>
        <InputField
          label={t('holderFullName')}
          value={agencyDetails.paymentBankTitular}
          fullWidth
          disabled
        />
        <div className={'hidden md:flex w-full'}/>
      </section>
      <section className={'flex flex-col md:flex-row gap-4'}>
        {agencyDetails.paymentMethod === 'BANK_TRANSFER' && (
          <InputField
            label={t('ibanSubscriptionBankTransfer')}
            loading={agenciesStore.isBankAccountsLoading}
            fullWidth
            value={!agenciesStore.isBankAccountsLoading
              ? agenciesStore.paymentBankAccountMain?.iban || t('notSpecified')
              : ''
            }
            disabled
          />
        )}

        {agencyDetails.paymentMethod === 'DIRECT_DEBIT' && (
          <InputField
            label={t('ibanSubscription')}
            fullWidth
            value={
              agencyDetails.iban
                ? `${obfuscateIbanNumbers(agencyDetails.iban)}`
                : t('notSpecified')
            }
            disabled
          />
        )}
      </section>

      <section className={'flex flex-col md:flex-row gap-4'}>
        <div className={'flex-1 space-y-2'}>
          <InputField
            label={t('address')}
            value={agencyDetails.paymentBankAddress}
            fullWidth
            disabled
          />
          <InputField
            label={t('zipcode')}
            value={agencyDetails.paymentBankZipCode}
            fullWidth
            disabled
          />
          <InputField
            label={t('country')}
            value={t(`country:${agencyDetails.paymentBankCountryCode}`)}
            fullWidth
            disabled
          />
        </div>
        <div className={'flex-1 space-y-2'}>
          <InputField
            label={t('additionalAddress1')}
            value={agencyDetails.paymentBankAdditionalAddress || t('notSpecified')}
            fullWidth
            disabled
          />
          <InputField
            label={t('city')}
            value={agencyDetails.paymentBankCity}
            fullWidth
            disabled
          />
        </div>


      </section>

      <h3 className={'font-bold text-base pt-5 pl-5'}>{t('restitutionTitle')}</h3>

      <form onSubmit={formik.handleSubmit}>
        <div className={'flex flex-col min-h-48'}>

          <FormControl>
            <RadioGroup
              id="restitutionMethod"
              name="resitution-radio-buttons-group"
              value={formik.values.restitutionMethod}
              onChange={handleRadioChanges}
            >
              <FormControlLabel
                value="PROVISIONS_WALLET_DEPOSIT"
                control={<Radio className={'text-primary'}></Radio>}
                label={t('PROVISIONS_WALLET_DEPOSIT')}
              />
              <FormHelperText className={'pl-5 -mt-2'}>
                {t('PROVISIONS_WALLET_DEPOSIT_HELPER_TEXT')}
              </FormHelperText>

              {agencyDetails.paymentMethod === 'DIRECT_DEBIT' && (<>
                <FormControlLabel
                  value="DIRECT_DEBIT_IBAN_PAYOUT"
                  control={<Radio className={'text-primary'}/>}
                  label={t('DIRECT_DEBIT_IBAN_PAYOUT')}
                  className={'mt-5'}
                />
                <FormHelperText className={'pl-5 -mt-2'}>
                  {t('DIRECT_DEBIT_IBAN_PAYOUT_HELPER_TEXT')}
                </FormHelperText>
              </>)}

              <FormControlLabel
                value="RESTITUTION_IBAN_PAYOUT"
                control={<Radio className={'text-primary'}/>}
                label={t('RESTITUTION_IBAN_PAYOUT')}
                className={'mt-5'}
              />
              <FormHelperText className={'pl-5 -mt-2'}>
                {t('RESTITUTION_IBAN_PAYOUT_HELPER_TEXT')}
              </FormHelperText>

            </RadioGroup>
          </FormControl>

          {formik.values.restitutionMethod === 'RESTITUTION_IBAN_PAYOUT' && (
            <InputField
              formClasses={'mt-5'}
              id="restitutionIban"
              name="restitutionIban"
              label={t('restitutionIban')}
              formik={formik}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleIbanChange(event)}
              countryFlag={getFlag(formik.values.restitutionIban)}
              placeholder={t(`${'restitutionIban'}Placeholder`)}
              required
              error={Boolean(formik.errors['restitutionIban']) && formik.touched['restitutionIban']}
              errorMessage={formik.errors['restitutionIban']}
            />
          )}

        </div>

        {isButtonContainerVisible && (
          <div className={'flex justify-end gap-4 pt-5'}>
            <Button
              variant={'outlined'}
              onClick={cancelRestitutionUpdate}
              disabled={agenciesStore.isUpdatingRestitution}
            >
              {t('cancel')}
            </Button>

            <Button
              onClick={onSubmitForm}
              loading={agenciesStore.isUpdatingRestitution}
            >
              {t('updateRestitution')}
            </Button>
          </div>
        )}

        {errorMessage && (<Toaster variant={'error'}>
          <>errorMessage</>
        </Toaster>)}
      </form>
      <div>
        <p>{t('mangoPayOpen')}</p>
        <div className={'flex items-center gap-4'}>
          <MangoSvg className={'w-28 h-28'} title={'logo de Mangopay'} />
          <div className={'flex gap-4'}>
            <a href={cguMangoPay} target="_blank" className={'text-primary hover:text-primary/50'}>
              Conditions générales d’utilisation
            </a>
            <a href={evolutionMangoPay} target="_blank" className={'text-primary hover:text-primary/50'}>
              Aperçu des évolutions
            </a>
            <a href={rgpdMangoPay} target="_blank" className={'text-primary hover:text-primary/50'}>
              Déclaration de confidentialité
            </a>
          </div>
        </div>
      </div>
    </>

  );
});

export default PaymentInformationComponent;
