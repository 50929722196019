import { RowTestResult } from './Tester/BaseTester.service';

export const trimCells = (csvData: string[][]): string[][] => {
  return csvData.map((row: string[]) => {
    return row.map((item: string) => item.trim());
  });
};

export const removeEmptyLines = (rows: string[][]): string[][] => {
  return rows.filter((row: string[]) => {
    return row.some((item: string) => item !== '');
  });
}

export const hasDataTheCorrectNumberOfColumns = (headersRow: string[], dataRows: string[][], numberOfColumns: number): boolean => {
  return (headersRow.length === numberOfColumns)
    && dataRows.every((row: string[]) => row.length === numberOfColumns);
}

export const removeEmptyLinesFromTestResult = <T>(fileTestResult: RowTestResult<T>[]): RowTestResult<T>[] => {
  return fileTestResult.filter((row: RowTestResult<T>) => {
    return Object.values(row.csvEntity).some((item: string) => item.trim() !== '');
  });
}