import { action, makeAutoObservable, runInAction } from 'mobx';
import { createAgencyMandate, getAgencyMandate } from '../Services/agenciesAPI.service';
import { MandateModel, MandateStatus } from '../assets/models/mandates/mandate.model';
import { isValidUrl } from '../assets/utils/testsLogic';
import { RedirectUrlResponse } from '../assets/responses/RedirectUrl.response';

export class MandateStore {
  isFindingMandate: boolean = false;
  isCreateMandateFailed: boolean = false;
  mandate: MandateModel = null;

  get mandateStatus(): MandateStatus {
    return this.mandate?.status || 'UNKNOWN';
  }

  get isSignaturePending(): boolean {
    return !this.isCreateMandateFailed
      && (!this.mandate || this.mandateStatus === 'CREATED' || this.mandateStatus === 'EXPIRED');
  }
  get hasSignatureError() {
    return this.isCreateMandateFailed
      || (!!this.mandate && (this.mandateStatus === 'UNKNOWN' || this.mandateStatus === 'INACTIVE'));
  }
  get isMandateSigned() {
    return this.mandateStatus === 'ACTIVE';
  }

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action reset () {
    this.isFindingMandate = false;
    this.isCreateMandateFailed = false;
    this.mandate = null;
  }

  @action setIsFindingMandate(value: boolean) {
    this.isFindingMandate = value;
  }

  async findMandateByAgencyId (agencyId: string) {
    try {
      this.setIsFindingMandate(true);
      const mandate: MandateModel | null = await getAgencyMandate(agencyId);
      runInAction(() => this.mandate = mandate);
    } catch (error) {
      console.error(error);
    } finally {
      this.setIsFindingMandate(false);
    }
  }

  async createMandate (agencyId: string) {
    try {
      const { approvalUrl }: RedirectUrlResponse = await createAgencyMandate(agencyId);

      if (isValidUrl(approvalUrl)) {
        window.location.href = approvalUrl;
      }
    } catch (error) {
      runInAction(() => this.isCreateMandateFailed = true);
    }
  }
}
