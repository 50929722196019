import { makeStyles } from '@mui/styles';
import { COLORS } from '../../Style/Style';

export const useConfirmationModalStyles = makeStyles({
  modalContentWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalBody: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  modalDescription: {
    color: COLORS.textPrimary,
    marginBottom: '10px',
  },
  modalSubDescription: {
    color: COLORS.textMuted,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
    gap: '1em',
  },
});
