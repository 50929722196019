const onboarding = {
  mandateTitle: '☕️ Parfait {{firstname}}, nous allons terminer la configuration de votre compte.',
  mandateTitleError: '😬 Oops, il semble qu’il y ait eu un problème.',
  mandateSubtitle: 'Vous avez souscrit à l’option all-inclusive, nous prenons donc en charge le versement des remboursements de vos collaborateurs.',
  mandateSubtitle2: 'Vous allez être redirigé sur la page de notre partenaire.',
  mandateSubtitleError: 'Un problème est survenu lors de la signature de votre mandat, nous allons devoir réessayer ?',
  mandateButton: 'Signer électroniquement mon mandat',
  step2Title: '👍 C’est bon, encore une dernière étape.',
  step2Subtitle1: 'Documents légaux',
  step2Subtitle2: 'En accord avec notre politique de confidentialité, vos documents sont vérifiés par notre prestataire de paiement.',
  identityProof: 'Justificatif d’identité du représentant légal',
  maxFile: 'Maximum un fichier',
  addFile: 'Fichier {{fileName}} ajouté avec succès'
};

export default onboarding;
