import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ContractType } from '@assets/models/agencies/Agency.model';
import AccountInformationFormComponent from '../FormParts/AccountInformationFormComponent';
import IdentityFormComponent from '../FormParts/IdentityFormComponent';
import ActivityFormComponent_Linear from '../FormParts/ActivityFormComponent_Linear';
import BankInformationFormComponent from '../FormParts/BankInformationFormComponent';
import AddressFormComponent from '../FormParts/AddressFormComponent';
import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { useTranslation } from 'react-i18next';
import {
  FieldTestReport,
  getObjectTestResult,
  ObjectTestSet,
} from '../../../Utils/Tester/BaseTester.service';
import { getFormBeneficiaryTestSet } from '../../../Utils/Tester/BeneficiaryTester.service';
import {
  CreateBeneficiaryRequest, CreateBeneficiaryRequest_STANDARD,
} from '@assets/requests/beneficiaries/CreateBeneficiary.request';
import { UpdateBeneficiaryRequest } from '@assets/requests/beneficiaries/UpdateBeneficiary.request';
import capitalizeFirstLetter from '../../../Function/CapitalizeFirstLetter';
import { FormikHelpers, useFormik } from 'formik';
import moment from 'moment/moment';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { BeneficiariesStore } from '../../../Stores/Beneficiaries.store';
import ActivityFormComponent_Discrete from '../FormParts/ActivityFormComponent_Discrete';
import SectorizationFormComponent from '../FormParts/SectorizationFormComponent';
import OrganizationFormComponent from '../FormParts/OrganizationFormComponent';
import { Button } from '../../../ui/Buttons/Button';
import { ReactComponent as CloseSvg } from './../../../assets/svg/close.svg';

type LocalStore = [AgenciesStore, BeneficiariesStore];

export interface ICreateNewBeneficiaryProps {
  onClose: () => void;
}

function getValidationSchema(contractType: ContractType, isManagedPaymentMode: boolean) {
  let shape: any = {
    ...AccountInformationFormComponent.getValidationSchema().fields,
    ...IdentityFormComponent.getValidationSchema().fields,
    ...SectorizationFormComponent.getValidationSchema().fields,
  };

  if (isManagedPaymentMode) {
    shape = {
      ...shape,
      ...BankInformationFormComponent.getValidationSchema().fields,
    };
  }

  if (['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(contractType)) {
    shape = {
      ...shape,
      ...AddressFormComponent.getValidationSchema().fields,
    };
  }

  if (contractType === 'PUBLIC_MEAL_SUBSIDY') {
    shape = {
      ...shape,
      ...OrganizationFormComponent.getValidationSchema().fields,
      ...ActivityFormComponent_Discrete.getValidationSchema().fields,
    };
  } else {
    shape = {
      ...shape,
      ...ActivityFormComponent_Linear.getValidationSchema().fields,
    };
  }

  return Yup.object().shape(shape);
}

function getFormInitialValues(agencyId: string, contractType: ContractType, isManagedPaymentMode: boolean) {
  let initialValues: any = {
    ...AccountInformationFormComponent.getInitialValues(),
    ...IdentityFormComponent.getInitialValues(),
    ...SectorizationFormComponent.getInitialValues(),

    agencyId: agencyId,
  };

  if (isManagedPaymentMode) {
    initialValues = {
      ...initialValues,
      ...BankInformationFormComponent.getInitialValues(),
    };
  }

  if (['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(contractType)) {
    initialValues = {
      ...initialValues,
      ...AddressFormComponent.getInitialValues(),
    };
  }

  if (contractType === 'PUBLIC_MEAL_SUBSIDY') {
    initialValues = {
      ...initialValues,
      ...OrganizationFormComponent.getInitialValues(),
      ...ActivityFormComponent_Discrete.getInitialValues(),
    };
  } else {
    initialValues = {
      ...initialValues,
      ...ActivityFormComponent_Linear.getInitialValues(),
    };
  }

  return initialValues;
}

const CreateNewBeneficiaryComponent: FunctionComponent<ICreateNewBeneficiaryProps> = observer(({ onClose }) => {
  const [
    agenciesStore,
    beneficiariesStore,
  ]: LocalStore = useInstances<LocalStore>(AgenciesStore, BeneficiariesStore);

  const { t } = useTranslation('beneficiaries');

  const [isServerError, setIsServerError] = useState<boolean>(false);

  const existingBeneficiaryList: BeneficiaryModel[] = beneficiariesStore.beneficiariesList;
  const formBeneficiaryTestSet: ObjectTestSet<CreateBeneficiaryRequest | UpdateBeneficiaryRequest> = useMemo(() => {
    return getFormBeneficiaryTestSet(existingBeneficiaryList);
  }, [existingBeneficiaryList]);

  const validationSchema = getValidationSchema(agenciesStore.currentAgency.contractType, agenciesStore.currentAgency.isManagedPaymentMode);
  const initialValues: any = getFormInitialValues(agenciesStore.currentAgency.uid, agenciesStore.currentAgency.contractType, agenciesStore.currentAgency.isManagedPaymentMode);

  const onCloseDrawer: () => void = useCallback(() => {
    onClose();
    formik.resetForm();
  }, []);

  const onSubmitForm: () => void = useCallback(() => {
    formik.handleSubmit();
  }, []);

  const analyseBeneficiaryData = <T, >(beneficiaryToTest: T) => {
    return getObjectTestResult(beneficiaryToTest, formBeneficiaryTestSet);
  };

  const addBeneficiary = async (
    beneficiaryToCreate: CreateBeneficiaryRequest,
    setFieldError: (field: string, errorMsg: string) => void,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const formattedBeneficiary: CreateBeneficiaryRequest = {
      ...beneficiaryToCreate,
      firstName: capitalizeFirstLetter(beneficiaryToCreate.firstName.toLowerCase()),
      lastName: beneficiaryToCreate.lastName.toUpperCase(),
      agencyId: agenciesStore.currentAgency?.uid,
    };

    if (agenciesStore.currentAgency?.isManagedPaymentMode && 'iban' in beneficiaryToCreate && beneficiaryToCreate.iban) {
      (formattedBeneficiary as CreateBeneficiaryRequest_STANDARD).iban = beneficiaryToCreate.iban.replaceAll(/\s+/g, '');
    }

    const beneficiaryTestResult = analyseBeneficiaryData(formattedBeneficiary);

    if (!beneficiaryTestResult.passed) {
      beneficiaryTestResult.errors.forEach(error => {
        if (error.errorMessage === 'EMAIL_ALREADY_TAKEN') {
          return setFieldError('email', 'Ce collaborateur existe déjà.');
        } else if (error.errorMessage === 'REGISTRATION_NUMBER_ALREADY_TAKEN') {
          return setFieldError('registrationNumber', 'Ce matricule existe déjà.');
        } else if ((error as FieldTestReport<CreateBeneficiaryRequest_STANDARD>).fieldName === 'iban') {
          return setFieldError('iban', 'L\'iban saisi n\'est pas valide.');
        }
      });
    }

    if (beneficiaryTestResult.passed) {
      try {
        await beneficiariesStore.createBeneficiary(agenciesStore.currentAgency.uid, formattedBeneficiary);
        onCloseDrawer();

      } catch (error) {
        setIsServerError(true);
        console.error(error);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit: async (beneficiaryFields: CreateBeneficiaryRequest, {
      setSubmitting,
      setFieldError,
    }: FormikHelpers<CreateBeneficiaryRequest>) => {
      setSubmitting(true);
      setIsServerError(false);

      const firstRightDateInTimeStamp: number = moment(beneficiaryFields.firstRightDate).valueOf();
      const fieldsToCreate: CreateBeneficiaryRequest = {
        ...beneficiaryFields,
        firstRightDate: firstRightDateInTimeStamp,
      };

      await addBeneficiary(fieldsToCreate, setFieldError, setSubmitting);

      setSubmitting(false);
    },
  });

  return (
    <div className={'p-10 min-h-screen'}>
      <div className={'flex justify-between items-center'}>
        <h3>{t('addBeneficiary')}</h3>
        <CloseSvg
          onClick={onClose}
          title={t('close')}
          className={'text-primary cursor-pointer hover:text-primary/75 transition-colors ease-in-out duration-300'}
        />
      </div>


      <form onSubmit={formik.handleSubmit} noValidate>
        <fieldset>

          {/* --- INFORMATIONS SUR LE COMPTE --- */}
          <AccountInformationFormComponent formik={formik}/>
          {/* ---------------------------------- */}

          {/* --- ORGANISATION --- */}
          {agenciesStore.currentAgency.contractType === 'PUBLIC_MEAL_SUBSIDY' &&
              <OrganizationFormComponent formik={formik}/>}
          {/* ---------------------------------- */}

          {/* --- SECTORISATION --- */}
          {agenciesStore.currentAgency.contractType === 'PUBLIC_MEAL_SUBSIDY' &&
              <SectorizationFormComponent formik={formik} showTitle={false}/>}

          {/* ---------------------------------- */}

          {/* ------------ IDENTITÉ ------------ */}
          <IdentityFormComponent formik={formik}/>
          {/* ---------------------------------- */}

          {/* ------------- ADRESSE ------------- */}
          {['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(agenciesStore.currentAgency.contractType) && (
            <AddressFormComponent formik={formik}/>
          )}
          {/* ---------------------------------- */}

          {/* -------- RÉFÉRENCE BANCAIRE ------- */}
          {agenciesStore.currentAgency.isManagedPaymentMode && (
            <BankInformationFormComponent formik={formik}/>
          )}
          {/* ---------------------------------- */}

          {/* ------------ ACTIVITÉ ------------ */}
          {agenciesStore.currentAgency.contractType === 'PUBLIC_MEAL_SUBSIDY'
            ? (
              <ActivityFormComponent_Discrete formik={formik}/>
            )
            : (
              <ActivityFormComponent_Linear formik={formik}/>
            )
          }
          {/* ---------------------------------- */}

          {isServerError && (
            <span className={'text-status-error'}>Erreur serveur veuillez réessayer plus tard !</span>
          )}
        </fieldset>

        <div className={'flex justify-end gap-4'}>
          <Button onClick={onCloseDrawer}>
            {t('cancel')}
          </Button>
          <Button onClick={onSubmitForm} loading={formik.isSubmitting} disabled={formik.isSubmitting}>
            {t('add')}
          </Button>
        </div>
      </form>
    </div>
  );
});

export default CreateNewBeneficiaryComponent;