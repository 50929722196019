import { PaybackModel } from '../../models/paybacks/Payback.model';
import { ExportingPaybackModel } from '../../models/paybacks/ExportingPayback.model';
import { convertCentToEuro } from '../functions/convertCentToEuro.util';
import { UpdatePaybackRequest } from '../../requests/paybacks/UpdatePayback.request';
import { convertEuroToCent } from '../functions/convertEuroToCent.util';
import { GeniusPaybackModel } from '../../models/paybacks/GeniusPayback.model';
import { ResolvePendingOcrPaybackRequest } from '../../requests/paybacks/ResolvePendingOcrPayback.request';

export function isGeoRestrictedPayback(payback: PaybackModel): boolean {
  return payback.contractType === 'GEOGRAPHIC_RESTRICTION';
}

export function isStandardPayback(payback: PaybackModel): boolean {
  return payback.contractType === 'STANDARD';
}

export function isScanCBPayback(payback: PaybackModel): boolean {
  return payback.channelType === 'SCAN';
}

export function isOpenBankingPayback(payback: PaybackModel): boolean {
  return ['OPEN_BANKING', 'SUPPORT'].includes(payback.channelType);
}

export function convertPaybackFromCentsToEuros(payback: PaybackModel): PaybackModel {
  const { transactionAmount, amount } = payback;

  return {
    ...payback,
    transactionAmount: convertCentToEuro(transactionAmount),
    amount: amount ? convertCentToEuro(amount) : null,
  };
}

export function convertExportingPaybacksFromCentsToEuros(exportingPayback: ExportingPaybackModel): ExportingPaybackModel {
  const { transactionAmount, amount } = exportingPayback;

  return {
    ...exportingPayback,
    transactionAmount: convertCentToEuro(transactionAmount),
    amount: amount ? convertCentToEuro(amount) : 0,
  };
}

export function convertUpdatePaybackRequestFromEurosToCents(request: UpdatePaybackRequest): UpdatePaybackRequest {
  const { transactionAmount, amount, ...rest } = request;
  const convertedRequest: UpdatePaybackRequest = { ...rest };
  if (typeof transactionAmount === 'number') {
    convertedRequest.transactionAmount = convertEuroToCent(transactionAmount);
  }
  if (typeof amount === 'number') {
    convertedRequest.amount = convertEuroToCent(amount);
  }

  return convertedRequest;
}

export function convertGeniusPaybackFromCentsToEuros(geniusPayback: GeniusPaybackModel): GeniusPaybackModel {
  const { transactionAmount, amount } = geniusPayback;
  return {
    ...geniusPayback,
    transactionAmount: convertCentToEuro(transactionAmount),
    amount: convertCentToEuro(amount || 0),
  };
}

export function convertResolvePendingOcrPaybackRequestFromEurosToCents(request: ResolvePendingOcrPaybackRequest): ResolvePendingOcrPaybackRequest {
  const { transactionAmount } = request;
  return {
    ...request,
    transactionAmount: convertEuroToCent(transactionAmount),
  };
}