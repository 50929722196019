import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { BeneficiariesStore } from '../../Stores/Beneficiaries.store';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { OrderValidationViewStore } from '../../Stores/viewStore/OrderValidationView.store';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import StyledTextField from '../../Style/MuiStyles/TextField';
import { InvoiceCustomMetadataModel } from '@assets/models/invoices/InvoiceCustomMetadata.model';
import { HydratedBeneficiaryCreditData } from '@assets/models/orders/Order.model';
import {
  getExcludingTaxesOrderFeesAmount,
  getProvisionAmount,
  getVatOrderFeesAmount,
} from '../../assets/utils/orders/order.util';
import { VatModel } from '@assets/models/vat/Vat.model';
import { Spinner } from '../../ui/Spinner';
import { Button } from '../../ui/Buttons/Button';

type LocalStore = [
  RechargingViewStore,
  OrderValidationViewStore,
  OrderPaymentViewStore,
  StepperRechargingStore,
  AgenciesStore,
  BeneficiariesStore,
  AgencyInvoiceStore,
];

type SubscriptionRow = {
  numberOfCredits: number;
  subscriptionFee: number;
  totalAmount: number;
}

type ProvisionRow = {
  numberOfWorkingDays: number;
  agencyPart: number;
  totalAmount: number;
}

/**
 * Tab: Rechargement des droits
 * Step: Validation (CONFIRMATION)
 */
const OrderValidation: FunctionComponent = observer(() => {
  const [
    rechargingViewStore,
    orderValidationViewStore,
    orderPaymentViewStore,
    stepperRechargingStore,
    agenciesStore,
    beneficiariesStore,
    agencyInvoiceStore,
  ]: LocalStore = useInstances<LocalStore>(
    RechargingViewStore,
    OrderValidationViewStore,
    OrderPaymentViewStore,
    StepperRechargingStore,
    AgenciesStore,
    BeneficiariesStore,
    AgencyInvoiceStore,
  );

  const { t } = useTranslation('reloading');

  const newActiveBeneficiaryCount = orderValidationViewStore.newActiveBeneficiaryCount;
  const beneficiariesToRecharge = beneficiariesStore.listOfBeneficiariesToRecharge;
  const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  const [isDissociatedPayment, setIsDissociatedPayment] = useState<boolean>(false);

  const beneficiaryCredits: HydratedBeneficiaryCreditData[] = useMemo(() => {
    return orderPaymentViewStore.credits.map(credit => {
      const beneficiary = beneficiariesToRecharge?.find(beneficiary => beneficiary.uid === credit.beneficiaryUid);
      const classification = agenciesStore.currentAgencyBeneficiaryClassification?.find(classification => classification.uid === credit.classificationId);

      return {
        ...credit,
        beneficiary: beneficiary,
        beneficiaryClassification: classification,
        userAccountId: beneficiary.geniusUserAccountId,
      };
    });
  }, [orderPaymentViewStore.credits, beneficiariesToRecharge, agenciesStore.currentAgencyBeneficiaryClassification]);

  const orderSubscriptionVat: VatModel = agenciesStore.currentAgency.vat;
  const feesAmountHT: number = getExcludingTaxesOrderFeesAmount(beneficiaryCredits);
  const feesTotalVat: number = getVatOrderFeesAmount(beneficiaryCredits, orderSubscriptionVat);
  const feesAmountTTC: number = feesAmountHT + feesTotalVat;
  const provisionTotalDays: number = beneficiariesToRecharge
    .reduce((sum: number, current: BeneficiaryModel) => sum + (current.numberOfWorkingDays ? current.numberOfWorkingDays : 0), 0);

  const provisionAmount: number = getProvisionAmount(beneficiaryCredits);
  const netAmountPayable: number = provisionAmount + feesAmountTTC;

  const { hasInvoiceInformation }: AgenciesStore = agenciesStore;
  const {
    customerReferenceValue, internalOrderNumberValue, marketNumberValue, commitmentNumberValue,
    feesCustomerReferenceValue,
  }: InvoiceCustomMetadataModel = orderPaymentViewStore.invoiceCustomMetadata;

  const month: number = rechargingViewStore.selectedReloadingDate.getMonth() + 1;
  const year: number = rechargingViewStore.selectedReloadingDate.getFullYear();
  const {
    dateString, hasAtLeastMetadata, countSetMetadata,
  }: OrderPaymentViewStore = orderPaymentViewStore;

  useEffect(() => {
    orderPaymentViewStore.setOrderDetails({
      ...orderPaymentViewStore.orderDetails,
      beneficiaryToRechargeCount: newActiveBeneficiaryCount,
      month,
      year,
      totalHT: feesAmountHT,
      tva: feesTotalVat,
      totalTTC: feesAmountTTC,
      provisionTotalDays,
      provisionAmount,
      netAmountPayable,
    });
  }, [feesAmountHT, feesAmountTTC, newActiveBeneficiaryCount, feesTotalVat, provisionTotalDays]);

  useEffect(() => {
    (async () => {
      await agencyInvoiceStore.fetchAgencyInvoiceBeneficiariesByDate(
        agenciesStore.currentAgency?.uid,
        rechargingViewStore.selectedReloadingDate.toDateString());
    })();
    setIsDissociatedPayment(agenciesStore.currentAgency?.paymentMethod === 'DISSOCIATED_BANK_TRANSFER');
  }, [agenciesStore.currentAgency?.uid]);

  useEffect(() => {
    if (agencyInvoiceStore.invoiceBeneficiariesLoadedStatus === 'success') {
      const response: string[] = agencyInvoiceStore.invoiceBeneficiariesByDateList;
      const newActiveBeneficiaries: BeneficiaryModel[] = beneficiariesStore.listOfBeneficiariesToRecharge
        .filter(beneficiary => response.indexOf(beneficiary.uid) === -1 && beneficiary.numberOfWorkingDays > 0);

      const newActiveBeneficiaryCount = newActiveBeneficiaries.length;

      orderValidationViewStore.setNewActiveBeneficiaryCount(newActiveBeneficiaryCount);
      orderPaymentViewStore.setCredits(newActiveBeneficiaries.map(beneficiary => {
        const classification = agenciesStore.currentAgencyBeneficiaryClassification?.find(classification => classification.uid === beneficiary.beneficiaryClassificationUid);

        return {
          beneficiaryUid: beneficiary.uid,
          classificationId: beneficiary.beneficiaryClassificationUid,
          classificationVersion: classification?.version,
          numberOfRechargingDays: beneficiary.numberOfWorkingDays,
        };
      }));
      agencyInvoiceStore.setInvoiceBeneficiariesLoading(false);
    }
  }, [agencyInvoiceStore.invoiceBeneficiariesLoadedStatus]);

  const orderClassificationDetails = useMemo(() => {
    return agenciesStore.currentAgencyBeneficiaryClassification?.map(classification => {
      const credits: HydratedBeneficiaryCreditData[] = beneficiaryCredits
        .filter(credit => credit.beneficiaryClassification.uid === classification.uid);
      const numberOfWorkingDays: number = credits
        .reduce((sum: number, current: HydratedBeneficiaryCreditData) => sum + (current.numberOfRechargingDays ? current.numberOfRechargingDays : 0), 0);

      return {
        ...classification,
        credits,
        numberOfWorkingDays,
      };
    }).filter(classification => classification.credits.length > 0);
  }, [beneficiaryCredits]);

  const subscriptionRows: SubscriptionRow[] = useMemo(() => {
    return orderClassificationDetails.reduce((rows: SubscriptionRow[], classification) => {
      if (classification.creditRedemptionMethod === 'DISCRETE') {
        return rows;
      }

      let existingRow: SubscriptionRow | undefined = rows.find(row => row.subscriptionFee === classification.contractSubscriptionFee);

      if (!existingRow) {
        existingRow = {
          subscriptionFee: classification.contractSubscriptionFee,
          numberOfCredits: 0,
          totalAmount: 0,
        };
        rows.push(existingRow);
      }

      existingRow.numberOfCredits += classification.credits.length;
      existingRow.totalAmount += existingRow.numberOfCredits * existingRow.subscriptionFee;

      return rows;
    }, []);
  }, [orderClassificationDetails]);

  const provisionRows: ProvisionRow[] = useMemo(() => {
    return orderClassificationDetails.reduce((rows: ProvisionRow[], classification) => {
      if (classification.creditRedemptionMethod === 'DISCRETE') {
        return rows;
      }
      let existingRow: ProvisionRow | undefined = rows.find(row => row.agencyPart === classification.contractAgencyPart);

      if (!existingRow) {
        existingRow = {
          agencyPart: classification.contractAgencyPart,
          numberOfWorkingDays: 0,
          totalAmount: 0,
        };
        rows.push(existingRow);
      }

      existingRow.numberOfWorkingDays += classification.numberOfWorkingDays;
      existingRow.totalAmount += existingRow.numberOfWorkingDays * existingRow.agencyPart;

      return rows;
    }, []);
  }, [orderClassificationDetails]);

  return (
    <>
      {agencyInvoiceStore.isInvoiceBeneficiariesLoading
        ? <Spinner size={'lg'}/>
        : <>
          {stepperRechargingStore.currentStepIndex === 2 &&
              <h1 className={'mt-10'}>{t('validationOrder.recap')}</h1>
          }

          <div className={'pt-10 space-y-4'}>
            <p className={'font-bold'}>
              {t('subscriptionTitle', { count: newActiveBeneficiaryCount, month: dateString })}
            </p>
            <p>{t('subscription')}</p>
            {
              subscriptionRows.map((row, index) => (
                <div key={index} className={'flex justify-between'}>
                  <p className={'text-grey'}>
                    {`${t('transaction', { count: row.numberOfCredits })} ${currencyFormat.format(Number(row.subscriptionFee))}`}
                  </p>
                  <p>{`${currencyFormat.format(row.totalAmount)}`}</p>
                </div>
              ))
            }
            <hr/>
            <div className={'flex flex-col place-items-end space-y-5 py-5'}>
              <div className={'flex justify-between w-1/3'}>
                <p>{t('totalExcl')}</p>
                <p>{`${currencyFormat.format(feesAmountHT)}`}</p>
              </div>
              <div className={'flex justify-between w-1/3'}>
                <p>{t('vat')} {orderSubscriptionVat.rate.toFixed(2)}%</p>
                <p>{currencyFormat.format(feesAmountTTC - feesAmountHT)}</p>
              </div>
              <div className={'flex justify-between w-1/3'}>
                <p>{t('totalIncl')}</p>
                <p>{`${currencyFormat.format(feesAmountTTC)}`}</p>
              </div>
            </div>
            <hr/>
            <br/>
            {agenciesStore.currentAgency?.isManagedPaymentMode
              ? <>
                <p className={'font-bold'}>{t('provisionManaged', { month: dateString })}</p>
                <br/>
                <p>{t('validationOrder.provisioned')}</p>
              </>
              : <>
                <p className={'font-bold'}>{t('provisionNotManaged', { month: dateString })}</p>
                <br/>
                <p>{t('validationOrder.provisionNotManaged')}</p>
              </>
            }
            {agenciesStore.currentAgency?.isManagedPaymentMode === false
              ? provisionRows.map((row, index) => (
                <div key={index} className={'flex'}>
                  <p className={'text-grey'}>
                    {t('provision_subtitle',
                      {
                        totalNumberOfWorkingDays: row.numberOfWorkingDays,
                        quota: currencyFormat.format(Number(row.agencyPart)),
                      })}
                    = {currencyFormat.format(row.totalAmount)} HT
                  </p>
                </div>
              ))
              : provisionRows.map((row, index) => (

                <div key={index} className={'flex justify-between'}>
                  <p className={'text-grey'}>
                    {t('provision_subtitle',
                      {
                        totalNumberOfWorkingDays: row.numberOfWorkingDays,
                        quota: currencyFormat.format(Number(row.agencyPart)),
                      })}
                  </p>
                  <p className={'font-bold'}>{currencyFormat.format(row.totalAmount)} HT</p>
                </div>
              ))
            }

            <hr/>
            {
              agenciesStore.currentAgency?.isManagedPaymentMode && agenciesStore.currentAgency.useBeneficiaryClassification && (
                <div className={'flex flex-col place-items-end m-0'} style={{ marginBottom: 0 }}>
                  <div className={'flex justify-between w-1/2'}>
                    <p>{t('provisionAmount')}</p>
                    <p>{`${currencyFormat.format(provisionAmount)}`}</p>
                  </div>
                </div>
              )
            }

            <br/>
            <div className={'flex flex-col place-items-end m-0'}>
              <div className={'flex justify-between w-1/2 font-bold'}>
                <p>{t('total')}</p>
                <p>
                  {agenciesStore.currentAgency?.isManagedPaymentMode === false
                    ? currencyFormat.format(feesAmountTTC)
                    : currencyFormat.format(netAmountPayable)}
                </p>
              </div>
            </div>
          </div>
          {hasInvoiceInformation && hasAtLeastMetadata &&
              <>
                  <h4>{t(`referenceTitle${isDissociatedPayment ? '_DISSOCIATED_PAYMENT' : ''}`)}</h4>

                  <div className={countSetMetadata === 2 ? 'flex' : 'flex justify-between'}>
                      <div className={isDissociatedPayment ? 'flex flex-col' : ''}>
                        {feesCustomerReferenceValue &&
                          <StyledTextField
                                className={'my-2 w-[400px] ml-2'}
                                id={'feesCustomerReferenceArea'}
                                name={'feesCustomerReferenceArea'}
                                label={t('yourInformation.feesCustomerReferenceArea')}
                                value={feesCustomerReferenceValue}
                                disabled={true}
                                fullWidth
                            />}

                        {customerReferenceValue &&
                            <StyledTextField
                                className={'my-2 w-[400px] ml-2'}
                                id={'customerReferenceArea'}
                                name={'customerReferenceArea'}
                                label={t(`yourInformation.customerReferenceArea${isDissociatedPayment ? '_DISSOCIATED_PAYMENT' : ''}`)}
                                value={customerReferenceValue}
                                disabled={true}
                                fullWidth
                            />}
                      </div>

                    {internalOrderNumberValue &&
                        <StyledTextField
                            className={'my-2 w-[400px] ml-2'}
                            id={'internalOrderNumberArea'}
                            name={'internalOrderNumberArea'}
                            label={t('yourInformation.internalOrderNumberArea')}
                            value={internalOrderNumberValue}
                            disabled={true}
                            fullWidth
                        />}

                    {marketNumberValue &&
                        <StyledTextField
                            className={'my-2 w-[400px] ml-2'}
                            id={'marketNumberArea'}
                            name={'marketNumberArea'}
                            label={t('yourInformation.marketNumberArea')}
                            value={marketNumberValue}
                            disabled={true}
                            fullWidth
                        />}

                    {commitmentNumberValue &&
                        <StyledTextField
                            className={'my-2 w-[400px] ml-2'}
                            id={'commitmentNumberArea'}
                            name={'commitmentNumberArea'}
                            label={t('yourInformation.commitmentNumberArea')}
                            value={commitmentNumberValue}
                            disabled={true}
                            placeholder={''}
                            fullWidth
                        />}
                  </div>
              </>}

          <div className={'flex justify-end gap-4 pt-10'}>
            <Button variant={'outlined'} onClick={() => stepperRechargingStore.goToPreviousStep()}>
              {t('cancel')}
            </Button>
            <Button onClick={() => stepperRechargingStore.goToNextStep()}>
              {t('nextStep')}
            </Button>
          </div>
        </>
      }
    </>
  );
});

export default OrderValidation;
