export interface EnvCookiesIds {
  clientId: string;
  cookiesVersion: string;
}

interface AcceptioCookiesIds {
  DEV: EnvCookiesIds;
  DEMO: EnvCookiesIds;
  UAT: EnvCookiesIds;
  PROD: EnvCookiesIds;
}

export const axeptioCookiesIds: AcceptioCookiesIds = {
  DEV: {
    clientId: '6380c0308f2b2f8e80c315ac',
    cookiesVersion: 'site employeur openeat-fr_DEV',
  },
  DEMO:{
    clientId: '',
    cookiesVersion: '',
  },
  UAT: {
    clientId: '6380c0308f2b2f8e80c315ac',
    cookiesVersion: 'site employeur openeat-fr_UAT',
  },
  PROD: {
    clientId: '6380c0308f2b2f8e80c315ac',
    cookiesVersion: 'site employeur open!eat-fr',
  },
} as const;