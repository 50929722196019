import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { InvoiceModel } from '../../../../assets/models/invoices/invoice.model';
import { AgenciesStore } from '../../../../Stores/Agencies.store';
import InvoiceFeesSummary from './InvoiceFeesSummary';
import InvoiceProvisionsSummary from './InvoiceProvisionsSummary';
import CopyButton from '../../../../ui/Buttons/CopyButton';

import { StatusLed } from '../../../../ui/StatusLed';
import { Button } from '../../../../ui/Buttons/Button';

import { ReactComponent as CloseSvg } from '../../../../assets/svg/close.svg';
import { useNavigate } from 'react-router';

export interface IInvoiceDetailsComponentProps {
  invoice: InvoiceModel;
  onClose: () => void;
}

const InvoiceDetailsComponent: FunctionComponent<IInvoiceDetailsComponentProps> = observer((props: IInvoiceDetailsComponentProps) => {
  const { invoice, onClose } = props;
  const agenciesStore: AgenciesStore = useInstance<AgenciesStore>(AgenciesStore);
  const dateOptions = { year: 'numeric', month: 'long' } as const;
  const statusDate: Date = new Date(invoice.statusDate);
  const { t } = useTranslation('accounting');
  const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  const navigate = useNavigate();

  const netAmountPayable: number = invoice.netAmount;

  const displayBankWireDescriptor: boolean = ['BANK_TRANSFER', 'DISSOCIATED_BANK_TRANSFER'].includes(invoice.paymentMethod);
  const displayFees: boolean = ['STANDARD', 'DISSOCIATED_FEES'].includes(invoice.invoiceType);
  const displayProvisions: boolean = ['STANDARD', 'DISSOCIATED_PROVISIONS'].includes(invoice.invoiceType) && agenciesStore.currentAgency?.isManagedPaymentMode;
  const displayTotal: boolean = invoice.invoiceType === 'STANDARD' && agenciesStore.currentAgency?.isManagedPaymentMode;

  const title = t(`invoiceDetails.title_${invoice.invoiceType}`, {
    reference: invoice.invoiceReference,
    date: moment(invoice.creationDate).format('DD/MM/YYYY'),
  });

  let statusLabel = invoice.status === 'SUCCEEDED'
    ? `${props.invoice.paymentMethod === 'DIRECT_DEBIT'
      ? t('paidDirectDebit')
      : t('paidBankTransfer')} ${statusDate.toLocaleDateString()}`
    : t('toPay', { mode: props.invoice.paymentMethod === 'DIRECT_DEBIT' ? 'prélèvement' : 'virement' });

  return (
    <section className={'bg-white rounded-br10 pb-10'}>
      <div
        className={'flex justify-between px-0 py-5 items-center border-solid border-0 border-b-[1px] border-b-secondary/30'}>
        <h1 className={'pl-8 font-normal text-sm'}>
          {title}
        </h1>
        <CloseSvg className={'mr-8 hover:cursor-pointer'} width={14} height={14} onClick={onClose}/>
      </div>

      <div>
        <div className={'px-8 p-5 flex justify-between items-center'}>
          <StatusLed
            condition={invoice.status === 'SUCCEEDED'}
            label={statusLabel}
            size={'sm'}/>
          {displayBankWireDescriptor &&
            <div className={'flex items-center gap-6'}>
              <p className={'font-bold'}>{t('invoiceDetails.expectedDescriptorLabel')}</p>
              <div className={'flex justify-between items-center'}>
                <p className={'font-bold'}>{invoice.bankWireReference}</p>
                <CopyButton value={invoice.bankWireReference}/>
              </div>
            </div>
          }
        </div>

        {displayFees &&
          <>
            <InvoiceFeesSummary
              invoice={invoice}
              currencyFormat={currencyFormat}
              dateOptions={dateOptions}/>
          </>
        }

        {displayProvisions &&
          <InvoiceProvisionsSummary
            invoice={invoice}
            currencyFormat={currencyFormat}
            dateOptions={dateOptions}/>
        }

        {displayTotal &&
          <div className={'flex flex-col items-end px-8'}>
            <div className={'total'}>
              <p>{t('invoiceDetails.total')}</p>
              <p className={'amount'}>
                {currencyFormat.format(netAmountPayable)} TTC
              </p>
            </div>
          </div>
        }

        <div className={'flex justify-end px-8 space-x-6 items-center pt-20'}>
          {invoice.invoiceUrl &&
              <a href={invoice.invoiceUrl} download target={'_blank'} className={'no-underline'}>
                  <Button className={'h-12'} variant={'outlined'}>
                    {t(`invoiceDetails.download_${invoice.invoiceType}`)}
                  </Button>
              </a>
          }

          <Button
            className={'h-12'}
            onClick={onClose}
            variant="contained"
          >
            {t('invoiceDetails.close')}
          </Button>
        </div>
      </div>
    </section>
  );
});

export default InvoiceDetailsComponent;
