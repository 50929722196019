import { makeAutoObservable } from 'mobx';
import { TStepAddNewRecharging } from '../../Models/Types/TStepAddNewRecharging.model';


export class StepperRechargingStore {
  isUploadingCsv = false;
  currentStep: TStepAddNewRecharging = 'SELECT_METHOD_RECHARGING';

  stepNameList: TStepAddNewRecharging[] = [];

  get currentStepIndex(): number {
    return this.stepNameList.indexOf(this.currentStep);
  }

  get previousStepIndex(): number {
    return Math.max(this.currentStepIndex - 1, 0);
  }

  get previousStep(): TStepAddNewRecharging {
    return this.stepNameList[this.previousStepIndex];
  }

  get nextStepIndex(): number {
    return Math.min(this.currentStepIndex + 1, this.stepNameList.length - 1);
  }

  get nextStep(): TStepAddNewRecharging {
    return this.stepNameList[this.nextStepIndex];
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setStepNameList(steps: TStepAddNewRecharging[]) {
    this.stepNameList = steps;
  }

  goToStep(stepName: TStepAddNewRecharging): void {
    this.setCurrentStep(stepName);
  }

  goToPreviousStep(): void {
    this.setCurrentStep(this.previousStep);
  }

  goToNextStep(): void {
    this.setCurrentStep(this.nextStep);
  }

  returnToStep(stepName: TStepAddNewRecharging) {
    const stepIndex = this.stepNameList.indexOf(stepName);
    const canReturn: boolean = (stepName !== 'LIST_BENEFICIARIES_FROM_CSV')
      && (stepName !== 'RECAP')
      && (stepIndex < this.currentStepIndex);

    if (!canReturn) {
      return;
    }

    this.setCurrentStep(stepName);
  }

  private setCurrentStep(stepName: TStepAddNewRecharging): void {
    if (stepName === 'ADD_NEW_BENEFICIARIES_UPLOAD' || stepName === 'LIST_BENEFICIARIES_FROM_CSV') {
      this.isUploadingCsv = true;
    }
    if (stepName === 'PARAMETERS' || stepName === 'LIST_BENEFICIARIES') {
      this.isUploadingCsv = false;
    }
    this.currentStep = stepName;
  }
}
