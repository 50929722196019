/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentRequestArrayRequestResult } from '../models/PaymentRequestArrayRequestResult';
import type { PaymentRequestListArrayRequestResult } from '../models/PaymentRequestListArrayRequestResult';
import type { PaymentRequestRequestResult } from '../models/PaymentRequestRequestResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AutonomousService {

  /**
   * Get request for payment
   * @param agencyId
   * @param to
   * @returns PaymentRequestRequestResult Success
   * @throws ApiError
   */
  public static getRequestPayment(
    agencyId: string,
    to: string,
  ): CancelablePromise<PaymentRequestRequestResult> {
    return __request({
      method: 'GET',
      path: `/api/autonomous/paymentrequest`,
      query: {
        'AgencyId': agencyId,
        'To': to,
      },
    });
  }

  /**
   * Get all payment request by agency id
   * @param agencyId
   * @param from
   * @returns PaymentRequestArrayRequestResult Success
   * @throws ApiError
   */
  public static getAllRequestPaymentByAgencyId(
    agencyId: string,
    from: string,
  ): CancelablePromise<PaymentRequestArrayRequestResult> {
    return __request({
      method: 'GET',
      path: `/api/autonomous/paymentrequest/all`,
      query: {
        'AgencyId': agencyId,
        'From': from,
      },
    });
  }

  /**
   * Get all payment request by agency id
   * @param agencyId
   * @param beneficiaryId
   * @param from
   * @returns PaymentRequestListArrayRequestResult Success
   * @throws ApiError
   */
  public static getPaymentRequestListByBeneficiaryId(
    agencyId: string,
    beneficiaryId: string,
    from: string,
  ): Promise<PaymentRequestListArrayRequestResult> {
    return Promise.resolve({
      'result': [{
        'id': '8274a852-7ec4-421e-648c-08d9e4c16280', 'agencyId': 'dbc2c09d-a719-4ece-cd6d-08d99eadebd2',
        'amount': 9.54, 'from': '2021-12-02T00:00:00', 'to': '2022-01-31T00:00:00',
        'createdOn': '2022-01-31T14:58:51.741451', 'modifiedOn': '2022-01-31T14:58:51.7414585',
        'paymentRequestsList': [{
          'id': '11f210c7-9030-46d6-c4cf-08d9e4c1cff2', 'paymentRequestId': '8274a852-7ec4-421e-648c-08d9e4c16280',
          'beneficiaryId': '7f9f25c0-5d0f-489a-bf0f-80aa3a5e6922',
          'transactionId': '0464755e-5517-4094-ac47-bc1984675639', 'amount': 9.54,
          'createdOn': '2022-01-31T14:58:51.8225036', 'modifiedOn': '2022-01-31T14:58:51.8226933', 'beneficiary': {
            'id': '7f9f25c0-5d0f-489a-bf0f-80aa3a5e6922', 'agencyId': 'dbc2c09d-a719-4ece-cd6d-08d99eadebd2',
            'email': 'alainterieur@yopmail.com', 'firstname': 'Térieur', 'lastname': 'ALAIN', 'gcuVersion': '1.1.0',
            'gcuDate': '2022-02-24T15:01:41.589676', 'mobilephone': '0669573323', 'registrationNumber': '123456',
            'streetAddress': '12 rue des mimosas', 'additionalAddress': '', 'postalCode': '75001', 'city': 'Paris',
            'countryCode': 'FR', 'title': 'Mr', 'isAccountValidated': true, 'isActive': true,
            'activeSundaysAndHolidays': false, 'userPath': 'BillPicture', 'createdOn': '2021-11-03T09:54:23.0161187',
            'modifiedOn': '2022-03-03T17:12:45.9544589', 'ibanLastNumbers': '0143', 'numberOfWorkingDays': 0,
            'firstRightDate': '2022-01-07T00:00:00', 'paymentRequestsList': [{
              'id': 'ad8e38e1-b6a5-445b-1f4c-08d9b5a4b546', 'paymentRequestId': 'b0e5a6be-ecd3-4027-c7c5-08d9b5a4b512',
              'beneficiaryId': '7f9f25c0-5d0f-489a-bf0f-80aa3a5e6922',
              'transactionId': 'b18ac2d7-9048-47c8-9a17-9c454a726fff', 'amount': 13.68,
              'createdOn': '2021-12-02T16:15:29.3966799', 'modifiedOn': '2021-12-02T16:15:29.3966808',
              'paymentRequest': {
                'id': 'b0e5a6be-ecd3-4027-c7c5-08d9b5a4b512', 'agencyId': 'dbc2c09d-a719-4ece-cd6d-08d99eadebd2',
                'validatedDate': '2021-12-02T16:15:39.6809867', 'amount': 25.08, 'from': '2021-11-09T00:00:00',
                'to': '2021-12-02T00:00:00', 'createdOn': '2021-12-02T16:15:29.3780238',
                'modifiedOn': '2021-12-02T16:15:39.6904265', 'paymentRequestsList': [{
                  'id': '2b9c57e2-b4bb-4a92-1f4d-08d9b5a4b546',
                  'paymentRequestId': 'b0e5a6be-ecd3-4027-c7c5-08d9b5a4b512',
                  'beneficiaryId': '445de4fb-565f-4329-abc7-e37081da8f52',
                  'transactionId': 'fe3d3e0e-7281-422d-8361-f87e22538e31', 'amount': 11.40,
                  'createdOn': '2021-12-02T16:15:30.2428413', 'modifiedOn': '2021-12-02T16:15:30.2428424',
                  'beneficiary': {
                    'id': '445de4fb-565f-4329-abc7-e37081da8f52', 'agencyId': 'dbc2c09d-a719-4ece-cd6d-08d99eadebd2',
                    'email': 'benjamin.guelle@gmail.com', 'firstname': 'Benjamin', 'lastname': 'GUELLE',
                    'mobilephone': '', 'registrationNumber': '123456', 'streetAddress': '12 rue des mimosas',
                    'additionalAddress': '', 'postalCode': '75001', 'city': 'Paris', 'countryCode': 'EE', 'title': 'Mr',
                    'isAccountValidated': true, 'isActive': true, 'activeSundaysAndHolidays': false,
                    'userPath': 'BillPicture', 'createdOn': '2021-11-15T12:20:32.0904809',
                    'modifiedOn': '2022-03-12T15:20:07.8014949', 'ibanLastNumbers': '0143', 'numberOfWorkingDays': 0,
                    'firstRightDate': '2022-01-07T00:00:00', 'paymentRequestsList': [],
                  },
                }],
              },
            }],
          },
        }],
      }, {
        'id': 'b0e5a6be-ecd3-4027-c7c5-08d9b5a4b512', 'agencyId': 'dbc2c09d-a719-4ece-cd6d-08d99eadebd2',
        'validatedDate': '2021-12-02T16:15:39.6809867', 'amount': 25.08, 'from': '2021-11-09T00:00:00',
        'to': '2021-12-02T00:00:00', 'createdOn': '2021-12-02T16:15:29.3780238',
        'modifiedOn': '2021-12-02T16:15:39.6904265', 'paymentRequestsList': [{
          'id': 'ad8e38e1-b6a5-445b-1f4c-08d9b5a4b546', 'paymentRequestId': 'b0e5a6be-ecd3-4027-c7c5-08d9b5a4b512',
          'beneficiaryId': '7f9f25c0-5d0f-489a-bf0f-80aa3a5e6922',
          'transactionId': 'b18ac2d7-9048-47c8-9a17-9c454a726fff', 'amount': 13.68,
          'createdOn': '2021-12-02T16:15:29.3966799', 'modifiedOn': '2021-12-02T16:15:29.3966808', 'beneficiary': {
            'id': '7f9f25c0-5d0f-489a-bf0f-80aa3a5e6922', 'agencyId': 'dbc2c09d-a719-4ece-cd6d-08d99eadebd2',
            'email': 'alainterieur@yopmail.com', 'firstname': 'Térieur', 'lastname': 'ALAIN', 'gcuVersion': '1.1.0',
            'gcuDate': '2022-02-24T15:01:41.589676', 'mobilephone': '0669573323', 'registrationNumber': '123456',
            'streetAddress': '12 rue des mimosas', 'additionalAddress': '', 'postalCode': '75001', 'city': 'Paris',
            'countryCode': 'FR', 'title': 'Mr', 'isAccountValidated': true, 'isActive': true,
            'activeSundaysAndHolidays': false, 'userPath': 'BillPicture', 'createdOn': '2021-11-03T09:54:23.0161187',
            'modifiedOn': '2022-03-03T17:12:45.9544589', 'ibanLastNumbers': '0143', 'numberOfWorkingDays': 0,
            'firstRightDate': '2022-01-07T00:00:00', 'paymentRequestsList': [{
              'id': '11f210c7-9030-46d6-c4cf-08d9e4c1cff2', 'paymentRequestId': '8274a852-7ec4-421e-648c-08d9e4c16280',
              'beneficiaryId': '7f9f25c0-5d0f-489a-bf0f-80aa3a5e6922',
              'transactionId': '0464755e-5517-4094-ac47-bc1984675639', 'amount': 9.54,
              'createdOn': '2022-01-31T14:58:51.8225036', 'modifiedOn': '2022-01-31T14:58:51.8226933',
              'paymentRequest': {
                'id': '8274a852-7ec4-421e-648c-08d9e4c16280', 'agencyId': 'dbc2c09d-a719-4ece-cd6d-08d99eadebd2',
                'amount': 9.54, 'from': '2021-12-02T00:00:00', 'to': '2022-01-31T00:00:00',
                'createdOn': '2022-01-31T14:58:51.741451', 'modifiedOn': '2022-01-31T14:58:51.7414585',
                'paymentRequestsList': [],
              },
            }],
          },
        }, {
          'id': '2b9c57e2-b4bb-4a92-1f4d-08d9b5a4b546', 'paymentRequestId': 'b0e5a6be-ecd3-4027-c7c5-08d9b5a4b512',
          'beneficiaryId': '445de4fb-565f-4329-abc7-e37081da8f52',
          'transactionId': 'fe3d3e0e-7281-422d-8361-f87e22538e31', 'amount': 11.40,
          'createdOn': '2021-12-02T16:15:30.2428413', 'modifiedOn': '2021-12-02T16:15:30.2428424', 'beneficiary': {
            'id': '445de4fb-565f-4329-abc7-e37081da8f52', 'agencyId': 'dbc2c09d-a719-4ece-cd6d-08d99eadebd2',
            'email': 'benjamin.guelle@gmail.com', 'firstname': 'Benjamin', 'lastname': 'GUELLE', 'mobilephone': '',
            'registrationNumber': '123456', 'streetAddress': '12 rue des mimosas', 'additionalAddress': '',
            'postalCode': '75001', 'city': 'Paris', 'countryCode': 'EE', 'title': 'Mr', 'isAccountValidated': true,
            'isActive': true, 'activeSundaysAndHolidays': false, 'userPath': 'BillPicture',
            'createdOn': '2021-11-15T12:20:32.0904809', 'modifiedOn': '2022-03-12T15:20:07.8014949',
            'ibanLastNumbers': '0143', 'numberOfWorkingDays': 0, 'firstRightDate': '2022-01-07T00:00:00',
            'paymentRequestsList': [],
          },
        }],
      }], 'errorCode': 'None',
    } as unknown as PaymentRequestListArrayRequestResult);
  }

}