import Yup from '../../../i18n/validation';
import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { useInstances } from 'react-ioc';
import { OrganizationInformationsModel } from '@assets/models/organizationInformations/OrganizationInformations.model';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { Select } from '../../../ui/Select';
import { MenuItem } from '@mui/material';

export const MINISTRY = 'organizationMinistryId';
export const ADMINISTRATION = 'organizationAdministrationId';
export const BOP = 'organizationBopId';

const OrganizationFormComponent: FunctionFormComponent = observer(({
                                                                     formik, beneficiary, isEditMode = false,
                                                                     disableModification = false,
                                                                   }) => {
  const [agenciesStore]: [AgenciesStore] = useInstances<[AgenciesStore]>(AgenciesStore);

  const agencyOrganizationInformations: OrganizationInformationsModel[] = agenciesStore.currentAgencyOrganizationInformations;
  const agencyMinistries: OrganizationInformationsModel[] = useMemo(() => agencyOrganizationInformations.filter(orga => orga.type === 'ministry'), [agencyOrganizationInformations]);
  const agencyAdministrations: OrganizationInformationsModel[] = useMemo(() => agencyOrganizationInformations.filter(orga => orga.type === 'administration'), [agencyOrganizationInformations]);
  // @todo : filter agencyBops with current selected agency admnistration children
  const agencyBops: OrganizationInformationsModel[] = useMemo(() => agencyOrganizationInformations.filter(orga => orga.type === 'bop'), [agencyOrganizationInformations]);

  // SETUP INITIAL FORM VALUES
  useEffect(() => {
    if (!!agencyMinistries.length && !beneficiary?.organizationMinistryId) {
      formik.setFieldValue(MINISTRY, agencyMinistries[0].uid);
    }
    if (!!agencyAdministrations.length && !beneficiary?.organizationAdministrationId) {
      formik.setFieldValue(ADMINISTRATION, agencyAdministrations[0].uid);
    }
    if (!!agencyBops.length && !beneficiary?.organizationBopId) {
      formik.setFieldValue(BOP, agencyBops[0].uid);
    }
  }, [agencyMinistries, agencyAdministrations, agencyBops, beneficiary]);

  return (
    <div className={'space-y-2'}>
      <h3 className={'font-bold'}>
        Organisation
      </h3>

      <Select
        value={formik.values[ADMINISTRATION]}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(ADMINISTRATION, evt.target.value)}
        id={ADMINISTRATION}
        label="Administration"
        fullWidth
        required
        disabled={disableModification}
      >
        {agencyAdministrations.map((item) => (
          <MenuItem key={item.uid} value={item.uid}>{item.name}</MenuItem>
        ))}
      </Select>

      <Select
        value={formik.values[MINISTRY]}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(MINISTRY, evt.target.value)}
        id={MINISTRY}
        disabled={disableModification}
        label="Ministère"
        fullWidth
        required
      >
        {agencyMinistries.map((item) => (
          <MenuItem key={item.uid} value={item.uid}>{item.name}</MenuItem>
        ))}
      </Select>

      <Select
        value={formik.values[BOP]}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(BOP, evt.target.value)}
        id={BOP}
        disabled={disableModification}
        label="BOP"
        fullWidth
        required
      >
        {agencyBops.map((item) => (
          <MenuItem key={item.uid} value={item.uid}>{item.name}</MenuItem>
        ))}
      </Select>
    </div>
  );
}) as FunctionFormComponent;

OrganizationFormComponent.getValidationSchema = () => {
  return Yup.object().shape({
    [MINISTRY]: Yup.string().required(),
    [ADMINISTRATION]: Yup.string().required(),
    [BOP]: Yup.string().required(),
  });
};

OrganizationFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [MINISTRY]: beneficiary?.organizationMinistryId ?? '',
    [ADMINISTRATION]: beneficiary?.organizationAdministrationId ?? '',
    [BOP]: beneficiary?.organizationBopId ?? '',
  };
};

export default OrganizationFormComponent;