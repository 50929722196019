import { makeStyles } from '@mui/styles';
import circleBackground from '../../assets/circle-background.svg';
import { COLORS, FONT } from '../../Style/Style';

export const useCGVStyles = makeStyles(() => ({
  root: {
    padding: '60px 130px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 210px)',
    background: `url(${circleBackground})  no-repeat`,
  },
  title: {
    fontFamily: FONT.secondary,
    textAlign: 'center',
    fontSize: '30px',
    color: COLORS.textPrimary,
    margin: '60px 0px',
  },
  subtitle: {
    fontFamily: FONT.secondary,
    fontSize: '20px',
    color: COLORS.textPrimary,
    margin: '60px 0px',
  },
  content: {
    fontSize: '14px',
    textAlign: 'justify',
    color: COLORS.textPrimary,
    paddingBottom: '20px',
  },
  logo: {
    cursor: 'pointer',
  },
  subtitleList: {
    fontFamily: FONT.secondary,
    fontSize: '18px',
    color: COLORS.textPrimary,
    paddingBottom: '10px',
  },
  textList: {
    fontSize: '14px',
    color: COLORS.textPrimary,
    paddingBottom: '10px',
  },
  textBottom: {
    textAlign: 'center',
    fontFamily: FONT.secondary,
  },
  textBold: {
    fontWeight: 'bold',
  },
}));
