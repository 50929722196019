import { BeneficiaryCreditModel, HydratedBeneficiaryCreditData, OrderModel } from '../../models/orders/Order.model';
import { VatModel } from '../../models/vat/Vat.model';
import { OrderBankWireReference } from '../../models/orders/OrderBankWireReference.model';
import { convertCentToEuro } from '../functions/convertCentToEuro.util';
import { ConfirmAgencyOrderResponse } from '../../responses/orders/ConfirmAgencyOrder.response';
import { convertInvoiceFromCentsToEuros } from '../invoices/invoice.utils';
import { GetAgencyOrderProvisionSummaryResponse } from '../../responses/orders/GetAgencyOrderProvisionSummary.response';
import {
  convertBeneficiaryClassificationProvisionSummaryFromCentsToEuros,
} from '../agencies/beneficiaryClassification.util';

export function getExcludingTaxesOrderFeesAmount(credits: HydratedBeneficiaryCreditData[]): number {
  return credits.reduce((amount: number, credit: HydratedBeneficiaryCreditData) => {
    if (credit.beneficiaryClassification.creditRedemptionMethod === 'DISCRETE') {
      return 0;
    }

    return amount + credit.beneficiaryClassification.contractSubscriptionFee;
  }, 0);
}

export function getVatOrderFeesAmount(credits: HydratedBeneficiaryCreditData[], vat: VatModel): number {
  const excludingTaxesFeesAmount = getExcludingTaxesOrderFeesAmount(credits);
  const vatRate = (vat.rate / 100);

  return excludingTaxesFeesAmount * vatRate;
}

export function getIncludingTaxesOrderFeesAmount(credits: HydratedBeneficiaryCreditData[], vat: VatModel): number {
  const excludingTaxesFeesAmount = getExcludingTaxesOrderFeesAmount(credits);
  const vatAmount = getVatOrderFeesAmount(credits, vat);

  return excludingTaxesFeesAmount + vatAmount;
}

export function getTotalOfCreditsAmount(beneficiaryCredits: HydratedBeneficiaryCreditData[]): number {
  return beneficiaryCredits.reduce((amount, credit) => amount + getBeneficiaryCreditAmount(credit), 0);
}

export function getProvisionAmount(credits: HydratedBeneficiaryCreditData[]): number {
  return credits.reduce((amount: number, credit: HydratedBeneficiaryCreditData) => {
    return amount + getBeneficiaryCreditAmount(credit);
  }, 0);
}

export function getBeneficiaryCreditAmount(credit: HydratedBeneficiaryCreditData): number {
  const { numberOfRechargingDays, beneficiaryClassification } = credit;
  if (beneficiaryClassification.creditRedemptionMethod === 'DISCRETE') {
    return 0;
  }

  const { contractAgencyPart: provisionDailyAmount } = beneficiaryClassification;

  return provisionDailyAmount * numberOfRechargingDays;
}

export function getContractFacialValue(credit: HydratedBeneficiaryCreditData): number {
  const { beneficiaryClassification } = credit;

  return beneficiaryClassification.creditRedemptionMethod === 'DISCRETE'
    ? 0
    : beneficiaryClassification.contractFacialValue;
}

export function getContractAgencyPart(credit: HydratedBeneficiaryCreditData): number {
  const { beneficiaryClassification } = credit;

  return beneficiaryClassification.creditRedemptionMethod === 'DISCRETE'
    ? 0
    : beneficiaryClassification.contractAgencyPart;
}

export function getContractSubscriptionFee(credit: HydratedBeneficiaryCreditData): number {
  const { beneficiaryClassification } = credit;

  return beneficiaryClassification.contractSubscriptionFee;
}

export function convertOrderFromCentsToEuros(order: OrderModel): OrderModel {
  const { feesAmount, ...rest } = order;
  return {
    ...rest,
    feesAmount: convertCentToEuro(feesAmount),
  };
}

export function convertConfirmAgencyOrderResponse(response: ConfirmAgencyOrderResponse): ConfirmAgencyOrderResponse {
  const { mainInvoice, feesInvoice, bankWireReference } = response;
  const convertedResponse: ConfirmAgencyOrderResponse = {
    mainInvoice: convertInvoiceFromCentsToEuros(mainInvoice),
  };

  if (feesInvoice) {
    convertedResponse.feesInvoice = convertInvoiceFromCentsToEuros(feesInvoice);
  }

  if (bankWireReference) {
    convertedResponse.bankWireReference = convertOrderBankWireReferenceFromCentsToEuros(bankWireReference);
  }

  return convertedResponse;
}

export function convertOrderBankWireReferenceFromCentsToEuros(orderBankWireReference: OrderBankWireReference): OrderBankWireReference {
  const { expectedBankWireAmount, expectedFeesBankWireAmount, ...rest } = orderBankWireReference;
  const convertedOrderBankWireReference: OrderBankWireReference = {
    ...rest,
    expectedBankWireAmount: convertCentToEuro(expectedBankWireAmount),
  };

  if (typeof expectedFeesBankWireAmount === 'number') {
    convertedOrderBankWireReference.expectedFeesBankWireAmount = convertCentToEuro(expectedFeesBankWireAmount);
  }

  return convertedOrderBankWireReference;
}

export function convertBeneficiaryCreditFromCentsToEuros(beneficiaryCredit: BeneficiaryCreditModel): BeneficiaryCreditModel {
  const {
    contractFacialValue, contractAgencyPart, contractSubscriptionFee, provisionAmount, ...rest
  } = beneficiaryCredit;

  return {
    ...rest,
    contractFacialValue: convertCentToEuro(contractFacialValue),
    contractAgencyPart: convertCentToEuro(contractAgencyPart),
    contractSubscriptionFee: convertCentToEuro(contractSubscriptionFee),
    provisionAmount: convertCentToEuro(provisionAmount),
  };
}

export function convertGetAgencyOrderProvisionSummaryResponseFromCentsToEuros(response: GetAgencyOrderProvisionSummaryResponse): GetAgencyOrderProvisionSummaryResponse {
  const { classifications, ...rest } = response;
  return {
    ...rest,
    classifications: classifications.map(convertBeneficiaryClassificationProvisionSummaryFromCentsToEuros),
  };
}
