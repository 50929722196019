import React, { FunctionComponent, useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import { ProvisionStore } from '../../../Stores/Provision.store';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { OptionalTimePeriodRequest } from '../../../assets/requests/TimePeriod.request';
import { BeneficiaryCreditModel } from '@assets/models/orders/Order.model';
import { IBeneficiaryDetailsProps } from '../../../Models/Interfaces/IBeneficiaryDetailsProps.model';
import { Select } from 'ui/Select';
import { Button } from '../../../ui/Buttons/Button';
import { InputField } from '../../../ui/Input';
import { ReactComponent as SearchSvg } from '../../../assets/svg/glasses.svg';
import { DataSpreadsheet } from '../../../ui/DataSpreadsheet';

interface RowData {
  period: string;
  creationDate: string;
  numberOfRechargingDays: number;
  contractAgencyPart: number;
}

const BeneficiaryProvisionsComponent: FunctionComponent<IBeneficiaryDetailsProps> = observer((props: IBeneficiaryDetailsProps) => {
  const provisionStore: ProvisionStore = useInstance(ProvisionStore);
  const isFetching = provisionStore.isLoading;

  const [data, setData] = useState<BeneficiaryCreditModel[]>([]);
  const [filteredData, setFilteredData] = useState<BeneficiaryCreditModel[]>([]);

  const { t } = useTranslation('beneficiaries');

  const [search, setSearch] = useState('');
  const [sinceDate, setSinceDate] = React.useState('0');
  const currencyFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  const dateOptions = { year: 'numeric', month: 'long' } as const;

  const applySearch = (newSearch: string, beneficiaryProvisions: BeneficiaryCreditModel[]) => {
    setSearch(newSearch);

    const filterData: BeneficiaryCreditModel[] = beneficiaryProvisions.filter((provision) => {
      const transactionDate: Date = new Date(provision.creationDate);

      return (
        (provision.creationDate && transactionDate.toLocaleDateString().search(newSearch) !== -1)
        || (new Date(provision.period).toLocaleDateString('fr-FR', dateOptions).search(newSearch) !== -1)
        || provision.numberOfRechargingDays.toLocaleString().search(newSearch) !== -1
        || (provision.numberOfRechargingDays * provision.contractAgencyPart).toLocaleString().search(newSearch) !== -1
      );
    });

    const beneficiaryProvisionSortByDateDesc: BeneficiaryCreditModel[] =
      filterData.sort((provision_a: BeneficiaryCreditModel, provision_b: BeneficiaryCreditModel) => provision_b.creationDate - provision_a.creationDate);

    setFilteredData(beneficiaryProvisionSortByDateDesc);
  };

  async function getProvisions(fromDate: number) {
    const request: OptionalTimePeriodRequest = {
      from: fromDate,
    };

    await provisionStore.getBeneficiaryProvisions(props.beneficiary.agencyId, props.beneficiary.uid, request);

    if (!provisionStore.isLoading && provisionStore.getBeneficiaryProvisionsStatus === 'SUCCESS') {
      setData(provisionStore.beneficiaryProvisions);
      applySearch(search, provisionStore.beneficiaryProvisions);
    }
  }

  useEffect(() => {
    const currentDate: Date = new Date();
    const fromDate = currentDate.setMonth(currentDate.getMonth() - 3);

    getProvisions(fromDate);
  }, [props.beneficiary]);

  const dataGridColumns: GridColDef<RowData>[] = [
    {
      field: 'invoiceDate',
      headerName: 'Période',
      flex: 1,
      valueGetter: (params: GridRenderCellParams<RowData>) => params.row.period,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div
            style={{ width: '100%' }}>{new Date(this.valueGetter(params)).toLocaleDateString('fr-FR', dateOptions)} </div>
        );
      },
    },
    {
      field: 'creationDate',
      headerName: 'Date de rechargement',
      flex: 1.1,
      valueGetter: (params: GridRenderCellParams<RowData>) => params.row.creationDate,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>{moment(new Date(this.valueGetter(params))).format('DD/MM/YYYY')} </div>
        );
      },
    },
    {
      field: 'nbDays',
      headerName: 'Détail',
      flex: 1,
      valueGetter: (params: GridRenderCellParams<RowData>) => `${t('day', { count: params.row.numberOfRechargingDays })} x ${currencyFormat.format(params.row.contractAgencyPart)}`,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div
            style={{ width: '100%' }}>{this.valueGetter(params)} </div>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Montant rechargé',
      flex: 1.1,
      valueGetter: (params: GridRenderCellParams<RowData>) => params.row.numberOfRechargingDays * params.row.contractAgencyPart,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div
            style={{ width: '100%' }}>{currencyFormat.format(this.valueGetter(params))} </div>
        );
      },
    },
  ];

  return (
    <div className={'space-y-2'}>
      <h3 className={'font-bold'}>
        Ensemble des droits attribués sur la période
      </h3>
      <div className={'flex gap-4'}>
        <Select
          value={sinceDate}
          onChange={(evt) => {
            const currentDate: Date = new Date();
            let from: number;
            switch (evt.target.value) {
              case '1':
                from = currentDate.setMonth(currentDate.getMonth() - 6);
                break;
              case '2':
                from = currentDate.setFullYear(currentDate.getFullYear() - 1);
                break;
              case '0':
              default:
                from = currentDate.setMonth(currentDate.getMonth() - 3);
            }

            setSinceDate(evt.target.value as string);
            getProvisions(from);
          }}
          labelId="label"
          id="select"
        >
          <MenuItem value="0">{t('since3months')}</MenuItem>
          <MenuItem value="1">{t('since6months')}</MenuItem>
          <MenuItem value="2">{t('since1year')}</MenuItem>
        </Select>

        <InputField
          placeholder={t('paybackSearchPlaceholder')}
          startIcon={<SearchSvg className={'w-4 h-4'} />}
          onChange={(e) => applySearch(e.target.value, data)}
          value={search}
        />

      </div>
      <DataSpreadsheet
        idKey={'orderId'}
        rows={filteredData}
        columns={dataGridColumns}
        disableRowSelectionOnClick
        loading={isFetching}
      />
      <div className={'flex justify-end'}>
        <Button onClick={props.onClose}>
          {t('close')}
        </Button>
      </div>
    </div>
  );
});

export default BeneficiaryProvisionsComponent;
