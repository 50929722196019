import { makeStyles } from '@mui/styles';
import { COLORS } from '../../Style/Style';

export const useBeneficiariesUploadTrackerStyles = makeStyles({
  wrapper: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
    zIndex: 2147483647,
  },
  toastsHost: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: '1em',
    alignItems: 'flex-start',
  },
  toast: {
    padding: '1em',
    background: 'white',
    borderRadius: '10px',
    boxShadow: '-3px 5px 10px 0 #00000045',
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    pointerEvents: 'all',
  },
  icon: {
    width: '32px',
    height: '32px',
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
  },
  button: {
    border: 'none',
    background: 'none',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: COLORS.textMuted,
    cursor: 'pointer',
    transition: 'all 0.2s',
    padding: '8px 4px',
    borderRadius: '3px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      color: COLORS.textPrimary,
    },
  },
});
