import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import logo from '../../assets/logo-text-new.svg';
import facebook from '../../assets/facebook.svg';
import twitter from '../../assets/twitter.svg';
import instagram from '../../assets/instagram.svg';
import linkedin from '../../assets/linkedin.svg';
import useDialogStyles from '../../Style/MuiStyles/Dialog';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../App';
import FooterContactComponent from './FooterContact/FooterContactComponent';
import { useNavigate } from 'react-router-dom';
import { useFooterComponentStyles } from "./FooterStyles";

export interface IFooterComponentProps {
  user?: IUser;
}

function FooterComponent (props: IFooterComponentProps) {
  const footerClasses = useFooterComponentStyles();
  const dialogClasses = useDialogStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('footer');
  const navigate = useNavigate();

  return (
    <div className={footerClasses.root}>
      <div className={footerClasses.blockLarge}>
        <img width={100} alt='logo' src={logo} />
        <div className={footerClasses.leitmotif}>{t('leitmotif')}</div>
        <div className={footerClasses.address} >{`© ${new Date().getFullYear()} ${t('address')}`}</div>
      </div>
      <div className={footerClasses.block}>
        <div className={footerClasses.title}>{t('assistance')}</div>
          <div className={footerClasses.content}>
              <a href="mailto:contact-pro@stayopen.io">contact-pro@stayopen.io</a>
          </div>
        <div className={footerClasses.logos}>
          <img width={25} alt='logo facebook' src={facebook} style={{color:'red'}}/>
          <img width={25} alt='logo twitter' src={twitter} />
          <img width={25} alt='logo instagram' src={instagram} />
          <img width={25} alt='logo linkedin' src={linkedin} />
        </div>
      </div>
      <div className={footerClasses.block}>
        <a className={footerClasses.title}>{t('company')}</a>
        <a className={footerClasses.content}>{t('about')}</a>
        <a className={footerClasses.content} href='https://www.stayopen.io/' target='_blank' rel='noreferrer'>{t('website')}</a>
      </div>
      <div className={footerClasses.block}>
        <a className={footerClasses.title}>{t('confidentiality')}</a>
        <a className={footerClasses.content} href='/rgpd' target='_blank' rel='noreferrer'>{t('personalData')}</a>
        <a className={footerClasses.content} href='/cgv' target='_blank' rel='noreferrer'>{t('cgu')}</a>
        <a className={footerClasses.content} href='/legalnotice' target='_blank' rel='noreferrer'>{t('ml')}</a>
      </div>
      <Dialog
        BackdropProps={{ classes: { root: footerClasses.backDrop } }}
        className={dialogClasses.dialogPaper} fullWidth maxWidth='md' open={isOpen} onClose={() => setIsOpen(false)}>
        <FooterContactComponent user={props.user} onClose={() => setIsOpen(false)} />
      </Dialog>
    </div>
  );
}

export default FooterComponent;
