import React, { useCallback, useState } from 'react';
import { IMandateSignatureDialog } from './MandateSignatureDialog';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { MandateStore } from '../../../../Stores/Mandate.store';
import { AgenciesStore } from '../../../../Stores/Agencies.store';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../ui/Buttons/Button';
import { ReactComponent as ArrowSvg } from '../../../../assets/svg/arrows/arrowRight.svg';
import { ReactComponent as MandateSvg } from '../../../../assets/svg/mandate/mandateSignature.svg';

const MandateSignaturePending = observer((props: IMandateSignatureDialog) => {
  const [mandateStore, agenciesStore] = useInstances(MandateStore, AgenciesStore);
  const { t } = useTranslation('mandate');
  const [isCreating, setIsCreating] = useState(false);

  const addMandate = useCallback(() => {
    setIsCreating(true);
    mandateStore.createMandate(agenciesStore.currentAgency?.uid);
    setIsCreating(false);
  }, []);

  return (
    <>
      <div className={'pt-32 flex flex-col justify-center items-center space-y-16'}>
        <MandateSvg title={'mandate signature'} className={'w-32 h-32'} />

        <p className={'text-sm text-center'}>
          {t('description')}<br/>
          {t('description2')}
        </p>
      </div>

      <div className={'flex justify-end gap-4 pt-10'}>
        <Button
          variant='outlined'
          onClick={props.onClose}
          loading={isCreating}
        >
          {t('cancelBtn')}
        </Button>

        <Button
          onClick={addMandate}
          loading={isCreating}
        >
          {t('submitBtn')}
          <ArrowSvg className={'w-4 h-4 text-white'} />
        </Button>
      </div>
    </>
  );
});

export default MandateSignaturePending;
