import axios, { AxiosResponse } from 'axios';
import { errorStore } from '../Stores/Error.store';

const refreshTokenErrorStack: ((error: any) => void)[] = [];

export const httpInterceptors = {
  request: axios.interceptors.request,
  response: axios.interceptors.response,
};

httpInterceptors.response.use(
  (response: AxiosResponse) => Promise.resolve(response),
  async (error: any) => {
    if (error.response?.status === 406) {
      // logout user
      refreshTokenErrorStack.forEach((eventHandler: (error) => void) => eventHandler(error));
      error = new Error('Votre session a expirée. Vous devez vous reconnecter.');
      errorStore.catchError(error, true);
    } else {
      errorStore.catchError(error);
    }

    return Promise.reject(error);
  },
);

export function onRefreshTokenError(eventHandler: (error: any) => void) {
  refreshTokenErrorStack.push(eventHandler);
};
