import { MenuItem } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { PaybackModel } from '../../../assets/models/paybacks/Payback.model';
import { GetBeneficiaryPaybacksRequest } from '../../../assets/requests/paybacks/GetBeneficiaryPaybacks.request';
import { BeneficiaryPaybackStore } from '../../../Stores/BeneficiaryPayback.store';
import { IBeneficiaryDetailsProps } from '../../../Models/Interfaces/IBeneficiaryDetailsProps.model';
import { Select } from '../../../ui/Select';
import { DataSpreadsheet } from '../../../ui/DataSpreadsheet';
import { Button } from '../../../ui/Buttons/Button';
import { InputField } from '../../../ui/Input';
import { ReactComponent as SearchSvg } from '../../../assets/svg/glasses.svg';

type LocalStore = [BeneficiaryPaybackStore];

const BeneficiaryPaybackComponent: FunctionComponent<IBeneficiaryDetailsProps> = observer((props: IBeneficiaryDetailsProps) => {
  const [beneficiaryPaybackStore]: [BeneficiaryPaybackStore] = useInstances<LocalStore>(BeneficiaryPaybackStore);
  const isFetching: boolean = beneficiaryPaybackStore.isLoading;

  const { t } = useTranslation('beneficiaries');

  const [data, setData] = useState<PaybackModel[]>([]);
  const [filteredData, setFilteredData] = useState<PaybackModel[]>([]);

  const [search, setSearch] = useState('');
  const [sinceDate, setSinceDate] = React.useState('0');

  const applySearch = (newSearch: string, paybacks: PaybackModel[]) => {
    setSearch(newSearch);

    const filterData: PaybackModel[] = paybacks.filter((payback: PaybackModel) => {
      const transactionDate: Date = new Date(payback.transactionDate);
      const providerPayoutRequestDate: Date = new Date(payback.providerPayoutRequestDate);

      return (
        (payback.transactionDate && transactionDate.toLocaleDateString().search(newSearch) !== -1) ||
        (payback.providerPayoutRequestDate && providerPayoutRequestDate.toLocaleDateString().search(newSearch) !== -1) ||
        payback.amount.toString().search(newSearch) !== -1
      );
    });

    setFilteredData(filterData);
  };

  async function getPaybacks(fromDate: number): Promise<void> {
    const request: GetBeneficiaryPaybacksRequest = {
      status: 'ACCEPTED',
      from: fromDate,
    };

    await beneficiaryPaybackStore.getBeneficiaryPaybacks(props.beneficiary.uid, request);

    if (!beneficiaryPaybackStore.isLoading && beneficiaryPaybackStore.getBeneficiaryPaybacksStatus === 'SUCCESS') {
      setData(beneficiaryPaybackStore.beneficiaryPaybacks);
      applySearch(search, beneficiaryPaybackStore.beneficiaryPaybacks);
    }
  }

  useEffect(() => {
    const currentDate: Date = new Date();
    const fromDate: number = currentDate.setMonth(currentDate.getMonth() - 3);
    getPaybacks(fromDate);
  }, [props.beneficiary]);

  const dataGridColumns: GridColDef[] = [
    {
      field: 'transactionDate',
      headerName: 'Date de transaction',
      flex: 2,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>{new Date(params.value as number).toLocaleDateString('fr-FR')} </div>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Montant à rembourser',
      flex: 2,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>
            {new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            }).format(params.value as number)}
          </div>
        );
      },
    },
    {
      field: 'providerPayoutRequestDate',
      headerName: 'Date de validation',
      flex: 3,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>
            {params.value ? new Date(params.value as number).toLocaleDateString('fr-FR') : ''}
          </div>
        );
      },
    },
  ];

  return (
    <div className={'space-y-2'}>
      <h3 className={'font-bold'}>
        Ensemble des remboursements versés sur la période
      </h3>
      <div className={'flex gap-4'}>
        <Select
          value={sinceDate}
          onChange={(evt) => {
            const currentDate = new Date();
            let from: number;
            switch (evt.target.value) {
              case '1':
                from = currentDate.setMonth(currentDate.getMonth() - 6);
                break;
              case '2':
                from = currentDate.setFullYear(currentDate.getFullYear() - 1);
                break;
              case '3':
                from = new Date(0).getTime();
                break;
              case '0':
              default:
                from = currentDate.setMonth(currentDate.getMonth() - 3);
            }

            setSinceDate(evt.target.value as string);
            getPaybacks(from);
          }}
          labelId="label"
          id="select"
        >
          <MenuItem value="0">{t('since3months')}</MenuItem>
          <MenuItem value="1">{t('since6months')}</MenuItem>
          <MenuItem value="2">{t('since1year')}</MenuItem>
          <MenuItem value="3">{t('sinceBeginning')}</MenuItem>
        </Select>

        <InputField
          placeholder={t('paybackSearchPlaceholder')}
          startIcon={<SearchSvg className={'w-4 h-4'}/>}
          onChange={(e) => applySearch(e.target.value, data)}
          value={search}
        />
      </div>
      <DataSpreadsheet
        rows={filteredData}
        columns={dataGridColumns}
        disableRowSelectionOnClick
        loading={isFetching}
      />
      <div className={'flex justify-end'}>
        <Button onClick={props.onClose}>
          {t('close')}
        </Button>
      </div>
    </div>
  );
});

export default BeneficiaryPaybackComponent;
