import React from 'react';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { AgenciesStore } from '../../../../Stores/Agencies.store';
import { Spinner } from '../../../../ui/Spinner';

type LocalStore = [AgenciesStore];

const MethodBankTransfer = observer(() => {
  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const {paymentBankAccountMain, isBankAccountsLoading} = agenciesStore;
  const { t } = useTranslation('payment');

  return (
    <div className={'bg-background rounded-br10 p-4 space-y-6'}>
      <h2 className={'m-0 text-base'}>
        {t('bankTransfer.title')}
      </h2>
      <p>{t('bankTransfer.subTitle')}</p>
      <div className={'flex flex-col border-solid !border rounded-br10 border-primary/50 p-4 space-y-2'}>
        <p>{t('bankTransfer.ibanTitle')}</p>
        <p className={'font-bold'}>
          {
            isBankAccountsLoading
              ? <Spinner />
              : t('bankTransfer.iban', { iban: paymentBankAccountMain?.iban ?? '-' })
          }
        </p>
      </div>
      <p>{t('bankTransfer.note')}</p>
    </div>
  );
});

export default MethodBankTransfer;
