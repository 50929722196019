import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { RestitutionMethod, RightsReconductionType } from '../../../../assets/models/agencies/Agency.model';
import moment from 'moment/moment';
import { Button } from '../../../../ui/Buttons/Button';

interface DefinedRightsReconductionTypePanelProps {
  endingMillesimeYear: number;
  selectedRightsReconductionType: RightsReconductionType;
  restitutionMethod: RestitutionMethod;
  restitutionIban?: string;
  deadlineDate: Date;
  allowToUpdate: boolean;
  isUpdating: boolean;
  onModifyButtonClicked: () => void;
}

const DefinedRightsReconductionTypePanel: FunctionComponent<DefinedRightsReconductionTypePanelProps> = (props) => {
  const { t } = useTranslation('millesime');

  const {
    endingMillesimeYear,
    selectedRightsReconductionType,
    restitutionMethod,
    restitutionIban,
    deadlineDate,
    allowToUpdate,
    isUpdating,
    onModifyButtonClicked,
  } = props;

  const formattedDate = moment(deadlineDate).format('DD MMMM YYYY');
  const shouldShowRestitutionIban = (selectedRightsReconductionType === 'DROP' && restitutionMethod === 'RESTITUTION_IBAN_PAYOUT');

  const getAccordionSummaryText = (selectedRightsReconductionType: RightsReconductionType, restitutionMethod: RestitutionMethod) => {
    return selectedRightsReconductionType === 'KEEP'
      ? t('definedRightsReconductionTypePanel.definedTypeDescription.KEEP')
      : t(`definedRightsReconductionTypePanel.definedTypeDescription.DROP.${restitutionMethod}`);
  };

  return (
    <div className={'p-5 rounded-br10 bg-background'}>
      <div className={'lex flex-wrap justify-center gap-2 px-4 py-6'}>
        <Typography>
          {t('definedRightsReconductionTypePanel.title', { endingMillesimeYear })}
        </Typography>
      </div>
      <div className={'flex flex-col'}>
        <Accordion
          className={'bg-inherit border-none shadow-none before:hidden'}
          defaultExpanded={allowToUpdate}
          disabled={!allowToUpdate}
          sx={{
            '& .MuiPaper-root.MuiAccordion-root.Mui-disabled': {
              backgroundColor: 'inherit',
            },
            '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-disabled': {
              opacity: 1,
            },
          }}
        >
          <AccordionSummary
            expandIcon={allowToUpdate && <ExpandMore/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={'grid gap-x-[100px] gap-y-[20px] grid-cols-[600px_240px_auto] items-end w-full text-primary text-sm md:grid-cols-1'}>
              <div>
                <Typography variant="inherit" component="div">
                  {getAccordionSummaryText(selectedRightsReconductionType, restitutionMethod)}
                </Typography>
              </div>
              <div>
                {shouldShowRestitutionIban &&
                    <div className={'flex flex-col'}>
                        <Typography variant="inherit" component="div" className={'text-muted text-sm mb-2'}>
                          {t('definedRightsReconductionTypePanel.restitutionIbanLabel')}
                        </Typography>
                        <Typography variant="inherit" component="div">
                          {restitutionIban || '-'}
                        </Typography>
                    </div>
                }
              </div>
            </div>

          </AccordionSummary>
          <AccordionDetails className={'flex justify-end items-center gap-4'}>
            {allowToUpdate
              ? <>
                  <Typography variant={'inherit'} className={'text-muted text-sm'}>
                    {t('definedRightsReconductionTypePanel.infoMessage', { deadlineDate: formattedDate })}
                  </Typography>
                  <Button
                    onClick={onModifyButtonClicked}
                    disabled={isUpdating}
                    loading={isUpdating}
                  >
                    {t('definedRightsReconductionTypePanel.submitButtonLabel')}
                  </Button>
                </>
              : <></>
            }
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default DefinedRightsReconductionTypePanel;
