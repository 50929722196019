import { convertEuroToCent } from '../functions/convertEuroToCent.util';
import { convertCentToEuro } from '../functions/convertCentToEuro.util';
import { CreateAgencyContractRequest } from '../../requests/agencies/CreateAgencyContract.request';
import { UpdateAgencyContractRequest } from '../../requests/agencies/UpdateAgencyContract.request';

interface ContractPartForLinearMethod {
  contractSubscriptionFee?: number;
  contractFacialValue?: number;
  contractAgencyPart?: number;
}

interface ContractPartForDiscreteMethod {
  contractSubscriptionFee?: number;
  subAmount?: number;
  pimAmount?: number;
  beneficiaryMinimumThreshold?: number;
}

export function convertCreateOrUpdateAgencyContractRequestFromEurosToCents<T extends CreateAgencyContractRequest | UpdateAgencyContractRequest>(request: T): T {
  const { contractFacialValue, contractAgencyPart, contractSubscriptionFee } = request;
  const contractPartInCents = convertContractPartValuesFromEurosToCentsForLinearMethod({
    contractFacialValue, contractAgencyPart, contractSubscriptionFee,
  });

  return {
    ...request,
    ...contractPartInCents,
  };
}

export function convertContractPartValuesFromEurosToCentsForLinearMethod(contractPart: ContractPartForLinearMethod): ContractPartForLinearMethod {
  const { contractFacialValue, contractAgencyPart, contractSubscriptionFee } = contractPart;
  const contractPartInCents: ContractPartForLinearMethod = {};
  if (typeof contractFacialValue === 'number') {
    contractPartInCents.contractFacialValue = convertEuroToCent(contractFacialValue);
  }
  if (typeof contractAgencyPart === 'number') {
    contractPartInCents.contractAgencyPart = convertEuroToCent(contractAgencyPart);
  }
  if (typeof contractSubscriptionFee === 'number') {
    contractPartInCents.contractSubscriptionFee = convertEuroToCent(contractSubscriptionFee);
  }

  return contractPartInCents;
}

export function convertContractPartValuesFromCentsToEurosForLinearMethod(contractPart: ContractPartForLinearMethod): ContractPartForLinearMethod {
  const { contractFacialValue, contractAgencyPart, contractSubscriptionFee } = contractPart;
  const contractPartInEuros: ContractPartForLinearMethod = {};
  if (typeof contractFacialValue === 'number') {
    contractPartInEuros.contractFacialValue = convertCentToEuro(contractFacialValue);
  }
  if (typeof contractAgencyPart === 'number') {
    contractPartInEuros.contractAgencyPart = convertCentToEuro(contractAgencyPart);
  }
  if (typeof contractSubscriptionFee === 'number') {
    contractPartInEuros.contractSubscriptionFee = convertCentToEuro(contractSubscriptionFee);
  }

  return contractPartInEuros;
}

export function convertContractPartValuesFromEurosToCentsForDiscreteMethod(contractPart: ContractPartForDiscreteMethod): ContractPartForDiscreteMethod {
  const { subAmount, pimAmount, beneficiaryMinimumThreshold, contractSubscriptionFee } = contractPart;
  const contractPartInCents: ContractPartForDiscreteMethod = {};
  if (typeof subAmount === 'number') {
    contractPartInCents.subAmount = convertEuroToCent(subAmount);
  }
  if (typeof pimAmount === 'number') {
    contractPartInCents.pimAmount = convertEuroToCent(pimAmount);
  }
  if (typeof beneficiaryMinimumThreshold === 'number') {
    contractPartInCents.beneficiaryMinimumThreshold = convertEuroToCent(beneficiaryMinimumThreshold);
  }
  if (typeof contractSubscriptionFee === 'number') {
    contractPartInCents.contractSubscriptionFee = convertEuroToCent(contractSubscriptionFee);
  }

  return contractPartInCents;
}

export function convertContractPartValuesFromCentsToEurosForDiscreteMethod(contractPart: ContractPartForDiscreteMethod): ContractPartForDiscreteMethod {
  const { subAmount, pimAmount, beneficiaryMinimumThreshold, contractSubscriptionFee } = contractPart;
  const contractPartInEuros: ContractPartForDiscreteMethod = {};
  if (typeof subAmount === 'number') {
    contractPartInEuros.subAmount = convertCentToEuro(subAmount);
  }
  if (typeof pimAmount === 'number') {
    contractPartInEuros.pimAmount = convertCentToEuro(pimAmount);
  }
  if (typeof beneficiaryMinimumThreshold === 'number') {
    contractPartInEuros.beneficiaryMinimumThreshold = convertCentToEuro(beneficiaryMinimumThreshold);
  }
  if (typeof contractSubscriptionFee === 'number') {
    contractPartInEuros.contractSubscriptionFee = convertCentToEuro(contractSubscriptionFee);
  }

  return contractPartInEuros;
}