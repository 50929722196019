import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { COLORS, PageBlock } from '../Style/Style';
import { Button, MenuItem, Select } from '@mui/material';
import { provider, useInstances } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { useInvoiceCardStyles } from '../Component/InvoiceCard/InvoiceCardStyles';
import { getAllDocs, getDocsWhere } from '../Services/firebase.service';
import { AgenciesStore } from '../Stores/Agencies.store';
import { ContractedBusinessModel } from '../assets/models/discreteVouchers/ContractedBusiness.model';
import { getDownloadUrl } from '../Services/storage.service';
import { BusinessVouchersInvoiceModel } from '@assets/models/accountingDocuments/BusinessAccountingDocument.model';
import { FRENCH_MONTHS } from '../assets/utils/dates/dateTime.util';
import { useDebounce } from '../Hooks/useDebounce';
import { StyledSelect } from '../Style/MuiStyles/Select/Select';
import Downarrow from '../assets/down-arrow';
import InputComponent from '../Component/InputComponent';
import magnifyingGlass from '../assets/magnifying-glass.svg';
import { useDocumentsTabStyles } from './tabs/DocumentsTabStyles';
import { useTranslation } from 'react-i18next';
import { getTimeStampMinusMonth } from '../assets/utils/dates/getParsedDate.util';
import { Timestamp } from 'firebase/firestore';

const InvoiceCard = (props: { invoice: BusinessVouchersInvoiceModel }) => {
  const { invoice } = props;
  const { invoiceReference, totalAgencyParticipationAmount, month, year, storagePath } = invoice;
  const invoiceCardClasses = useInvoiceCardStyles({ width: '270px' });

  return (
    <div className={invoiceCardClasses.block}>
      <div>
        <div className={invoiceCardClasses.label}>
          Commerçant
        </div>
        <div className={invoiceCardClasses.value}>
          {invoice.businessCompanyName}
        </div>
        <div className={invoiceCardClasses.label}>
          Facture n°
        </div>
        <div className={invoiceCardClasses.value}>
          {invoiceReference}
        </div>
        <div className={invoiceCardClasses.label}>
          Période
        </div>
        <div className={invoiceCardClasses.value}>
          {FRENCH_MONTHS[month - 1]} {year}
        </div>
        <div className={invoiceCardClasses.label}>
          Montant TTC
        </div>
        <div className={invoiceCardClasses.value}>
          {String(totalAgencyParticipationAmount / 100).replace('.', ',')}€
        </div>
      </div>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={async () => {
          const downloadUrl = await getDownloadUrl(storagePath);
          window.open(downloadUrl);
        }}>
        Voir la facture et son annexe
      </Button>
    </div>
  );
};

const VouchersInvoicesComponent: FunctionComponent = provider()(observer(() => {
  const [
    agenciesStore,
  ]: [AgenciesStore] = useInstances(AgenciesStore);
  const [invoices, setInvoices] = useState([]);
  const [search, setSearch] = useState('');
  const [selectPeriodValue, setSelectPeriodValue] = useState('12');
  const [debouncedSearch] = useDebounce(search, 500);
  const documentsTabClasses = useDocumentsTabStyles();
  const { t } = useTranslation('accounting');

  useEffect(() => {
    const getInvoices = async (agencyUid: string): Promise<void> => {
      const contractedBusinesses: ContractedBusinessModel[] = await getAllDocs<ContractedBusinessModel>(`AGENCIES/${agencyUid}/CONTRACTED_BUSINESSES`) ?? [];
      const fetchedInvoices: BusinessVouchersInvoiceModel[] = (await Promise.all(contractedBusinesses.map(async (business) =>
        getDocsWhere<BusinessVouchersInvoiceModel>(`BUSINESSES/${business.uid}/ACCOUNTING_DOCUMENTS`, [
          {
            fieldName: 'agencyUid',
            operator: '==',
            value: agencyUid,
          },
          {
            fieldName: 'documentType',
            operator: '==',
            value: 'Invoice:FOOD_VOUCHER',
          }])))).flat();

      setInvoices(fetchedInvoices);
    };

    const agencyUid: string | undefined = agenciesStore?.currentAgency?.uid;
    if (!!agencyUid) {
      getInvoices(agencyUid);
    }
  }, [agenciesStore.currentAgency]);

  const normalizeString = (str: string) =>
    str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

  const filteredInvoices: BusinessVouchersInvoiceModel[] = useMemo(() => {
    const normalizedSearch = normalizeString(debouncedSearch);

    return invoices
      .filter(i =>
        (i.invoiceDate as Timestamp).toMillis() >= getTimeStampMinusMonth(+selectPeriodValue))
      .filter(
        ({ invoiceReference, businessCompanyName, totalAgencyParticipationAmount, invoiceDate, month }) => {
          const fieldsToSearch = [
            invoiceReference,
            businessCompanyName,
            String(totalAgencyParticipationAmount / 100),
            (invoiceDate as Timestamp).toDate().toLocaleDateString('fr-FR'),
            normalizeString(FRENCH_MONTHS[month - 1])
          ];

          return fieldsToSearch.some(field => normalizeString(field).includes(normalizedSearch));
        }
      )
      .sort((a, b) => {
        const dateDifference = (b.invoiceDate as Timestamp).toMillis() - (a.invoiceDate as Timestamp).toMillis();
        return dateDifference !== 0 ? dateDifference : a.businessCompanyName.localeCompare(b.businessCompanyName);
      });
  }, [invoices, debouncedSearch, selectPeriodValue]);

  return (
    <PageBlock>
      <h1>Factures conventions</h1>
      <p style={{ paddingTop: '1rem' }}>Liste des factures des commerçants conventionnés</p>
      <div className={documentsTabClasses.block}>
        <Select
          value={selectPeriodValue}
          onChange={(event) => setSelectPeriodValue(event.target.value as string)}
          labelId="label"
          id="select"
          input={<StyledSelect/>}
          IconComponent={(props) => Downarrow({
            svgColor: COLORS.white, ...props, className: documentsTabClasses.icon,
          })}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            disablePortal: true,
          }}
        >
          <MenuItem value="6">{t('since6months')}</MenuItem>
          <MenuItem value="12">{t('since1year')}</MenuItem>
          <MenuItem value="24">{t('since2years')}</MenuItem>
          <MenuItem value="120">{t('sinceBeginning')}</MenuItem>
        </Select>
        <div className={documentsTabClasses.search}>
          <InputComponent
            placeholder={t('invoiceSearchPlaceholder')}
            icon={magnifyingGlass}
            onChange={setSearch}
            value={search}
            iconLeft
          />
        </div>
      </div>
      <div style={{ display: 'flex', gap: '1.5rem', paddingTop: '1rem', flexWrap: 'wrap' }}>
        {!!filteredInvoices.length && filteredInvoices.map(invoice => (<InvoiceCard key={invoice.uid} invoice={invoice}/>))}
      </div>
    </PageBlock>
  );
}));

export default VouchersInvoicesComponent;