import React, { FC } from 'react';
import clsx from 'clsx';
import { ReactComponent as ArrowSvg } from './../../assets/svg/arrow.svg';
import { Link } from 'react-router-dom';

interface BackButtonProps {
  to?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const CLASSES = {
  base: 'flex items-center justify-center cursor-pointer rounded-full bg-background hover:bg-green disabled:opacity-50 disabled:cursor-not-allowed',
  animation: 'transition-colors duration-300 ease-in-out',
  size: 'w-10 h-10',
}

export const BackButton: FC<BackButtonProps> = ({ to, onClick, className, disabled }) => {
  const buttonContent = (
    <div
      className={clsx(
        CLASSES.base, CLASSES.animation, CLASSES.size, className
      )}
    >
      <ArrowSvg className="w-5 h-5 text-primary rotate-90" />
    </div>
  );

  if (to) {
    return (
      <Link to={to} className={disabled ? 'pointer-events-none' : ''}>
        {buttonContent}
      </Link>
    );
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type="button"
      className={clsx('border-none rounded-full', 'p-0')}
    >
      {buttonContent}
    </button>
  );
};