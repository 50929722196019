import React, { ReactNode } from 'react';
import clsx from 'clsx';

interface Props {
  children: ReactNode;
  className?: string;
}

export const PageBlock = ({ children, className }: Props) => {
  return (
    <div className={clsx('min-h-[calc(100%-40px)]', className)}>
      {children}
    </div>
  );
};
