import { InvoiceModel } from '../../models/invoices/invoice.model';
import { AgencyModel, PaymentDeadlineType } from '../../models/agencies/Agency.model';
import { BeneficiaryCredit, MinimalBeneficiaryCreditData } from '../../models/orders/Order.model';
import { matchesPattern } from '../functions/matchPattern.util';
import { convertCentToEuro } from '../functions/convertCentToEuro.util';

export function getInvoicePaymentDeadline(invoice: InvoiceModel, agency: AgencyModel): PaymentDeadlineType {
  if (invoice.invoiceType === 'DISSOCIATED_FEES' && agency.subscriptionPaymentDeadline === '30_DAYS') {
    return '30_DAYS';
  }

  return 'ON_BILL';
}

export function getProvisionsTotalDays(beneficiaryCredits: MinimalBeneficiaryCreditData[]): number {
  return beneficiaryCredits.reduce((accumulator, { numberOfRechargingDays }) => (accumulator + numberOfRechargingDays), 0);
}

export function getSubscriptionsBeneficiaryCount(beneficiaryCredits: BeneficiaryCredit[] | MinimalBeneficiaryCreditData[]): number {
  return beneficiaryCredits.length;
}

export function getInvoiceDate(rechargingYear: number, rechargingMonth: number): Date {
  return new Date(rechargingYear, rechargingMonth - 1);
}

export function isMainInvoiceReferenceLike(input: unknown): boolean {
  return matchesPattern(input, /^F\d{4}\d{6}SASOPEN$/);
}

export function isProvisionsInvoiceReferenceLike(input: unknown): boolean {
  return matchesPattern(input, /^R\d{4}\d{6}SASOPEN$/);
}

export function getMainReferenceFromProvisionReference(input: string): string {
  if (!isProvisionsInvoiceReferenceLike(input)) {
    return input;
  }

  return input.replace(/^R/, 'F');
}

export function convertInvoiceFromCentsToEuros(invoice: InvoiceModel): InvoiceModel {
  const {
    feesAmountHT,
    feesTotalVat,
    feesAmountTTC,
    provisionsAmount,
    netAmount,
    ...rest
  } = invoice;
  return {
    ...rest,
    feesAmountHT: convertCentToEuro(feesAmountHT),
    feesTotalVat: convertCentToEuro(feesTotalVat),
    feesAmountTTC: convertCentToEuro(feesAmountTTC),
    provisionsAmount: convertCentToEuro(provisionsAmount),
    netAmount: convertCentToEuro(netAmount),
  };
}
