import { manageError } from '../errors';

export function convertCentToEuro(valueInCent: unknown): number {
  if (!valueInCent) {
    return 0;
  }

  if (typeof valueInCent !== 'number' || isNaN(valueInCent)) {
    manageError({ code: 'invalid-argument', channel: 'STANDARD', message: 'type of value in cent must be a number' });
  }

  return valueInCent / 100;
}

export function convertCentToEuroString(valueInCents: string | number, showCurrency: boolean = true): string {
  let amountInCents = +valueInCents;

  if (isNaN(amountInCents)) {
    amountInCents = 0;
  }

  return (amountInCents / 100).toLocaleString('fr-FR', {
    minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'EUR',
    ...(showCurrency ? { style: 'currency' } : {}),
  });
}
