import React, { useRef } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { ReactComponent as PrinterSvg } from './../../../assets/svg/pinter.svg';
import { Button } from '../../../ui/Buttons/Button';

const PrintQRCodeButton = ({ title, qrCodeString, buttonLabel }) => {
  const qrRef = useRef<HTMLDivElement>();

  const displayPrintPopup = (): void => {
    const div: HTMLDivElement = qrRef.current;
    const elementStr = div.outerHTML;
    const popup = window.open('', 'popup', 'width=512,height=512');

    if (!popup) {
      return;
    }

    popup.document.body.innerHTML = `
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
      .print-wrapper {
        width: 3.5in;
        height: 2in;
        background-color: white;
        border: 1px solid black;
        border-radius: 5px;
        margin: 20px;
        padding: 0.2in;
        font-family: "Montserrat", sans-serif;
        font-size: 10pt;
        color: black;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .print-title {
        font-weight: bold;
        margin: 0 5pt 0 0;
        overflow: hidden;
        text-align: left;
      }
      .print-qr-container {
        display: flex;
        justify-content: flex-start;
        width: auto;
        height: auto;
      }
      .print-qr-code {
        width: 40%;
        height: auto;
      }
    </style>
    ${elementStr}
    `;

    const script = popup.document.createElement('script');
    script.innerHTML = 'window.print();';
    popup.document.body.appendChild(script);
  };

  return (
    <div>
      <Button onClick={displayPrintPopup}>
        <PrinterSvg className="w-10 h-10"/>
        {buttonLabel}
      </Button>

      <div ref={qrRef} className="hidden print-wrapper">
        {title && <p className="print-title">{title}</p>}
        <div className="print-qr-container">
          <QRCodeSVG
            value={qrCodeString}
            size={256}
            className="print-qr-code"
          />
        </div>
      </div>
    </div>
  );
};

export default PrintQRCodeButton;