import Snackbar from "@mui/material/Snackbar";
import React, { FC } from 'react';
import { SnackbarProps } from '@mui/material';
import clsx from 'clsx';

interface ToasterProps extends SnackbarProps {
  variant?: 'success' | 'error' | 'info';
}

const CLASSES = {
  base: 'flex items-center px-10 py-6 rounded-lg shadow-xl bg-background border border-l-4 w-96 min-h-28',
  border: {
    base: 'before:content-[""] before:absolute before:top-0 before:left-0 before:h-full before:w-3 before:rounded-l-lg',
    success: 'before:bg-status-success',
    error: 'before:bg-status-error',
    info: 'before:bg-status-info',
  },
};

export const Toaster: FC<ToasterProps> = (props) => {
  const { children, anchorOrigin, autoHideDuration = 3000, variant = 'success', ...rest} = props;

  const classes = clsx(
    CLASSES.base,
    CLASSES.border.base,
    CLASSES.border[variant],
  )

  return (
    <Snackbar
      {...rest}
      anchorOrigin={{
        vertical: anchorOrigin?.vertical || 'top',
        horizontal: anchorOrigin?.horizontal || 'right',
      }}
      autoHideDuration={autoHideDuration}
      className="z-50"
    >
      <div className={classes}>
        {children}
      </div>
    </Snackbar>
  )
}