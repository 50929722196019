import { action, makeAutoObservable } from 'mobx';
import { IOrderDetails } from '../../Models/Interfaces/IOrderDetails.model';
import { PaymentMethodType } from '../../assets/types/PaymentMethod.type';
import moment from 'moment';
import {
  CreateAgencyOrderRequest,
} from '../../assets/requests/orders/CreateAgencyOrder.request';
import { InvoiceCustomMetadataModel } from '@assets/models/invoices/InvoiceCustomMetadata.model';
import { MinimalBeneficiaryCreditData } from '@assets/models/orders/Order.model';

export class OrderPaymentViewStore {
  paymentMethod: PaymentMethodType = null;
  isOpenMandateDialog: boolean = false;

  orderDetails: IOrderDetails = {
    beneficiaryToRechargeCount: 0,
    credits: [],
    month: 0,
    year: 0,
    totalHT: 0,
    tva: 0,
    totalTTC: 0,
    provisionTotalDays: 0,
    provisionAmount: 0,
    netAmountPayable: 0,
    invoiceCustomMetadata: {},
  };

  get dateString(): string {
    const year: number = this.orderDetails.year;
    const month: number = this.orderDetails.month;
    return !!month && !!year ? moment(`${month} ${year}`, 'MM YYYY').format('MMMM YYYY') : '';
  }

  get tva(): string {
    return this.currencyFormat(this.orderDetails.tva);
  }

  get totalHT(): string {
    return this.currencyFormat(this.orderDetails.totalHT);
  }

  get totalTTC(): string {
    return this.currencyFormat(this.orderDetails.totalTTC);
  }

  get beneficiaryToRechargeCount(): number {
    return this.orderDetails.beneficiaryToRechargeCount;
  }

  get provisionTotalDays(): number {
    return this.orderDetails.provisionTotalDays;
  }

  get provisionAmount(): string {
    return this.currencyFormat(this.orderDetails.provisionAmount);
  }

  get netAmountPayable(): string {
    return this.currencyFormat(this.orderDetails.netAmountPayable);
  }

  get agencyOrderCreationRequest(): Omit<CreateAgencyOrderRequest, 'agencyId'> {
    const { year, month, credits, invoiceCustomMetadata }: IOrderDetails = this.orderDetails;

    return { month, year, credits, invoiceCustomMetadata };
  }

  get credits() {
    return this.orderDetails.credits;
  }

  get invoiceCustomMetadata(): InvoiceCustomMetadataModel {
    return this.orderDetails.invoiceCustomMetadata;
  }

  get hasAtLeastMetadata(): boolean {
    return !!this.countSetMetadata;
  }

  get countSetMetadata(): number {
    return Object.values(this.invoiceCustomMetadata).filter(value => value !== undefined).length;
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setOpenMandateDialog(value: boolean) {
    this.isOpenMandateDialog = value;
  }

  @action setPaymentMethod(newMethod: PaymentMethodType) {
    this.paymentMethod = newMethod;
  }

  @action setOrderDetails(orderDetails: IOrderDetails): void {
    this.orderDetails = orderDetails;
    localStorage.setItem('openPendingOrderDetails', JSON.stringify(orderDetails));
  }

  @action setCredits(credits: MinimalBeneficiaryCreditData[]): void {
    this.setOrderDetails({
      ...this.orderDetails,
      credits,
    });
  }

  @action setInvoiceCustomMetadataItem(key: keyof InvoiceCustomMetadataModel, value: string): void {
    this.setOrderDetails({
      ...this.orderDetails,
      invoiceCustomMetadata: {
        ...this.orderDetails.invoiceCustomMetadata,
        [key]: value,
      },
    });
  }

  @action reloadPendingOrderDetails(): void {
    const jsonStringOrderDetails: string = localStorage.getItem('openPendingOrderDetails');
    const pendingOrderDetails: IOrderDetails = jsonStringOrderDetails && JSON.parse(jsonStringOrderDetails);

    if (pendingOrderDetails) {
      this.orderDetails = pendingOrderDetails;
    }
  }

  private currencyFormat(value: number) {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value);
  }
}
