/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateBeneficiary = {
  firstname: string;
  lastname: string;
  mobilephone?: string | null;
  registrationNumber: string;
  streetAddress: string;
  additionalAddress?: string | null;
  postalCode: string;
  city: string;
  countryCode: string;
  title: UpdateBeneficiary.title;
  activeSundaysAndHolidays: boolean;
  isActive: boolean;
  numberOfWorkingDays: number;
  iban?: string | null;
}

export namespace UpdateBeneficiary {

  export enum title {
    UNKNOW = 'Unknow',
    MR = 'Mr',
    MRS = 'Mrs',
  }

}
