import { makeStyles } from '@mui/styles';

const useDialogStyles = makeStyles(() => ({
  dialogPaper: {
    '& .MuiBackdrop-root': {
      transition: 'opacity 225ms, backdrop-filter 225ms !important',
      backdropFilter: 'blur(5px)',
    },
    '& .MuiDialog-paper': {
      margin: 0
    },
    '& .MuiDialog-scrollPaper': {
      justifyContent: 'flex-end !important'
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100vh !important'

    }
  }
}));

export default useDialogStyles;
