import * as React from 'react';
import { ReactComponent as Arrow } from 'assets/svg/arrows/arrowRight.svg';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { CommonProps } from '@mui/material/OverridableComponent';

interface IHeaderMenuProps {
  navigate: NavigateFunction;
  pathname: string;
}

const BreadCrumb = (props: IHeaderMenuProps & CommonProps) => {
  const { t } = useTranslation('common');
  const [, firstSegment] = props.pathname.split('/');

  return (
    <div className="relative -left-5 bg-white flex items-center rounded-r-full px-3.5 py-3 pl-8 space-x-2 h-12">
      {firstSegment !== 'dashboard' && (
        <>
          <span className="text-black cursor-pointer text-sm hover:underline"
            onClick={() => props.navigate('dashboard')}
          >
            Accueil
          </span>
          <Arrow height={12} />
        </>
      )}
      <span className="text-sm">{t(firstSegment.toLocaleLowerCase())}</span>
    </div>
  );
};
export default BreadCrumb;