import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, StandardTextFieldProps, InputAdornment, FormControl } from '@mui/material';
import clsx from 'clsx';
import { Spinner } from './Spinner';
import { FormikProps } from 'formik';

interface CustomInputProps {
  loading?: boolean;
  errorMessage?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  formik?: FormikProps<any>;
  countryFlag?: string;
  formClasses?: string;
}

type InputProps = StandardTextFieldProps & CustomInputProps;

const styles = {
  base: {
    root: 'w-full',
    input: 'h-16 text-sm rounded-br30 bg-background disabled:opacity-50 text-primary pl-6',
    label: 'absolute top-2 left-0 z-10 px-6 w-full flex justify-between text-primary/50 text-sm',
    error: 'pt-1 text-sm transition-opacity duration-200 ease-in-out text-status-error',
  },
  states: {
    withLabel: 'pt-0 pt-6',
    disabled: 'bg-white',
  },
  transitions: {
    border: 'transition-all duration-300 ease-in-out',
  },
  mui: {
    outline: {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--color-background-DEFAULT)',
          transition: 'border-color 0.3s ease-in-out',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--color-muted)',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--color-primary)',
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--color-status-error)',
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--color-status-disabled)',
        },
      },
    },
  },
};

const InputAdornmentWrapper: FC<{ position: 'start' | 'end'; children: ReactNode }> = ({ position, children }) => {
  if (!children) return null;

  return (
    <InputAdornment position={position} className={position === 'start' ? 'ml-4' : 'mr-4'}>
      {children}
    </InputAdornment>
  );
};

const getEndAdornment = (loading?: boolean, countryFlag?: string, endIcon?: ReactNode) => {
  if (loading) return <Spinner size="sm" />;
  if (countryFlag) return <span className={`fi fi-${countryFlag}`} />;
  return endIcon;
};

const getFormikError = (formik: FormikProps<any>, id: string) => {
  if (!formik || !id) return undefined;
  return formik.touched[id] && formik.errors[id] ? formik.errors[id] as string : undefined;
};

export const InputField: FC<InputProps> = (props) => {
  const {
    loading,
    label,
    errorMessage,
    fullWidth,
    required,
    size = 'medium',
    startIcon,
    endIcon,
    className,
    formik,
    countryFlag,
    id,
    formClasses,
    error,
    ...rest
  } = props;
  const { t } = useTranslation('common');

  const formikError = getFormikError(formik, id as string);
  const hasError = error || Boolean(formikError);
  const finalErrorMessage = errorMessage || formikError;

  const fieldProps = formik && id ? {
    value: formik.values[id],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
  } : rest;

  return (
    <FormControl fullWidth={fullWidth} required={required} className={formClasses}>
      {label && (
        <div className={styles.base.label}>
          <div>{label}</div>
          {required && <div>{t('required')}</div>}
        </div>
      )}

      <TextField
        {...fieldProps}
        id={id}
        name={id}
        variant="outlined"
        placeholder={rest.placeholder}
        error={hasError}
        slotProps={{
          input: {
            className: clsx(
              styles.base.input,
              styles.transitions.border,
              label && styles.states.withLabel,
              rest.disabled && styles.states.disabled,
            ),
            startAdornment: (<InputAdornmentWrapper position="start">{startIcon}</InputAdornmentWrapper>),
            endAdornment: (
              <InputAdornmentWrapper position="end">
                {getEndAdornment(loading, countryFlag, endIcon)}
              </InputAdornmentWrapper>
            ),
          },
          inputLabel: {
            shrink: false,
            required: false,
            classes: 'hidden',
          },
        }}
        sx={styles.mui.outline}
      />

      <p className={clsx(styles.base.error, hasError ? 'opacity-100' : 'opacity-0')}>
        {finalErrorMessage}
      </p>
    </FormControl>
  );
};