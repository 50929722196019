import {action, makeAutoObservable} from 'mobx';

export class LoginViewStore {
  loginStep: number = 0;
  params: URLSearchParams;
  isPasswordHidden: boolean = true;
  showErrorSnackbar: boolean = false;
  resetPasswordLoading: boolean = false;

  isNewPasswordHidden = false;
  isNewConfirmPasswordHidden = false;

  hasChangedPasswordScreen = false;
  firstConnexionScreen = false;

  email?: string;

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action resetLogin() {
    this.isNewPasswordHidden = false;
    this.isNewConfirmPasswordHidden = false;
  }

  @action setLoginStep (step: number) {
    this.loginStep = step;
  }

  @action setIsPasswordHidden (isHidden: boolean) {
    this.isPasswordHidden = isHidden;
  }

  @action setShowErrorSnackbar (isError: boolean) {
    this.showErrorSnackbar = isError;
  }

  @action setResetPasswordLoading (isLoading: boolean) {
    this.resetPasswordLoading = isLoading;
  }

  @action setEmail(value: string) {
    this.email = value;
  }

  @action openHasChangedPasswordScreen() {
    this.hasChangedPasswordScreen = true;
  }

  @action openFirstConnexionScreen() {
    this.firstConnexionScreen = true;
  }

  @action setIsNewPasswordHidden (isHidden: boolean) {
    this.isNewPasswordHidden = isHidden;
  }

  @action setIsNewConfirmPasswordHidden (isHidden: boolean) {
    this.isNewConfirmPasswordHidden = isHidden;
  }
}
