import { provider, useInstances } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import React from 'react';
import { AgencyAdminStore } from '../../Stores/AgencyAdmin.store';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { UserConnectStore } from '../../Stores/UserConnect.store';
import { MandateStore } from '../../Stores/Mandate.store';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { ReactComponent as Arrow } from '../../assets/svg/arrows/arrowRight.svg';
import { HoverDropdown } from '../../ui/HoverDropdown';

type LocalStore = [AgencyAdminStore, AgenciesStore, UserConnectStore, MandateStore, AgencyInvoiceStore];

const RightBlock = provider()(observer(() => {
  const [
    agencyAdminStore,
    agenciesStore,
    userConnectStore,
    mandateStore,
    agencyInvoiceStore,
  ]: LocalStore = useInstances<LocalStore>(AgencyAdminStore, AgenciesStore, UserConnectStore, MandateStore, AgencyInvoiceStore);

  const navigate: NavigateFunction = useNavigate();

  const handleClickLogout = async () => {
    await userConnectStore.userLogout();
    agencyAdminStore.resetAgencyAdmin();
    agenciesStore.reset();
    mandateStore.reset();
    agencyInvoiceStore.reset();
    navigate('/login');
  };

  return (
    <div className="group flex justify-center items-center cursor-pointer">
      <div className="relative flex flex-row gap-2.5">
        <div className="flex flex-col justify-center">
          <div
            className="flex justify-center items-center w-12 h-12 bg-green rounded-full font-bold text-sm group-hover:bg-green/50">
            <p>{agencyAdminStore.agencyAdminSelected ?
              `${agencyAdminStore.agencyAdminSelected?.firstName[0]}${agencyAdminStore.agencyAdminSelected?.lastName[0]}`
              : ''
            }</p>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-y-1">
          <div className="font-semibold text-sm">
            {`${agencyAdminStore.agencyAdminSelected?.firstName ?? ''} ${agencyAdminStore.agencyAdminSelected?.lastName.toLocaleUpperCase() ?? ''}`}
          </div>
          <div className="bg-white rounded flex items-center justify-center">
            {/*@todo devrait venir de firestore*/}
            <span>Administrateur</span>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <HoverDropdown childrenPosition={'right'}>
              <ul
                className="bg-white shadow-2xl text-primary rounded-br10 px-0 m-0 mt-2 py-4 space-y-2">
                <li onClick={handleClickLogout}
                    className="px-4 h-10 text-sm flex items-center justify-start cursor-pointer hover:bg-green/50 transition-colors">
                  <p>Déconnexion</p>
                </li>
              </ul>
          </HoverDropdown>
        </div>
      </div>
    </div>
  );
}));

export default RightBlock;
