import React, { FC } from 'react';
import { Switch as MuiSwitch, SwitchProps } from '@mui/material';
import clsx from 'clsx';

interface CustomSwitchProps extends SwitchProps {
  label?: string;
}

export const Switch: FC<CustomSwitchProps> = ({ className, label, ...props }) => {
  return (
    <MuiSwitch
      {...props}
      className={clsx('relative inline-flex items-center', className)}
      sx={{
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: '2px',
          transitionDuration: '300ms',
          '&:hover': {
            backgroundColor: 'transparent',
            '& + .MuiSwitch-track': {
              backgroundColor: 'var(--color-green-DEFAULT)',
            },
          },
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: 'var(--color-white)',
            '&:hover': {
              backgroundColor: 'transparent',
              '& + .MuiSwitch-track': {
                backgroundColor: 'var(--color-status-success)',
              },
            },
            '& + .MuiSwitch-track': {
              backgroundColor: 'var(--color-status-success)',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: 'var(--color-status-success)',
            border: '6px solid var(--color-white)',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'var(--color-status-disabled)',
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: 'var(--color-background-DEFAULT)',
          opacity: 1,
          transition: 'background-color 300ms',
        },
      }}
    />
  );
};

export const SwitchWithLabel: FC<CustomSwitchProps> = ({ label, ...props }) => {
  return (
    <div className="flex items-center gap-4">
      <span className="text-sm text-primary">{label}</span>
      <Switch {...props} />
    </div>
  );
};