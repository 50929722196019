import { styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid, DataGridProps, GridPagination } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { COLORS, FONT } from '../Style';

const useStyles = makeStyles({
  datagrid: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: (props: any) => `${170 + 72 * (props.numberOfRowToDisplay || 2)}px`,
    transition: 'height 0.1s',
  },
});

interface CustomDataGridProps extends Omit<DataGridProps, 'components'> {
  idKey?: string;
  showHeader?: boolean;
  components?: {
    Header?: React.ElementType;
    Footer?: React.ElementType;
  };
  paginationModel?: { pageSize: number; page: number };
  onPaginationModelChange?: (model: { pageSize: number; page: number }) => void;
}

function countRowToDisplay(totalRows: number, currentPageIndex: number, rowsPerPage: number): number {
  const rest = totalRows - (rowsPerPage * currentPageIndex);
  return Math.min(rest, rowsPerPage);
}
const DEFAULT_PAGE_SIZE = 25;

const StyledDataGrid = styled(
  (props: CustomDataGridProps) => {
    const { paginationModel, onPaginationModelChange, showHeader = true, ...rest } = props;

    const rowCount = props.rowCount ?? props.rows?.length ?? 0;
    const [currentPage, setCurrentPage] = useState(paginationModel?.page ?? 0);
    const [currentPageSize, setCurrentPageSize] = useState(paginationModel?.pageSize ?? DEFAULT_PAGE_SIZE);
    const [numberOfRowToDisplay, setNumberOfRowToDisplay] = useState(currentPageSize);
    const dataGridClasses = useStyles({ numberOfRowToDisplay });

    const updatePaginationModel = useCallback(
      (model: { page: number; pageSize: number }) => {
        setCurrentPage(model.page);
        setCurrentPageSize(model.pageSize);
        onPaginationModelChange?.(model);
      },
      [onPaginationModelChange]
    );

    useEffect(() => {
      const rowsToDisplay = countRowToDisplay(rowCount, currentPage, currentPageSize);
      setNumberOfRowToDisplay(rowsToDisplay);

      requestAnimationFrame(() => setNumberOfRowToDisplay(rowsToDisplay));
    }, [currentPage, currentPageSize, rowCount, props.rows]);


    return (
      <div className={dataGridClasses.datagrid}>
        <DataGrid
          getRowId={(row) => (props.idKey ? row[props.idKey] : row.uid)}
          localeText={{
            noRowsLabel: 'Pas de résultats',
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `${count > 1 ? `Lignes` : `Ligne`} ${from} à ${to} sur ${count.toLocaleString()}`,
            },
          }}
          components={{
            ...(showHeader ? { Header: () => <GridPagination/> } : {}),
            Footer: () => <GridPagination/>,
          }}
          pagination
          paginationModel={{ page: currentPage, pageSize: currentPageSize }}
          onPaginationModelChange={updatePaginationModel}
          {...rest}
        />
      </div>
    )
      ;
  },
)({
  border: 'none  !important',
  '& .MuiDataGrid-cell': {
    display: 'flex',
    borderBottom: '1px solid white !important',
    color: COLORS.textPrimary,
  },
  '& .MuiDataGrid-row': {
    backgroundColor: COLORS.backgroundBlock,
    borderRadius: '20px',
    marginBottom: '20px',
    width: '100%',
    cursor: ({ onRowClick }: DataGridProps) => onRowClick ? 'pointer' : 'initial',
  },
  '& .MuiDataGrid-colCellTitle': {
    color: COLORS.textPrimary,
    fontFamily: FONT.primary,
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-colCell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none !important',
  },
  '& .MuiDataGrid-columnsContainer': {
    border: 'none !important',
  },
  '& .MuiSvgIcon-root': {
    color: COLORS.primary,
  },
  '& .MuiDataGrid-renderingZone': {
    maxHeight: '100% !important',
  },
  '& .MuiIconButton-root.Mui-disabled': {
    visibility: 'hidden',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    paddingRight: '36px',
  },
  '& .MuiTablePagination-spacer': {
    display: 'none !important',
  },
});

export default StyledDataGrid;
