import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import OrderDetailsComponent from './OrderDetails';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../Stores/Agencies.store';
import { AgencyInvoiceStore, OrderInvoices } from '../Stores/AgencyInvoice.store';
import OrderCard from '../Component/OrderCard/OrderCard';
import { Select } from './../ui/Select';
import { OrderCardEmpty } from '../Component/OrderCard/OrderCardEmpty';
import { Spinner } from '../ui/Spinner';
import { PageBlock } from 'ui/layout/PageBlock';
import { RechargingViewStore } from '../Stores/viewStore/RechargingView.store';
import { InvoiceModel } from '../assets/models/invoices/invoice.model';
import { SectionWrapper } from 'ui/layout/SectionWrapper';
import { Dialog } from '../ui/Dialog';

type LocalStore = [AgenciesStore, AgencyInvoiceStore, RechargingViewStore];

interface Option {
  label: string;
  value: number;
}

const RechargingPage: FunctionComponent = (observer(() => {
  const [
    agenciesStore,
    agencyInvoiceStore,
    rechargingViewStore,
  ]: [AgenciesStore, AgencyInvoiceStore, RechargingViewStore] = useInstances<LocalStore>(AgenciesStore, AgencyInvoiceStore, RechargingViewStore);

  const { t } = useTranslation('reloading');
  const navigate: NavigateFunction = useNavigate();

  const [sinceDate, setSinceDate] = useState<number>(1);

  const selectSinceDate: (value: number) => Date = useCallback((value: number) => {
    let from = new Date();
    switch (value) {
      case 0:
        from.setMonth(from.getMonth() - 6);
        break;
      case 1:
        from.setFullYear(from.getFullYear() - 1);
        break;
      case 2:
        from.setFullYear(from.getFullYear() - 2);
        break;
      case 3:
      default:
        from = null;
    }
    return from;
  }, []);

  const getInvoicesByDate: (from: Date) => Promise<void> = useCallback(async (from: Date) => {
    if (!agenciesStore.currentAgency) {
      return;
    }
    const invoices: InvoiceModel[] = await agencyInvoiceStore.fetchAgencyInvoices(agenciesStore.currentAgency.uid, from?.getTime());
    agencyInvoiceStore.sortInvoicesByRechargingMonthAndYear(invoices);
  }, [agenciesStore.currentAgency?.uid]);

  useEffect(() => {
    if (agenciesStore.currentAgency) {
      void getInvoicesByDate(selectSinceDate(sinceDate));
    }
    sessionStorage.removeItem('reloadingDateKey');
  }, [agenciesStore.currentAgency, sinceDate]);

  const options: Option[] = [
    { label: t('since6months'), value: 0 },
    { label: t('since1year'), value: 1 },
    { label: t('since2years'), value: 2 },
    { label: t('sinceBeginning'), value: 3 },
  ];

  return (
    <PageBlock>
      <SectionWrapper className={'space-y-8'}>
        <h1>Vos derniers rechargements</h1>

        <Select
          value={sinceDate}
          onChange={(evt) => setSinceDate(evt.target.value as unknown as number)}
          labelId="label"
          id="select"
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>

        <div className={'flex flex-wrap gap-6'}>
          <OrderCardEmpty onClick={() => navigate('/addNewRecharging')}/>

          {agencyInvoiceStore.isInvoicesLoading
            ? <Spinner/>
            : <>
              {agencyInvoiceStore.ordersInvoices?.map((orderInvoices: OrderInvoices, i: number) =>
                <OrderCard
                  key={i}
                  invoiceKey={i}
                  orderInvoices={orderInvoices}
                />,
              )}
            </>
          }
        </div>
      </SectionWrapper>

      <Dialog
        open={rechargingViewStore.openOrderDetails}
        onClose={() => rechargingViewStore.setOpenOrderDetails(false)}
      >
        <OrderDetailsComponent
          orderInvoices={agencyInvoiceStore.selectedOrder}
          onClose={() => rechargingViewStore.setOpenOrderDetails(false)}
        />
      </Dialog>
    </PageBlock>
  );
}));

export default RechargingPage;