import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Chip, ListItemText, MenuItem } from '@mui/material';
import { useInstances } from 'react-ioc';
import { AgencyAdminStore } from '../../Stores/AgencyAdmin.store';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { getListOfProfiles } from './../../assets/utils/security/agencyAdmins.util';
import { useNavigate, useLocation } from 'react-router-dom';
import { AgencyAdminProfileDefinition } from '@assets/models/security/agencyAdmins.model';
import { ManagementUnitModel } from '@assets/models/agencies/ManagementUnit.model';
import GlobalDeleteConfirmationDialog from 'Component/Dialog/GlobalDeleteConfirmationDialog';
import { PageBlock } from 'ui/layout/PageBlock';
import { FormikMultiSelect, FormikSelect } from '../../ui/Select';
import { Button } from '../../ui/Buttons/Button';
import { SectionWrapper } from '../../ui/layout/SectionWrapper';
import { BackButton } from '../../ui/Buttons/BackButton';
import { Toaster } from 'ui/Toaster';
import clsx from 'clsx';
import { InputField } from 'ui/Input';

type PartialManagementUnit = {
  label: string;
  value: string;
};

const AddorUpdateAgencyAdminPage = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const agencyAdminData = location.state?.agencyAdminData || null;
  const isUpdateMode = Boolean(agencyAdminData);
  const { t } = useTranslation(['settings', 'common']);
  const [agencyAdminStore] = useInstances(AgencyAdminStore);
  const [agenciesStore] = useInstances(AgenciesStore);
  const { currentAgencyManagementUnits, currentAgency } = agenciesStore;
  const [showSucessSnackbar, setShowSucessSnackbar] = useState(false);
  const [listOfProfiles, setListOfProfiles] = useState<AgencyAdminProfileDefinition[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const goBack = () => {
    navigate(`/settings/agencyadmins`);
  };

  const listOfManagementUnits: PartialManagementUnit[] = useMemo(() => {
    return currentAgencyManagementUnits.map(
      (unit: ManagementUnitModel): PartialManagementUnit => ({
        label: unit.name,
        value: unit.uid,
      }),
    );
  }, [currentAgencyManagementUnits]);

  useEffect(() => {
    const loadProfiles = async () => {
      const result: AgencyAdminProfileDefinition[] = await getListOfProfiles(currentAgency?.uid);
      setListOfProfiles(result);
    };
    loadProfiles();
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email invalide').required('Requis'),
    firstname: Yup.string().required('Requis'),
    lastname: Yup.string().required('Requis'),
    selectedProfilValue: Yup.string().required('Requis'),
    selectedUnitValues: Yup.array().of(Yup.string()).min(1, 'Au moins une unité de gestion doit être sélectionnée'),
  });

  const initialValues = {
    email: agencyAdminData?.email || '',
    firstname: agencyAdminData?.firstName || '',
    lastname: agencyAdminData?.lastName || '',
    selectedProfilValue: agencyAdminData?.profile || '',
    selectedUnitValues: agencyAdminData?.managementUnits?.map((unit) => unit.value) || [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
      setSubmitting(true);

      const formattedValues = {
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        profileId: values.selectedProfilValue,
        managementUnitsIds: values.selectedUnitValues,
      };

      if (isUpdateMode) {
        delete formattedValues.email;
        await agencyAdminStore.updateAgencyAdmin(currentAgency.companyId, currentAgency.uid, agencyAdminData.agencyAdminId, formattedValues);
      } else {
        await agencyAdminStore.createAgencyAdmin(currentAgency.companyId, currentAgency.uid, formattedValues);
      }
      if (agencyAdminStore.userAdminCreatedOrUpdated === 'SUCCESS') {
        setShowSucessSnackbar(true);
        await agenciesStore.refreshAgencyAdminsWithSettings();
        setTimeout(() => {
          navigate(`/settings/agencyadmins`);
        }, 1000);
      }

    },
  });

  const handleDeactivateUserAdmin = async () => {

    await agencyAdminStore.deactivateAgencyAdmin(currentAgency.companyId, currentAgency.uid, agencyAdminData.agencyAdminId);

    if (agencyAdminStore.userAdminDeactivated === 'SUCCESS') {
      setIsDialogOpen(false);
      await agenciesStore.refreshAgencyAdminsWithSettings();
      navigate(`/settings/agencyadmins`);
    }
    setIsDialogOpen(false);
  };

  return (
    <PageBlock>
      <SectionWrapper>
        <div className={'flex items-center gap-4'}>
          <BackButton to={'/settings/agencyadmins'}/>
          <h2>{isUpdateMode ? t('updateEmpUserSubTitle', {
            name: agencyAdminData.firstName, surname: agencyAdminData.lastName.toUpperCase(),
          }) : t('addEmpUserSubTitle')}</h2>
        </div>
        <form onSubmit={formik.handleSubmit} className={''}>
          <h3 className={'font-bold'}>Identité</h3>
          <section className={'flex gap-2'}>
            <InputField
              id="email"
              label="Email"
              required
              formik={formik}
              disabled={isUpdateMode}
              fullWidth
            />
            <InputField
              id="firstname"
              label={'Prénom'}
              required
              formik={formik}
              placeholder={'Prénom'}
              fullWidth
            />
            <InputField
              id="lastname"
              title="Prénom"
              required
              label={'Nom'}
              placeholder={'Nom'}
              formik={formik}
              fullWidth
            />
          </section>

          <h3 className={'font-bold'}>Profil</h3>
          <div className={'w-1/3'}>
            <FormikSelect
              name="selectedProfilValue"
              formik={formik}
              fullWidth
              label="Profil"
            >
              {listOfProfiles.map((profile, index) => (
                <MenuItem key={index} value={profile.uid}>
                  {profile.name}
                </MenuItem>
              ))}
            </FormikSelect>
          </div>

          <h3 className={'font-bold'}>Unité de gestion</h3>

          <div className={'w-1/3'}>
            <FormikMultiSelect
              name={'selectedUnitValues'}
              label={'Unité de gestion'}
              formik={formik}
              fullWidth
              renderValue={(selected: string[]) => (
                <div>
                  {selected.map((value: string) => (
                    <Chip
                      size="small"
                      key={value}
                      label={listOfManagementUnits.find((item) => item.value === value)?.label}
                      className={'bg-link text-white text-sm'}
                    />
                  ))}
                </div>
              )}
            >
              {listOfManagementUnits.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  <Checkbox
                    className={'text-primary'}
                    checked={formik.values.selectedUnitValues.indexOf(item.value) > -1}
                  />
                  <ListItemText primary={t(item.label)}/>
                </MenuItem>
              ))}
            </FormikMultiSelect>
          </div>

            <div className={clsx('flex items-center pt-10', isUpdateMode ? 'justify-between' : 'justify-end')}>
              {isUpdateMode && (
                <div>
                  <Button onClick={() => setIsDialogOpen(true)}>
                    {t('deleteUser')}
                  </Button>
                </div>
              )}
              <div className={'flex gap-4'}>
                <Button onClick={goBack}>
                  {t('cancel', { ns: 'common' })}
                </Button>

                <Button
                  disabled={formik.isSubmitting}
                  loading={agencyAdminStore.isLoadingCreatedOrUpdatedAgencyAdmin}
                >
                  {isUpdateMode ? t('validate', { ns: 'common' }) : t('add', { ns: 'common' })}
                </Button>
              </div>
            </div>
        </form>

        <Toaster open={showSucessSnackbar} onClose={() => setShowSucessSnackbar(false)}>
          <>{isUpdateMode ? t('successUpdatedAdmin') : t('successCreatedAdmin')}</>
        </Toaster>

        <GlobalDeleteConfirmationDialog
          isActive={isDialogOpen}
          onClose={setIsDialogOpen}
          onConfirm={handleDeactivateUserAdmin}
          loading={agencyAdminStore.isLoadingDeactivateAgencyAdmin}
          confirmText="Oui, supprimer"
          cancelText="Non, annuler"
          deleteMessage="Êtes-vous certain de vouloir supprimer cet utilisateur ?"
        />
      </SectionWrapper>
    </PageBlock>
  );
});

export default AddorUpdateAgencyAdminPage;