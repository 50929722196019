import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { provider, useInstances } from 'react-ioc';
import { useLocation } from 'react-router-dom';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import StepperComponent from '../Component/StepperComponent';
import { TStepAddNewRecharging } from '../Models/Types/TStepAddNewRecharging.model';
import { AgenciesStore } from '../Stores/Agencies.store';
import { BeneficiariesStore } from '../Stores/Beneficiaries.store';
import { PayingServiceStore } from '../Stores/PayingService.store';
import { OrderPaymentViewStore } from '../Stores/viewStore/OrderPaymentView.store';
import { OrderValidationViewStore } from '../Stores/viewStore/OrderValidationView.store';
import { RechargingViewStore } from '../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../Stores/viewStore/StepperRecharging.store';
import { useNavigate } from 'react-router';
import CreateNewBeneficiaryComponent
  from '../Beneficiaries/BeneficiaryDetails/CreateNewBeneficiary/CreateNewBeneficiaryComponent';
import { PageBlock } from '../ui/layout/PageBlock';
import { SectionWrapper } from '../ui/layout/SectionWrapper';
import { Dialog } from '../ui/Dialog';
import SelectMethodRecharging from './Steps/SelectMethodRecharging';
import OrderParameters from './Steps/OrderParameters';
import AddNewBeneficiariesUpload from './Steps/AddNewBeneficiariesUpload';
import OrderListBeneficiariesFromCsv from './Steps/OrderListBeneficiaries/OrderListBeneficiariesFromCsv';
import OrderSummary from './Steps/OrderSummary';
import OrderListBeneficiaries from './Steps/OrderListBeneficiaries/OrderListBeneficiaries';
import OrderValidation from './Steps/OrderValidation';
import YourInformation from './Steps/YourInformation';
import OrderPayment from './Steps/OrderPayment/OrderPayment';

type LocalStore = [AgenciesStore, RechargingViewStore, StepperRechargingStore, BeneficiariesStore, OrderPaymentViewStore];

const NewRechargingProcessPage: FunctionComponent = provider(
  StepperRechargingStore,
  OrderValidationViewStore,
  OrderPaymentViewStore,
  PayingServiceStore,
)(observer(() => {
  const [
    agenciesStore,
    rechargingViewStore,
    stepperRechargingStore,
    beneficiariesStore,
    orderPaymentViewStore,
  ]: [
    AgenciesStore,
    RechargingViewStore,
    StepperRechargingStore,
    BeneficiariesStore,
    OrderPaymentViewStore,
  ] = useInstances<LocalStore>(AgenciesStore, RechargingViewStore, StepperRechargingStore, BeneficiariesStore, OrderPaymentViewStore);

  const location = useLocation();

  const { hasInvoiceInformation }: AgenciesStore = agenciesStore;

  const currentStep: TStepAddNewRecharging = stepperRechargingStore.currentStep;
  // const currentStep: TStepAddNewRecharging = 'RECAP';
  const beneficiariesActiveList: BeneficiaryModel[] = beneficiariesStore.activeBeneficiaryList;
  const { dateString } = orderPaymentViewStore;

  const { t } = useTranslation('reloading');
  const navigate = useNavigate();

  const steps: TStepAddNewRecharging[] = [
    'SELECT_METHOD_RECHARGING',
    stepperRechargingStore.isUploadingCsv ? 'ADD_NEW_BENEFICIARIES_UPLOAD' : 'PARAMETERS',
    hasInvoiceInformation ? 'YOUR_INFORMATION' : null,
    stepperRechargingStore.isUploadingCsv ? 'LIST_BENEFICIARIES_FROM_CSV' : 'LIST_BENEFICIARIES',
    'CONFIRMATION',
    'PAYMENT'
  ].filter((step): step is TStepAddNewRecharging => !!step);

  useEffect(() => {
    stepperRechargingStore.setStepNameList([...steps, 'RECAP']);
  }, [steps]);

  useEffect(() => {
    if (agenciesStore.currentAgency && !rechargingViewStore.openBeneficiaryDetails) {
      void beneficiariesStore.fetchAllBeneficiariesByAgencyId(agenciesStore.currentAgency.uid);
    }
  }, [agenciesStore.currentAgency, rechargingViewStore.openBeneficiaryDetails]);

  useEffect(() => {
    if (location.pathname === '/continueOrder') {
      stepperRechargingStore.goToStep('PAYMENT');
      orderPaymentViewStore.reloadPendingOrderDetails();
      agenciesStore.setPendingCurrentAgency();
      navigate('/addNewRecharging');
    }
  }, [location.pathname]);

  return (
    <PageBlock>
      <SectionWrapper>
        {currentStep !== 'SELECT_METHOD_RECHARGING'
          && <>
            {currentStep !== 'ADD_NEW_BENEFICIARIES_UPLOAD'
              ? <>
                {dateString &&
                    <h1>{`${t('title')} ${dateString}`}</h1>}
                <br/>
                {t('activeBeneficiaries', { count: beneficiariesActiveList.length })}
              </>
              : <h1 className={'pb-4 font-bold text-xl'}>{t('titleChoice')}</h1>}
                <div className={'flex self-center mt-16'}>
                    <StepperComponent steps={steps}/>
                </div>
            </>
        }
        {currentStep === 'SELECT_METHOD_RECHARGING' && <SelectMethodRecharging/>}
        {currentStep === 'PARAMETERS' && <OrderParameters/>}
        {currentStep === 'ADD_NEW_BENEFICIARIES_UPLOAD' && <AddNewBeneficiariesUpload/>}
        {currentStep === 'YOUR_INFORMATION' && <YourInformation/>}
        {currentStep === 'LIST_BENEFICIARIES' && <OrderListBeneficiaries/>}
        {currentStep === 'LIST_BENEFICIARIES_FROM_CSV' && <OrderListBeneficiariesFromCsv/>}
        {currentStep === 'CONFIRMATION' && <OrderValidation/>}
        {currentStep === 'PAYMENT' && <OrderPayment/>}
        {currentStep === 'RECAP' && <OrderSummary/>}

        {(currentStep === 'LIST_BENEFICIARIES' || currentStep === 'LIST_BENEFICIARIES_FROM_CSV') &&
            <Dialog
                open={rechargingViewStore.openBeneficiaryDetails}
                onClose={() => rechargingViewStore.setOpenBeneficiaryDetails(false)}
            >
                <CreateNewBeneficiaryComponent onClose={() => rechargingViewStore.setOpenBeneficiaryDetails(false)}/>

            </Dialog>
        }
      </SectionWrapper>
    </PageBlock>
  );
}));

export default NewRechargingProcessPage;
