import React, { FunctionComponent } from 'react';
import { useConfirmationModalStyles } from './ConfirmationModalStyles';
import { Button, IconButton, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

interface ConfirmationModalComponentProps {
  title: string;
  description: string;
  subDescription?: string;
  cancelButtonLabel: string;
  submitButtonLabel: string;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const ConfirmationModalComponent: FunctionComponent<ConfirmationModalComponentProps> = (props) => {
  const {
    title,
    description,
    subDescription,
    cancelButtonLabel,
    submitButtonLabel,
    isOpen,
    onSubmit,
    onCancel,
  } = props;

  const style = useConfirmationModalStyles();

  return (
    <Modal
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <div className={style.modalContentWrapper}>
        <div id="confirmation-modal-title" className={style.modalHeader}>
          <Typography variant="inherit" component="div">
            {title}
          </Typography>
          <IconButton onClick={onCancel} size="small">
            <Close fontSize="inherit"/>
          </IconButton>
        </div>

        <div id="confirmation-modal-description" className={style.modalBody}>
          <Typography className={style.modalDescription}>{description}</Typography>
          {subDescription &&
              <Typography className={style.modalSubDescription}>{subDescription}</Typography>
          }
        </div>

        <div className={style.modalFooter}>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {cancelButtonLabel}
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {submitButtonLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModalComponent;
