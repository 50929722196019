/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CsvReloadingModelIEnumerableRequestResult } from '../models/CsvReloadingModelIEnumerableRequestResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CsvService {

    /**
     * Control reloading import csv
     * @param formData
     * @returns CsvReloadingModelIEnumerableRequestResult Success
     * @throws ApiError
     */
    public static controlReloadingImport(
        formData?: {
            AgencyId: string;
            File: Blob;
        },
    ): CancelablePromise<CsvReloadingModelIEnumerableRequestResult> {
        return __request({
            method: 'PUT',
            path: `/api/csv/reloading`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}