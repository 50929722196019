import { useState } from 'react';

export interface SortConfig<T> {
  key: keyof T | null;
  direction: 'asc' | 'desc';
}

export interface UseSortableData<T> {
  items: T[];
  requestSort: (key: keyof T) => void;
  sortConfig: SortConfig<T>;
}

export const useSortableData = <T>(items: T[], config: SortConfig<T> = { key: null, direction: 'asc' }): UseSortableData<T> => {
  const [sortConfig, setSortConfig] = useState<SortConfig<T>>(config);

  const sortedItems = [...items].sort((a, b) => {
    if (sortConfig.key === null) return 0;
    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const requestSort = (key: keyof T) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};