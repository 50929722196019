import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { InputField } from '../../../ui/Input';
import { ReactComponent as CalendarSvg } from '../../../assets/svg/calendar.svg';
import { SwitchWithLabel } from '../../../ui/Switch';

export const NUMBER_OF_WORKING_DAYS = 'numberOfWorkingDays';
export const FIRST_RIGHT_DATE = 'firstRightDate';
export const ACTIVE_SUNDAYS_AND_HOLIDAYS = 'activeSundaysAndHolidays';

const ActivityFormComponent_Linear: FunctionFormComponent = observer(({
                                                                        formik, beneficiary, isEditMode = false,
                                                                        disableModification = false,
                                                                      }) => {
  const { t } = useTranslation('beneficiaries');

  return (
    <div className={'space-y-2'}>
      <h3 className={'font-bold'}>
        Activité
      </h3>
      <div className={'flex gap-2'}>
        <InputField
          id={FIRST_RIGHT_DATE}
          name={FIRST_RIGHT_DATE}
          label={t(FIRST_RIGHT_DATE)}
          value={formik.values[FIRST_RIGHT_DATE]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          endIcon={<CalendarSvg className={'w-4 h-4'}/>}
          type={'date'}
          required
          fullWidth
          error={formik.touched[FIRST_RIGHT_DATE] && Boolean(formik.errors[FIRST_RIGHT_DATE])}
          errorMessage={formik.touched[FIRST_RIGHT_DATE] && formik.errors[FIRST_RIGHT_DATE] as string}
          disabled={isEditMode || disableModification}
        />
        <InputField
          id={NUMBER_OF_WORKING_DAYS}
          name={NUMBER_OF_WORKING_DAYS}
          label={t(NUMBER_OF_WORKING_DAYS)}
          value={formik.values[NUMBER_OF_WORKING_DAYS]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          endIcon={<CalendarSvg className={'w-4 h-4'}/>}
          type={'date'}
          required
          fullWidth
          error={formik.touched[NUMBER_OF_WORKING_DAYS] && Boolean(formik.errors[NUMBER_OF_WORKING_DAYS])}
          errorMessage={formik.touched[NUMBER_OF_WORKING_DAYS] && formik.errors[NUMBER_OF_WORKING_DAYS] as string}
          disabled={isEditMode || disableModification}
        />
      </div>
      <SwitchWithLabel
        label={'Travaille le dimanche et les jours fériés'}
        checked={formik.values[ACTIVE_SUNDAYS_AND_HOLIDAYS]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(ACTIVE_SUNDAYS_AND_HOLIDAYS, event.target.checked)}
        name="checkedA"
        disabled={disableModification}
      />
    </div>
  );
}) as FunctionFormComponent;

ActivityFormComponent_Linear.getValidationSchema = () => {
  return Yup.object().shape({
    [NUMBER_OF_WORKING_DAYS]: Yup.number()
      .min(0, 'Ce nombre doit être compris entre 0 et 31')
      .max(31, 'Ce nombre doit être compris entre 0 et 31')
      .required(),
  });
};

ActivityFormComponent_Linear.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [NUMBER_OF_WORKING_DAYS]: beneficiary?.numberOfWorkingDays ?? 0,
    [FIRST_RIGHT_DATE]: beneficiary?.firstRightDate
      ? moment(beneficiary.firstRightDate).format('YYYY-MM-DD')
      : moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'),
    [ACTIVE_SUNDAYS_AND_HOLIDAYS]: beneficiary?.activeSundaysAndHolidays ?? false,
  };
};

export default ActivityFormComponent_Linear;
