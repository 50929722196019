import React, { useEffect } from 'react';
import { useSortableData } from '../../../Utils/SortGenericList';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { AgencyAdminSettingModel } from '../../../assets/models/agencyAdmin/AgencyAdmin.model';
import { useNavigate } from 'react-router-dom';
import { ManagementUnitModel } from '@assets/models/agencies/ManagementUnit.model';
import { ReactComponent as ArrowBracketSvg } from '../../../assets/svg/arrowBracket.svg';
import { Spinner } from 'ui/Spinner';

type LocalStore = [AgenciesStore];

const AgencyAdminsList = observer(() => {
  const { t } = useTranslation(['settings', 'icon-alt', 'common']);

  const navigate = useNavigate();

  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const {
    currentAgency,
    currentAgencyAgencyAdmins,
    currentAgencyAgencyAdminsStatus,
    currentAgencyManagementUnits,
  } = agenciesStore;

  const { items: sortedList, requestSort, sortConfig } = useSortableData(currentAgencyAgencyAdmins, {
    key: null, direction: 'asc',
  });

  useEffect(() => {
    if (currentAgency?.uid && currentAgencyAgencyAdminsStatus !== 'SUCCESS') {
      void agenciesStore.refreshAgencyAdminsWithSettings();
    }
  }, [currentAgency?.uid]);

  const handleUpdateAgencyAdmin = (agencyAdmin: AgencyAdminSettingModel) => {

    const serializedAdminDataToUpdate = {
      id: agencyAdmin.uid,
      email: agencyAdmin.email,
      firstName: agencyAdmin.firstName,
      lastName: agencyAdmin.lastName,
      profile: agencyAdmin.profile.profileId,
      managementUnits: agencyAdmin.managementUnits.map((unit) => ({
        label: unit.name,
        value: unit.uid,
      })),
      agencyAdminId: agencyAdmin.uid,
    };

    navigate('/settings/add-agencyadmin', { state: { agencyAdminData: serializedAdminDataToUpdate } });
  };

  const compareManagementUnitLists = (list1: ManagementUnitModel[], list2: ManagementUnitModel[]): boolean => {
    if (list1.length !== list2.length) {
      return false;
    }

    const sortByUid = (a: ManagementUnitModel, b: ManagementUnitModel) => a.uid.localeCompare(b.uid);

    const sortedList1 = list1.slice().sort(sortByUid);
    const sortedList2 = list2.slice().sort(sortByUid);

    return sortedList1.every((unit, index) => unit.uid === sortedList2[index].uid);
  };

  //@todo petit bug sur le tri, si on clique sur trier par profile puis sur identifiant les animations se jouent mais le tri ne s'update pas
  const getArrowDirection = (key: string) => {
    return (
      sortConfig.key === key && sortConfig.direction === 'asc'
        ? '-rotate-90'
        : 'rotate-90'
    );
  };

  //@todo Changement de couleur pour les tableRow en cas de hover + cursorPointer
  //@todo Pas de cursorPointer pour les tableHeader sans tris
  //@todo harmoniser les badges Layouts/Header/RightBlock pour que le badge en haut a droite soit du même style ?
  return (
    ['LOADING', 'PENDING'].includes(currentAgencyAgencyAdminsStatus) ? <Spinner size={'lg'}/> :
      <table className={'w-full border-separate border-spacing-y-2.5'}>
        <thead>
        <tr>
          <th className={'text-left font-bold p-3 hover:cursor-pointer hover:opacity-90'} onClick={() => requestSort('email')}>
            <div className={'flex gap-4'}>
              <p>{t('email', { ns: 'common' })}</p>
              <ArrowBracketSvg width={10}
                               className={`${getArrowDirection('email')} text-primary transition-all ease-in-out duration-500`}/>
            </div>
          </th>
          <th className={'text-left font-bold p-3 hover:cursor-pointer hover:opacity-90'} onClick={() => requestSort('firstName')}>
            <div className={'flex gap-4'}>
              <p>{t('name', { ns: 'common' })}</p>
              <ArrowBracketSvg width={10}
                               className={`${getArrowDirection('firstName')} text-primary transition-all ease-in-out duration-500`}/>
            </div>
          </th>
          <th className={'text-left font-bold p-3 hover:cursor-pointer hover:opacity-90'}>{t('profile')}</th>
          <th className={'text-left font-bold p-3 hover:cursor-pointer hover:opacity-90'}>{t('managementUnits')}</th>
          <th className={'text-left font-bold p-3 hover:cursor-pointer hover:opacity-90'}></th>
        </tr>
        </thead>
        <tbody>
        {sortedList.map((agencyAdmin: AgencyAdminSettingModel, index) => {
          const managementUnitsAreEqual = compareManagementUnitLists(
            agencyAdmin.managementUnits,
            currentAgencyManagementUnits,
          );

          return (
            <tr key={index} className={'h-12 hover:cursor-pointer hover:opacity-90 bg-background rounded-br20'} onClick={() => handleUpdateAgencyAdmin(agencyAdmin)}>
              <td className={'p-3'}>{agencyAdmin.email}</td>
              <td className={'p-3'}>{agencyAdmin.firstName + ' ' + agencyAdmin.lastName.toUpperCase()}</td>
              <td className={'p-3'}>
                <span className={'bg-green text-primary px-2 py-1 rounded-lg mr-2'}>{agencyAdmin.profile.profileName}</span>
              </td>
              <td className={'p-3'}>
                {managementUnitsAreEqual ? (
                  <span className={'text-primary px-2 py-1 rounded-lg mr-2 bg-accent'}>{t('all_females', { ns: 'common' })}</span>
                ) : (
                  <>
                    {agencyAdmin.managementUnits.length > 1 ? (
                      <>
                        <span className={'bg-green text-primary px-2 py-1 rounded-lg mr-2'}>{agencyAdmin.managementUnits[0].name}</span>
                        <span className={'bg-green text-primary px-2 py-1 rounded-lg mr-2'}>+{agencyAdmin.managementUnits.length - 1}</span>
                      </>
                    ) : (
                      <span className={'bg-green text-primary px-2 py-1 rounded-lg mr-2'}>{agencyAdmin.managementUnits[0].name}</span>
                    )}
                  </>
                )}
              </td>
              <td className={'p-3'}>
                <ArrowBracketSvg width={10} />
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
  );
});

export default AgencyAdminsList;