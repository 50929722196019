import { action, makeAutoObservable } from 'mobx';

export class DashboardViewStore {
  tabNumber: number = 0;
  isAnyProvisions: boolean = false;

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setTabNumber (number: number) {
    this.tabNumber = number;
  }

  @action setIsAnyProvisions (value: boolean) {
    this.isAnyProvisions = value;
  }
}
