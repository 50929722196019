import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { SelectCountryCodeModel } from '@assets/models/specials/selectListData.model';
import { ListOfCountryCode } from '../../../assets/models/specials/listOfCountryCode';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { Select } from '../../../ui/Select';
import { InputField } from '../../../ui/Input';

export const STREET_ADDRESS = 'streetAddress';
export const ADDITIONAL_ADDRESS = 'additionalAddress';
export const POSTAL_CODE = 'postalCode';
export const CITY = 'city';
export const COUNTRY_CODE = 'countryCode';

const AddressFormComponent: FunctionFormComponent = observer(({
                                                                formik, beneficiary, isEditMode = false,
                                                                disableModification = false,
                                                              }) => {
  const { t } = useTranslation('beneficiaries');

  const listOfCountryCode: SelectCountryCodeModel[] = ListOfCountryCode;

  return (
    <div className={'space-y-2'}>

      <h3 className={'font-bold'}>
        Adresse
      </h3>

      <div className={'w-1/2'}>
        <Select
          value={formik.values[COUNTRY_CODE]}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(COUNTRY_CODE, evt.target.value)}
          labelId="label"
          id="select"
          label={'Pays de résidence'}
          disabled={disableModification}
          fullWidth
        >
          {
            listOfCountryCode.map((countryCode, index) =>
              <MenuItem key={index} value={countryCode.value}>{t(countryCode.label)}</MenuItem>,
            )
          }
        </Select>
      </div>
      <InputField
        id={STREET_ADDRESS}
        name={STREET_ADDRESS}
        label={t(STREET_ADDRESS)}
        placeholder={t(`${STREET_ADDRESS}Placeholder`)}
        disabled={disableModification}
        formik={formik}
        required
        fullWidth
      />
      <InputField
        id={ADDITIONAL_ADDRESS}
        name={ADDITIONAL_ADDRESS}
        label={t(ADDITIONAL_ADDRESS)}
        placeholder={t(`${ADDITIONAL_ADDRESS}Placeholder`)}
        disabled={disableModification}
        formik={formik}
        required
        fullWidth
      />
      <div className={'flex gap-2'}>
        <InputField
          id={POSTAL_CODE}
          name={POSTAL_CODE}
          label={t(POSTAL_CODE)}
          placeholder={t(`${POSTAL_CODE}Placeholder`)}
          disabled={disableModification}
          formik={formik}
          required
          fullWidth
        />
        <InputField
          id={CITY}
          name={CITY}
          label={t(CITY)}
          placeholder={t(`${CITY}Placeholder`)}
          disabled={disableModification}
          formik={formik}
          required
          fullWidth
        />
      </div>
    </div>
  );
}) as FunctionFormComponent;

AddressFormComponent.getValidationSchema = () => {
  return Yup.object().shape({
    [STREET_ADDRESS]: Yup.string(),
    [POSTAL_CODE]: Yup.string()
      .min(5, 'Ce champ doit être renseigné avec 5 caractères obligatoirement.')
      .max(5, 'Ce champ doit être renseigné avec 5 caractères obligatoirement.')
      .required(),
    [CITY]: Yup.string().required(),
    [COUNTRY_CODE]: Yup.string(),
  });
};

AddressFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [STREET_ADDRESS]: beneficiary?.streetAddress ?? '',
    [ADDITIONAL_ADDRESS]: beneficiary?.additionalAddress ?? '',
    [POSTAL_CODE]: beneficiary?.postalCode ?? '',
    [CITY]: beneficiary?.city ?? '',
    [COUNTRY_CODE]: beneficiary?.countryCode ?? 'FR',
  };
};

export default AddressFormComponent;