import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import StyledTextField from '../../Style/MuiStyles/TextField';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import {Button} from '../../ui/Buttons/Button';

type LocalStore = [AgenciesStore, StepperRechargingStore, OrderPaymentViewStore];

const YourInformation: FunctionComponent = observer(() => {
  const [
    agenciesStore,
    stepperRechargingStore,
    orderPaymentViewStore,
  ]: LocalStore = useInstances<LocalStore>(AgenciesStore, StepperRechargingStore, OrderPaymentViewStore);

  const {
    hasMarketAndCommitmentNumberArea,
    hasInternalOrderNumberArea,
    hasCustomerReferenceArea,
    isDissociatedPayment,
    hasFeesCustomerReferenceArea,
  } = agenciesStore;

  const {
    customerReferenceValue,
    internalOrderNumberValue,
    marketNumberValue,
    commitmentNumberValue,
    feesCustomerReferenceValue,
  } = orderPaymentViewStore.invoiceCustomMetadata;

  const { t } = useTranslation('reloading');

  return (
    <>
      <div className={'flex flex-col justify-center items-center my-4'}>
        <div className={'w-1/2 space-y-4'}>
          <h3 className={'text-center pb-5'}>{t('yourInformation.title')}</h3>

          {hasFeesCustomerReferenceArea &&
              <StyledTextField
                  id={'feesCustomerReferenceArea'}
                  name={'feesCustomerReferenceArea'}
                  label={t('yourInformation.feesCustomerReferenceArea')}
                  value={feesCustomerReferenceValue || ''}
                  onChange={(event) => orderPaymentViewStore.setInvoiceCustomMetadataItem('feesCustomerReferenceValue', event.currentTarget.value)}
                  fullWidth
              />}

          {hasCustomerReferenceArea &&
              <StyledTextField
                  id={'customerReferenceArea'}
                  name={'customerReferenceArea'}
                  label={t(`yourInformation.customerReferenceArea${isDissociatedPayment ? '_DISSOCIATED_PAYMENT' : ''}`)}
                  value={customerReferenceValue || ''}
                  onChange={(event) => orderPaymentViewStore.setInvoiceCustomMetadataItem('customerReferenceValue', event.currentTarget.value)}
                  fullWidth
              />}

          {hasInternalOrderNumberArea &&
              <StyledTextField
                  id={'internalOrderNumberArea'}
                  name={'internalOrderNumberArea'}
                  label={t('yourInformation.internalOrderNumberArea')}
                  value={internalOrderNumberValue || ''}
                  onChange={(event) => orderPaymentViewStore.setInvoiceCustomMetadataItem('internalOrderNumberValue', event.currentTarget.value)}
                  fullWidth
              />}
          {hasMarketAndCommitmentNumberArea &&
              <>
                  <StyledTextField
                      id={'marketNumberArea'}
                      name={'marketNumberArea'}
                      label={t('yourInformation.marketNumberArea')}
                      value={marketNumberValue || ''}
                      onChange={(event) => orderPaymentViewStore.setInvoiceCustomMetadataItem('marketNumberValue', event.currentTarget.value)}
                      fullWidth
                  />
                  <StyledTextField
                      id={'commitmentNumberArea'}
                      name={'commitmentNumberArea'}
                      label={t('yourInformation.commitmentNumberArea')}
                      value={commitmentNumberValue || ''}
                      onChange={(event) => orderPaymentViewStore.setInvoiceCustomMetadataItem('commitmentNumberValue', event.currentTarget.value)}
                      fullWidth
                  />
              </>}
        </div>
      </div>
      <div className={'flex justify-end mt-20 gap-4'}>
        <Button variant={'outlined'} onClick={() => stepperRechargingStore.goToPreviousStep()}>
          {t('cancel')}
        </Button>
        <Button onClick={() => stepperRechargingStore.goToNextStep()}>
          {t('nextStep')}
        </Button>
      </div>
    </>
  );
});

export default YourInformation;