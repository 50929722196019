import 'moment/locale/fr';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useCallback, useEffect, useState } from 'react';
import { provider, useInstances } from 'react-ioc';
import { BrowserRouter } from 'react-router-dom';
import { AgencyAdminStore } from './Stores/AgencyAdmin.store';
import { LoginViewStore } from './Stores/viewStore/LoginView.store';
import { UserConnectStore } from './Stores/UserConnect.store';
import AppModule from './AppModule';
import { AppAvailability } from '@assets/models/configuration/appAvailability.model';
import AppUnavailableComponent from './Component/AppUnvailable/AppUnavailableComponent';
import ChatBotComponent from './Component/ChatBot/ChatBotComponent';
import PageLoader from './Component/PageLoader/PageLoader';
import SnackErrorComponent from './Component/SnackbarError/SnackErrorComponent';
import { useFeatureFlag } from './Hooks/useFeatureFlag';
import { AgenciesStore } from './Stores/Agencies.store';
import { AgencyInvoiceStore } from './Stores/AgencyInvoice.store';
import { AppConfigStore } from './Stores/AppConfig.store';
import { BeneficiariesStore } from './Stores/Beneficiaries.store';
import { BeneficiaryPaybackStore } from './Stores/BeneficiaryPayback.store';
import { MandateStore } from './Stores/Mandate.store';
import { PayinStore } from './Stores/Payin.store';
import { ProvisionStore } from './Stores/Provision.store';
import { RechargingViewStore } from './Stores/viewStore/RechargingView.store';
import { onRefreshTokenError } from './Services/interceptors.service';
import { errorStore } from './Stores/Error.store';
import { MillesimesStore } from './Stores/Millesimes.store';
import { getAuth } from './Services/authAPI.service';
import { onAuthStateChanged, User } from 'firebase/auth';
import { BeneficiariesUploadStore } from './Stores/BeneficiariesUpload.store';
import BeneficiariesUploadTrackerComponent
  from './Component/BeneficiariesUploadTracker/BeneficiariesUploadTrackerComponent';
import { THEME_MUI } from './ui/ThemeMui';
import { Toaster } from 'ui/Toaster';

export interface IUser {
  companyId: string,
  firstName: string,
  lastName: string,
  email: string,
  role: string,
  userId: string

}

type LocalStore = [UserConnectStore, AgenciesStore, AgencyAdminStore, MandateStore, AgencyInvoiceStore, AppConfigStore, BeneficiariesUploadStore];

const App = provider(
  UserConnectStore,
  AgenciesStore,
  MandateStore,
  AgencyInvoiceStore,
  RechargingViewStore,
  BeneficiariesStore,
  AgencyAdminStore,
  PayinStore,
  BeneficiaryPaybackStore,
  ProvisionStore,
  LoginViewStore,
  AppConfigStore,
  MillesimesStore,
  BeneficiariesUploadStore,
)(observer(() => {
  const [
    userConnectStore,
    agenciesStore,
    agencyAdminStore,
    mandateStore,
    agencyInvoiceStore,
    appConfigStore,
    beneficiariesUploadStore,
  ] = useInstances<LocalStore>(UserConnectStore, AgenciesStore, AgencyAdminStore, MandateStore, AgencyInvoiceStore, AppConfigStore, BeneficiariesUploadStore);

  const isConfigReady = appConfigStore.isReady();
  const [isAuthReady, setAuthReady] = useState<boolean>(false);
  const appAvailability = useFeatureFlag<AppAvailability>('appAvailability', false);
  const isAppAvailable = appAvailability.enabled;

  const logout: () => void = useCallback(() => {
    userConnectStore.userLogout();
    agenciesStore.reset();
    agencyAdminStore.resetAgencyAdmin();
    mandateStore.reset();
    agencyInvoiceStore.reset();
  }, []);

  useEffect(() => {
    onRefreshTokenError(logout);
  }, []);

  useEffect(() => {
    moment.locale('fr');
  }, []);

  useEffect(() => {
    return onAuthStateChanged(getAuth(), (user: User) => {
      if (!!user && !userConnectStore.isConnected) {
        userConnectStore.userLogin();
      }
      if (!user && userConnectStore.isConnected) {
        logout();
      }
      if (!isAuthReady) {
        setAuthReady(true);
      }
    });
  }, [isAuthReady]);

  useEffect(() => {
    if (userConnectStore.isConnected && isAppAvailable) {
      void agencyAdminStore.getConnectedAgencyAdmin();
    }
  }, [userConnectStore.isConnected, isAppAvailable]);

  useEffect(() => {
    if (agencyAdminStore.agencyAdminStatus === 'ERROR') {
      logout();
    }
  }, [agencyAdminStore.agencyAdminStatus]);

  useEffect(() => {
    if (userConnectStore.isConnected && isAppAvailable) {
      const { agencyAdminSelected }: AgencyAdminStore = agencyAdminStore;
      if (!agencyAdminSelected) {
        return;
      }
      void agenciesStore.setConnectedAgencyAdmin(agencyAdminSelected);
    }
  }, [agencyAdminStore.agencyAdminSelected, userConnectStore.isConnected, isAppAvailable]);

  useEffect(() => {
    beneficiariesUploadStore.setContext(agencyAdminStore.agencyAdminSelected, agenciesStore.currentAgency);
    if (agencyAdminStore.agencyAdminSelected?.uid && agenciesStore.currentAgency?.uid) {
      void agencyAdminStore.getAgencyAdminSettings(agenciesStore.currentAgency.uid, agencyAdminStore.agencyAdminSelected.uid);
    }
  }, [agencyAdminStore.agencyAdminSelected, agenciesStore.currentAgency]);

  return (
    <ThemeProvider theme={THEME_MUI}>
      <CssBaseline />
      {(!isConfigReady || !isAuthReady) && <PageLoader/>}

      {isConfigReady && isAuthReady && !isAppAvailable &&
          <AppUnavailableComponent message={appAvailability.message}/>}

      {isConfigReady && isAuthReady && isAppAvailable &&
          <BrowserRouter>
              <AppModule/>
            {(errorStore.error && (errorStore.forceDisplay || userConnectStore.isConnected)) && (
              <Toaster onClose={() => errorStore.clear()}>
                <>{errorStore.messageToDisplay}</>
              </Toaster>
            )}

              <BeneficiariesUploadTrackerComponent/>

              <ChatBotComponent/>
          </BrowserRouter>
      }
    </ThemeProvider>
  );
}));

export default App;
